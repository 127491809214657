export { getTransactions } from '@multiversx/sdk-dapp/apiCalls/transactions/getTransactions';
export { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
export { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
export { logout } from '@multiversx/sdk-dapp/utils/logout';
export { signTransactions } from '@multiversx/sdk-dapp/services/transactions/signTransactions';
export { trimUsernameDomain } from '@multiversx/sdk-dapp/hooks/account/helpers';
export { newTransaction } from '@multiversx/sdk-dapp/models';

import { chainIdByEnvironment } from '@multiversx/sdk-dapp/constants/network';
import { Environment } from 'config';

export const getChainId = () => {
  return chainIdByEnvironment[Environment];
};
