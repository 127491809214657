import React, { useEffect, useState, FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Backdrop, Box, Modal, Checkbox, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InputNumber } from 'antd';
import { animated, useSpring } from 'react-spring';
import BigNumber from 'bignumber.js';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { queryFarmGetEquivalent, queryViewAllOpenedPositionIds, queryViewJewelFarmBaseContextData, queryViewJewelLendContext, queryViewPositionsByIds, queryViewPositionsSafetyContextByIds } from 'z/elrond';
import { CommonSettingsType, EsdtTokenPayment, JewelFarmBaseContext, JewelFarmBaseContextData, JewelFarmContext, JewelFarmContextData, JewelFarmPosition, JewelFarmPositionSafetyContext, JewelFarmPositionStateEnum, JewelLendPool, LoanType } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, createNftId, ERROR_CONNECT_YOUR_WALLET, ERROR_DATA_NOT_LOADED, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, formatLocalDateTime, getAshswapPositionFarmId, getFarmApy, getFarmerRewardPercent, getFarmTokenIds, getIsStablePool, getOldFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, PERCENT_DENOMINATOR, printEsdt, SECOND_TO_MILLISECONDS, sleep, toastError, YEAR_IN_HOURS } from 'z/utils';
import { isMobile } from 'react-device-detect';
import { ASH_TOKEN_ID, contracts, JEWEL_ASHSWAP_SC_ADDRESS, JEWEL_LEND_MAX_BORROWING_CAP_PERCENT, MAX_LEVERAGE, SLIPPAGE, STABLE_MAX_LEVERAGE } from 'config';
import { adjustBorrowMore, adjustPosition, closePosition, partiallyClosePosition } from 'z/elrond/sc/jewel-farm';
import { AshswapFarm, AshswapFarmCommonSetting, AshswapPosition } from 'z/types/farm';
import { MAX_1_LEVERAGE_FARM_POOL_ID } from 'data';
import { getChainId } from 'z/elrond/sdkDappHelpers';

const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 2,
    py: 2,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const PercentSlider = styled(Slider)({
    color: '#54F5B7',
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-markLabel': {
        top: '30px'
    },
    '& .MuiSlider-thumb': {
        height: 15,
        width: 15,
        backgroundColor: 'currentColor',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1,
        fontSize: 10,
        background: 'unset',
        padding: 0,
        width: 35,
        height: 35,
        color: 'black',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#54F5B7',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const sliderMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    },
];

const valueLabelFormat = (value: number) => {
    return `${value} %`;
};

interface AshswapPositionsProps {
    commonSettings: AshswapFarmCommonSetting | undefined,
    farms: AshswapFarm[],
    lendPools: JewelLendPool[],
    userTokens: any,
    openedPositions: JewelFarmPosition[],
    positionSafety: JewelFarmPositionSafetyContext[],
    ashswapTokens: any,
    isPositionsLoading: boolean,
}

export const AshswapPositions: FC<AshswapPositionsProps> = ({
    commonSettings,
    farms,
    lendPools,
    userTokens,
    openedPositions,
    positionSafety,
    ashswapTokens,
    isPositionsLoading,
}) => {
  const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
    const { address, balance, shard } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const isLoggedIn = useGetIsLoggedIn();

    const [selectedSlippage, setSelectedSlippage] = useState<number | string | null>(SLIPPAGE);
    const [showClosePositionModal, setShowClosePositionModal] = useState<boolean>(false);
    const [selectedPosition, setSelectedPosition] = useState<JewelFarmPosition | undefined>();

    const [showAdjustModal, setShowAdjustModal] = useState<boolean>(false);
    const [isBorrowMore, setIsBorrowMore] = useState<boolean>(false);
    const [tokenAmounts, setTokenAmounts] = useState<string[]>([]);
    const [borrowAmount, setBorrowAmount] = useState<string>('0');
    const [availableBorrowAmount, setAvailableBorrowAmount] = useState<string>('0');

    const [isPartiallyClose, setIsPartiallyClose] = useState<boolean>(false);
    const [partiallyClosePercent, setPartiallyClosePercent] = useState<number>(0);
    const [partiallyCloseRepayPercent, setPartiallyCloseRepayPercent] = useState<number>(0);

    const getOutputAmount = async (farmId: number, tokenIn: string, tokenOut: string) => {
        const outputAmount = await queryFarmGetEquivalent(apiAddress, farmId, tokenIn, tokenOut, new BigNumber(convertEsdtToWei(1, getTokenDecimal(tokenIn))).toFixed());
        return outputAmount;
    };

    const handleOpenClosePositionModal = (item: JewelFarmPosition) => {
        setSelectedPosition(item);
        setSelectedSlippage(SLIPPAGE);
        setIsPartiallyClose(false);
        setPartiallyClosePercent(0);
        setPartiallyCloseRepayPercent(0);
        setShowClosePositionModal(true);
    };

    const handleChangeSlippage = (value: number | string | null) => {
        setSelectedSlippage(value);
    };

    const handleClosePosition = async () => {
        if (!commonSettings) return;

        if (selectedPosition && typeof selectedSlippage === "number") {
            if (isPartiallyClose) {
                if (partiallyClosePercent <= 0) return;
                const farmPool = farms.filter((item: any) => item.farm_id === selectedPosition.position_farm_id);
                const safetyValue = positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === selectedPosition.position_id);
                if (farmPool.length > 0 && safetyValue.length > 0) {
                    const positionValue = safetyValue[0].value;
                    const debtValue = selectedPosition.position_debt_amount;
                    const equityValue = BigNumber(positionValue).minus(debtValue);
                    const minDepositToken = farmPool[0].min_deposit_base_token;
                    let minDepositAmount = farmPool[0].min_deposit_base_amount;

                    if (selectedPosition.position_debt_token !== minDepositToken) {
                        const outputAmount = await getOutputAmount(getAshswapPositionFarmId(selectedPosition.position_farm_id), minDepositToken, selectedPosition.position_debt_token);
                        minDepositAmount = convertWeiToEsdt(minDepositAmount, getTokenDecimal(minDepositToken)).multipliedBy(outputAmount).toFixed();
                    }

                    const availablePartiallyClosePercent = BigNumber(positionValue).minus(minDepositAmount).multipliedBy(100).dividedBy(positionValue).toFixed(0);
                    const maxPercent = Number(BigNumber(availablePartiallyClosePercent).minus(BigNumber(availablePartiallyClosePercent).multipliedBy(0.05)).toFixed(0));

                    if (partiallyClosePercent > maxPercent) {
                        toastError(`Can't close postion more than ${maxPercent}%`);
                        return;
                    }

                    const closeAmount = BigNumber(positionValue).multipliedBy(partiallyClosePercent).dividedBy(100);
                    const repayAmount = closeAmount.multipliedBy(partiallyCloseRepayPercent).dividedBy(100);
                    const receiveAmount = closeAmount.minus(repayAmount);

                    if (receiveAmount.gte(equityValue)) {
                        const expectedRepayPercent = 100 - Number(equityValue.multipliedBy(100).dividedBy(closeAmount).toFixed(0));
                        toastError(`Please repay more than ${expectedRepayPercent}%`);
                        return;
                    }

                    const remainedEquityAmount = equityValue.minus(receiveAmount);
                    const expectedPositionAmount = BigNumber(positionValue).minus(closeAmount);
                    const expectedLeverage = expectedPositionAmount.dividedBy(remainedEquityAmount);

                    let maxLeverage = MAX_LEVERAGE;
                    if (getIsStablePool(farmPool[0].farm_id)) {
                        maxLeverage = STABLE_MAX_LEVERAGE;
                    }

                    if (MAX_1_LEVERAGE_FARM_POOL_ID.includes(farmPool[0].farm_id)) {
                        maxLeverage = 1;
                    }

                    if (expectedLeverage.gt(maxLeverage)) {
                        let expectedRepayPercent = 100 - Number(equityValue.multipliedBy(maxLeverage).minus(expectedPositionAmount).dividedBy(positionValue).dividedBy(maxLeverage).toFixed());
                        if (expectedRepayPercent > 100) expectedRepayPercent = 100;
                        toastError(`Please repay less than ${partiallyCloseRepayPercent}%`);
                        return;
                    }

                    await partiallyClosePosition(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address, selectedPosition.position_id, partiallyClosePercent * PERCENT_DENOMINATOR, partiallyCloseRepayPercent * PERCENT_DENOMINATOR, selectedSlippage * PERCENT_DENOMINATOR);

                    setShowClosePositionModal(false);
                }
            } else {
                await closePosition(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address, selectedPosition.position_id, selectedSlippage * PERCENT_DENOMINATOR);

                setShowClosePositionModal(false);
            }
        }
    };

    const handleOpenAdjustModal = (item: JewelFarmPosition) => {
        if (!commonSettings || lendPools.length === 0) return;
        const lendPool = lendPools.filter((pool: JewelLendPool) => pool.deposit_token_id === item.position_debt_token);
        if (lendPool.length > 0) {
            let remainedAmount = BigNumber('0');
            if (BigNumber(lendPool[0].deposit_token_amount).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT).gt(lendPool[0].loan_amount)) {
                remainedAmount = BigNumber(lendPool[0].deposit_token_amount).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT).minus(lendPool[0].loan_amount);
            }

            const selectedPositionSafety = positionSafety.filter((safety: JewelFarmPositionSafetyContext) => safety.position_id === item.position_id);
            if (selectedPositionSafety.length > 0) {
                const postionAmount = selectedPositionSafety[0].value;
                const currentPositionDebtAmount = item.position_debt_amount;
                const equityValue = BigNumber(postionAmount).minus(currentPositionDebtAmount);

                let maxLeverage = MAX_LEVERAGE;
                if (getIsStablePool(farms[getAshswapPositionFarmId(item.position_farm_id) - 1].farm_id)) {
                    maxLeverage = STABLE_MAX_LEVERAGE;
                }

                if (MAX_1_LEVERAGE_FARM_POOL_ID.includes(farms[getAshswapPositionFarmId(item.position_farm_id) - 1].farm_id)) {
                    maxLeverage = 1;
                }

                const maxDebtAmount = BigNumber(equityValue).multipliedBy(maxLeverage - 1).minus(currentPositionDebtAmount).minus(convertEsdtToWei(0.01, getTokenDecimal(item.position_debt_token)));
                const liquidationAmount = BigNumber(equityValue).dividedBy(1 - selectedPositionSafety[0].liquidation_threshold / PERCENT_DENOMINATOR / 100);

                const expectedDebtAmount = BigNumber.min(maxDebtAmount, liquidationAmount);
                const borrowAvailAmount = convertWeiToEsdt(BigNumber.min(remainedAmount, expectedDebtAmount), getTokenDecimal(item.position_debt_token));
                if (borrowAvailAmount.gt('0')) {
                    setAvailableBorrowAmount(borrowAvailAmount.toFixed());
                }
            }
        }

        setSelectedPosition(item);

        const _tokenAmounts = [];
        for (let i = 0; i < farms[getAshswapPositionFarmId(item.position_farm_id) - 1].token_ids.length; i++) {
            _tokenAmounts.push('0');
        }
        setTokenAmounts(_tokenAmounts);
        setIsBorrowMore(false);
        setBorrowAmount('0');

        setSelectedSlippage(SLIPPAGE);
        setShowAdjustModal(true);

    };

    const handleOnChange = (index: number, value: string) => {
        const amounts: string[] = [];
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (index === i) {
                if (value === '') {
                    value = '0';
                }
                amounts.push(value);
            } else {
                amounts.push(tokenAmounts[i]);
            }
        }
        setTokenAmounts(amounts);
    };

    const handleMax = (index: number) => {
        if (!commonSettings || !selectedPosition) return;
        const amounts: string[] = [];
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (index === i) {
                let maxAmount = '0';
                if (getTokenTicker(farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids[i]) === 'EGLD') {
                    maxAmount = userTokens['EGLD'].balance;
                } else {
                    maxAmount = userTokens[farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids[i]].balance;
                }
                amounts.push(convertWeiToEsdt(maxAmount, getTokenDecimal(farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids[index])).toFixed());
            } else {
                amounts.push(tokenAmounts[i]);
            }
        }
        setTokenAmounts(amounts);
    };

    const handleAdjust = async () => {
        if (!commonSettings || !isLoggedIn || !selectedPosition) return;

        if (isBorrowMore) {
            // borrow more
            if (BigNumber(borrowAmount).gt(availableBorrowAmount) || BigNumber(availableBorrowAmount).lte('0')) {
                toastError(ERROR_NOT_ENOUGH_BALANCE);
                return;
            }

            const value = convertEsdtToWei(borrowAmount, getTokenDecimal(selectedPosition.position_debt_token)).toFixed();

            const result = await adjustBorrowMore(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address, selectedPosition.position_id, value, SLIPPAGE * PERCENT_DENOMINATOR);

            setShowAdjustModal(false);
        } else {
            const tokenIds: string[] = [];
            const amounts: string[] = [];

            const farmPool = farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1];
            for (let i = 0; i < tokenAmounts.length; i++) {
                if (new BigNumber(tokenAmounts[i]).gt(0)) {
                    // check balance
                    if (convertEsdtToWei(tokenAmounts[i], getTokenDecimal(farmPool.token_ids[i])).gt(getTokenTicker(farmPool.token_ids[i]) === 'EGLD' ? userTokens['EGLD'].balance : userTokens[farmPool.token_ids[i]].balance)) {
                        toastError(ERROR_NOT_ENOUGH_BALANCE);
                        return;
                    }

                    if (farmPool.token_ids[i] === 'EGLD' && new BigNumber(userTokens['EGLD'].balance).lte(EXTRA_GAS_FEE_AMOUNT)) {
                        toastError(ERROR_NOT_ENOUGH_BALANCE);
                        return;
                    }

                    tokenIds.push(farmPool.token_ids[i]);
                    const value = convertEsdtToWei(tokenAmounts[i], getTokenDecimal(farmPool.token_ids[i])).toFixed();
                    amounts.push(value);
                }
            }

            let wrapAddress = contracts.Wrap0.address;

            if (shard === 1) {
                wrapAddress = contracts.Wrap1.address;
            } else if (shard === 2) {
                wrapAddress = contracts.Wrap2.address;
            }

            const result = await adjustPosition(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address, tokenIds, amounts, selectedPosition.position_id, '0', SLIPPAGE * PERCENT_DENOMINATOR, wrapAddress);

            setShowAdjustModal(false);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'position_id',
            headerName: '#',
            width: isMobile ? 120 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => (
                <div className='py-2'>
                    {`Jewel #${params.value}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_farm_id',
            headerName: 'Pool',
            width: isMobile ? 120 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => (
                <div className='py-2'>
                    {commonSettings && farms.length >= getAshswapPositionFarmId(params.value) ? (
                        <div>
                            {getFarmTokenIds(farms[getAshswapPositionFarmId(params.value) - 1].token_ids, farms[getAshswapPositionFarmId(params.value) - 1].lp_token_id).map((item: string, key: number) => {
                                let poolName = '';
                                if (key === (getFarmTokenIds(farms[getAshswapPositionFarmId(params.value) - 1].token_ids, farms[getAshswapPositionFarmId(params.value) - 1].lp_token_id).length - 1)) {
                                    poolName += getTokenTicker(item);
                                } else {
                                    poolName += `${getTokenTicker(item)}-`;
                                }
                                return poolName;
                            })}
                        </div>
                    ) : ''}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_farm_apy',
            headerName: 'APY',
            width: isMobile ? 100 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => {
                const farmPool = farms.filter((item: AshswapFarm) => item.farm_id === params.row.position_farm_id);
                if (farmPool.length > 0) {
                    const positionLeverage = params.row.position_leverage_percent / PERCENT_DENOMINATOR / 100;
                    return <div className='py-2'>
                        {`${convertBigNumberValueToLocalString(getFarmApy(farmPool[0].apr * ((positionLeverage - 1) * 0.15 + 1), YEAR_IN_HOURS))} %`}
                    </div>;
                }
                return <div className='py-2'>-</div>;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_state',
            headerName: 'State',
            width: isMobile ? 120 : 0,
            flex: isMobile ? 0 : 2,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_value',
            headerName: 'Position Value',
            width: isMobile ? 200 : 0,
            flex: isMobile ? 0 : 4,
            renderCell: (params) => {
                const safetyValue = positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
                return <div>
                    {safetyValue.length > 0 && safetyValue[0].value && safetyValue[0].lp_token_amount ? (
                        <div className='d-flex gap-2 align-items-center'>
                            <img className='egld-badge' src={getTokenImage(params.row.position_debt_token)} alt='logo' />
                            <span>{`${printEsdt(convertWeiToEsdt(safetyValue[0].value, getTokenDecimal(params.row.position_debt_token)), getTokenDecimal(params.row.position_debt_token))} (= ${printEsdt(convertWeiToEsdt(safetyValue[0].lp_token_amount), 18)} LP)`}</span>
                        </div>
                    ) : (<></>)}
                </div>;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_debt_amount',
            headerName: 'Debt Value',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <div className='d-flex gap-2 align-items-center'>
                    <img className='egld-badge' src={getTokenImage(params.row.position_debt_token)} alt='logo' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value, getTokenDecimal(params.row.position_debt_token)))}</span>
                </div>
            ),
        },
        {
            field: 'equity_value',
            headerName: 'Equity Value',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => {
                const safetyValue = positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
                return <div>
                    {safetyValue.length > 0 && safetyValue[0].value ? (
                        <div className='d-flex gap-2 align-items-center'>
                            <img className='egld-badge' src={getTokenImage(params.row.position_debt_token)} alt='logo' />
                            <span>{printEsdt(convertWeiToEsdt(BigNumber(safetyValue[0].value).minus(params.row.position_debt_amount), getTokenDecimal(params.row.position_debt_token)), getTokenDecimal(params.row.position_debt_token))}</span>
                        </div>
                    ) : (<></>)}
                </div>;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_debt_ratio',
            headerName: 'Debt Ratio',
            width: isMobile ? 120 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => {
                const safetyValue = positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
                return <div>
                    {safetyValue.length > 0 ? (
                        <div className='d-flex gap-2 align-items-center'>
                            <span>{(safetyValue[0].debt_ratio / PERCENT_DENOMINATOR).toFixed(2)}%</span>
                        </div>
                    ) : (<></>)}
                </div>;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_liquidation_threshold',
            headerName: 'Liquidation Threshold',
            width: isMobile ? 120 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => {
                const safetyValue = positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
                return <div>
                    {safetyValue.length > 0 ? (
                        <div className='d-flex gap-2 align-items-center'>
                            <span>{(safetyValue[0].liquidation_threshold / PERCENT_DENOMINATOR).toFixed(2)}%</span>
                        </div>
                    ) : (<></>)}
                </div>;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_safety_buffer',
            headerName: 'Safety Buffer',
            width: isMobile ? 120 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => {
                const safetyValue = positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
                let safetyBuffer = 0;
                if (safetyValue[0].liquidation_threshold >= safetyValue[0].debt_ratio) {
                    safetyBuffer = safetyValue[0].liquidation_threshold - safetyValue[0].debt_ratio;
                } else {
                    safetyBuffer = safetyValue[0].debt_ratio - safetyValue[0].liquidation_threshold;
                }
                return <div>
                    {safetyValue.length > 0 ? (
                        <div className='d-flex gap-2 align-items-center'>
                            <span>{(safetyBuffer / PERCENT_DENOMINATOR).toFixed(2)}%</span>
                        </div>
                    ) : (<></>)}
                </div>;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'position_leverage_percent',
            headerName: 'Leverage Percentage',
            width: isMobile ? 100 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => (
                <div className='d-flex gap-2 align-items-center'>
                    <span>{convertBigNumberValueToLocalString(params.value / PERCENT_DENOMINATOR / 100, 2)}X</span>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'button',
            headerName: 'Action',
            width: isMobile ? 100 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='my-1'>
                    <button className='approval_but' onClick={() => handleOpenAdjustModal(params.row)}>Adjust</button>
                    <button className='approval_but mt-1' onClick={() => handleOpenClosePositionModal(params.row)}>Close</button>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
            sortable: false,
        },
    ];

    return (
        <>
            <div className='w-100' style={{ marginTop: '30px' }}>
                <DataGrid
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: 20 },
                        fontSize: '12px',
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            minHeight: '400px'
                        },
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    rows={openedPositions}
                    columns={columns}
                    getRowId={(row) => row.position_id}
                    disableColumnMenu
                    disableSelectionOnClick
                    hideFooter
                    loading={commonSettings && farms.length > 0 && !isPositionsLoading ? false : true}
                />
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showClosePositionModal}
                onClose={() => setShowClosePositionModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showClosePositionModal}>
                    <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                        <div className='d-flex mt-4 justify-content-between'>
                            <div className='d-flex align-items-center'>
                                Initial Deposit Tokens
                            </div>
                            <div className='d-flex align-items-center text-end'>
                                {selectedPosition && (
                                    <>
                                        {
                                            selectedPosition.position_deposit_payments.map((item: EsdtTokenPayment, index: number) => {
                                                return `${convertBigNumberValueToLocalString(convertWeiToEsdt(item.amount, getTokenDecimal(item.token_identifier)))} ${getTokenTicker(item.token_identifier)} ${selectedPosition.position_deposit_payments.length === (index + 1) ? '' : ' + '}`;
                                            })
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                        {/* <div className='d-flex mt-2 justify-content-between'>
                <div className='d-flex align-items-center'>
                  Initial Equity Value
                </div>
                <div className='d-flex align-items-center'>
                  {selectedPosition && (
                    <>
                      {`${convertBigNumberValueToLocalString(convertWeiToEsdt(selectedPosition.position_leverage_percent > 100 * PERCENT_DENOMINATOR ? BigNumber(selectedPosition.position_debt_amount).multipliedBy(selectedPosition.position_leverage_percent / 100 / PERCENT_DENOMINATOR - 1) : selectedPosition.position_debt_amount, getTokenDecimal(selectedPosition.position_debt_token)))} ${getTokenTicker(selectedPosition.position_debt_token)}`}
                    </>
                  )}
                </div>
              </div> */}
                        <div className='d-flex mt-2 justify-content-between'>
                            <div className='d-flex align-items-center'>
                                Current Equity Value
                            </div>
                            <div className='d-flex align-items-center text-end'>
                                {selectedPosition && (
                                    <>
                                        {`${convertBigNumberValueToLocalString(convertWeiToEsdt(positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === selectedPosition.position_id).length > 0 ? BigNumber(positionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === selectedPosition.position_id)[0].value).minus(selectedPosition.position_debt_amount) : '0', getTokenDecimal(selectedPosition.position_debt_token)))} ${getTokenTicker(selectedPosition.position_debt_token)}`}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='d-flex mt-2 justify-content-between'>
                            <div className='d-flex align-items-center'>
                                Slippage
                            </div>
                            <div className='d-flex align-items-center'>
                                <InputNumber
                                    className={`farm-leverage-input`}
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={selectedSlippage}
                                    formatter={(value) => `${value}%`}
                                    parser={(value) => value!.replace('%', '')}
                                    onChange={handleChangeSlippage}
                                />
                            </div>
                        </div>
                        <div className='d-flex mt-2'>
                            <Checkbox
                                checked={isPartiallyClose}
                                onChange={e => setIsPartiallyClose(e.target.checked)}
                                sx={{ '&': { padding: '0px', marginRight: '3px' } }}
                            />
                            <div>Partially close your position?</div>
                        </div>
                        {isPartiallyClose && (
                            <>
                                <div className='d-flex mt-2'>
                                    What percentage of position value would you like to close?
                                </div>
                                <div className='d-flex justify-content-center mt-1'>
                                    <div style={{ width: '100%', marginLeft: '10px' }}>
                                        <PercentSlider
                                            valueLabelDisplay="auto"
                                            aria-label="pretto slider"
                                            min={0}
                                            max={100}
                                            step={1}
                                            marks={sliderMarks}
                                            value={partiallyClosePercent}
                                            valueLabelFormat={valueLabelFormat}
                                            onChange={(e: any) => setPartiallyClosePercent(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <InputNumber
                                            className={`farm-leverage-input`}
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={partiallyClosePercent}
                                            formatter={(value) => `${value}%`}
                                            parser={(value: any) => value!.replace('%', '')}
                                            onChange={(value: any) => setPartiallyClosePercent(value)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex mt-2'>
                                    How much of the closed amount would you like to use for debt repayment?
                                </div>
                                <div className='d-flex justify-content-center mt-1'>
                                    <div style={{ width: '100%', marginLeft: '10px' }}>
                                        <PercentSlider
                                            valueLabelDisplay="auto"
                                            aria-label="pretto slider"
                                            min={0}
                                            max={100}
                                            step={1}
                                            marks={sliderMarks}
                                            value={partiallyCloseRepayPercent}
                                            valueLabelFormat={valueLabelFormat}
                                            onChange={(e: any) => setPartiallyCloseRepayPercent(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <InputNumber
                                            className={`farm-leverage-input`}
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={partiallyCloseRepayPercent}
                                            formatter={(value) => `${value}%`}
                                            parser={(value: any) => value!.replace('%', '')}
                                            onChange={(value: any) => setPartiallyCloseRepayPercent(value)}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {selectedPosition && commonSettings && (
                            <div className={`${isMobile ? '' : 'd-flex'} mt-4`}>
                                {farms.filter(farm => farm.farm_id === selectedPosition.position_farm_id)[0].token_ids.map((item: string, index: number) => {
                                    const farmPool = farms.filter(farm => farm.farm_id === selectedPosition.position_farm_id)[0];
                                    return <div className={`d-flex mx-1 currency-item ${isMobile ? 'mt-2' : ''}`} key={index}>
                                        {
                                            farmPool.token_ids.length === (index + 1) ? (
                                                <div className={`combine-bage ${farmPool.token_ids.length === 4 ? 'combine-bage-width' : ''}`}>
                                                    {getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((tokenId: string, i: number) => (<img key={i} src={getTokenImage(tokenId)} className='egld-image mx-2' alt={tokenId} />))}
                                                </div>
                                            ) : (
                                                <img className='egld-badge' src={getTokenImage(item)} alt={item} />
                                            )
                                        }
                                        <div className='d-flex align-items-center mx-1' style={{ fontSize: farmPool.token_ids.length === 4 ? '0.7rem' : '0.9rem' }}>
                                            {`1 ${getTokenTicker(item)} = ${ashswapTokens.data.tokens.length > 0 ? ashswapTokens.data.tokens.filter((token: any) => token.id === item)[0]['price'] : '-'} USD`}
                                        </div>
                                    </div>;
                                })}
                            </div>
                        )}
                        <div className='d-flex justify-content-center mt-4'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleClosePosition}>
                                Close Position
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            {selectedPosition && commonSettings && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={showAdjustModal}
                    onClose={() => setShowAdjustModal(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showAdjustModal}>
                        <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex h4 align-items-center'>
                                    Adjust Position
                                </div>
                                <div>
                                    <div className='d-flex'>{`Jewel #${selectedPosition.position_id}`}</div>
                                    <div className='d-flex mt-1'>
                                        {farms.length >= getAshswapPositionFarmId(selectedPosition.position_farm_id) ? (
                                            <div>
                                                {getFarmTokenIds(farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids, farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].lp_token_id).map((item: string, key: number) => {
                                                    let poolName = '';
                                                    if (key === (getFarmTokenIds(farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids, farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].lp_token_id).length - 1)) {
                                                        poolName += getTokenTicker(item);
                                                    } else {
                                                        poolName += `${getTokenTicker(item)}-`;
                                                    }
                                                    return poolName;
                                                })}
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex mt-4 h5'>
                                How much would you like to adjust your position?
                            </div>
                            <div className='d-flex my-3 h6'>
                                Collateral added will be optimally swapped into equal values of each asset and then as LP to your position.
                            </div>
                            {
                                BigNumber(availableBorrowAmount).gt('0') && (
                                    <div className='d-flex mt-4'>
                                        <Checkbox
                                            checked={isBorrowMore}
                                            onChange={e => setIsBorrowMore(e.target.checked)}
                                            sx={{ '&': { padding: '0px', marginRight: '3px' } }}
                                        />
                                        <div>Borrow more?</div>
                                    </div>
                                )
                            }
                            {!isBorrowMore && (
                                <>
                                    {farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids.map((item: string, index: number) => (
                                        <div className='mt-2 borrow-form' key={index}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                                    {
                                                        farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids.length === (index + 1) ? (
                                                            <div className={`combine-bage ${farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids.length === 4 ? 'combine-bage-width' : ''}`}>
                                                                {getFarmTokenIds(farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids, farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].lp_token_id).map((tokenId: string, i: number) => (<img key={i} src={getTokenImage(tokenId)} className='egld-image mx-2' alt={tokenId} />))}
                                                            </div>
                                                        ) : (
                                                            <img src={getTokenImage(item)} alt={item} />
                                                        )
                                                    }
                                                    <div style={{ fontSize: farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids.length === (index + 1) ? '0.7rem' : '0.9rem' }}>{getTokenTicker(item)}</div>
                                                </div>
                                                <input type="number" value={tokenAmounts[index]} onChange={(e: any) => handleOnChange(index, e.target.value)} />
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <div className='d-flex'>
                                                        <div className='d-flex align-items-center' style={{ fontSize: farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids.length === (index + 1) ? '0.7rem' : '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                                        <div>
                                                            <div style={{ fontSize: farms[getAshswapPositionFarmId(selectedPosition.position_farm_id) - 1].token_ids.length === (index + 1) ? '0.7rem' : '0.9rem' }}>{`${commonSettings ? convertBigNumberValueToLocalString(convertWeiToEsdt(getTokenTicker(item) === 'EGLD' ? userTokens['EGLD']?.balance : userTokens[item]?.balance, getTokenDecimal(item))) : '-'} ${getTokenTicker(item)}`}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                                    <button className='max-but' onClick={() => handleMax(index)}>MAX</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                            {isBorrowMore && (
                                <div className='mt-3 borrow-form'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                            <img src={getTokenImage(selectedPosition.position_debt_token)} alt={selectedPosition.position_debt_token} />
                                            <div style={{ fontSize: '0.9rem' }}>{getTokenTicker(selectedPosition.position_debt_token)}</div>
                                        </div>
                                        <input type="number" value={borrowAmount} onChange={(e: any) => setBorrowAmount(e.target.value)} />
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <div className='d-flex'>
                                                <div className='d-flex align-items-center' style={{ fontSize: '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                                <div>
                                                    <div style={{ fontSize: '0.9rem' }}>{`${convertBigNumberValueToLocalString(availableBorrowAmount)} ${getTokenTicker(selectedPosition.position_debt_token)}`}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                            <button className='max-but' onClick={() => setBorrowAmount(availableBorrowAmount)}>MAX</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='d-flex justify-content-center mt-4'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleAdjust}>
                                    Adjust
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    );
};
