import React, { useEffect, useState, useContext } from 'react';
import { useGetAccountInfo, useGetNetworkConfig, useGetIsLoggedIn, useGetPendingTransactions, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { EPunkStepper } from 'components/Elements/stepper';
import { TextField } from '@mui/material';
import { getAmmNftsByCollectionFromApi, getRarityFromXoxno } from 'z/api';
import { NftCollection, NftRarity, NftWithAmmData } from 'z/types';
import { toastError, getCollectionId } from 'z/utils';
import { PoolPrice, PoolAsset, PoolActionType } from 'components/Pool';
import { createPool } from 'z/elrond';
import { contracts } from 'config';
import { Spinner } from 'components/Spinner';
import BigNumber from 'bignumber.js';
import { AmmCollectionContext } from 'App';
import { CollectionsTab } from 'components/CollectionsTab';
import { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { AMM_COLLECTION_IDS } from 'data';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { CollectionLogo } from 'components/CollectionLogo';

const steps = ['Select NFT Collection', 'Select Pool Type', 'Pool Settings'];

export const CreatePool = () => {
    const navigate = useNavigate();
    const { address, shard } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();

    const lastPath = window.location.pathname;
    const nftCollectionId = lastPath.split('/')[2];

    const { isLoading, ammCollections, isChanged, setIsChanged } = useContext(AmmCollectionContext);

    const [tab, setTab] = useState<number>(0);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [poolType, setPoolType] = useState<number>(2);
    const [isNftLoading, setIsNftLoading] = useState<boolean>(false);
    const [nfts, setNfts] = useState<NftWithAmmData[]>([]);
    const [collectionId, setCollectionId] = useState<string>('');

    const [rarity, setRarity] = useState<NftRarity[]>([]);

    useEffect(() => {
        if (nftCollectionId) {
            setCollectionId(nftCollectionId.replaceAll('?', ''));
            setActiveStep(1);
        }
    }, [nftCollectionId]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            if (activeStep === 1 && collectionId && collectionId.length > 0) {
                if (!AMM_COLLECTION_IDS.includes(collectionId)) {
                    toastError('not available NFT collection id');
                    setActiveStep(activeStep - 1);
                    return;
                }
                setIsNftLoading(true);
                const _nfts = await getAmmNftsByCollectionFromApi(apiAddress, address, collectionId, false);
                setNfts(_nfts);
                
                const _rarity: NftRarity[] = await getRarityFromXoxno(collectionId);
                // console.log('_rarity: ', _rarity);
                setRarity(_rarity);
                setIsNftLoading(false);
            }
        })();
    }, [hasPendingTransactions, activeStep]);

    const [filterCollections, setFilterCollections] = useState<NftCollection[]>(ammCollections);
    useEffect(() => {
        setFilterCollections(ammCollections);
    }, [ammCollections]);

    const handleOnChange = (e: any) => {
        const id = getCollectionId(e.target.value);
        const items = ammCollections.filter((collection: NftCollection) => { return collection.nft_token_id.includes(id); });
        setFilterCollections(items);
        setCollectionId(id);
    };

    const onClickNextButton = () => {
        if (activeStep === 2) {
            return;
        }

        if (collectionId == '') {
            toastError('You must input corrected NFT identifier');
            return;
        }

        if (isLoggedIn) {
            setActiveStep(activeStep + 1);
        }
    };

    const onClickPrevButton = () => {
        if (activeStep === 0) {
            return;
        }
        setActiveStep(activeStep - 1);
    };

    const handlePoolType = (type: number) => {
        setPoolType(type);
        setActiveStep(2);
    };

    const [fee, setFee] = useState<number>(0);
    const [spotPrice, setSpotPrice] = useState<number>(0);
    const [delta, setDelta] = useState<number>(0);
    const [ordersAmount, setOrdersAmount] = useState<number>(0);
    const [lockedAmount, setLockedAmount] = useState<BigNumber>(new BigNumber(0));

    const [addNfts, setAddNfts] = useState<NftWithAmmData[]>([]);

    const handleCreatePool = async () => {
        if (nfts.length == 0) {
            return;
        }

        if (!spotPrice || spotPrice == 0) {
            toastError('Please input spot price');
            return;
        }

        if (poolType != 2 && addNfts.length == 0) {
            toastError('Please add nfts');
            return;
        }

        let wrapAddress = contracts.Wrap0.address;

        if (shard === 1) {
            wrapAddress = contracts.Wrap1.address;
        } else if (shard === 2) {
            wrapAddress = contracts.Wrap2.address;
        }

        const result = await createPool(address, wrapAddress, collectionId, poolType, ordersAmount, spotPrice, delta, lockedAmount, tab, fee, addNfts);
        setSessionId(result.sessionId);
    };

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('handleCreatePool: success');
            setIsChanged(!isChanged);
            setAddNfts([]);
            navigate(routeNames.mypools);
        }
    }, [sessionId, hasPendingTransactions]);

    return (
        <div className='container-fluid amm-container' style={{ marginTop: '40px' }}>
            {isNftLoading ? (
                <Spinner />
            ) : (
                <>
                    <div className='row'>
                        <div className='col-12 mt-5 text-center h2'>
                            Create Pool
                        </div>
                    </div>
                    {!isLoggedIn ? (
                        <div className='row'>
                            <div className='col-12 mt-3 text-center h4'>
                                Please Connect Wallet
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12 mt-3 text-center h4'>
                                        Create Liquidity Pool and Earn Trading Fees
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <EPunkStepper steps={steps} activeStep={activeStep} />
                                    </div>
                                    <div className='col-12 d-flex justify-content-end gap-3 mt-4'>
                                        {
                                            activeStep == 0 ? (
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickNextButton}>
                                                    {'Next >'}
                                                </div>
                                            ) : (
                                                activeStep == 1 ? (
                                                    <div className='d-flex w-100 justify-content-between'>
                                                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickPrevButton}>
                                                            {'< Prev'}
                                                        </div>
                                                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickNextButton}>
                                                            {'Next >'}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickPrevButton}>
                                                        {'< Prev'}
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {activeStep == 0 ? (
                                <div className='container'>
                                    <div className='row mt-5'>
                                        <div className='col-lg-2 col-md-2 col-sm-12'></div>
                                        <div className='col-lg-8 col-md-8 col-sm-12'>
                                            <TextField
                                                fullWidth
                                                label='Search by identifier'
                                                InputProps={{
                                                    type: 'search',
                                                    style: { color: '#37b06f' },
                                                    startAdornment: <InputAdornment position='start'>
                                                        <IconButton
                                                            aria-label='search'
                                                            edge='end'
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                                value={collectionId}
                                                onChange={handleOnChange}
                                            />
                                            {/*<TextField*/}
                                            {/*    fullWidth*/}
                                            {/*    label=' NFT collection identifier'*/}
                                            {/*    InputProps={{*/}
                                            {/*        type: 'search',*/}
                                            {/*    }}*/}
                                            {/*    value={collectionId}*/}
                                            {/*    onChange={handleOnChange}*/}
                                            {/*/>*/}
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-12'></div>
                                    </div>
                                    <CollectionsTab collections={filterCollections} isLoading={isLoading} type='create' />
                                </div>
                            ) : (
                                activeStep == 1 ? (
                                    <>
                                        <div className='container'>
                                            <div className='row mt-2'>
                                                <div className='col-lg-4 col-md-4 col-sm-12 mt-3'>
                                                    <div className={poolType == 2 ? 'amm-create-pool-type amm-create-pool-type-active' : 'amm-create-pool-type'} onClick={() => handlePoolType(2)}>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <img src={egld_white_logo} className='egld-image mx-2' alt='egld-logo'></img>
                                                            <svg
                                                                width="33"
                                                                height="32"
                                                                viewBox="0 0 33 32"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M4.5 15.6667C4.5 16.403 5.09695 17 5.83333 17H23.9477L20.8905 20.0572C20.3698 20.5779 20.3698 21.4221 20.8905 21.9428C21.4112 22.4635 22.2554 22.4635 22.7761 21.9428L28.1095 16.6095C28.6302 16.0888 28.6302 15.2446 28.1095 14.7239L22.7761 9.39052C22.2554 8.86983 21.4112 8.86983 20.8905 9.39052C20.3698 9.91122 20.3698 10.7554 20.8905 11.2761L23.9477 14.3333H5.83333C5.09695 14.3333 4.5 14.9303 4.5 15.6667Z"
                                                                    fill="#E3E2E5"
                                                                />
                                                            </svg>
                                                            <CollectionLogo className='collection-image mx-2' collectionId={collectionId} />
                                                        </div>
                                                        <div className='mt-3'>Buy NFTs with EGLD</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-sm-12 mt-3'>
                                                    <div className={poolType == 1 ? 'amm-create-pool-type amm-create-pool-type-active' : 'amm-create-pool-type'} onClick={() => handlePoolType(1)}>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <CollectionLogo className='collection-image mx-2' collectionId={collectionId} />
                                                            <svg
                                                                width="33"
                                                                height="32"
                                                                viewBox="0 0 33 32"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M4.5 15.6667C4.5 16.403 5.09695 17 5.83333 17H23.9477L20.8905 20.0572C20.3698 20.5779 20.3698 21.4221 20.8905 21.9428C21.4112 22.4635 22.2554 22.4635 22.7761 21.9428L28.1095 16.6095C28.6302 16.0888 28.6302 15.2446 28.1095 14.7239L22.7761 9.39052C22.2554 8.86983 21.4112 8.86983 20.8905 9.39052C20.3698 9.91122 20.3698 10.7554 20.8905 11.2761L23.9477 14.3333H5.83333C5.09695 14.3333 4.5 14.9303 4.5 15.6667Z"
                                                                    fill="#E3E2E5"
                                                                />
                                                            </svg>
                                                            <img src={egld_white_logo} className='egld-image mx-2' alt='egld-logo'></img>
                                                        </div>
                                                        <div className='mt-3'>Sell NFTs for EGLD</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-sm-12 mt-3'>
                                                    <div className={poolType == 0 ? 'amm-create-pool-type amm-create-pool-type-active' : 'amm-create-pool-type'} onClick={() => handlePoolType(0)}>
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <div className='combine-bage'>
                                                                <img src={egld_white_logo} className='egld-image mx-2' alt='egld-logo'></img>
                                                                <CollectionLogo className='collection-image mx-2' collectionId={collectionId} />
                                                            </div>
                                                            <svg
                                                                width="32"
                                                                height="32"
                                                                viewBox="0 0 32 32"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M4 9.33366C4 10.07 4.59695 10.667 5.33333 10.667H23.4477L20.3905 13.7242C19.8698 14.2449 19.8698 15.0891 20.3905 15.6098C20.9112 16.1305 21.7554 16.1305 22.2761 15.6098L27.6095 10.2765C28.1302 9.75577 28.1302 8.91155 27.6095 8.39085L22.2761 3.05752C21.7554 2.53682 20.9112 2.53682 20.3905 3.05752C19.8698 3.57822 19.8698 4.42244 20.3905 4.94313L23.4477 8.00033H5.33333C4.59695 8.00033 4 8.59728 4 9.33366ZM4.39052 21.7242C3.86983 22.2449 3.86983 23.0891 4.39052 23.6098L9.72386 28.9431C10.2446 29.4638 11.0888 29.4638 11.6095 28.9431C12.1302 28.4224 12.1302 27.5782 11.6095 27.0575L8.55228 24.0003H26.6667C27.403 24.0003 28 23.4034 28 22.667C28 21.9306 27.403 21.3337 26.6667 21.3337H8.55228L11.6095 18.2765C12.1302 17.7558 12.1302 16.9115 11.6095 16.3908C11.0888 15.8701 10.2446 15.8701 9.72386 16.3908L4.39052 21.7242Z"
                                                                    fill="#E3E2E5"
                                                                />
                                                            </svg>
                                                            <div className='tradingBadge'>
                                                                <span>trading fees</span>
                                                            </div>
                                                        </div>
                                                        <div className='mt-3'>Do both and earn trading fees</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='row p-4'>
                                            <div className='col-lg-5 col-md-5 col-sm-12 mb-3'>
                                                <PoolPrice collectionId={collectionId} poolType={poolType} fee={fee} setFee={setFee} spotPrice={spotPrice} setSpotPrice={setSpotPrice} tab={tab} setTab={setTab} delta={delta} setDelta={setDelta} ordersAmount={ordersAmount} setOrdersAmount={setOrdersAmount} lockedAmount={lockedAmount} setLockedAmount={setLockedAmount} actionType={PoolActionType.PoolCreate} />
                                            </div>
                                            <div className='col-lg-7 col-md-7 col-sm-12 mb-3'>
                                                <PoolAsset collectionId={collectionId} poolType={poolType} ordersAmount={ordersAmount} setOrdersAmount={setOrdersAmount} userNfts={nfts} actionType={PoolActionType.PoolCreate} setAddNfts={setAddNfts} rarity={rarity} />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleCreatePool}>
                                                    Create Pool
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
