import React, { useEffect, useState, FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Backdrop, Box, Modal, Checkbox, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InputNumber } from 'antd';
import { animated, useSpring } from 'react-spring';
import BigNumber from 'bignumber.js';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { closeJewelFlashMintPosition, closeJewelLpFarmPosition, queryFarmGetEquivalent, queryViewAllOpenedPositionIds, queryViewJewelFarmBaseContextData, queryViewJewelLendContext, queryViewPositionsByIds, queryViewPositionsSafetyContextByIds } from 'z/elrond';
import { AshswapPool, CommonSettingsType, EsdtTokenPayment, JewelFarmBaseContext, JewelFarmBaseContextData, JewelFarmContext, JewelFarmContextData, JewelFarmPosition, JewelFarmPositionSafetyContext, JewelFarmPositionStateEnum, JewelFlashMintCommonContext, JewelFlashMintFarmContext, JewelFlashMintPositionContext, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelHatomPostionContext, JewelLendPool, JewelLpFarmContext, JewelswapFarm, LoanType } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, createNftId, ERROR_CONNECT_YOUR_WALLET, ERROR_DATA_NOT_LOADED, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, formatLocalDateTime, getAshswapPoolApr, getAshswapPoolTradingApr, getFarmApy, getFarmerRewardPercent, getFarmTokenIds, getIsStablePool, getJewelFlashMintHatomPoolApy, getJewelHatomPoolApy, getOldFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, PERCENT_DENOMINATOR, SECOND_TO_MILLISECONDS, sleep, toastError, YEAR_IN_HOURS } from 'z/utils';
import { isMobile } from 'react-device-detect';
import { ASH_TOKEN_ID, contracts, JEWEL_ASHSWAP_SC_ADDRESS, MAX_LEVERAGE, SLIPPAGE, STABLE_MAX_LEVERAGE } from 'config';
import { adjustBorrowMore, adjustPosition, closePosition, partiallyClosePosition } from 'z/elrond/sc/jewel-farm';
import { getChainId } from 'z/elrond/sdkDappHelpers';

const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 2,
  py: 2,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

interface JewelPositionsProps {
  commonSettings: JewelFlashMintCommonContext | undefined,
  farms: JewelFlashMintFarmContext[],
  jewelswapFarms: JewelswapFarm[],
  lpFarms: JewelLpFarmContext[];
  liquidStakeApy: string,
  hatomMoneyMarkets: JewelHatomMoneyMarket[],
  hatomFormattedRewards: any[],
  isPositionsLoading: boolean,
  openedPositions: JewelFlashMintPositionContext[],
  jewelLpFarmPositionSafety: JewelFarmPositionSafetyContext[],
}

export const JewelPositions: FC<JewelPositionsProps> = ({
  commonSettings,
  farms,
  lpFarms,
  jewelswapFarms,
  liquidStakeApy,
  hatomMoneyMarkets,
  hatomFormattedRewards,
  isPositionsLoading,
  openedPositions,
  jewelLpFarmPositionSafety,
}) => {
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address, balance, shard } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  const [showClosePositionModal, setShowClosePositionModal] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<JewelFlashMintPositionContext | undefined>();
  const [selectedFarm, setSelectedFarm] = useState<any>();
  const handleClosePosition = async () => {
    if (selectedPosition) {
      if (!selectedPosition.is_lp_position) {
        // close position
        await closeJewelFlashMintPosition(chainID, address, selectedPosition.position_id);
        setShowClosePositionModal(false);
      } else {
        // close lp position
        await closeJewelLpFarmPosition(chainID, address, selectedPosition.position_id);
        setShowClosePositionModal(false);
      }
    }
  };

  const handleOpenClosePositionModal = (item: JewelFlashMintPositionContext) => {
    setSelectedPosition(item);
    if (!item.is_lp_position) {
      const farm = farms.filter((v: JewelFlashMintFarmContext) => v.farm_id === item.position_farm_id);
      if (farm.length > 0) {
        setSelectedFarm(farm[0]);

        setShowClosePositionModal(true);
      }
    } else {
      const farm = lpFarms.filter((v: JewelLpFarmContext) => v.lp_farm_id === item.position_farm_id);
      if (farm.length > 0) {
        setSelectedFarm(farm[0]);

        setShowClosePositionModal(true);
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'position_id',
      headerName: '#',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => (
        <div className='py-2'>
          {`Jewel #${params.row.index}`}
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_farm_id',
      headerName: 'Pool',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        if (!params.row.is_lp_position) {
          const farm = farms.filter((v: JewelFlashMintFarmContext) => v.farm_id === params.row.position_farm_id);
          return <div className='py-2 text-center'>
            {farm.length > 0 ? getTokenTicker(farm[0].farm_id === 1 ? farm[0].farm_deposit_token_id : farm[0].farm_base_token_id) : 'EGLD'}
          </div>;
        } else {
          const farm = lpFarms.filter((v: JewelLpFarmContext) => v.lp_farm_id === params.row.position_farm_id);
          return <div className='py-2 text-center'>
            {farm.length > 0 ? farm[0].token_ids.length > 2 ? `${getTokenTicker(farm[0].token_ids[0])}-${getTokenTicker(farm[0].token_ids[1])}-${getTokenTicker(farm[0].token_ids[2])}` : `${getTokenTicker(farm[0].token_ids[0])}-${getTokenTicker(farm[0].token_ids[1])}` : 'EGLD'}
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_farm_apy',
      headerName: 'APY',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        if (!params.row.is_lp_position) {
          const farm = farms.filter((v: JewelFlashMintFarmContext) => v.farm_id === params.row.position_farm_id);
          return <div className='py-2'>{farm.length > 0 && commonSettings ? `${farm[0].farm_id === 1 ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(liquidStakeApy).multipliedBy(1 - commonSettings.protocol_fee_percent / PERCENT_DENOMINATOR / 100)).toNumber() * params.row.position_leverage_percent / PERCENT_DENOMINATOR / 100) : convertBigNumberValueToLocalString(getJewelFlashMintHatomPoolApy(farm[0].farm_hatom_money_market_sc_address, farm[0].farm_base_token_id, hatomMoneyMarkets, hatomFormattedRewards, commonSettings.protocol_fee_percent) * params.row.position_leverage_percent / PERCENT_DENOMINATOR / 100)}%` : '-'}</div>;
        } else {
          try {
            const farm = jewelswapFarms.filter((v: JewelswapFarm) => v.farm_id === params.row.position_farm_id);
            return <div className='py-2'>{farm.length > 0 ? `${convertBigNumberValueToLocalString(getFarmApy(farm[0].apr, YEAR_IN_HOURS) * params.row.position_leverage_percent / PERCENT_DENOMINATOR / 100)}%` : '-'}</div>;
          } catch (error) {
            return <div className='py-2'>-</div>;
          }
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_state',
      headerName: 'State',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_value',
      headerName: 'Position Value',
      width: isMobile ? 200 : 0,
      flex: isMobile ? 0 : 4,
      renderCell: (params) => {
        if (!params.row.is_lp_position) {
          const farm = farms.filter((v: JewelFlashMintFarmContext) => v.farm_id === params.row.position_farm_id);
          if (farm.length > 0) {
            const positionValue = BigNumber(farm[0].farm_base_supply_amount).dividedBy(BigNumber(farm[0].farm_share).dividedBy(params.row.position_farm_share));
            return <div className='d-flex gap-2 align-items-center'>
              <img className='egld-badge' src={getTokenImage(params.row.position_farm_id === 1 ? 'EGLD' : params.row.position_deposit_payment.token_identifier)} alt='logo' />
              <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(positionValue, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))}</span>
            </div>;
          } else {
            return <div className='d-flex gap-2 align-items-center'>
              <img className='egld-badge' src={getTokenImage(params.row.position_farm_id === 1 ? 'EGLD' : params.row.position_deposit_payment.token_identifier)} alt='logo' />
              <span>-</span>
            </div>;
          }
        } else {
          const farm = lpFarms.filter((v: JewelLpFarmContext) => v.lp_farm_id === params.row.position_farm_id);
          const safetyValue = jewelLpFarmPositionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
          const positionValue = safetyValue.length > 0 ? safetyValue[0].value : '0';
          return <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(farm[0].lp_farm_base_token_id)} alt='logo' />
            <span>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(positionValue, getTokenDecimal(farm[0].lp_farm_base_token_id)))} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(safetyValue[0].lp_token_amount))} LP)`}</span>
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_debt_amount',
      headerName: 'Debt Value',
      width: isMobile ? 200 : 0,
      flex: isMobile ? 0 : 5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.row.is_lp_position) {
          const farm = farms.filter((v: JewelFlashMintFarmContext) => v.farm_id === params.row.position_farm_id);
          if (farm.length > 0) {
            let debtValue = BigNumber('0');
            if (BigNumber(farm[0].farm_id <= 2 ? farm[0].farm_jwlegld_borrow_amount : farm[0].farm_jwlusd_borrow_amount).gt('0')) {
              debtValue = BigNumber(farm[0].farm_base_borrow_amount).dividedBy(BigNumber(farm[0].farm_id <= 2 ? farm[0].farm_jwlegld_borrow_amount : farm[0].farm_jwlusd_borrow_amount).dividedBy(params.row.position_debt_amount));
            }
            return <div className='d-flex gap-2 align-items-center'>
              <img className='egld-badge' src={getTokenImage(params.row.position_farm_id === 1 ? 'EGLD' : params.row.position_deposit_payment.token_identifier)} alt='logo' />
              <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(debtValue, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))}</span>
            </div>;
          } else {
            return <div className='d-flex gap-2 align-items-center'>
              <img className='egld-badge' src={getTokenImage(params.row.position_farm_id === 1 ? 'EGLD' : params.row.position_deposit_payment.token_identifier)} alt='logo' />
              <span>-</span>
            </div>;
          }
        } else {
          const farm = lpFarms.filter((v: JewelLpFarmContext) => v.lp_farm_id === params.row.position_farm_id);
          return <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(farm[0].lp_farm_debt_token_id)} alt='logo' />
            <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.row.position_debt_amount, getTokenDecimal(farm[0].lp_farm_debt_token_id)))}</span>
          </div>;
        }
      },
    },
    {
      field: 'deposit_value',
      headerName: 'Deposit Value',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        if (!params.row.is_lp_position) {
          return <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(params.row.position_farm_id === 1 ? 'EGLD' : params.row.position_deposit_payment.token_identifier)} alt='logo' />
            <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.row.position_deposit_payment.amount, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))}</span>
          </div>;
        } else {
          const farm = lpFarms.filter((v: JewelLpFarmContext) => v.lp_farm_id === params.row.position_farm_id);
          return <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(farm[0].lp_farm_base_token_id)} alt='logo' />
            <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.row.lp_position_deposit_value, getTokenDecimal(farm[0].lp_farm_base_token_id)))}</span>
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_debt_ratio',
      headerName: 'Debt Ratio',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        if (!params.row.is_lp_position) {
          const farm = farms.filter((v: JewelFlashMintFarmContext) => v.farm_id === params.row.position_farm_id);
          if (farm.length > 0) {
            const positionValue = BigNumber(farm[0].farm_base_supply_amount).dividedBy(BigNumber(farm[0].farm_share).dividedBy(params.row.position_farm_share));
            let debtValue = BigNumber('0');
            if (BigNumber(farm[0].farm_id <= 2 ? farm[0].farm_jwlegld_borrow_amount : farm[0].farm_jwlusd_borrow_amount).gt('0')) {
              debtValue = BigNumber(farm[0].farm_base_borrow_amount).dividedBy(BigNumber(farm[0].farm_id <= 2 ? farm[0].farm_jwlegld_borrow_amount : farm[0].farm_jwlusd_borrow_amount).dividedBy(params.row.position_debt_amount));
            }
            const debtRatio = debtValue.multipliedBy(100).dividedBy(positionValue);
            return <div className='d-flex gap-2 align-items-center'>
              <span>{convertBigNumberValueToLocalString(debtRatio)}%</span>
            </div>;
          } else {
            return <div className='d-flex gap-2 align-items-center'>
              <span>-%</span>
            </div>;
          }
        } else {
          const farm = lpFarms.filter((v: JewelLpFarmContext) => v.lp_farm_id === params.row.position_farm_id);
          const safetyValue = jewelLpFarmPositionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
          const positionValue = safetyValue.length > 0 ? safetyValue[0].value : '0';
          const debtRatio = convertWeiToEsdt(params.row.position_debt_amount, getTokenDecimal(farm[0].lp_farm_debt_token_id)).multipliedBy(100).dividedBy(convertWeiToEsdt(positionValue, getTokenDecimal(farm[0].lp_farm_base_token_id)));
          return <div className='d-flex gap-2 align-items-center'>
            <span>{convertBigNumberValueToLocalString(debtRatio)}%</span>
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_liquidation_threshold',
      headerName: 'Liquidation Threshold',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        return <div className='d-flex gap-2 align-items-center'>
          <span>90%</span>
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_safety_buffer',
      headerName: 'Safety Buffer',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        if (!params.row.is_lp_position) {
          const farm = farms.filter((v: JewelFlashMintFarmContext) => v.farm_id === params.row.position_farm_id);
          if (farm.length > 0) {
            const positionValue = BigNumber(farm[0].farm_base_supply_amount).dividedBy(BigNumber(farm[0].farm_share).dividedBy(params.row.position_farm_share));
            let debtValue = BigNumber('0');
            if (BigNumber(farm[0].farm_id <= 2 ? farm[0].farm_jwlegld_borrow_amount : farm[0].farm_jwlusd_borrow_amount).gt('0')) {
              debtValue = BigNumber(farm[0].farm_base_borrow_amount).dividedBy(BigNumber(farm[0].farm_id <= 2 ? farm[0].farm_jwlegld_borrow_amount : farm[0].farm_jwlusd_borrow_amount).dividedBy(params.row.position_debt_amount));
            }
            const debtRatio = debtValue.multipliedBy(100).dividedBy(positionValue);
            return <div className='d-flex gap-2 align-items-center'>
              <span>{convertBigNumberValueToLocalString(BigNumber(90).minus(debtRatio))}%</span>
            </div>;
          } else {
            return <div className='d-flex gap-2 align-items-center'>
              <span>-%</span>
            </div>;
          }
        } else {
          const farm = lpFarms.filter((v: JewelLpFarmContext) => v.lp_farm_id === params.row.position_farm_id);
          const safetyValue = jewelLpFarmPositionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === params.row.position_id);
          const positionValue = safetyValue.length > 0 ? safetyValue[0].value : '0';
          const debtRatio = convertWeiToEsdt(params.row.position_debt_amount, getTokenDecimal(farm[0].lp_farm_debt_token_id)).multipliedBy(100).dividedBy(convertWeiToEsdt(positionValue, getTokenDecimal(farm[0].lp_farm_base_token_id)));
          return <div className='d-flex gap-2 align-items-center'>
            <span>{convertBigNumberValueToLocalString(BigNumber(90).minus(debtRatio))}%</span>
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_leverage_percent',
      headerName: 'Leverage Percentage',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        <div className='d-flex gap-2 align-items-center'>
          <span>{`${params.value / PERCENT_DENOMINATOR / 100}X`}</span>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'button',
      headerName: 'Action',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => (
        <div className='my-1'>
          <button className='approval_but' onClick={() => handleOpenClosePositionModal(params.row)}>Close</button>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  return (
    <>
      <div className='w-100' style={{ marginTop: '30px' }}>
        <DataGrid
          sx={{
            '& .MuiSvgIcon-root': { fontSize: 20 },
            fontSize: '12px',
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'hidden',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '400px'
            },
          }}
          autoHeight
          getRowHeight={() => 'auto'}
          rows={openedPositions}
          columns={columns}
          getRowId={(row) => row.position_id}
          disableColumnMenu
          disableSelectionOnClick
          hideFooter
          loading={isPositionsLoading}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showClosePositionModal}
        onClose={() => setShowClosePositionModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showClosePositionModal}>
          <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
            {
              selectedPosition && selectedFarm && selectedPosition.is_lp_position && (
                getTokenTicker(selectedFarm.lp_farm_debt_token_id) === 'JWLEGLD' ? (
                  <div className='d-flex'>
                    Please take note that when closing positions, LPs are split into their respective tokens, swapped into JWLEGLD, repay JWLEGLD and balance JWLEGLD repaid to the user.
                  </div>
                ) : (
                  <div className='d-flex'>
                    Please take note that when closing positions, LPs are split into their respective tokens, swapped into JWLUSD, repay JWLUSD and balance JWLUSD repaid to the user.
                  </div>
                )
              )
            }
            <div className='d-flex mt-4 justify-content-between gap-5'>
              <div className='d-flex align-items-center'>
                Initial Deposit Tokens
              </div>
              <div className='d-flex align-items-center text-end'>
                {selectedPosition ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(selectedPosition.position_deposit_payment.amount, getTokenDecimal(selectedPosition.position_deposit_payment.token_identifier)))} ${getTokenTicker(selectedPosition.position_deposit_payment.token_identifier)}` : '-'}
              </div>
            </div>
            <div className='d-flex mt-2 justify-content-between gap-5'>
              <div className='d-flex align-items-center'>
                Current Position Value
              </div>
              {
                selectedPosition && (
                  !selectedPosition.is_lp_position ? (
                    <div className='d-flex align-items-center text-end'>
                      {selectedPosition && selectedFarm ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(selectedFarm.farm_base_supply_amount).dividedBy(BigNumber(selectedFarm.farm_share).dividedBy(selectedPosition.position_farm_share)), getTokenDecimal(selectedPosition.position_deposit_payment.token_identifier)))} ${getTokenTicker(selectedPosition.position_deposit_payment.token_identifier)}` : '-'}
                    </div>
                  ) : (
                    <div className='d-flex align-items-center text-end'>
                      {selectedPosition && selectedFarm ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelLpFarmPositionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === selectedPosition.position_id).length > 0 ? BigNumber(jewelLpFarmPositionSafety.filter((item: JewelFarmPositionSafetyContext) => item.position_id === selectedPosition.position_id)[0].value) : '0', getTokenDecimal(selectedFarm.lp_farm_base_token_id)))} ${getTokenTicker(selectedFarm.lp_farm_base_token_id)}` : '-'}
                    </div>
                  )
                )
              }
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleClosePosition}>
                Close Position
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
