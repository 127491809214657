import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, Modal, Slider } from '@mui/material';
import { useGetAccount, useGetNetworkConfig, useGetIsLoggedIn, useTrackTransactionStatus, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from '@multiversx/sdk-network-providers/node_modules/bignumber.js/bignumber';
import { styled } from '@mui/material/styles';
import { InputNumber } from 'antd';
import Dropdown from 'react-bootstrap/Dropdown';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import './index.scss';
import { animated, useSpring } from 'react-spring';
import { JewelFarmBaseContextData, JewelFarmBaseContext, JewelFarmContextData, JewelFarmContext, JewelLendPool, NftWithIndexType, AshswapPool } from 'z/types';
import { ASH_LP_DECIMALS, convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, ERROR_CONNECT_YOUR_WALLET, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, FARM_MAX_PERCENT, getApy, getAshswapPoolApr, getAshswapPoolTradingApr, getFarmApy, getFarmerRewardPercent, getFarmTokenIds, getIsStablePool, getOldFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, parseBigNumber, PERCENT_DENOMINATOR, toastError, YEAR_IN_HOURS, YEAR_IN_SECONDS } from 'z/utils';
import { ASH_TOKEN_ID, AUTO_COMPOUND_CRON_JOB_TIME, contracts, isDev, JEWEL_ASHSWAP_SC_ADDRESS, JEWEL_LEND_MAX_BORROWING_CAP_PERCENT, LEND_GAS_LIMIT, MAX_GAS_LIMIT, MAX_LEVERAGE, SLIPPAGE, STABLE_MAX_LEVERAGE } from 'config';
import { openPosition, wrapEgld } from 'z/elrond/sc/jewel-farm';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { queryFarmGetEquivalent, queryViewJewelFarmData } from 'z/elrond';
import { routeNames } from 'routes';
import { width } from '@mui/system';
import { FARM_POOL_TOKENS } from 'data';
import { getChainId } from 'z/elrond/sdkDappHelpers';

const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const LeverageSlider = styled(Slider)({
    color: '#54F5B7',
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-markLabel': {
        top: '30px'
    },
    '& .MuiSlider-thumb': {
        height: 15,
        width: 15,
        backgroundColor: 'currentColor',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1,
        fontSize: 10,
        background: 'unset',
        padding: 0,
        width: 35,
        height: 35,
        color: 'black',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#54F5B7',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const valueLabelFormat = (value: number) => {
    if (value === 1) {
        return `${value} X`;
    }
    return `${value} X`;
};

interface valueMark {
    value: number,
    label: string,
}

// interface FarmPoolProps {
//     commonSettings: JewelFarmBaseContextData | undefined,
//     farmPool: JewelFarmContextData,
//     lendPools: JewelLendPool[],
// }

interface FarmPoolProps {
    ashswapPools: AshswapPool[]
    commonSettings: JewelFarmBaseContext | undefined,
    farm: JewelFarmContext,
    lendPools: JewelLendPool[],
    userTokens: any,
    farmIndex: number,
    poolName: string,
}

export const FarmPool: FC<FarmPoolProps> = ({
    ashswapPools,
    commonSettings,
    farm,
    lendPools,
    userTokens,
    farmIndex,
    poolName,
}) => {
    const navigate = useNavigate();
  const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
    const { address, balance, shard } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [isCalculatingApy, setIsCalculatingApy] = useState<boolean>(false);
    const [farmPool, setFarmPool] = useState<JewelFarmContextData>();
    const [selectedLeverage, setSelectedLeverage] = useState<number>(1);
    const [showFarmModal, setShowFarmModal] = useState<boolean>(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(FARM_POOL_TOKENS[farmIndex][farmIndex == 1 ? 1 : 0]);
    const [leverage, setLeverage] = useState<number>(1);
    const [tokenAmounts, setTokenAmounts] = useState<string[]>([]);
    const [minDepositToken, setMinDepositToken] = useState<string>('');
    const [minDepositAmount, setMinDepositAmount] = useState<string>('0');
    // const [minDepositAmounts, setMinDepositAmounts] = useState<string[]>([]);
    const [maxMark, setMaxMark] = useState<number>(1);
    const [amountMarks, setAmountMarks] = useState<valueMark[]>([
        {
            value: 1,
            label: '1',
        },
    ]);
    const [debtAmount, setDebtAmount] = useState<string>('0');
    const [currentDepositAmount, setCurrentDepositAmount] = useState<string>('0');

    const [stakedLpAmount, setStakedLpAmount] = useState<string>('0');
    const [lpTokenPrice, setLpTokenPrice] = useState<string>('0');
    const [tokenPrices, setTokenPrices] = useState([]);
    const [reserves, setReserves] = useState<string[]>([]);
    const [lpPercent, setLpPercent] = useState<string>('0');
    const [apr, setApr] = useState<number>(0);
    // const [apy, setApy] = useState<number>(0);

    useEffect(() => {
        if (!commonSettings) return;

        (async () => {
            setIsCalculatingApy(true);
            const _tokens = commonSettings.ashswap_tokens;
            const _farmPool = await queryViewJewelFarmData(apiAddress, farm, _tokens);
            // console.log(_farmPool);
            if (_farmPool) {
                setLpTokenPrice(_farmPool.total_staked_lp_value);
                if (_tokens?.data) {
                    // setLpTokenPrice(new BigNumber(_farmPool.total_staked_lp_value).multipliedBy(_farmPool.lp_price).toFixed());
                    setTokenPrices(_tokens.data.tokens);
                    // if (_farmPool.farm_id === 1) {
                    //     const ashPrice = _tokens.data.tokens.filter((token: any) => token.id === ASH_TOKEN_ID)[0]['price'];

                    //     // setApy(getFarmApy(_farmPool.reward_per_day, commonSettings.fee_collector_percents[0], new BigNumber(_farmPool.total_staked_lp_value).multipliedBy(_farmPool.lp_price).dividedBy(ashPrice).toFixed()) * 100);
                    //     // setLendApy(getFarmApy(_farmPool.reward_per_day, commonSettings.fee_collector_percents[1], new BigNumber(_farmPool.total_staked_lp_value).multipliedBy(_farmPool.lp_price).dividedBy(ashPrice).toFixed()) * 100);

                    //     const farmerRewardPecentage = getFarmerRewardPercent(_farmPool.farm_shares_per_token, _farmPool.borrow_shares_per_token, _farmPool.total_farm_share, commonSettings.fee_collector_percents[0]);
                    //     const _apy = getOldFarmApy(_farmPool.reward_per_day, farmerRewardPecentage, new BigNumber(_farmPool.total_staked_lp_value).multipliedBy(_farmPool.lp_price).dividedBy(ashPrice).toFixed()) * 100;

                    //     setApy(_apy);
                    // }
                }
                const _tradingApr = getAshswapPoolTradingApr(ashswapPools, _farmPool.dex_pool_address);
                const _apr = getAshswapPoolApr(_farmPool.ash_base_apr, _farmPool.current_boost, _tradingApr);
                setApr(_apr);

                setStakedLpAmount(_farmPool.staked_lp_amount);
                setReserves(_farmPool.reserves);
                setLpPercent(new BigNumber(_farmPool.lp_total_supply).dividedBy(_farmPool.staked_lp_amount).toFixed());

                setFarmPool(_farmPool);
            }
            setIsCalculatingApy(false);
        })();

    }, [commonSettings]);

    // useEffect(() => {
    //     if (!commonSettings) return;

    //     const _tokens = commonSettings.ashswap_tokens;
    //     if (_tokens?.data) {
    //         setLpTokenPrice(new BigNumber(farmPool.total_staked_lp_value).multipliedBy(farmPool.lp_price).toFixed());
    //         setTokenPrices(_tokens.data.tokens);
    //         const ashPrice = _tokens.data.tokens.filter((token: any) => token.id === ASH_TOKEN_ID)[0]['price'];
    //         setApy(getFarmApy(farmPool.reward_per_day, commonSettings.fee_collector_percents[0], new BigNumber(farmPool.total_staked_lp_value).multipliedBy(farmPool.lp_price).dividedBy(ashPrice).toFixed()) * 100);
    //         setLendApy(getFarmApy(farmPool.reward_per_day, commonSettings.fee_collector_percents[1], new BigNumber(farmPool.total_staked_lp_value).multipliedBy(farmPool.lp_price).dividedBy(ashPrice).toFixed()) * 100);
    //     }
    //     setStakedLpAmount(farmPool.total_staked_lp_value);
    //     setReserves(farmPool.reserves);
    //     setLpPercent(new BigNumber(farmPool.lp_total_supply).dividedBy(farmPool.total_staked_lp_value).toFixed());
    // }, [commonSettings]);

    useEffect(() => {
        if (!commonSettings || !selectedCurrency || !farmPool) return;

        // const _minDepositAmounts = [];
        // for (let i = 0; i < farmPool.token_ids.length; i++) {
        //     for (let j = 0; j < commonSettings.min_deposit_tokens.length; j++) {
        //         if (farmPool.token_ids[i] === commonSettings.min_deposit_tokens[j]) {
        //             _minDepositAmounts.push(new BigNumber(commonSettings.min_deposit_values[j]).toFixed());
        //             break;
        //         }
        //     }
        // }
        // setMinDepositAmounts(_minDepositAmounts);

        // let _minDepositAmount = '0';
        // for (let i = 0; i < commonSettings.min_deposit_tokens.length; i++) {
        //     if (commonSettings.min_deposit_tokens[i] === selectedCurrency) {
        //         _minDepositAmount = new BigNumber(commonSettings.min_deposit_values[i]).toFixed();
        //         break;
        //     }
        // }
        setMinDepositToken(farmPool.min_deposit_base_token);
        setMinDepositAmount(farmPool.min_deposit_base_amount);

        // check max leverage
        let _liquidationThresholds = 0;
        for (let i = 0; i < commonSettings.liquidation_tokens.length; i++) {
            if (commonSettings.liquidation_tokens[i] === selectedCurrency) {
                _liquidationThresholds = commonSettings.liquidation_thresholds[i];
                break;
            }
        }
        if (_liquidationThresholds > 0) {
            const percent = 100 * PERCENT_DENOMINATOR;
            let maxLeverage = Math.floor((percent / (percent - _liquidationThresholds) * 100) / 100);
            // maxLeverage = Math.floor(maxLeverage - maxLeverage / 10);

            if (getIsStablePool(farmPool.farm_id)) {
                if (maxLeverage > STABLE_MAX_LEVERAGE) {
                    maxLeverage = STABLE_MAX_LEVERAGE;
                }
            } else {
                if (maxLeverage > MAX_LEVERAGE) {
                    maxLeverage = MAX_LEVERAGE;
                }
            }

            if (maxLeverage > 1) {
                const labels = [];
                let i = 1;
                for (i = 1; i <= maxLeverage; i += 0.5) {
                    labels.push({
                        value: i,
                        label: i.toString(),
                    });
                }
                setAmountMarks(labels);
                setMaxMark(i - 0.5);
                if (leverage > maxLeverage) {
                    setLeverage(maxLeverage);
                }
            }
        }

    }, [commonSettings, selectedCurrency, farmPool]);

    useEffect(() => {
        (async () => {
            if (selectedCurrency) {
                await updateDebtAmount(selectedCurrency, tokenAmounts);
            }

            if (leverage === 1) {
                setDebtAmount('0');
            }
        })();
    }, [leverage, selectedCurrency]);

    const updateDebtAmount = async (borrowTokenId: string, amounts: string[]) => {
        if (!farmPool) return '0';

        let _depositAmount = new BigNumber('0');
        for (let i = 0; i < farmPool.token_ids.length; i++) {
            if (farmPool.token_ids[i] === borrowTokenId) {
                _depositAmount = _depositAmount.plus(convertEsdtToWei(amounts[i], getTokenDecimal(borrowTokenId)));
            } else {
                if (new BigNumber(amounts[i]).gt(0)) {
                    const outputAmount = await getOutputAmount(farmPool.token_ids[i], borrowTokenId);
                    _depositAmount = new BigNumber(amounts[i]).multipliedBy(outputAmount).plus(_depositAmount);
                }
            }
        }

        setCurrentDepositAmount(_depositAmount.toFixed());
        if (_depositAmount.gt('0')) {
            const _debtAmount = _depositAmount.multipliedBy(leverage - 1);
            setDebtAmount(_debtAmount.toFixed());

            return _debtAmount.toFixed();
        }

        return '0';
    };

    const getOutputAmount = async (tokenIn: string, tokenOut: string) => {
        if (!farmPool) return '0';

        const outputAmount = await queryFarmGetEquivalent(apiAddress, farmPool.farm_id, tokenIn, tokenOut, new BigNumber(convertEsdtToWei(1, getTokenDecimal(tokenIn))).toFixed());
        return outputAmount;
        // const proxy = new ProxyNetworkProvider(isDev ? MVXProxyNetworkAddress.Devnet : MVXProxyNetworkAddress.Mainnet);
        // const amountIn = 1;
        // let firstTokenIndex = 0;
        // let secondTokenIndex = 1;
        // for (let i = 0; i < farmPool.token_ids.length; i++) {
        //     if (farmPool.token_ids[i] === tokenIn) {
        //         firstTokenIndex = i;
        //     }
        //     if (farmPool.token_ids[i] === tokenOut) {
        //         secondTokenIndex = i;
        //     }
        // }

        // if (farmPool.token_ids.length > 2) {
        //     ContractManager.setAshNetwork(isDev ? AshNetwork.DevnetBeta : AshNetwork.Mainnet);
        //     console.log(tokenIn, tokenOut, farmPool.dex_pool_address);
        //     const _tokenIn = getToken(tokenIn);
        //     const _tokenOut = getToken(tokenOut);
        //     const pool = getPool(farmPool.dex_pool_address);
        //     const outputAmount = await queryPoolContract.estimateAmountOut(
        //         pool,
        //         _tokenIn.identifier,
        //         _tokenOut.identifier,
        //         new BigNumber(convertEsdtToWei(amountIn, getTokenDecimal(tokenIn))),
        //     );
        //     console.log(outputAmount.toFixed());

        //     return outputAmount;
        //     // const poolContract = ContractManager.getPoolContract(
        //     //     farmPool.dex_pool_address
        //     // ).onChain(isDev ? ChainId.Devnet : ChainId.Mainnet).onProxy(proxy);
        //     // const { admin_fee, amount_out, token_in_balance, token_out_balance, total_fee } = await poolContract.getAmountOut(
        //     //     tokenIn,
        //     //     tokenOut,
        //     //     new BigNumber(convertEsdtToWei(amountIn, getTokenDecimal(tokenIn))), // amount_in
        //     // );
        //     // console.log(tokenIn, tokenOut, (new BigNumber(convertEsdtToWei(amountIn, getTokenDecimal(tokenIn)))).toFixed());
        //     // console.log(amount_out);

        //     // return token_out_balance.toFixed();
        // } else {
        //     const poolContract = ContractManager.getPoolV2Contract(
        //         farmPool.dex_pool_address
        //     ).onChain(isDev ? ChainId.Devnet : ChainId.Mainnet).onProxy(proxy);
        //     const { fee, outputAmount } = await poolContract.estimateAmountOut(
        //         firstTokenIndex,
        //         secondTokenIndex,
        //         new BigNumber(convertEsdtToWei(amountIn, getTokenDecimal(tokenIn))), // amount_in
        //     );

        //     return outputAmount.toFixed();
        // }
    };

    const handleShowModal = () => {
        if (!farmPool) return;
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }
        setLeverage(1);
        const _tokenAmounts = [];
        for (let i = 0; i < farmPool.token_ids.length; i++) {
            _tokenAmounts.push('0');
        }
        setTokenAmounts(_tokenAmounts);
        setShowFarmModal(true);
        setCurrentDepositAmount('0');
        setDebtAmount('0');
    };

    const handleSelectedCurrency = async (value: string) => {
        setSelectedCurrency(value);
    };

    // useEffect(() => {
    //     if (!commonSettings) return;
    //     const amounts: string[] = [];
    //     for (let i = 0; i < farmPool.token_ids.length; i++) {
    //         if (tokenAmounts.length > i) {
    //             amounts[i] = tokenAmounts[i];
    //         } else {
    //             amounts[i] = '0';
    //         }
    //     }
    //     setTokenAmounts(amounts);
    // }, [commonSettings]);

    const handleOnChange = async (index: number, value: string) => {
        const amounts: string[] = [];
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (index === i) {
                if (value === '') {
                    value = '0';
                }
                amounts.push(value);
            } else {
                amounts.push(tokenAmounts[i]);
            }
        }
        setTokenAmounts(amounts);
        if (selectedCurrency) {
            await updateDebtAmount(selectedCurrency, amounts);
        }
    };

    const handleMax = async (index: number) => {
        if (!commonSettings || !farmPool) return;
        const amounts: string[] = [];
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (index === i) {
                let maxAmount = '0';
                if (getTokenTicker(farmPool.token_ids[i]) === 'EGLD') {
                    // if (new BigNumber(userTokens['EGLD'].balance).gt(EXTRA_GAS_FEE_AMOUNT)) {
                    //     maxAmount = new BigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
                    // } else {
                    //     maxAmount = '0';
                    // }
                    maxAmount = userTokens['EGLD'].balance;
                } else {
                    maxAmount = userTokens[farmPool.token_ids[i]].balance;
                }
                amounts.push(convertWeiToEsdt(maxAmount, getTokenDecimal(farmPool.token_ids[index])).toFixed());
            } else {
                amounts.push(tokenAmounts[i]);
            }
        }
        setTokenAmounts(amounts);
        if (selectedCurrency) {
            await updateDebtAmount(selectedCurrency, amounts);
        }
    };

    const handleEnterFarm = async () => {
        if (!commonSettings || !isLoggedIn || !selectedCurrency || !farmPool) return;

        let availableAmount = '0';
        for (let i = 0; i < lendPools.length; i++) {
            if (lendPools[i].deposit_token_id === selectedCurrency) {
                if (new BigNumber(lendPools[i].deposit_token_amount).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT).gt(lendPools[i].loan_amount)) {
                    availableAmount = new BigNumber(lendPools[i].deposit_token_amount).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT).minus(lendPools[i].loan_amount).toFixed();
                    break;
                }
            }
        }

        const tokenIds: string[] = [];
        const amounts: string[] = [];
        // let _minDepositAmount = '0';
        // let depositAmount = BigNumber('0');
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (new BigNumber(tokenAmounts[i]).gt(0)) {
                // check balance
                if (convertEsdtToWei(tokenAmounts[i], getTokenDecimal(farmPool.token_ids[i])).gt(getTokenTicker(farmPool.token_ids[i]) === 'EGLD' ? userTokens['EGLD'].balance : userTokens[farmPool.token_ids[i]].balance)) {
                    toastError(ERROR_NOT_ENOUGH_BALANCE);
                    return;
                }

                if (farmPool.token_ids[i] === 'EGLD' && new BigNumber(userTokens['EGLD'].balance).lte(EXTRA_GAS_FEE_AMOUNT)) {
                    toastError(ERROR_NOT_ENOUGH_BALANCE);
                    return;
                }

                tokenIds.push(farmPool.token_ids[i]);
                const value = convertEsdtToWei(tokenAmounts[i], getTokenDecimal(farmPool.token_ids[i])).toFixed();
                amounts.push(value);

                // get amount by borrow token
                // if (farmPool.token_ids[i] === selectedCurrency) {
                //     depositAmount = depositAmount.plus(value);
                // } else {
                //     const outputAmount = await getOutputAmount(farmPool.token_ids[i], selectedCurrency);
                //     depositAmount = BigNumber(tokenAmounts[i]).multipliedBy(outputAmount).plus(depositAmount);
                // }
            }
            // if (farmPool.token_ids[i] === selectedCurrency) {
            //     _minDepositAmount = minDepositAmounts[i];
            // }
        }

        if (leverage > 1) {
            const _debtAmount = await updateDebtAmount(selectedCurrency, tokenAmounts);
            if (new BigNumber(_debtAmount).lte('0')) {
                toastError('Invalid debt amount');
                return;
            }
            if (new BigNumber(availableAmount).lte('0') || new BigNumber(availableAmount).lt(_debtAmount)) {
                toastError('Not enough liquidity in lend pool');
                return;
            }
        }

        const errorMsg = `Please deposit more than ${convertBigNumberValueToLocalString(convertWeiToEsdt(minDepositAmount, getTokenDecimal(minDepositToken)))} ${getTokenTicker(minDepositToken)}`;

        if (selectedCurrency === minDepositToken) {
            if (new BigNumber(currentDepositAmount).lte(minDepositAmount)) {
                toastError(errorMsg);
                return;
            }
        } else {
            const outputAmount = await getOutputAmount(selectedCurrency, minDepositToken);
            if (convertWeiToEsdt(currentDepositAmount, getTokenDecimal(selectedCurrency)).multipliedBy(outputAmount).lte(minDepositAmount)) {
                toastError(errorMsg);
                return;
            }
        }

        let _maxDebtAmount = '0';
        for (let i = 0; i < commonSettings.max_debt_tokens.length; i++) {
            if (commonSettings.max_debt_tokens[i] === selectedCurrency) {
                _maxDebtAmount = commonSettings.max_debt_amounts[i];
                break;
            }
        }

        if (new BigNumber(_maxDebtAmount).lte(0) || new BigNumber(debtAmount).gt(_maxDebtAmount)) {
            toastError('Invalid debt amount');
            return;
        }

        let wrapAddress = contracts.Wrap0.address;

        if (shard === 1) {
            wrapAddress = contracts.Wrap1.address;
        } else if (shard === 2) {
            wrapAddress = contracts.Wrap2.address;
        }

        const result = await openPosition(chainID, JEWEL_ASHSWAP_SC_ADDRESS, address, farmPool.farm_id, tokenIds, amounts, Math.round(leverage * 100) * PERCENT_DENOMINATOR, SLIPPAGE * PERCENT_DENOMINATOR, selectedCurrency, wrapAddress, balance);

        setShowFarmModal(false);

        setSessionId(result.sessionId);
    };

    const handleChangeSelectedLeverage = (value: any, isModal = false) => {
        let maxLeverage = MAX_LEVERAGE;
        if (getIsStablePool(farmPool ? farmPool.farm_id : 1)) {
            maxLeverage = STABLE_MAX_LEVERAGE;
        }

        if (Number(value) > maxLeverage) {
            if (isModal) {
                setLeverage(maxLeverage);
            } else {
                setSelectedLeverage(maxLeverage);
            }
        } else {
            if (isModal) {
                setLeverage(value);
            } else {
                setSelectedLeverage(value);
            }
        }
    };

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('Enter farm request was successful');
            navigate('/mypositions/ashswap');
        }
    }, [sessionId, hasPendingTransactions]);

    // const handleWrap = async () => {
    //     if (!isLoggedIn || new BigNumber(balance).lte(convertEsdtToWei(1, 18))) return;

    //     let wrapAddress = contracts.Wrap0.address;

    //     if (shard === 1) {
    //         wrapAddress = contracts.Wrap1.address;
    //     } else if (shard === 2) {
    //         wrapAddress = contracts.Wrap2.address;
    //     }

    //     await wrapEgld(chainID, wrapAddress, address, new BigNumber(balance).minus(convertEsdtToWei(1, 18)).toFixed());
    // };

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 farm-container mobile-farm-container'>
                    <div className='col-lg-4 col-md-4 col-sm-4 align-self-center d-flex mt-2'>
                        <div className='d-flex justify-content-center align-items-center'>
                            {/* <div className='combine-bage'>
                                {farmPool && (
                                    getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((item: string, index: number) => (<img key={index} src={getTokenImage(item)} className='egld-image mx-2' alt={item} />))
                                )}
                            </div> */}
                            <div className='combine-bage'>
                                {FARM_POOL_TOKENS[farmIndex].map((item: string, index: number) => (<img key={index} src={getTokenImage(item)} className='egld-image mx-2' alt={item} />))}
                            </div>
                            {/* <div>
                                {farmPool ? (
                                    <div>
                                        {getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((item: string, key: number) => {
                                            let poolName = '';
                                            if (key === (getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).length - 1)) {
                                                poolName += getTokenTicker(item);
                                            } else {
                                                poolName += `${getTokenTicker(item)}-`;
                                            }
                                            return poolName;
                                        })}
                                    </div>
                                ) : (
                                    <div>-</div>
                                )}
                                <div>{poolName}</div>
                            </div> */}
                            <div>
                                <div>
                                    {FARM_POOL_TOKENS[farmIndex].map((item: string, key: number) => {
                                        let poolName = '';
                                        if (key === (FARM_POOL_TOKENS[farmIndex].length - 1)) {
                                            poolName += getTokenTicker(item);
                                        } else {
                                            poolName += `${getTokenTicker(item)}-`;
                                        }
                                        return poolName;
                                    })}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-4'>
                        <div className='d-flex align-items-center'>TVL</div>
                        <div>
                            <div className='text-end'>
                                {`$${convertBigNumberValueToLocalString(convertWeiToEsdt(lpTokenPrice, ASH_LP_DECIMALS))} (${convertBigNumberValueToLocalString(convertWeiToEsdt(stakedLpAmount, ASH_LP_DECIMALS))} LP tokens)`}
                            </div>
                            {
                                farmPool ? (
                                    getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((item: string, index: number) => {
                                        const price = tokenPrices.filter((token: any) => token.id === item);
                                        return <div key={index} className='text-end'>
                                            {`${reserves.length > 0 ? convertBigNumberValueToLocalString(convertWeiToEsdt(new BigNumber(reserves[index]).dividedBy(lpPercent), getTokenDecimal(item))) : '0'} ${getTokenTicker(item)} (1 ${getTokenTicker(item)} = ${price.length > 0 ? price[0]['price'] : '-'} USD)`}
                                        </div>;
                                    })
                                ) : (
                                    <div className='text-end'>-</div>
                                )
                            }
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-between mt-2'>
                        <div className='d-flex'>
                            <div className='d-flex'>APY</div>
                            <Tooltip
                                placement="top"
                                title="Based on APY of the current underlying farming pools. It does not include fees from occasional rebalancing"
                            >
                                <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                        <div className='avaliable-color'>{`${isCalculatingApy ? '-' : convertBigNumberValueToLocalString(getFarmApy(apr * ((selectedLeverage - 1) * 0.15 + 1), YEAR_IN_HOURS))} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-2'>
                        <div className='d-flex align-items-center'>Leverage</div>
                        <InputNumber
                            className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                            type="number"
                            min={1}
                            // max={farmPool && farmPool.token_ids.length === 4 ? STABLE_MAX_LEVERAGE : MAX_LEVERAGE}
                            step={0.5}
                            value={selectedLeverage}
                            onChange={(value: any) => handleChangeSelectedLeverage(value)}
                        />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center mt-4'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2 w-100" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 farm-container'>
                    <div className='col-lg-4 col-md-4 col-sm-4 align-self-center'>
                        <div className='d-flex align-items-center'>
                            {/* <div className='combine-bage'>
                                {farmPool && (getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((item: string, index: number) => (<img key={index} src={getTokenImage(item)} className='egld-image mx-2' alt={item} />)))}
                            </div> */}
                            <div className='combine-bage'>
                                {
                                    FARM_POOL_TOKENS[farmIndex].map((item: string, index: number) => (<img key={index} src={getTokenImage(item)} className='egld-image mx-2' alt={item} />))
                                }
                            </div>
                            {/* <div>
                                {farmPool ? (
                                    <div>
                                        {getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((item: string, index: number) => {
                                            let poolName = '';
                                            if (index === (getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).length - 1)) {
                                                poolName += getTokenTicker(item);
                                            } else {
                                                poolName += `${getTokenTicker(item)}-`;
                                            }
                                            return poolName;
                                        })}
                                    </div>
                                ) : (
                                    <div>-</div>
                                )}
                                <div>{poolName}</div>
                            </div> */}
                            <div>
                                <div>
                                    {FARM_POOL_TOKENS[farmIndex].map((item: string, index: number) => {
                                        let poolName = '';
                                        if (index === (FARM_POOL_TOKENS[farmIndex].length - 1)) {
                                            poolName += getTokenTicker(item);
                                        } else {
                                            poolName += `${getTokenTicker(item)}-`;
                                        }
                                        return poolName;
                                    })}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 text-center align-self-center'>
                        <div className='d-flex justify-content-center'>
                            {`$${convertBigNumberValueToLocalString(convertWeiToEsdt(lpTokenPrice, ASH_LP_DECIMALS))} (${convertBigNumberValueToLocalString(convertWeiToEsdt(stakedLpAmount, ASH_LP_DECIMALS))} LP tokens)`}
                        </div>
                        {
                            farmPool ? (
                                getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((item: string, index: number) => {
                                    const price = tokenPrices.filter((token: any) => token.id === item);
                                    return <div key={index} className='d-flex justify-content-center'>
                                        {`${reserves.length > 0 && new BigNumber(reserves[index]).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(new BigNumber(reserves[index]).dividedBy(lpPercent), getTokenDecimal(item))) : '0'} ${getTokenTicker(item)} (1 ${getTokenTicker(item)} = ${price.length > 0 ? price[0]['price'] : '-'} USD)`}
                                    </div>;
                                })
                            ) : (
                                <div>-</div>
                            )
                        }
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 text-center align-self-center'>
                        <div className='avaliable-color'>{`${isCalculatingApy ? '-' : convertBigNumberValueToLocalString(getFarmApy(apr * ((selectedLeverage - 1) * 0.15 + 1), YEAR_IN_HOURS))} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <InputNumber
                            className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                            type="number"
                            min={1}
                            // max={farmPool && farmPool.token_ids.length === 4 ? STABLE_MAX_LEVERAGE : MAX_LEVERAGE}
                            step={0.5}
                            value={selectedLeverage}
                            onChange={(value: any) => handleChangeSelectedLeverage(value)}
                        />
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {farmPool && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={showFarmModal}
                    onClose={() => setShowFarmModal(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showFarmModal}>
                        <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                            <div className='d-flex'>
                                Warning: Please be aware of a potential large price impact when opening a large position. This is due to your position size compared to the underlying liquidity of the pool.
                            </div>
                            <div className='d-flex mt-4'>
                                How much would you like to add for farming?
                            </div>
                            {farmPool.token_ids.map((item: string, index: number) => (
                                <div className='mt-2 borrow-form' key={index}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                            {
                                                farmPool.token_ids.length === (index + 1) ? (
                                                    <div className={`combine-bage ${farmPool.token_ids.length === 4 ? 'combine-bage-width' : ''}`}>
                                                        {getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((tokenId: string, i: number) => (<img key={i} src={getTokenImage(tokenId)} className='egld-image mx-2' alt={tokenId} />))}
                                                    </div>
                                                ) : (
                                                    <img src={getTokenImage(item)} alt={item} />
                                                )
                                            }
                                            <div style={{ fontSize: farmPool.token_ids.length === (index + 1) ? '0.7rem' : '0.9rem' }}>{getTokenTicker(item)}</div>
                                        </div>
                                        <input type="number" value={tokenAmounts[index]} onChange={(e: any) => handleOnChange(index, e.target.value)} />
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <div className='d-flex'>
                                                <div className='d-flex align-items-center' style={{ fontSize: farmPool.token_ids.length === (index + 1) ? '0.7rem' : '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                                <div>
                                                    <div style={{ fontSize: farmPool.token_ids.length === (index + 1) ? '0.7rem' : '0.9rem' }}>{`${commonSettings ? convertBigNumberValueToLocalString(convertWeiToEsdt(getTokenTicker(item) === 'EGLD' ? userTokens['EGLD']?.balance : userTokens[item]?.balance, getTokenDecimal(item))) : '-'} ${getTokenTicker(item)}`}</div>
                                                </div>
                                            </div>
                                            {/* {getTokenTicker(item) === 'WEGLD' && convertWeiToEsdt(balance).gt(1) && (
                                            <div className='d-flex'>
                                                <div className='d-flex align-items-center' style={{ fontSize: '0.85rem', marginRight: '3px' }}>
                                                    <button className='max-but' onClick={handleWrap}>WRAP</button>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='d-flex align-items-center' style={{ fontSize: '0.85rem', marginRight: '3px' }}>{`: ${convertBigNumberValueToLocalString(convertWeiToEsdt(balance))} EGLD`}</div>
                                                </div>
                                            </div>
                                        )} */}
                                        </div>
                                        {/* <span style={{ fontSize: '0.85rem' }}>{`Available : ${commonSettings ? convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[item]?.balance, getTokenDecimal(item))) : '-'} ${getTokenTicker(item)}`}</span> */}
                                        <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                            <button className='max-but' onClick={() => handleMax(index)}>MAX</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className='d-flex mt-4 gap-2'>
                                <div className='d-flex align-items-center'>Leverage</div>
                                <InputNumber
                                    className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                                    type="number"
                                    min={1}
                                    // max={maxMark}
                                    step={0.5}
                                    value={leverage}
                                    onChange={(value: any) => handleChangeSelectedLeverage(value, true)}
                                />
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div style={{ width: '95%' }}>
                                    <LeverageSlider
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        min={1}
                                        max={maxMark}
                                        step={0.01}
                                        marks={amountMarks}
                                        value={leverage}
                                        valueLabelFormat={valueLabelFormat}
                                        onChange={(e: any) => setLeverage(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex mt-1'>
                                Which asset would you like to borrow? (Not applicable with 1x leverage)
                            </div>
                            <div className='d-flex mt-2'>
                                <Dropdown className='select-borrow-currency-dropdown'>
                                    <Dropdown.Toggle id="dropdown-basic-button" className='currency-item justify-content-between' disabled={leverage === 1 ? true : false}>
                                        <div className='d-flex align-items-center'>
                                            <img src={selectedCurrency ? getTokenImage(selectedCurrency) : egld_white_logo} />
                                            <div>{selectedCurrency ? getTokenTicker(selectedCurrency) : 'EGLD'}</div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((item: string, index: number) => (
                                            <Dropdown.Item className='currency-item d-flex align-items-center' onClick={() => handleSelectedCurrency(item)} key={index}>
                                                <img src={getTokenImage(item)} alt={item}></img>
                                                <div>{getTokenTicker(item)}</div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='d-flex mt-4'>
                                Summary
                            </div>
                            <div className='farm-summary mt-2'>
                                <div className='w-100 d-flex justify-content-between'>
                                    <span className='d-flex align-items-center'>Leverage</span>
                                    <div className='d-flex gap-2'>
                                        <span className='d-flex align-items-center'>{`${leverage}X`}</span>
                                    </div>
                                </div>
                                <div className='w-100 d-flex justify-content-between mt-2'>
                                    <span className='d-flex align-items-center'>Yield Farm APY</span>
                                    <div className='d-flex gap-2'>
                                        {/* <span>{`${leverage >= 1 && leverage <= 2 ? convertBigNumberValueToLocalString((apy + lendApy + (leverage - 1) * (apy - lendApy)), 0) : convertBigNumberValueToLocalString(apy * leverage, 0)} %`}</span> */}
                                        <span className='avaliable-color d-flex align-items-center'>{`${isCalculatingApy ? '-' : convertBigNumberValueToLocalString(getFarmApy(apr * ((leverage - 1) * 0.15 + 1), YEAR_IN_HOURS))} %`}</span>
                                    </div>
                                </div>
                                <div className='w-100 d-flex justify-content-between mt-2'>
                                    <span className='d-flex align-items-center'>Deposit Amount (MIN)</span>
                                    <div className='d-flex gap-2'>
                                        <img className='egld-badge' src={getTokenImage(minDepositToken)} alt={minDepositToken} />
                                        <span className='d-flex align-items-center'>{convertBigNumberValueToLocalString(convertWeiToEsdt(minDepositAmount, getTokenDecimal(minDepositToken)))}</span>
                                    </div>
                                </div>

                                {/* <div className='w-100 d-flex justify-content-between mt-2'>
                                <span>Deposit Amount (MIN)</span>
                                <div className='d-flex gap-1'>
                                    {minDepositAmounts.length === farmPool.token_ids.length && farmPool.token_ids.map((item: string, index: number) => (
                                        <div className='d-flex gap-1' key={index}>
                                            <img className='egld-badge' src={getTokenImage(item)} alt={item} />
                                            <span>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(minDepositAmounts[index], getTokenDecimal(item)))} ${farmPool.token_ids.length === (index + 1) ? '' : 'or'}`}</span>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                                <div className='w-100 d-flex justify-content-between align-items-center mt-2'>
                                    <span className='d-flex align-items-center'>Deposit Amount</span>
                                    <div className={`${isMobile ? '' : 'd-flex'} gap-1`}>
                                        {farmPool.token_ids.map((item: string, index: number) => (
                                            <div className={`${isMobile ? 'mt-1 justify-content-between' : ''} d-flex gap-1 currency-item`} key={index}>
                                                {
                                                    farmPool.token_ids.length === (index + 1) ? (
                                                        <div className={`combine-bage ${farmPool.token_ids.length === 4 ? 'combine-bage-width' : ''}`}>
                                                            {getFarmTokenIds(farmPool.token_ids, farmPool.lp_token_id).map((tokenId: string, i: number) => (<img key={i} src={getTokenImage(tokenId)} className='egld-image mx-2' alt={tokenId} />))}
                                                        </div>
                                                    ) : (
                                                        <img className='egld-badge' src={getTokenImage(item)} alt={item} />
                                                    )
                                                }
                                                {isMobile ? (
                                                    <span className='d-flex align-items-center'>{`${convertBigNumberValueToLocalString(tokenAmounts[index], 0)}`}</span>
                                                ) : (
                                                    <span className='d-flex align-items-center'>{`${convertBigNumberValueToLocalString(tokenAmounts[index], 0)} ${farmPool.token_ids.length === (index + 1) ? '' : '+'}`}</span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {selectedCurrency && (
                                    <div className='w-100 d-flex justify-content-between mt-2'>
                                        <span className='d-flex align-items-center'>Debt {getTokenTicker(selectedCurrency)}</span>
                                        <div className='d-flex gap-2'>
                                            <img className='egld-badge' src={getTokenImage(selectedCurrency)} alt={selectedCurrency} />
                                            <span className='d-flex align-items-center'>{convertBigNumberValueToLocalString(convertWeiToEsdt(debtAmount, getTokenDecimal(selectedCurrency)))}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex mt-4'>
                                Please keep in mind that when you leverage above 2x, you will have a slight short on the borrowed asset. The other paired asset will have typical long exposure, so choose which asset you borrow wisely.
                            </div>
                            <div className='d-flex justify-content-center mt-4'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleEnterFarm}>
                                    Farm
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    );
};
