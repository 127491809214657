import { TokenPayment } from "@multiversx/sdk-core/out";
import { NftWithIndexType } from "./common";

export enum LoanTypeEnum {
  BorrowEgld = 'BorrowEgld',
  DownPayment = 'Mortgage',
}

export enum LoanStatusEnum {
  Opened = 'Opened',
  Closed = 'Closed',
  Liquidated = 'Liquidated',
  Expired = 'Expired',
  Failed = 'NftPurchaseFailed',
  FailedClosed = 'NftPurchaseFailedClosed',
  TopUpClosed = 'TopUpClosed',
  LiquidationGraced = 'LiquidationGraced',
  ExpirationGraced = 'ExpirationGraced',
}

export function parseLoanTypeEnum(val: string): LoanTypeEnum {
  return LoanTypeEnum[val as Exclude<keyof typeof LoanTypeEnum, 'parse'>];
}

export function parseLoanStatusEnum(val: string): LoanStatusEnum {
  return LoanStatusEnum[val as Exclude<keyof typeof LoanStatusEnum, 'parse'>];
}

//
export interface CommonSettingsType {
  rewards_pool_rate: number,
  epunks_buyback_rate: number,
  team_rate: number,
  epunks_buyback_wallet: string,
  team_wallet: string,
  liquidation_wallet: string,

  lend_epoch_length: number,
  lend_lock_period: number,
  liquidation_threshold: number,
  lend_grace_period: number,
  liquidation_fee: number,

  total_lending_amount: string,
  lend_users: number,
  current_lend_epoch_id: number,
  lend_epoch_start_timestamp: number,
  lend_rewards_pool_amount: string,

  global_liquidation_rate: number,
  global_max_borrow_amount: string,
  global_collection_max_lend_cap_percent: number,
  global_max_borrower_cap_percent: number,

  borrow_plan_count: number,
  max_borrow_periods: number[],
  borrow_rates: number[],

  accumulated_loan_amount: string,
  total_loan_amount: string,
  total_collateral_amount: string,
  opened_loan_count: number,
  loan_owners: number,
  last_loan_id: number,

  collection_ids: string[],
  collection_collateral_rates: number[],
  collection_trait_counts: number[],
  collection_supplies: number[],
  collection_max_borrow_amounts: string[],
  collection_borrow_amounts: string[],
  collection_max_lend_cap_percents: number[],

  // lend_user_data: LendUserDataType[],
  user_borrow_status: boolean,
}

export interface LoanType {
  index: number,  // only for view purpose
  loan_id: number,
  loan_type: LoanTypeEnum,
  loan_status: LoanStatusEnum,
  loan_owner: string,
  loan_collection_id: string,
  loan_nft_nonce: number,
  loan_nft_price: string,
  loan_borrow_amount: string,
  loan_debt_amount: string,
  loan_deadline_timestamp: number,
  loan_borrow_plan_id: number,
  loan_open_timestamp: number,
  loan_close_timestamp: number,
  loan_nft_valuation: string,
  loan_liquidation_price: string,
  loan_cashout: string,
}

export interface LendUserType {
  user_lend_amount: string,
  user_last_lend_timestamp: number,
  lender_rewards_amounts: string,

  user_loan_ids: number[],
  user_borrow_amount: string,
  user_debt_amount: string,
}

export interface LendUserDataType {
  user_lend_amount: string,
  user_last_lend_timestamp: number,
  user_address: string,
}

// AMM
export enum PoolStateEnum {
  Inactive = 'Inactive',
  Active = 'Active',
  ActiveButNoSwap = 'ActiveButNoSwap',
}

export enum PoolTypeEnum {
  Liquidity = 'Liquidity',
  Sell = 'Sell',
  Buy = 'Buy',
}

export enum PoolCurveTypeEnum {
  Linear = 'Linear',
  Exponential = 'Exponential',
}

export enum SwapTypeEnum {
  Buy = 'Buy',
  Sell = 'Sell'
}

export function parsePoolStateEnum(val: string): PoolStateEnum {
  return PoolStateEnum[val as Exclude<keyof typeof PoolStateEnum, 'parse'>];
}

export function parsePoolTypeEnum(val: string): PoolTypeEnum {
  return PoolTypeEnum[val as Exclude<keyof typeof PoolTypeEnum, 'parse'>];
}

export function parsePoolCurveTypeEnum(val: string): PoolCurveTypeEnum {
  return PoolCurveTypeEnum[val as Exclude<keyof typeof PoolCurveTypeEnum, 'parse'>];
}

export function parseSwapTypeEnum(val: string): SwapTypeEnum {
  return SwapTypeEnum[val as Exclude<keyof typeof SwapTypeEnum, 'parse'>];
}

export interface NftCollection {
  nft_token_id: string,
  nft_collection_name: string,
  nft_listed_count: number,
  nft_floor_price: string,
  nft_best_offer_price: string,
  nft_offer_tvl: string,
  nft_collection_trading_volume: string,
}

export interface NftCollectionPoolInfo {
  pool_nft_token_id: string,
  pool_id: number,
  pool_owner: string,
  pool_state: PoolStateEnum,
  pool_type: PoolTypeEnum,
  pool_trade_fee_percent: number,
  pool_spot_price: string,
  pool_curve_type: PoolCurveTypeEnum,
  pool_linear_curve_step_amount: string,
  pool_exponential_curve_step_percent: number,
  pool_accumulated_fee: string,
  pool_swap_token_id: string,
  pool_swap_token_decimal: number,
  pool_swap_token_reserve: string,
  pool_buy_order_count: number,
  pool_nft_token_reserve: number,
  pool_spot_sell_price: string,
  pool_trade_buy_price: string,
  pool_trade_sell_price: string,
  service_fee: number,
  pool_delta: string,
  nft_collection_name?: string,
}

export interface PoolNft {
  pool_id: number,
  nft_token_id: string,
  nft_token_nonce: number,
  nft_token_amount: string,
  pool_spot_buy_price: string,
  pool_spot_sell_price: string,
  pool_trade_buy_price: string,
  pool_trade_sell_price: string,
}

export interface PoolActivity {
  index: number,
  pool_nft_token_id: string,
  pool_id: number,
  pool_activity_id: number,
  pool_activity_token_nonce: number,
  pool_activity_token_amount: string,
  pool_activity_caller_address: string,
  pool_activity_type: SwapTypeEnum,
  pool_activity_price: string,
  pool_activity_timestamp: number
}

export interface CollectionData {
  collection_pools: NftCollectionPoolInfo[],
  collection_nfts: PoolNft[],
}

export interface PoolData {
  collection_pools: NftCollectionPoolInfo,
  collection_nfts: PoolNft[],
}


//////////////// FARM ////////////

export enum JewelLendStateEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export function parseJewelLendStateEnum(val: string): JewelLendStateEnum {
  return JewelLendStateEnum[val as Exclude<keyof typeof JewelLendStateEnum, 'parse'>];
}

export interface JewelLendPool {
  deposit_token_id: string,
  ib_token_id: string,
  deposit_token_amount: string,
  ib_token_amount: string,
  loan_amount: string,
  apy?: string,
}
export interface JewelLendContext {
  state: JewelLendStateEnum,
  admins: string[],
  borrow_whitelist: string[],
  lendPool: JewelLendPool[],
}

export enum JewelFarmStateEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export function parseJewelFarmStateEnum(val: string): JewelFarmStateEnum {
  return JewelFarmStateEnum[val as Exclude<keyof typeof JewelFarmStateEnum, 'parse'>];
}

export interface JewelFarmContext {
  farm_id: number,
  dex_pool_address: string,
  dex_farm_address: string,
  token_ids: string[],
  lp_token_id: string,
  farm_token_id: string,
  farm_token_nonce: number,
  farm_token_amount: string,
  min_deposit_base_token: string,
  min_deposit_base_amount: string,
  pending_reward_token_amount: string,
  borrow_amounts: string[],
  farm_shares_per_token: string[],
  borrow_shares_per_token: string[],
  total_farm_share: string,
  opened_position_count: number,
  nfts: NftWithIndexType[],
}

export interface JewelFarmContextData {
  farm_id: number,
  dex_pool_address: string,
  dex_farm_address: string,
  token_ids: string[],
  lp_token_id: string,
  farm_token_id: string,
  farm_token_nonce: number,
  farm_token_amount: string,
  min_deposit_base_token: string,
  min_deposit_base_amount: string,
  pending_reward_token_amount: string,
  borrow_amounts: string[],
  farm_shares_per_token: string[],
  borrow_shares_per_token: string[],
  total_farm_share: string,
  opened_position_count: number,
  staked_lp_amount: string,
  lp_price: string,
  reserves: string[],
  lp_total_supply: string,
  total_staked_lp_value: string,
  total_liquidity_value: string,
  lp_locked_amt: string,
  farm_token_supply: string,
  exist_farm_token_bal: string,
  reward_per_day: string,
  ash_base_apr: number,
  max_yeild_boost: number,
  current_boost: number,
}

export interface JewelFarmBaseContextData {
  state: JewelFarmStateEnum,
  admins: string[],
  jewel_lend_address: string,
  reward_token_id: string,
  wegld_token_id: string,
  ashswap_governance_address: string,
  ashswap_fee_distributor_address: string,
  ashswap_pool_addresses: string[],
  xexchange_pool_addresses: string[],
  min_compound_rewards_amount: string,
  liquidation_tokens: string[],
  liquidation_thresholds: number[],
  max_debt_tokens: string[],
  max_debt_amounts: string[],
  fee_collector_addresses: string[],
  fee_collector_percents: number[],
  farms: JewelFarmContextData[],
  last_position_id: number,
  total_opened_position_count: number,
  user_tokens: any,
  ashswap_tokens: any,
}
export interface JewelFarmBaseContext {
  state: JewelFarmStateEnum,
  admins: string[],
  jewel_lend_address: string,
  reward_token_id: string,
  wegld_token_id: string,
  ashswap_governance_address: string,
  ashswap_fee_distributor_address: string,
  ashswap_pool_addresses: string[],
  xexchange_pool_addresses: string[],
  min_compound_rewards_amount: string,
  liquidation_tokens: string[],
  liquidation_thresholds: number[],
  max_debt_tokens: string[],
  max_debt_amounts: string[],
  fee_collector_addresses: string[],
  fee_collector_percents: number[],
  ashswap_tokens: any,
}

export enum JewelFarmPositionStateEnum {
  None = "None",
  Opened = "Opened",
  Closed = "Closed",
  Liquidated = "Liquidated",
}

export function parseJewelFarmPositionStateEnum(val: string): JewelFarmPositionStateEnum {
  return JewelFarmPositionStateEnum[val as Exclude<keyof typeof JewelFarmPositionStateEnum, 'parse'>];
}

export interface EsdtTokenPayment {
  token_identifier: string,
  token_nonce: number,
  amount: string,
}

export interface JewelFarmPosition {
  position_id: number,
  position_farm_id: number,
  position_state: JewelFarmPositionStateEnum,
  position_owner: string,
  position_leverage_percent: number,
  position_debt_token: string,
  position_debt_amount: string,
  position_farm_share: string,
  position_open_timestamp: number,
  position_deposit_payments: EsdtTokenPayment[],
}

export interface JewelFarmPositionSafetyContext {
  position_id: number,
  value: string,
  lp_token_amount: string,
  debt_ratio: number,
  liquidation_threshold: number,
  to_be_liquidated: boolean,
}

// stake
export enum JewelStakeStateEnum {
  Active = "Active",
  Inactive = "Inactive",
}

export function parseJewelStakeStateEnum(val: string): JewelStakeStateEnum {
  return JewelStakeStateEnum[val as Exclude<keyof typeof JewelStakeStateEnum, 'parse'>];
}

export enum JewelStakeEpochStateEnum {
  Pending = "Pending",
  Opened = "Opened",
  Finished = "Finished",
  Claimable = "Claimable",
}

export function parseJewelStakeEpochStateEnum(val: string): JewelStakeEpochStateEnum {
  return JewelStakeEpochStateEnum[val as Exclude<keyof typeof JewelStakeEpochStateEnum, 'parse'>];
}

export interface JewelStakeBaseContext {
  state: JewelStakeStateEnum,
  admins: string[],
  jewel_farm_address: string,
  ash_token: string,
  jwlash_token: string,
  redeem_token_id: string,
  epoch_length: number,
  lock_period: number,
  current_epoch_id: number,
  current_epoch_state: JewelStakeEpochStateEnum,
  total_stake_amount: string,
  total_staker_count: number,
}

export interface JewelStakeEpochContext {
  epoch_id: number,
  epoch_state: JewelStakeEpochStateEnum,
  epoch_start_timestamp: number,
  epoch_end_timestamp: number,
  epoch_total_stake_amount: string,
  epoch_total_staker_count: number,
  epoch_reward_tokens: string[],
  epoch_reward_amounts: string[],
  epoch_claimed_reward_amounts: string[],
}

export interface JewelStakeUserContext {
  user_address: string,
  user_stake_amount: string,
  user_last_stake_timestamp: number,
}

export interface JewelStakeUserEpochContext {
  epoch_id: number,
  claimed: boolean,
  claimable: boolean,
  rewards_tokens: string[],
  rewards_amounts: string[],
}

// onedex farm
export enum JewelOnedexFarmStateEnum {
  Inactive = "Inactive",
  Active = "Active",
}

export function parseJewelOnedexFarmStateEnum(val: string): JewelOnedexFarmStateEnum {
  return JewelOnedexFarmStateEnum[val as Exclude<keyof typeof JewelOnedexFarmStateEnum, 'parse'>];
}

export enum JewelOnedexPositionEnum {
  None = "None",
  Opened = "Opened",
  Closed = "Closed",
  Liquidated = "Liquidated",
}

export function parseJewelOnedexPositionEnum(val: string): JewelOnedexPositionEnum {
  return JewelOnedexPositionEnum[val as Exclude<keyof typeof JewelOnedexPositionEnum, 'parse'>];
}


export interface JewelOnedexFarmBaseContext {
  state: JewelOnedexFarmStateEnum,
  admins: string[],
  onedex_swap_address: string,
  onedex_farm_address: string,
  wegld_token_id: string,
  egld_wrapper_address: string,
  fee_address: string,
  farm_count: number,
  last_position_id: number,
  total_opened_position_count: number,
}

export interface JewelOnedexFarmContext {
  farm_id: number,
  dex_swap_pool_id: number,
  dex_farm_pool_id: number,
  min_deposit_base_token: string,
  min_deposit_base_amount: string,
  min_compound_rewards_amount: string,
  fee_percent: number,
  first_token_id: string,
  second_token_id: string,
  lp_token_id: string,
  rewards_token_id: string,
  pending_first_token_amount: string,
  pending_second_token_amount: string,
  farm_share: string,
  lp_token_amount: string,
  current_rewards_amount: string,
  farm_yearly_reward_amount: string,
  lp_token_supply: string,
  first_token_reserve: string,
  second_token_reserve: string,
  opened_position_count: number,
  apr: number,
}

export interface JewelOnedexPostionContext {
  position_id: number,
  position_farm_id: number,
  position_state: JewelOnedexPositionEnum,
  position_owner: string,
  position_farm_share: string,
  position_open_timestamp: number,
  position_deposit_payments: EsdtTokenPayment[],
}

// Liquid stake
export enum JewelLiquidStakeStateEnum {
  Inactive = "Inactive",
  Active = "Active",
}

export function parseJewelLiquidStakeStateEnum(val: string): JewelLiquidStakeStateEnum {
  return JewelLiquidStakeStateEnum[val as Exclude<keyof typeof JewelLiquidStakeStateEnum, 'parse'>];
}

export interface JewelLiquidStakeBaseContext {
  state: JewelLiquidStakeStateEnum,
  admins: string[],

  fee_percent: number,
  fee_address: string,

  jwlegld_token_id: string,
  sjwlegld_token_id: string,

  unstake_token_id: string,

  whitelisted_sp_addresses: string[],
  sp_vote_percents: number[],
  sp_undelegation_ranking: string[],
  sp_delegated_amounts: string[],
}

export interface JewelLiquidStakeStatsContext {
  prestaked_egld_amount: string,
  staked_egld_reserve: string,
  preunstaked_egld_amount: string,
  jwlegld_supply: string,
  delegating_egld_amount: string,
  undelegating_egld_amount: string,

  unstake_token_supply: string,
  unbonded_egld_amount: string,

  jwlegld_pool_reserve: string,
  sjwlegld_pool_reserve: string,
}

export interface Delegation {
  delegation_id: number,
  time: string,
  contract: string,
  userUnBondable: string,
  userActiveStake: string,
  claimableRewards: string,
  userUndelegatedList: {
    amount: string,
    seconds: number,
  }[],
  undelegated: string,
}

// Gauge
export interface JewelGaugeCommonContext {
  ash_stake_address: string,
  liquid_stake_address: string,
  vote_reset_timestamp: number,
  user_count: number,
  sp_addresses: string[],
  vote_points: string[],
  total_jwlash_stake_amount: string,
}

export interface JewelGaugeUserContext {
  user_jwlash_stake_amount: string,
  user_vote_percents: number[],
  user_last_vote_timestamps: number[],
  user_vote_points: string[],
}

export interface JewelVoteAllocation {
  title: string,
  value: number,
  color: string,
}

// Hatom Farm
export interface JewelHatomFarmCommonContext {
  hatom_controller_address: string
  jewel_lend_address: string,
  jewel_swap_router_address: string,
  jewel_liquid_stake_address: string,
  fee_percents: number[],
  fee_addresses: string[],
  number_of_farms: number,
  number_of_positions: number,
}

export interface JewelHatomFarmContext {
  hatom_money_market_address: string,
  hatom_base_token_id: string,
  hatom_ib_token_id: string,
  min_deposit_amount: string,
  max_debt_amount: string,
  hatom_loop_count: number,
  hatom_borrow_percent: number,
  hatom_reward_token_ids: string[],

  hatom_base_supply_amount: string,
  hatom_base_debt_amount: string,
  hatom_ib_supply_amount: string,
  jewel_lend_borrow_amount: string,
  hatom_farm_share: string,
  hatom_farm_lender_share: string,
  hatom_debt_share: string,

  number_of_positions: number,
}

export interface JewelHatomPostionContext {
  position_id: number,
  position_hatom_mm_address: string,
  position_state: JewelFarmPositionStateEnum,
  position_owner: string,
  position_leverage_percent: number,
  position_jewel_debt_amount: string,
  position_farm_share: string,
  position_farm_lender_share: string,
  position_debt_share: string,
  position_open_timestamp: number,
  position_deposit_payment: EsdtTokenPayment,
}

// JWLUSD
export interface JewelStableCommonContext {
  jewel_ashswap_farm_address: string,
  jewel_swap_router_address: string,
  hatom_controller_address: string,
  hatom_base_token_ids: string[],
  hatom_mm_addresses: string[],
  hatom_ib_token_ids: string[],
  hatom_reward_token_ids: string[],
  fee_percent: number,
  fee_address: string,
  jwlusd_token: string,
  sjwlusd_token: string,
  unstake_token: string,
}

export interface JewelStableStatsContext {
  hatom_base_current_reserves: string[],
  hatom_ib_token_reserves: string[],
  jwlusd_supply: string,
  jwlusd_pool_reserve: string,
  sjwlusd_pool_reserve: string,
  unbonding_jwlusd_reserve: string,
}

export interface JewelHatomDailyStateHistory {
  moneyMarketState: {
    borrowRatePerSecond: string,
    borrows: string,
    cash: string,
    reserves: string,
    supplyRatePerSecond: string,
    timestamp: string,
    totalSupply: string,
  }
}

export interface HatomHTokenType {
  decimals: number,
  id: string,
  name: string,
  symbol: string,
}

export interface JewelHatomMoneyMarket {
  address: string,
  collateralFactor: string,
  liquidationIncentive: string,
  supported: boolean,
  hToken: HatomHTokenType,
  dailyStateHistory: JewelHatomDailyStateHistory[],
}

export interface JewelFlashMintCommonContext {
  liquid_stake_sc_address: string,
  jewel_swap_router_sc_address: string,
  hatom_controller_sc_address: string,
  egld_wrapper_sc_address: string,
  jewel_stable_sc_address: string,
  jewel_ashswap_farm_sc_address: string,
  wegld_token_id: string,
  jwlegld_token_id: string,
  sjwlegld_token_id: string,
  jwlusd_token_id: string,
  usdc_token_id: string,
  farmer_fee_percent: number,
  protocol_fee_percent: number,
  treasury_wallet: string,
}

export interface JewelFlashMintFarmContext {
  farm_id: number,
  farm_max_cap: string,
  farm_base_token_id: string,
  farm_deposit_token_id: string,
  farm_debt_token_id: string,
  min_deposit_base_amount: string,
  farm_leverage_max_percent: number,

  farm_base_supply_amount: string,
  farm_base_borrow_amount: string,
  farm_share: string,
  farm_jwlegld_borrow_amount: string,
  farm_jwlusd_borrow_amount: string,
  farm_hatom_money_market_sc_address: string,

  number_of_positions: number,
}

export interface JewelFlashMintPositionContext {
  index: number,
  position_id: number,
  position_farm_id: number,
  position_state: JewelFarmPositionStateEnum,
  position_owner: string,
  position_leverage_percent: number,
  position_farm_share: string,
  position_debt_amount: string,
  position_open_timestamp: number,
  position_deposit_payment: EsdtTokenPayment,
  lp_position_deposit_value: string,
  is_lp_position: boolean,
}

export interface JewelLpFarmContext {
  lp_farm_id: number,
  jaf_farm_id: number,
  lp_farm_base_token_id: string,
  lp_farm_debt_token_id: string,
  lp_farm_min_deposit_base_amount: string,
  lp_farm_leverage_max_percent: number,
  lp_farm_max_cap: string,
  lp_farm_debt_token_amount: string,

  token_ids: string[],
  lp_token_id: string,

  number_of_positions: number,
}

// Jewel Borrow
export interface JewelMoneyMarketCommonContext {
  jewel_swap_router_address: string,
  jewel_liquid_stake_address: string,
  jewel_stablecoin_address: string,
  jewel_lend_address: string,
  hatom_controller_address: string,
  hatom_liquid_staking_address: string,
  jewel_hatom_farm_address: string,
  jewel_ashswap_farm_address: string,
  egld_wrapper_address: string,

  jwlegld_token_id: string,
  jwlusd_token_id: string,
  wegld_token_id: string,
  ashswap_farm_reward_token_id: string,

  fee_address: string,
}

export enum MoneyMarketPoolTypeEnum {
  Sjwlegld = 'Sjwlegld',
  JewelLend = 'JewelLend',
  Hatom = 'Hatom',
  Sjwlusd = 'Sjwlusd',
  BaseHatom = 'BaseHatom',
  Lp = 'Lp',
  Idle = 'Idle',
}

export function parseMoneyMarketPoolTypeEnum(val: string): MoneyMarketPoolTypeEnum {
  return MoneyMarketPoolTypeEnum[val as Exclude<keyof typeof MoneyMarketPoolTypeEnum, 'parse'>];
}

export interface JewelMoneyMarketPoolContext {
  debt_token_id: string,
  base_token_id: string,
  borrow_pool_type: MoneyMarketPoolTypeEnum,
  price_multiplier: string,
  max_borrow_percent: number,
  liquidation_percent: number,
  max_debt_cap: string,
  hatom_mm_address: string,
  supplied_amount: string,
  supplied_share: string,
  borrowed_amount: string,
  base_token_amount: string,
  fee_rate: string,
  fee_rate_update_timestamp: number,
  instant_apr: number,

  hatom_base_token_id: string,

  ashswap_farm_id: number,
  ashswap_farm_token_id: string,
  ashswap_farm_token_nonce: number,
  ashswap_farm_token_amount: string,
  ashswap_farm_reward_pending_amount: string,
  ashswap_farm_lp_supply_amount: string,
  ashswap_farm_lp_supply_debt_value: string,
}

export interface JewelMoneyMarketUserPoolContext {
  debt_token_id: string,
  base_token_id: string,
  user_supplied_share: string,
  user_supplied_amount: string,
  user_max_borrow_amount: string,
  user_borrowed_amount: string,
  user_debt_amount: string,
}

// Jewel xExchange Farm
export interface JewelExchangeFarmBaseContext {
  wrapped_farm_token: string,
  unstake_farm_token: string,
  jwlxmex_token: string,
  sjwlxmex_token: string,

  fee_percents: number[],
  fee_addresses: string[],
}

export interface JewelExchangeFarmStatsContext {
  locked_xmex_reserve: string,
  jwlxmex_supply: string,
  jwlxmex_pool_reserve: string,
  sjwlxmex_pool_reserve: string,
  internal_locked_token: EsdtTokenPayment,
}

export interface JewelExchangeFarmContext {
  farm_address: string,
  farm_token_id: string,
  farming_token_id: string,
  minimum_farming_epochs: number,

  farm_token_payment: EsdtTokenPayment,
  farm_unstaked_reserve: string,
  farm_reward_reserve: string,
  farm_rps: string,

  pair_address: string,
  pair_token_ids: string[],
  pair_token_amounts: string[],

  min_deposit_base_token: string,
  min_deposit_base_amount: string,
}

export interface JewelExchangeLockOptionContext {
  lock_epochs: number,
  penalty_start_percentage: number,
}

export interface JewelExchangeFarmTokenContext {
  user_reward_amounts_vec: string[],
  pair_token_payments_vec: EsdtTokenPayment[][],
}

export enum JewelExchangePositionStateEnum {
  Active = 'Active',
  Unstake = 'Unstake',
  Unbond = 'Unbond',
}

export interface JewelExchangePositionContext extends NftWithIndexType {
  farm_address: string,
  farm_apy: number,
  position_state: JewelExchangePositionStateEnum,
  deposit_value: string,
  unstake_epoch: number,
  contextIndex: number,
}

// Jewel Flexiloan

export enum JewelFlexiLoanDexTypeEnum {
  Ashswap = 'Ashswap',
  Onedex = 'Onedex',
}

export function parseJewelFlexiLoanDexTypeEnum(val: string): JewelFlexiLoanDexTypeEnum {
  return JewelFlexiLoanDexTypeEnum[val as Exclude<keyof typeof JewelFlexiLoanDexTypeEnum, 'parse'>];
}

export interface JewelFlexiLoanCommonContext {
  liquid_stake_sc_address: string,
  onedex_swap_sc_address: string,
  onedex_farm_sc_address: string,
  jewel_ashswap_farm_sc_address: string,
  wegld_token_id: string,
  jwlegld_token_id: string,
  sjwlegld_token_id: string,
  lock_period: number,
  debt_limit: string,
  treasury_address: string,
}

export interface JewelFlexiLoanWhitelistContext {
  wp_address: string,
  wp_token_id: string,
  wp_dex_type: JewelFlexiLoanDexTypeEnum,
  wp_ashswap_pool_address: string,
  wp_ashswap_farm_address: string,
  wp_onedex_swap_pool_id: number,
  wp_onedex_farm_pool_id: number,

  wp_sjwlegld_amount: string,
  wp_jwlegld_debt_amount: string,

  wp_avail_jwlegld_withdraw_amount: string,
}

// Jewel Global Pool
export interface JewelGlobalPoolCommonContext {
  treasury_address: string,
  treasury_percent: number,
  jewel_swap_router_address: string,
  jewel_oracle_address: string,
  max_debt_percent: number,
  liquidation_percent: number,

  pools_num: number,
  users_num: number,
}

export interface JewelGlobalPoolContext {
  token_id: string,
  supplied_amount: string,
  supplied_share: string,
  debt_amount: string,
  debt_share: string,
  update_timestamp: number,
  borrow_apr: number,
  token_price: string,
}

export interface JewelGlobalUserPoolContext {
  token_id: string,
  supplied_share: string,
  supplied_amount: string,
  debt_share: string,
  debt_amount: string,
}

export interface JewelGlobalUserContext {
  supplied_value: string,
  debt_value: string,
}

// Jewel Facilitator
export interface JewelFacilitatorCommonContext {
  treasury_address: string,
  jewel_global_pool_address: string,

  jewel_liquid_stake_address: string,
  jwlegld_token_id: string,
  jewel_stablecoin_address: string,
  jwlusd_token_id: string,

  jwlegld_facilitated_amount: string,
  jwlusd_facilitated_amount: string,
}

// Jewel BTC,ETH Stake
export interface JewelBtcEthStakeBaseContext {
  jewel_hatom_farm_address: string,
  jewel_swap_router_address: string,
  base_token_id: string,
  fee_percent: number,
  fee_address: string,
  base_token_decimal: number,
  base_token_hatom_mm_address: string,
  min_compound_reward_amount: string,

  jwl_token_id: string,
  unstake_token_id: string,

  jwl_token_supply: string,
  ib_token_supply: string,

  jwl_token_pool_reserve: string,
  sjwl_token_pool_reserve: string,
}

export interface JewelBtcEthStakeUserContext {
  user_sjwl_amount: string,
  jwl_token_id: string,
  user_address: string,
}
