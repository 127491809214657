import { Address, ArgSerializer, BigUIntValue, BooleanValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import {
  JWLASH_CONVERT_GAS_LIMIT,
  JWLASH_STAKE_GAS_LIMIT,
  JWLASH_CLAIM_GAS_LIMIT,
  JWLASH_CONVERT_LOCK_GAS_LIMIT,
  JWLASH_UNSTAKE_GAS_LIMIT,
  JEWEL_LIQUID_STAKE_SC_ADDRESS, JEWEL_LIQUID_UNSTAKE_GAS_LIMIT, JEWEL_HTM_STAKE_SC_ADDRESS
} from 'config';

export const sendJewelLock = async (chainID: string, address: string, lockTokenId: string, lockAmount: string, isStakeAfterLock: boolean, scAddress: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(lockTokenId),
    new BigUIntValue(lockAmount),
    new StringValue('lock'),
    new BooleanValue(isStakeAfterLock)
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: isStakeAfterLock ? JWLASH_CONVERT_LOCK_GAS_LIMIT : JWLASH_CONVERT_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Lock Request',
      errorMessage: 'Error occured during Lock Request',
      successMessage: 'Lock Request Successful',
    }
  });
};

export const sendJewelStake = async (chainID: string, address: string, stakeTokenId: string, stakeAmount: string, scAddress: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(stakeTokenId),
    new BigUIntValue(stakeAmount),
    new StringValue('stake'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: JWLASH_STAKE_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Stake Request',
      errorMessage: 'Error occured during Stake Request',
      successMessage: 'Stake Request Successful',
    }
  });
};

  export const sendJewelHtmRedeem = async (chainID: string, address: string, unstakeTokenId: string, unstakeAmount: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(unstakeTokenId),
    new BigUIntValue(unstakeAmount),
    new StringValue('redeemHtm'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_HTM_STAKE_SC_ADDRESS),
    gasLimit: JEWEL_LIQUID_UNSTAKE_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Redeem Request',
      errorMessage: 'Error occured during Redeem Request',
      successMessage: 'Redeem Request Successful',
    }
  });
};

export const sendJewelUnstake = async (chainID: string, address: string, unstakeAmount: string, scAddress: string) => {
  const args: TypedValue[] = [
    new BigUIntValue(unstakeAmount),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`unstake@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: JWLASH_UNSTAKE_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unstake Request',
      errorMessage: 'Error occured during Unstake Request',
      successMessage: 'Unstake Request Successful',
    }
  });
};

export const sendJewelClaimReward = async (chainID: string, address: string, epochIds: number[], scAddress: string) => {

  const args: TypedValue[] = [];
  epochIds.map(epoch_id => args.push(new U32Value(epoch_id)));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`claimRewards@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: JWLASH_CLAIM_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Claim Request',
      errorMessage: 'Error occured during Claim Request',
      successMessage: 'Claim Request Successful',
    }
  });
};
