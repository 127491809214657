import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, Modal, Slider, styled } from '@mui/material';
import { useGetAccount, useGetNetworkConfig, useGetIsLoggedIn, useTrackTransactionStatus, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { InputNumber } from 'antd';
import './index.scss';
import { animated, useSpring } from 'react-spring';
import { JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelLendPool, JewelOnedexFarmContext } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, ERROR_CONNECT_YOUR_WALLET, ERROR_NOT_ENOUGH_BALANCE, ERROR_VALUE_CANNOT_BE_NEGATIVE, EXTRA_GAS_FEE_AMOUNT, getFarmApy, getHatomApy, getJewelHatomPoolApy, getTokenDecimal, getTokenImage, getTokenTicker, PERCENT_DENOMINATOR, toastError, YEAR_IN_HOURS } from 'z/utils';
import { contracts, JEWEL_LEND_MAX_BORROWING_CAP_PERCENT, SLIPPAGE } from 'config';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { openHatomPosition, openOnedexPosition } from 'z/elrond';
import { routeNames } from 'routes';
import { JewelHistory } from 'z/types/history';
import { getChainId } from 'z/elrond/sdkDappHelpers';

const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const LeverageSlider = styled(Slider)({
    color: '#54F5B7',
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-markLabel': {
        top: '30px'
    },
    '& .MuiSlider-thumb': {
        height: 15,
        width: 15,
        backgroundColor: 'currentColor',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1,
        fontSize: 10,
        background: 'unset',
        padding: 0,
        width: 35,
        height: 35,
        color: 'black',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#54F5B7',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const valueLabelFormat = (value: number) => {
    if (value === 1) {
        return `${value} X`;
    }
    return `${value} X`;
};

interface valueMark {
    value: number,
    label: string,
}

const LEVERAGE_MARKS: valueMark[] = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 1.5,
        label: '1.5',
    },
    {
        value: 2,
        label: '2',
    },
];

interface HatomFarmPoolProps {
    farm: JewelHatomFarmContext,
    hatomMoneyMarkets: JewelHatomMoneyMarket[],
    hatomFormattedRewards: any[],
    lendPools: JewelLendPool[],
    userTokens: any,
    feePercent: number,
    poolName: string,
    jewelHatomAprs: JewelHistory[],
}

export const HatomFarmPool: FC<HatomFarmPoolProps> = ({
    farm,
    hatomMoneyMarkets,
    hatomFormattedRewards,
    lendPools,
    userTokens,
    feePercent,
    poolName,
    jewelHatomAprs,
}) => {
    const navigate = useNavigate();
  const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
    const { address, balance, shard } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [jewelHatomApr, setJewelHatomApr] = useState<number>(0);
    useEffect(() => {
        for (const jewelHatomApr of jewelHatomAprs) {
            if (getTokenTicker(farm.hatom_base_token_id) === jewelHatomApr.name) {
                setJewelHatomApr(jewelHatomApr.aprs[0].apr);
                break;
            }
        }
    }, [jewelHatomAprs]);

    const [apy, setApy] = useState<number>(0);
    useEffect(() => {
        if (!farm || hatomMoneyMarkets.length === 0) return;

        setApy(getJewelHatomPoolApy(farm, hatomMoneyMarkets, hatomFormattedRewards, feePercent));

    }, [farm, hatomMoneyMarkets, hatomFormattedRewards, feePercent]);

    const [showFarmModal, setShowFarmModal] = useState<boolean>(false);
    const [selectedLeverage, setSelectedLeverage] = useState<number>(1);
    const [tokenAmount, setTokenAmount] = useState<string>('0');
    const [tokenErrorMsg, setTokenErrorMsg] = useState<string>('');
    const [leverage, setLeverage] = useState<number>(1); // modal

    const handleChangeSelectedLeverage = (value: any, isModal = false) => {
        const maxLeverage = farm.hatom_loop_count > 0 ? 2 : 1;
        if (Number(value) > maxLeverage) {
            if (isModal) {
                setLeverage(maxLeverage);
            } else {
                setSelectedLeverage(maxLeverage);
            }
        } else {
            if (isModal) {
                setLeverage(value);
            } else {
                setSelectedLeverage(value);
            }
        }
    };

    const handleShowModal = () => {
        if (!farm) return;
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }
        setLeverage(1);
        setTokenAmount('0');
        setShowFarmModal(true);
    };

    const getErrorMsg = (value: BigNumber, index: number): string => {
        let userBalance = '0';
        if (getTokenTicker(farm.hatom_base_token_id) === 'EGLD') {
            userBalance = BigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
        } else {
            userBalance = userTokens[farm.hatom_base_token_id].balance;
        }

        let error = '';

        if (value == undefined) {
            error = 'Invalid number';
        } else if (value.comparedTo(0) < 0) {
            error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
        } else {
            if (value.comparedTo(userBalance) > 0) {
                error = ERROR_NOT_ENOUGH_BALANCE;
            }
        }

        return error;
    };

    const handleChangeTokenAmount = (value: string, index: number) => {
        setTokenAmount(value);
        setTokenErrorMsg(getErrorMsg(convertEsdtToWei(value, getTokenDecimal(farm.hatom_base_token_id)), index));
    };

    const handleMax = async (index: number) => {
        let value = '0';
        if (getTokenTicker(farm.hatom_base_token_id) === 'EGLD') {
            value = convertWeiToEsdt(BigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT), getTokenDecimal(farm.hatom_base_token_id)).toFixed();
        } else {
            value = convertWeiToEsdt(userTokens[farm.hatom_base_token_id].balance, getTokenDecimal(farm.hatom_base_token_id)).toFixed();
        }
        handleChangeTokenAmount(value, index);
    };

    const handleOpenPosition = async () => {
        if (!farm || tokenErrorMsg !== '') return;

        // check min deposit
        if (convertEsdtToWei(tokenAmount, getTokenDecimal(farm.hatom_base_token_id)).lt(farm.min_deposit_amount)) {
            toastError(`Please deposit more than ${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.min_deposit_amount, getTokenDecimal(farm.hatom_base_token_id)))} ${getTokenTicker(farm.hatom_base_token_id)}`);
            return;
        }

        let availableAmount = '0';
        for (let i = 0; i < lendPools.length; i++) {
            if (lendPools[i].deposit_token_id === farm.hatom_base_token_id) {
                if (BigNumber(lendPools[i].deposit_token_amount).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT).gt(lendPools[i].loan_amount)) {
                    availableAmount = BigNumber(lendPools[i].deposit_token_amount).multipliedBy(JEWEL_LEND_MAX_BORROWING_CAP_PERCENT).minus(lendPools[i].loan_amount).toFixed();
                    break;
                }
            }
        }

        const debtAmount = convertEsdtToWei(BigNumber(tokenAmount).multipliedBy(leverage - 1), getTokenDecimal(farm.hatom_base_token_id));
        if (leverage > 1) {
            if (new BigNumber(debtAmount).lte('0')) {
                toastError('Invalid debt amount');
                return;
            }
            if (new BigNumber(availableAmount).lte('0') || new BigNumber(availableAmount).lt(debtAmount)) {
                toastError('Not enough liquidity in lend pool');
                return;
            }
        }

        let wrapAddress = contracts.Wrap0.address;
        if (shard === 1) {
            wrapAddress = contracts.Wrap1.address;
        } else if (shard === 2) {
            wrapAddress = contracts.Wrap2.address;
        }

        // open position 
        const result = await openHatomPosition(chainID, address, farm.hatom_base_token_id, convertEsdtToWei(tokenAmount, getTokenDecimal(farm.hatom_base_token_id)).toFixed(), debtAmount.toFixed(), farm.hatom_money_market_address, wrapAddress, farm.hatom_loop_count > 0 ? 600_000_000 : 150_000_000);

        setShowFarmModal(false);

        setSessionId(result.sessionId);
    };

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('Enter farm request was successful');
            navigate('/mypositions/hatom');
        }
    }, [sessionId, hasPendingTransactions]);

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 farm-container mobile-farm-container'>
                    <div className='col-lg-4 col-md-4 col-sm-4 align-self-center d-flex mt-2'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='combine-bage'>
                                <img src={getTokenImage(farm.hatom_base_token_id)} className='egld-image mx-2' alt={farm.hatom_base_token_id} />
                            </div>
                            <div>
                                <div>
                                    {`${getTokenTicker(farm.hatom_base_token_id)}${farm.hatom_loop_count > 0 ? ` (${farm.hatom_loop_count}X loop)` : ''}`}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-4'>
                        <div className='d-flex align-items-center'>TVL</div>
                        <div>
                            <div className='text-end'>
                                {`${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)))} ${getTokenTicker(farm.hatom_base_token_id)}`}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-between mt-2'>
                        <div className='d-flex'>
                            <div className='d-flex'>APY / APR(24H)</div>
                            <Tooltip
                                placement="top"
                                title="Based on APY of the current underlying farming pools. It does not include fees from occasional rebalancing"
                            >
                                <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                        <div className='avaliable-color'>{`${apy > 0 ? convertBigNumberValueToLocalString(apy * selectedLeverage) : '-'}% / ${convertBigNumberValueToLocalString(jewelHatomApr)}%`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>Leverage</div>
                        <InputNumber
                            className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                            type="number"
                            min={1}
                            step={0.5}
                            value={selectedLeverage}
                            onChange={(value: any) => handleChangeSelectedLeverage(value)}
                        />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center mt-4'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2 w-100" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 farm-container'>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center'>
                        <div className='d-flex align-items-center'>
                            <div className='combine-bage'>
                                <img src={getTokenImage(farm.hatom_base_token_id)} className='egld-image mx-2' alt={farm.hatom_base_token_id} />
                            </div>
                            <div>
                                <div>
                                    {`${getTokenTicker(farm.hatom_base_token_id)}${farm.hatom_loop_count > 0 ? ` (${farm.hatom_loop_count}X loop)` : ''}`}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 text-center align-self-center'>
                        <div className='justify-content-center'>
                            {`${convertBigNumberValueToLocalString(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)))} ${getTokenTicker(farm.hatom_base_token_id)}`}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <div className='avaliable-color'>{`${apy > 0 ? convertBigNumberValueToLocalString(apy * selectedLeverage) : '-'}% / ${convertBigNumberValueToLocalString(jewelHatomApr)}%`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <InputNumber
                            className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                            type="number"
                            min={1}
                            step={0.5}
                            value={selectedLeverage}
                            onChange={(value: any) => handleChangeSelectedLeverage(value)}
                        />
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showFarmModal}
                onClose={() => setShowFarmModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showFarmModal}>
                    <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                        <div className='d-flex'>
                            Looping, an advanced strategy is utilised to allow you to utilize the same collateral multiple times optimally using Hatom, resulting in a higher yield from a larger principal.
                        </div>
                        <div className='d-flex mt-4'>
                            How much would you like to add for farming?
                        </div>
                        <div className='mt-3 borrow-form'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                    <img src={getTokenImage(farm.hatom_base_token_id)} alt={farm.hatom_base_token_id} />
                                    <div style={{ fontSize: '0.9rem' }}>{getTokenTicker(farm.hatom_base_token_id)}</div>
                                </div>
                                <input type="number" value={tokenAmount} onChange={(e: any) => handleChangeTokenAmount(e.target.value, 0)} />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='d-flex align-items-center' style={{ fontSize: '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                    <div style={{ fontSize: '0.9rem' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(getTokenTicker(farm.hatom_base_token_id) === 'EGLD' ? userTokens['EGLD']?.balance : userTokens[farm.hatom_base_token_id]?.balance, getTokenDecimal(farm.hatom_base_token_id)))} ${getTokenTicker(farm.hatom_base_token_id)}`}</div>
                                </div>
                                <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                    <button className='max-but' onClick={() => handleMax(0)}>MAX</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                            {tokenErrorMsg}
                        </div>
                        {
                            farm.hatom_loop_count > 0 && (
                                <>
                                    <div className={`d-flex gap-2 ${tokenErrorMsg === '' ? 'mt-2' : ''}`}>
                                        <div className='d-flex align-items-center'>Leverage</div>
                                        <InputNumber
                                            className={`farm-leverage-input ${isMobile ? 'text-center' : 'px-3'}`}
                                            type="number"
                                            min={1}
                                            step={0.5}
                                            value={leverage}
                                            onChange={(value: any) => handleChangeSelectedLeverage(value, true)}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div style={{ width: '95%' }}>
                                            <LeverageSlider
                                                valueLabelDisplay="auto"
                                                aria-label="pretto slider"
                                                min={1}
                                                max={2}
                                                step={0.01}
                                                marks={LEVERAGE_MARKS}
                                                value={leverage}
                                                valueLabelFormat={valueLabelFormat}
                                                onChange={(e: any) => setLeverage(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <div className='d-flex mt-2'>
                            Summary
                        </div>
                        <div className='farm-summary mt-2'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span className='d-flex align-items-center'>Leverage</span>
                                <div className='d-flex gap-2'>
                                    <span className='d-flex align-items-center'>{`${leverage}X`}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-1'>
                                <span className='d-flex align-items-center'>Yield Farm APY</span>
                                <div className='d-flex gap-2'>
                                    <span className='avaliable-color d-flex align-items-center'>{`${convertBigNumberValueToLocalString(apy * leverage)}%`}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-1'>
                                <span className='d-flex align-items-center'>Deposit Amount (MIN)</span>
                                <div className='d-flex gap-2'>
                                    <img className='egld-badge' src={getTokenImage(farm.hatom_base_token_id)} alt={farm.hatom_base_token_id} />
                                    <span className='d-flex align-items-center'>{convertBigNumberValueToLocalString(convertWeiToEsdt(farm.min_deposit_amount, getTokenDecimal(farm.hatom_base_token_id)))}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-1'>
                                <span className='d-flex align-items-center'>Debt Amount</span>
                                <div className='d-flex gap-2'>
                                    <img className='egld-badge' src={getTokenImage(farm.hatom_base_token_id)} alt={farm.hatom_base_token_id} />
                                    <span className='d-flex align-items-center'>{convertBigNumberValueToLocalString(BigNumber(tokenAmount).multipliedBy(leverage - 1))}</span>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleOpenPosition}>
                                Farm
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};
