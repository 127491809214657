import { YEAR_IN_SECONDS } from "./constant";
import { floorNumber } from "./number";

export interface PrintDateTimeType {
  years?: string,
  days: string,
  hours: string,
  minutes: string,
  seconds: string,
}

export const convertSecondsToPrintDateTime = (totalSeconds: number): PrintDateTimeType => {
  const days = Math.floor(totalSeconds / (3600 * 24));
  totalSeconds %= 3600 * 24;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
      days: days.toFixed(),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
  };
};

export const convertSecondsToPrintDateTimeString = (totalSeconds: number): string => {
  const { days, hours, minutes, seconds } = convertSecondsToPrintDateTime(totalSeconds);
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

export const convertSecondsToPrintDateTimeInYear = (totalSeconds: number): PrintDateTimeType => {
  const years = Math.floor(totalSeconds / YEAR_IN_SECONDS);
  totalSeconds %= YEAR_IN_SECONDS; 
  const days = Math.floor(totalSeconds / (3600 * 24));
  totalSeconds %= 3600 * 24;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
      years: years.toFixed(),
      days: days.toFixed(),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
  };
};

export const convertSecondsToPrintDateTimeStringInYear = (totalSeconds: number): string => {
  const { years, days, hours, minutes, seconds } = convertSecondsToPrintDateTimeInYear(totalSeconds);
  return `${years}Y ${days}D ${hours}H ${minutes}M ${seconds}S`;
};

export const convertSecondsToDays = (seconds: number, precision?: number): number => {
  return floorNumber(seconds / (3600 * 24), precision);
};

export const convertSecondsToDaysWithWords = (seconds: number, precision?: number): string => {
  const days = floorNumber(seconds / (3600 * 24), precision);
  // console.log("days");
  // console.log(days);
  return days > 1 ? `${days} days` : `${days} day`;
};

export const convertMillisecondsToDays = (seconds: number, precision?: number): number => {
  return floorNumber(seconds / (3600 * 24 * 1000), precision);
};

export const formatLocalDateTime = (milliseconds: number): string => {
  return (new Date(milliseconds)).toLocaleString();
};
