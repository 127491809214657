import React, { FC, useEffect, useState } from 'react';
import { Backdrop, Box, InputAdornment, InputBase, Select, MenuItem } from '@mui/material';
import './index.scss';
import { animated, useSpring } from 'react-spring';
import { JewelFarmBaseContextData, JewelLendPool, NftWithIndexType } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, DAY_IN_SECONDS, ERROR_CONNECT_YOUR_WALLET, ERROR_EXCEED_MAX_VALUE, ERROR_VALUE_CANNOT_BE_NEGATIVE, getFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, parseBigNumber, SECOND_TO_MILLISECONDS, sleep, toastError } from 'z/utils';
import BigNumber from 'bignumber.js';
import { useGetAccount, useGetAccountInfo, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { sendJewelLend, sendJewelWithdraw } from 'z/elrond/sc/jewel-lend';
import { isMobile } from 'react-device-detect';
import { queryGetEquivalent } from 'z/elrond';
import { ASH_TOKEN_ID, contracts, JEWEL_ASHSWAP_SC_DEPLOYED_TIMESTAMP, MAX_GAS_LIMIT, XMEX_TOKEN_ID } from 'config';
import { getNftsByCollectionFromApi } from 'z/api';
import { getChainId } from 'z/elrond/sdkDappHelpers';
interface XMexInputProps {
    amount: string,
    onChangeAmount: any,
    setAmountError: any,
}

export const XMexInput: FC<XMexInputProps> = ({
    amount,
    onChangeAmount,
    setAmountError,
}) => {
  const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
    const { address, shard, account } = useGetAccountInfo();

    // xmex
    const [userXmexTokens, setUserXmexTokens] = useState<NftWithIndexType[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    useEffect(() => {
        (async () => {
            const _userXmexTokens = await getNftsByCollectionFromApi(apiAddress, address, XMEX_TOKEN_ID);
            // console.log('_userXmexTokens: ', _userXmexTokens);
            setUserXmexTokens(_userXmexTokens);
        })();
    }, []);

    const handleMax = () => {
        if (selectedIndex < 0) return;
        onChangeAmount(convertWeiToEsdt(userXmexTokens[selectedIndex].balance, userXmexTokens[selectedIndex].decimals).toFixed());
    };

    return (
        <>
            <div className='d-flex'>
                <InputBase
                    sx={{
                        flex: 1,
                        border: '1px',
                        borderStyle: 'solid',
                        borderRadius: '5px',
                        borderColor: 'gray',
                        padding: '2px 5px',
                    }}
                    endAdornment={
                        <InputAdornment position='end' className='d-flex align-items-center'>
                            <div className='mx-2' style={{ cursor: 'pointer' }} onClick={handleMax}>Max</div>
                            {/* <div className='d-flex align-items-center'>
                                <img className='egld-image' src={getTokenImage(XMEX_TOKEN_ID)} alt={XMEX_TOKEN_ID} />
                                <div className='mx-1'>{getTokenTicker(XMEX_TOKEN_ID)}</div>
                            </div> */}
                        </InputAdornment>
                    }
                    type='number'
                    placeholder='0.00'
                    value={amount}
                    onChange={(e: any) => onChangeAmount(e.target.value)}
                />
            </div>
            <div className='d-flex mt-3 w-100'>
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label=''
                    placeholder='Select Token'
                    renderValue={
                        selectedIndex >= 0 ? undefined : () => <div className='text-muted'>Select Token</div>
                    }
                    value={selectedIndex}
                    onChange={(e: any) => setSelectedIndex(e.target.value)}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-input': { padding: 1 },
                    }}
                >
                    {userXmexTokens.length > 0 ? (
                        userXmexTokens.map((item: NftWithIndexType, index: number) => (
                            <MenuItem key={index} value={index}>
                                <div className='d-flex justify-content-between align-items-center w-100 lend-modal'>
                                    <div className='d-flex align-items-center'>
                                        <img className='egld-image' src={getTokenImage(item.collection)} alt={item.collection} />
                                        <div className='mx-2'>{item.name}</div>
                                    </div>
                                    <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(item.balance, item.decimals))}</div>
                                </div>
                            </MenuItem>
                        ))
                    ) : (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status" />
                        </div>
                    )}

                </Select>
            </div>
        </>
    );
};
