import { Address, AddressValue, ArgSerializer, BigUIntValue, BooleanValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import {
  JWLASH_CONVERT_GAS_LIMIT,
  JWLASH_STAKE_GAS_LIMIT,
  JWLASH_CLAIM_GAS_LIMIT,
  JWLASH_CONVERT_LOCK_GAS_LIMIT,
  JWLASH_UNSTAKE_GAS_LIMIT,
  JEWEL_LIQUID_STAKE_SC_ADDRESS, JEWEL_LIQUID_UNSTAKE_GAS_LIMIT, JEWEL_HTM_STAKE_SC_ADDRESS, JEWEL_BTC_ETH_STAKE_SC_ADDRESS
} from 'config';
import { NftWithIndexType } from 'z/types';
import { NFT_QUANTITY } from 'z/utils';

export const sendJewelBtcEthMint = async (chainID: string, address: string, tokenId: string, amount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(amount),
    new StringValue('mint')
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BTC_ETH_STAKE_SC_ADDRESS),
    gasLimit: 200_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Mint Request',
      errorMessage: 'Error occured during Mint Request',
      successMessage: 'Mint Request Successful',
    }
  });
};

export const sendJewelBtcEthRedeem = async (chainID: string, address: string, baseTokenId: string, tokenId: string, amount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(amount),
    new StringValue('redeem'),
    new TokenIdentifierValue(baseTokenId),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BTC_ETH_STAKE_SC_ADDRESS),
    gasLimit: 120_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Redeem Request',
      errorMessage: 'Error occured during Redeem Request',
      successMessage: 'Redeem Request Successful',
    }
  });
};

export const sendJewelBtcEthWithdraw = async (chainID: string, address: string, baseTokenId: string, nfts: NftWithIndexType[]) => {
  const args: TypedValue[] = [
    new AddressValue(new Address(JEWEL_BTC_ETH_STAKE_SC_ADDRESS)),
  ];
  args.push(new U32Value(nfts.length));
  for (const nft of nfts) {
    args.push(new TokenIdentifierValue(nft.collection));
    args.push(new U64Value(nft.nonce));
    args.push(new U64Value(NFT_QUANTITY));
  }
  args.push(new StringValue('withdraw'));
  args.push(new TokenIdentifierValue(baseTokenId));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  let gasLimit = 300_000_000 + 10_000_000 * nfts.length;
  if (gasLimit > 600_000_000) {
    gasLimit = 600_000_000;
  }

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: gasLimit,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Withdraw Request',
      errorMessage: 'Error occured during Withdraw Request',
      successMessage: 'Withdraw Request Successful',
    }
  });

  return result;
};

export const sendJewelBtcEthStake = async (chainID: string, address: string, baseTokenId: string, tokenId: string, amount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(amount),
    new StringValue('stake'),
    new TokenIdentifierValue(baseTokenId),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BTC_ETH_STAKE_SC_ADDRESS),
    gasLimit: 200_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Stake Request',
      errorMessage: 'Error occured during Stake Request',
      successMessage: 'Stake Request Successful',
    }
  });
};

export const sendJewelBtcEthUnstake = async (chainID: string, address: string, tokenId: string, amount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(amount),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`unstake@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BTC_ETH_STAKE_SC_ADDRESS),
    gasLimit: 300_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unstake Request',
      errorMessage: 'Error occured during Unstake Request',
      successMessage: 'Unstake Request Successful',
    }
  });
};

