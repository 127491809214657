import React, { useEffect, useState } from 'react';
import { Backdrop, Box, InputAdornment, InputBase, Modal } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { animated, useSpring } from 'react-spring';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { isMobile } from 'react-device-detect';
import BigNumber from 'bignumber.js';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { queryCommonSettings, queryViewJewelFarmBaseContextData, queryViewJewelLendContext, queryViewUser, sendClaimRewards, sendLendEgld, sendWithdrawEgld } from 'z/elrond';
import { CommonSettingsType, JewelFarmBaseContextData, JewelLendPool, LendUserType } from 'z/types';
import {
  convertBigNumberValueToLocalString,
  convertEsdtToWei,
  convertSecondsToDays,
  convertSecondsToDaysWithWords,
  convertSecondsToPrintDateTimeString,
  convertWeiToEsdt,
  DAY_IN_SECONDS,
  EGLD_DECIMALS,
  ERROR_CONNECT_YOUR_WALLET,
  ERROR_DATA_NOT_LOADED,
  ERROR_INVALID_VALUE,
  ERROR_NOT_ENOUGH_BALANCE,
  ERROR_VALUE_CANNOT_BE_NEGATIVE,
  EXTRA_GAS_FEE_AMOUNT,
  getApr,
  getApy,
  getTokenImage,
  getTokenTicker,
  parseBigNumber,
  SECOND_TO_MILLISECONDS,
  sleep,
  toastError
} from 'z/utils';
import { Spinner } from 'components/Spinner';
import { LendPool } from 'components/LendPool';
import { LendPoolHeader } from 'components/LendPoolHeader';
import { getAccountBalance, getJewelLendAprs, getUserTokenBalance, getXexchangeTokenPrice } from 'z/api';
import { ASH_TOKEN_ID, NFT_LENDING_SC_ADDRESS, NFT_LENDING_SC_MIN_BALANCE } from 'config';
import { PROTOCOL_ESDT_TOKENS } from 'data';
import { JewelHistory } from 'z/types/history';
import { getChainId } from 'z/elrond/sdkDappHelpers';

const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 3,
  py: 4,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export const Lend = () => {
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address, balance } = useGetAccount();
  // const address = 'erd126rkz9fle5uc9l6z8hqzsfkvy50zy4a4me2748drg0re0pqx6gdq4vzz9z';
  const isLoggedIn = useGetIsLoggedIn();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const [commonSettings, setCommonSettings] = useState<CommonSettingsType | undefined>();
  const [lendUser, setLendUser] = useState<LendUserType | undefined>();
  const [apy, setApy] = useState<string>('7.2');
  const [reward, setReward] = useState<string>('0');

  // const [farmCommonSettings, setFarmCommonSettings] = useState<JewelFarmBaseContextData | undefined>();
  // const [ashPrice, setAshPrice] = useState<number>(0);

  // async function loadAshPrice() {
  //   const _ashPrice = await getXexchangeTokenPrice(apiAddress, ASH_TOKEN_ID);
  //   setAshPrice(_ashPrice);
  // }
  // useEffect(() => {
  //   loadAshPrice();

  //   const debounceFn = setInterval(() => {
  //     loadAshPrice();
  //   }, 30_000);

  //   return () => clearInterval(debounceFn);
  // }, []);

  // get apr from api
  const [jewelLendAprs, setJewelLendAprs] = useState<JewelHistory[]>([]);
  useEffect(() => {
    (async () => {
      const aprs = await getJewelLendAprs();
      // console.log('jewel_lend_aprs: ', aprs);
      setJewelLendAprs(aprs);
    })();
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _commonSettings = await queryCommonSettings(apiAddress, address);
      // console.log('_commonSettings', _commonSettings);
      setCommonSettings(_commonSettings);
      // if (_commonSettings) {
      //   const _apr = getApr(_commonSettings.lend_rewards_pool_amount, _commonSettings.total_lending_amount, _commonSettings.lend_epoch_start_timestamp);
      //   const _apy = getApy(_apr);
      //   setApy(convertBigNumberValueToLocalString(_apy, 2));
      // }
    })();
  }, [hasPendingTransactions]);

  // useEffect(() => {
  //   if (hasPendingTransactions) return;

  //   const aprAddress = 'erd126rkz9fle5uc9l6z8hqzsfkvy50zy4a4me2748drg0re0pqx6gdq4vzz9z';
  //   const aprComputeStartTimestamp = 1711964266;
  //   (async () => {
  //     const _lendUser = await queryViewUser(apiAddress, aprAddress);
  //     // console.log(_lendUser);
  //     if (_lendUser) {
  //       const passedDays = (Date.now() / SECOND_TO_MILLISECONDS - aprComputeStartTimestamp) / DAY_IN_SECONDS;
  //       const apy = BigNumber(_lendUser.lender_rewards_amounts).dividedBy(passedDays).multipliedBy(365).multipliedBy(100);
  //       console.log(convertWeiToEsdt(apy).toFixed(0));
  //     }
  //   })();
  // }, [hasPendingTransactions, address]);

  useEffect(() => {
    if (hasPendingTransactions || !address) return;

    (async () => {
      const _lendUser = await queryViewUser(apiAddress, address);
      setLendUser(_lendUser);
      if (_lendUser) {
        setReward(_lendUser.lender_rewards_amounts);
      }
    })();
  }, [hasPendingTransactions, address]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lendPools, setLendPools] = useState<JewelLendPool[]>([]);
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      setIsLoading(true);
      const _commonSettings = await queryViewJewelLendContext(apiAddress);
      // console.log('_commonSettings', _commonSettings);
      if (_commonSettings) {
        const _lendPools: JewelLendPool[] = [];
        for (let i = 0; i < _commonSettings.lendPool.length; i++) {
          if (getTokenTicker(_commonSettings.lendPool[i].deposit_token_id) === 'EGLD') {
            _lendPools.unshift(_commonSettings.lendPool[i]);
          } else {
            _lendPools.push(_commonSettings.lendPool[i]);
          }
        }

        setLendPools(_lendPools);
      }
      setIsLoading(false);
    })();
  }, [hasPendingTransactions]);

  const [userTokens, setUserTokens] = useState<any>();
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const userTokensBalance: any = [];
      if (isLoggedIn) {
        const balances = await getUserTokenBalance(apiAddress, address, PROTOCOL_ESDT_TOKENS);
        // console.log('balances ', balances);

        userTokensBalance['EGLD'] = {
          balance: balance,
        };

        for (const item of balances) {
          userTokensBalance[item.identifier] = {
            balance: item.balance,
          };
        }
      }

      for (const item of PROTOCOL_ESDT_TOKENS) {
        if (!userTokensBalance[item]) {
          userTokensBalance[item] = {
            balance: '0',
          };
        }
      }

      // console.log('userTokensBalance: ', userTokensBalance);
      setUserTokens(userTokensBalance);
    })();
  }, [hasPendingTransactions, isLoggedIn]);

  const [tab, setTab] = useState<number>(0);
  const [showLendModal, setShowLendModal] = useState<boolean>(false);
  const [lendAmount, setLendAmount] = useState<string>('0');
  const [withdrawAmount, setWithdrawAmount] = useState<string>('0');
  const [lendAmountError, setLendAmountError] = useState('');
  const [withdrawAmountError, setWithdrawAmountError] = useState('');
  const [showClaimModal, setShowClaimModal] = useState<boolean>(false);
  const [isCompound, setIsCompound] = useState<boolean>(true);

  const handleLendModal = () => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    setTab(0);
    setShowLendModal(true);
  };

  const handleWithdrawModal = () => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    setTab(1);
    setShowLendModal(true);
  };

  const onLendAmountChange = (value: string) => {
    if (!address) return;

    const bValue = parseBigNumber(value);
    let error = '';
    if (bValue == undefined) {
      error = 'Invalid number';
    } else if (bValue.comparedTo(0) < 0) {
      error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
    } else if (convertEsdtToWei(bValue).comparedTo(BigNumber(balance).minus(EXTRA_GAS_FEE_AMOUNT)) >= 0) {
      error = ERROR_NOT_ENOUGH_BALANCE;
    }

    setLendAmountError(error);
    setLendAmount(value);
  };
  const onMaxLendAmount = () => {
    if (!address) return;

    onLendAmountChange(
      BigNumber(balance).comparedTo(EXTRA_GAS_FEE_AMOUNT) <= 0
        ? '0'
        : convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(balance).minus(EXTRA_GAS_FEE_AMOUNT)))
    );
  };

  const onWithdrawAmountChange = (value: string) => {
    if (!address) return;

    const bValue = parseBigNumber(value);
    let error = '';
    if (bValue == undefined) {
      error = 'Invalid number';
    } else if (bValue.comparedTo(0) < 0) {
      error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
    } else if (lendUser && convertEsdtToWei(bValue).comparedTo(lendUser.user_lend_amount) > 0) {
      error = ERROR_NOT_ENOUGH_BALANCE;
    }

    setWithdrawAmountError(error);
    setWithdrawAmount(value);
  };
  const onMaxWithdrawAmount = () => {
    if (!address) return;

    onWithdrawAmountChange(
      lendUser
        ? convertBigNumberValueToLocalString(convertWeiToEsdt(lendUser.user_lend_amount))
        : '0'
    );
  };

  const onLendEgld = async () => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (BigNumber(lendAmount).comparedTo(0) <= 0) {
      toastError(ERROR_INVALID_VALUE);
      return;
    }
    if (lendAmountError) {
      toastError(lendAmountError);
      return;
    }

    await sendLendEgld(chainID, address, convertEsdtToWei(lendAmount).toFixed());

    //
    onLendAmountChange('0');
    setShowLendModal(false);
  };

  let lockReleaseDate: Date | undefined;
  if (commonSettings && lendUser) {
    lockReleaseDate = new Date((lendUser.user_last_lend_timestamp + commonSettings.lend_lock_period) * SECOND_TO_MILLISECONDS);
  }

  const onWithdrawEgld = async () => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (BigNumber(withdrawAmount).comparedTo(0) <= 0) {
      toastError(ERROR_INVALID_VALUE);
      return;
    }
    if (withdrawAmountError) {
      toastError(withdrawAmountError);
      return;
    }
    if (lockReleaseDate && new Date() < lockReleaseDate) {
      toastError(`You cannot withdraw before ${lockReleaseDate.toLocaleString()}`);
      return;
    }

    const scBalance = await getAccountBalance(apiAddress, NFT_LENDING_SC_ADDRESS);
    // console.log(scBalance);
    if (BigNumber(scBalance).lt(convertEsdtToWei(NFT_LENDING_SC_MIN_BALANCE).plus(convertEsdtToWei(withdrawAmount)))) {
      toastError('Withdrawal Cap reached for the day. Pls try again in 24 hrs');
      return;
    }

    await sendWithdrawEgld(chainID, address, convertEsdtToWei(withdrawAmount).toFixed());

    //
    onWithdrawAmountChange('0');
    setShowLendModal(false);
  };

  const onClaimRewards = async () => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (!(commonSettings && lendUser)) {
      toastError(ERROR_DATA_NOT_LOADED);
      return;
    }


    if (BigNumber(lendUser.lender_rewards_amounts).lte('0')) {
      toastError('No claimable rewards');
      return;
    }

    await sendClaimRewards(chainID, address, isCompound);
  };

  const passedMilliseconds = commonSettings ? Date.now() - commonSettings.lend_epoch_start_timestamp * SECOND_TO_MILLISECONDS : 0;
  const leftMilliseconds = commonSettings ? Math.max((commonSettings.lend_epoch_start_timestamp + commonSettings.lend_epoch_length) * SECOND_TO_MILLISECONDS - Date.now(), 0) : 0;
  const progressBarFullPercentage = commonSettings ? passedMilliseconds / (commonSettings.lend_epoch_length * SECOND_TO_MILLISECONDS) * 100 : 0;

  return (
    <>
      <div className='container' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>Lend (For NFT Loans)</span>
              <span>Lend and Claim attractive APR rewards. During periods of heavy redemption, there may be a cap set for the day. Kindly wait up to 24 hours to withdraw.</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>

          <div className='text-center mt-4' style={{ color: 'white' }}>
            {/*<h4 className='text-center text-primary'>Epoch {commonSettings ? commonSettings.current_lend_epoch_id : '-'}</h4>*/}
            {/*<h6 className='mt-4 mb-3'>{commonSettings ? convertSecondsToPrintDateTimeString(  SECOND_TO_MILLISECONDS) : '-'} left until next rewards distribution</h6>*/}
            {/*<div className="progress">*/}
            {/*  <div className="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow={progressBarFullPercentage} aria-valuemin={0} aria-valuemax={100} style={{ width: `${progressBarFullPercentage}%` }} />*/}
            {/*</div>*/}
            {/* <h6 className='mt-3'>Accumulated Rewards: {commonSettings ? convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.lend_rewards_pool_amount)) : '-'} EGLD</h6> */}
          </div>
          <LendPoolHeader isApr={false} />
          <div className='row mt-1' style={{ justifyContent: "center" }}>
            {!commonSettings ? (
              <div className='col-12'>
                <Spinner />
              </div>
            ) : (
              <div className='col-12'>
                {isMobile ? (
                  <div className='row mt-4 lend-pool-container mobile-lend-pool-container'>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <img src={getTokenImage('EGLD')} className='egld-image mx-2' alt='egld-logo'></img>
                        <div>
                          <div>EGLD</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-3'>
                      <div>APY:</div>
                      <div className='avaliable-color'>{`${apy} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                      <div>Total Supply</div>
                      <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.total_lending_amount))} EGLD`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                      <div>Total Borrowed</div>
                      <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.total_loan_amount))} EGLD`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                      <div>Utilization</div>
                      <div className='avaliable-color'>{`${convertBigNumberValueToLocalString(BigNumber(commonSettings.total_loan_amount).multipliedBy(100).dividedBy(commonSettings.total_lending_amount))} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                      <div className='align-self-center'>Your Balance</div>
                      <div className='text-end'>
                        <div>{`Lending. ${lendUser ? convertBigNumberValueToLocalString(convertWeiToEsdt(lendUser.user_lend_amount)) : '-'} EGLD`}</div>
                        <div>{`Expected Rewards. ${lendUser ? BigNumber(commonSettings.lend_rewards_pool_amount).comparedTo(0) <= 0 ? '0' : convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(commonSettings.lend_rewards_pool_amount).multipliedBy(lendUser.user_lend_amount).div(commonSettings.total_lending_amount))) : '-'} EGLD`}</div>
                        <div>{`Claimable Rewards. ${lendUser ? convertBigNumberValueToLocalString(convertWeiToEsdt(reward)) : '-'} EGLD`}</div>
                        <div>{`Avail. ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(balance)) : '-'} EGLD`}</div>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center mt-2'>
                      <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                          <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={handleLendModal}>
                            Lend
                          </div>
                        </div>
                        <div className='col-12 d-flex justify-content-center mt-2'>
                          <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={handleWithdrawModal}>
                            Withdraw
                          </div>
                        </div>
                        <div className='col-12 d-flex justify-content-center mt-2'>
                          <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => setShowClaimModal(true)}>
                            Claim Rewards
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='row mt-4 lend-pool-container'>
                    <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                      <div className='d-flex align-items-center'>
                        <img src={getTokenImage('EGLD')} className='egld-image mx-2' alt='egld-logo'></img>
                        <div>
                          <div>EGLD</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                      <div className='avaliable-color'>{`${apy} %`}</div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12 d-flex'>
                      <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                        <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.total_lending_amount))} EGLD`}</div>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                        <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.total_loan_amount))} EGLD`}</div>
                      </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                      <div className='avaliable-color'>{`${convertBigNumberValueToLocalString(BigNumber(commonSettings.total_loan_amount).multipliedBy(100).dividedBy(commonSettings.total_lending_amount))} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center text-end'>
                      <div>{`Lending. ${lendUser ? convertBigNumberValueToLocalString(convertWeiToEsdt(lendUser.user_lend_amount)) : '-'} EGLD`}</div>
                      <span>
                        Expected Rewards
                        <Tooltip
                          placement='top'
                          title='From Start of Epoch to now'
                        >
                          <InfoCircleOutlined className='mx-1' style={{ fontSize: '14px', cursor: 'pointer' }} />
                        </Tooltip>
                        {`${lendUser ? BigNumber(commonSettings.lend_rewards_pool_amount).comparedTo(0) <= 0 ? '0' : convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(commonSettings.lend_rewards_pool_amount).multipliedBy(lendUser.user_lend_amount).dividedBy(commonSettings.total_lending_amount))) : '-'} EGLD`}
                      </span>
                      <div>{`Claimable Rewards. ${lendUser ? convertBigNumberValueToLocalString(convertWeiToEsdt(reward)) : '-'} EGLD`}</div>
                      <div>{`Avail. ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(balance)) : '-'} EGLD`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                      <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                          <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={handleLendModal}>
                            Lend
                          </div>
                        </div>
                        <div className='col-12 d-flex justify-content-center mt-2'>
                          <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={handleWithdrawModal}>
                            Withdraw
                          </div>
                        </div>
                        <div className='col-12 d-flex justify-content-center mt-2'>
                          <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => setShowClaimModal(true)}>
                            Claim Rewards
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className='d-flex mt-5 justify-content-between align-items-center'>
            <div className='title'>
              <span>Lend (For Farms)</span>
              <span>Asset return may risk being delayed in case of the high pools utilisation. Farmers can borrow funds from lending pool for as long as they like, without deadlines for returning the funds.</span>
            </div>
          </div>
          <LendPoolHeader isApr={true} isFarmLend={true} />
          <div className='row mt-1' style={{ justifyContent: "center" }}>
            {isLoading ? (
              <div className='col-12 mt-5'>
                <Spinner />
              </div>
            ) : (
              <div className='col-12'>
                {lendPools.map((item: JewelLendPool, index: number) => (
                  <LendPool key={index} pool={item} userTokens={userTokens} isXMex={false} jewelLendAprs={jewelLendAprs} />
                ))}
                {/* {lendPools.length > 0 && <LendPool pool={lendPools[0]} isXMex={true} />} */}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLendModal}
        onClose={() => setShowLendModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showLendModal}>
          <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
            <div className='d-flex align-items-center'>
              <div>{tab === 0 ? 'Lend EGLD' : 'Withdraw EGLD'}</div>
              <img src={getTokenImage('EGLD')} className='egld-image mx-2' alt='egld-logo'></img>
            </div>
            <div className='d-flex mt-3'>
              {`Available Balance: ${tab === 0 ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(balance))} EGLD` : `${lendUser ? convertBigNumberValueToLocalString(convertWeiToEsdt(lendUser.user_lend_amount)) : '-'} EGLD`}`}
            </div>
            <div className='d-flex mt-1'>
              <InputBase
                sx={{
                  flex: 1,
                  border: '1px',
                  borderStyle: 'solid',
                  borderRadius: '5px',
                  borderColor: 'gray',
                  padding: '2px 5px',
                }}
                endAdornment={<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={tab === 0 ? onMaxLendAmount : onMaxWithdrawAmount}>Max</InputAdornment>}
                type='number'
                placeholder='0.00'
                value={tab === 0 ? lendAmount : withdrawAmount}
                onChange={(e: any) => tab === 0 ? onLendAmountChange(e.target.value) : onWithdrawAmountChange(e.target.value)}
              />
            </div>
            <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
              {tab === 0 ? lendAmountError : withdrawAmountError}
            </div>
            {
              tab !== 0 && lockReleaseDate && (
                <div className='d-flex mt-3'>
                  {
                    new Date() >= lockReleaseDate ? 'You can withdraw EGLD now' : `You can withdraw EGLD after ${lockReleaseDate.toLocaleString()}`
                  }
                </div>
              )
            }
            {
              tab === 0 && (
                <div className='d-flex mt-3'>
                  When you lend EGLD, the 21 days lock period will be reset.
                </div>
              )
            }
            <div className='d-flex justify-content-center mt-4'>
              {tab === 0 ? (
                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onLendEgld}>
                  Lend
                </div>
              ) : (
                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onWithdrawEgld}>
                  Withdraw
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showClaimModal}
        onClose={() => setShowClaimModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showClaimModal}>
          <Box sx={fadeBoxStyle} className='lend-modal' style={{ width: 'auto' }}>
            <div className='d-flex align-items-center'>
              <span>Compound</span>
              <Checkbox
                checked={isCompound}
                onChange={e => setIsCompound(e.target.checked)}
                sx={{ '&': { padding: '0px', marginLeft: '3px' } }}
              />
            </div>
            <div className='d-flex mt-1'>
              {`When you compound rewards, the 21 days lock period will NOT be reset.`}
            </div>
            <div className='d-flex mt-3'>
              {`Rewards: ${lendUser ? convertBigNumberValueToLocalString(convertWeiToEsdt(reward)) : '-'} EGLD`}
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClaimRewards}>
                Claim
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
