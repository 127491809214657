import React from 'react';
import { useNavigate } from 'react-router-dom';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { CollectionLogo } from 'components/CollectionLogo';
import './index.scss';
import { convertBigNumberValueToLocalString, convertWeiToEsdt } from 'z/utils';

export const PoolCard = (props: any) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`/collection/${props.item?.pool_nft_token_id}/${props.item?.pool_id}`);
    };

    return (
        <div className='row mt-3' onClick={handleOnClick}>
            <div className='col-12'>
                <div className='amm-pool-mobile-card'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <CollectionLogo className='amm-pool-mobile-card-logo mt-2' collectionId={props.item?.pool_nft_token_id} />
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                        <div>pool type</div>
                        <div>{props.item?.pool_type}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>curve type</div>
                        <div>{props.item?.pool_curve_type}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>spot price</div>
                        <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.pool_spot_price))}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>fee</div>
                        <div>{props.item?.pool_trade_fee_percent}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>EGLD balance</div>
                        <div className='d-flex gap-1'>
                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                            <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.pool_swap_token_reserve))}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>buy orders</div>
                        <div>{props.item?.pool_buy_order_count}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>sell orders</div>
                        <div>{props.item?.pool_nft_token_reserve}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>accumulated fees</div>
                        <div className='d-flex gap-1'>
                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                            <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.pool_accumulated_fee))}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>delta</div>
                        {props.item?.pool_delta.includes('%') ? (
                            <div>{props.item?.pool_delta}</div>
                        ) : (
                            <div className='d-flex gap-1'>
                                <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.pool_delta))}</div>
                            </div>
                        )}
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>owner</div>
                        <div>{`${props.item?.pool_owner.substring(0, 5)}...${props.item?.pool_owner.slice(-5)}`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
