import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { convertBigNumberValueToLocalString, convertWeiToEsdt } from 'z/utils';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { isMobile, isTablet } from 'react-device-detect';
import { CollectionLogo } from 'components/CollectionLogo';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CollectionCard, TradedColllectionsCard } from 'components/CollectionCard';
import { NftCollection } from 'z/types';

export const CollectionsTab = (props: any) => {
    const columns: GridColDef[] = [
        {
            field: 'nft_collection_name',
            headerName: 'name',
            flex: 3,
            renderCell: (params) => (
                <div className='d-flex gap-2 py-1 align-items-center'>
                    <CollectionLogo className='egld-badge-collection' collectionId={params.row.nft_token_id} />
                    <span>{params.value}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'nft_token_id',
            headerName: 'collection',
            flex: 2,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'nft_listed_count',
            headerName: 'listings',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'nft_floor_price',
            headerName: 'floor price',
            renderHeader: () => (
                <div className='d-flex align-items-center'>
                    <div>floor price</div>
                    <Tooltip
                        placement="top"
                        title="Price of the cheapest NFT listed best offer. Price include fees."
                    >
                        <InfoCircleOutlined className='h5 p-1 mt-2' />
                    </Tooltip>
                </div>
            ),
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'nft_best_offer_price',
            headerName: 'best offer',
            renderHeader: () => (
                <div className='d-flex align-items-center'>
                    <div>best offer</div>
                    <Tooltip
                        placement="top"
                        title="Value of the highest collection offer. Offer include fees."
                    >
                        <InfoCircleOutlined className='h5 p-1 mt-2' />
                    </Tooltip>
                </div>
            ),
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'nft_offer_tvl',
            headerName: 'offer TVL',
            renderHeader: () => (
                <div className='d-flex align-items-center'>
                    <div>offer TVL</div>
                    <Tooltip
                        placement="top"
                        title="total amount of EGLD locked in collection"
                    >
                        <InfoCircleOutlined className='h5 p-1 mt-2' />
                    </Tooltip>
                </div>
            ),
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
    ];

    const navigate = useNavigate();

    const handleOnCellClick = (params: any) => {
        if (props.type === 'create') {
            navigate(`/create-pool/${params.row.nft_token_id}`);
        } else {
            navigate(`/collection/${params.row.nft_token_id}`);
        }
    };

    return (
        <div className='row' style={{ marginTop: '20px' }}>
            {
                props.collections?.map((item: NftCollection, index: number) => {
                    return <div key={index} className='col-lg-2 col-md-3 col-sm-6 col-12 mt-3'><TradedColllectionsCard item={item} /></div>;
                })
            }
            {/* <div className='col-12'>
                {isMobile || isTablet ? (
                    <div className='container'>
                        {props.collections?.map((item: NftCollection, index: number) => {
                            return <CollectionCard key={index} item={item} />;
                        })}
                    </div>
                ) : (
                    <DataGrid
                        sx={{
                            // border: "1px solid #23303d",
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            fontSize: '18px',
                            '& .MuiDataGrid-row': {
                                padding: '4px',
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                color: '#939da7',
                            },
                            color: 'white',
                            '& .MuiDataGrid-virtualScroller': {
                                minHeight: '400px'
                            },
                            '&:hover:not(.Mui-disabled)': {
                                cursor: 'pointer'
                            },
                            fontFamily: 'Poppins',
                        }}
                        autoHeight
                        getRowHeight={() => 'auto'}
                        rows={props.collections}
                        columns={columns}
                        getRowId={(row) => `collection-${row.nft_token_id}`}
                        disableColumnMenu
                        disableSelectionOnClick
                        // hideFooter
                        loading={props.isLoading}
                        onCellClick={handleOnCellClick}
                    />
                )}
            </div> */}
        </div>
    );
};
