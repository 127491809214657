import { Address, ArgSerializer, BigUIntValue, BooleanValue, EnumValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { JEWEL_BORROW_SC_ADDRESS, JEWEL_GLOBAL_POOL_SC_ADDRESS, WEGLD_TOKEN_ID } from 'config';
import { MoneyMarketPoolTypeEnum } from 'z/types';
import { getTokenTicker } from 'z/utils';

export const sendJewelGlobalPoolSupply = async (chainID: string, address: string, selectedTokenId: string, supplyAmount: string, wrapAddress: string) => {
    const txs: any = [];
    if (getTokenTicker(selectedTokenId) === 'EGLD') {
        // wrap egld
        const data = new TransactionPayload('wrapEgld');

        const wrapTx = new Transaction({
            value: supplyAmount,
            sender: new Address(address),
            receiver: new Address(wrapAddress),
            gasLimit: 10_000_000,
            chainID: chainID,
            data,
        });
        txs.push(wrapTx);
    }

    const args: TypedValue[] = [
        new TokenIdentifierValue(getTokenTicker(selectedTokenId) === 'EGLD' ? WEGLD_TOKEN_ID : selectedTokenId),
        new BigUIntValue(supplyAmount),
        new StringValue('supply'),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

    const tx = new Transaction({
        value: '0',
        sender: new Address(address),
        receiver: new Address(JEWEL_GLOBAL_POOL_SC_ADDRESS),
        gasLimit: 40_000_000,
        chainID: chainID,
        data,
    });
    txs.push(tx);

    await refreshAccount();
    await sendTransactions({
        transactions: txs,
        transactionsDisplayInfo: {
            processingMessage: 'Processing Supply Request',
            errorMessage: 'Error occured during Supply Request',
            successMessage: 'Supply Request Successful',
        }
    });
};

export const sendJewelGlobalPoolWithdraw = async (chainID: string, address: string, selectedTokenId: string, withdrawAmount: string) => {
    const args: TypedValue[] = [
        new TokenIdentifierValue(getTokenTicker(selectedTokenId) === 'EGLD' ? WEGLD_TOKEN_ID : selectedTokenId),
        new BigUIntValue(withdrawAmount),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`withdraw@${argumentsString}`);

    const tx = new Transaction({
        value: '0',
        sender: new Address(address),
        receiver: new Address(JEWEL_GLOBAL_POOL_SC_ADDRESS),
        gasLimit: 40_000_000,
        chainID: chainID,
        data,
    });

    await refreshAccount();
    await sendTransactions({
        transactions: tx,
        transactionsDisplayInfo: {
            processingMessage: 'Processing Withdraw Request',
            errorMessage: 'Error occured during Withdraw Request',
            successMessage: 'Withdraw Request Successful',
        }
    });
};

export const sendJewelGlobalPoolBorrow = async (chainID: string, address: string, selectedTokenId: string, borrowAmount: string) => {
    const args: TypedValue[] = [
        new TokenIdentifierValue(getTokenTicker(selectedTokenId) === 'EGLD' ? WEGLD_TOKEN_ID : selectedTokenId),
        new BigUIntValue(borrowAmount),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`borrow@${argumentsString}`);

    const tx = new Transaction({
        value: '0',
        sender: new Address(address),
        receiver: new Address(JEWEL_GLOBAL_POOL_SC_ADDRESS),
        gasLimit: 600_000_000,
        chainID: chainID,
        data,
    });

    await refreshAccount();
    await sendTransactions({
        transactions: tx,
        transactionsDisplayInfo: {
            processingMessage: 'Processing Borrow Request',
            errorMessage: 'Error occured during Borrow Request',
            successMessage: 'Borrow Request Successful',
        }
    });
};

export const sendJewelGlobalPoolRepay = async (chainID: string, address: string, selectedTokenId: string, repayAmount: string, wrapAddress: string) => {
    const txs: any = [];
    if (getTokenTicker(selectedTokenId) === 'EGLD') {
        // wrap egld
        const data = new TransactionPayload('wrapEgld');

        const wrapTx = new Transaction({
            value: repayAmount,
            sender: new Address(address),
            receiver: new Address(wrapAddress),
            gasLimit: 10_000_000,
            chainID: chainID,
            data,
        });
        txs.push(wrapTx);
    }

    const args: TypedValue[] = [
        new TokenIdentifierValue(getTokenTicker(selectedTokenId) === 'EGLD' ? WEGLD_TOKEN_ID : selectedTokenId),
        new BigUIntValue(repayAmount),
        new StringValue('repay'),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

    const tx = new Transaction({
        value: '0',
        sender: new Address(address),
        receiver: new Address(JEWEL_GLOBAL_POOL_SC_ADDRESS),
        gasLimit: 40_000_000,
        chainID: chainID,
        data,
    });
    txs.push(tx);

    await refreshAccount();
    await sendTransactions({
        transactions: txs,
        transactionsDisplayInfo: {
            processingMessage: 'Processing Repay Request',
            errorMessage: 'Error occured during Repay Request',
            successMessage: 'Repay Request Successful',
        }
    });
};
