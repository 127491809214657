import { Address, AddressValue, ArgSerializer, Transaction, TransactionPayload, TypedValue, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { JEWEL_GAUGE_SC_ADDRESS } from 'config';

export const sendJewelGaugeVote = async (chainID: string, address: string, spAddress: string, votePercent: number) => {
  const args: TypedValue[] = [
    new AddressValue(new Address(spAddress)),
    new U64Value(votePercent),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`vote@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_GAUGE_SC_ADDRESS),
    gasLimit: 60_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Vote Request',
      errorMessage: 'Error occured during Vote Request',
      successMessage: 'Vote Request Successful',
    }
  });
};