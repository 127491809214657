import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetAccountInfo, useGetNetworkConfig, useGetIsLoggedIn, useGetPendingTransactions, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { getAmmNftsByCollectionFromApi, getRarityFromXoxno } from 'z/api';
import { NftWithAmmData, NftCollectionPoolInfo, PoolCurveTypeEnum, PoolStateEnum, PoolTypeEnum, NftRarity } from 'z/types';
import {toastError, convertWeiToEsdt, parseBigNumber, convertBigNumberValueToLocalString, sleep} from 'z/utils';
import { PoolPrice, PoolAsset, PoolActionType } from 'components/Pool';
import { Spinner } from 'components/Spinner';
import { queryViewCollectionPool, queryViewPoolNfts, savePool, handleAmmPool, queryViewPoolData } from 'z/elrond';
import { contracts, NFT_AMM_SC_ADDRESS } from 'config';
import { routeNames } from 'routes';
import BigNumber from 'bignumber.js';
import { AmmCollectionContext } from 'App';

export const EditPool = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { address, shard } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();

    const { isChanged, setIsChanged } = useContext(AmmCollectionContext);

    const [poolLoading, setPoolLoading] = useState<boolean>(false);
    const [poolInfo, setPoolInfo] = useState<NftCollectionPoolInfo>();
    const [poolNfts, setPoolNfts] = useState<NftWithAmmData[]>([]);
    const [userNfts, setUserNfts] = useState<NftWithAmmData[]>([]);

    const [poolType, setPoolType] = useState<number>(0);
    const [fee, setFee] = useState<number>(0);
    const [spotPrice, setSpotPrice] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [delta, setDelta] = useState<number>(0);
    const [ordersAmount, setOrdersAmount] = useState<number>(0);
    const [lockedAmount, setLockedAmount] = useState<BigNumber>(new BigNumber(0));

    const [addNfts, setAddNfts] = useState<NftWithAmmData[]>([]);
    const [removeNfts, setRemoveNfts] = useState<NftWithAmmData[]>([]);

    const [rarity, setRarity] = useState<NftRarity[]>([]);

    useEffect(() => {

        if (hasPendingTransactions) return;

        if (!isLoggedIn) {
            navigate(routeNames.mypools);
            return;
        }

        (async () => {
            if (params.collectionId && params.poolId && params.collectionId.length > 0) {
                setPoolLoading(true);
                const [_pool, _poolNfts] = await queryViewPoolData(apiAddress, params.collectionId, Number(params.poolId));
                // console.log('_pool: ', _pool);
                if (_pool) {
                    if (_pool.pool_state === PoolStateEnum.Inactive || _pool.pool_state === PoolStateEnum.ActiveButNoSwap || _pool.pool_owner != address) {
                        setPoolLoading(false);
                        navigate(routeNames.mypools);
                        return;
                    }
                    setPoolInfo(_pool);
                    setFee(_pool.pool_trade_fee_percent);
                    setSpotPrice(convertWeiToEsdt(_pool.pool_spot_price).toNumber());
                    if (_pool.pool_curve_type == PoolCurveTypeEnum.Linear) {
                        setTab(0);
                        setDelta(convertWeiToEsdt(_pool.pool_linear_curve_step_amount).toNumber());
                    } else if (_pool.pool_curve_type == PoolCurveTypeEnum.Exponential) {
                        setTab(1);
                        setDelta(_pool.pool_exponential_curve_step_percent);
                    }
                    if (_pool.pool_type == PoolTypeEnum.Buy) {
                        setOrdersAmount(_pool.pool_buy_order_count);
                        setPoolType(2);
                    } else if (_pool.pool_type == PoolTypeEnum.Sell) {
                        setPoolType(1);
                    } else {
                        setOrdersAmount(_pool.pool_buy_order_count);
                        setPoolType(0);
                    }
                }
                // console.log('_poolNfts: ', _poolNfts);
                const _nfts = await getAmmNftsByCollectionFromApi(apiAddress, NFT_AMM_SC_ADDRESS, params.collectionId, true, _poolNfts);
                setPoolNfts(_nfts);
                
                const _userNfts = await getAmmNftsByCollectionFromApi(apiAddress, address, params.collectionId, false);
                // console.log('_userNfts: ', _userNfts);
                setUserNfts(_userNfts);

                const _rarity: NftRarity[] = await getRarityFromXoxno(params.collectionId);
                // console.log('_rarity: ', _rarity);
                setRarity(_rarity);

                setPoolLoading(false);
            }
        })();

    }, [hasPendingTransactions]);

    const handleEditPool = async () => {
        if (!params.collectionId || !params.poolId) return;
        if (!spotPrice || spotPrice == 0) {
            toastError('Please input spot price');
            return;
        }
        if (!poolInfo || !isPoolChanged()) {
            return;
        }

        if (ordersAmount > 0 && !lockedAmount.gt(new BigNumber(0))) return;

        let wrapAddress = contracts.Wrap0.address;

        if (shard === 1) {
            wrapAddress = contracts.Wrap1.address;
        } else if (shard === 2) {
            wrapAddress = contracts.Wrap2.address;
        }

        let tokenAmount = new BigNumber(0);
        if (poolInfo.pool_type != PoolTypeEnum.Sell && lockedAmount.gt(poolInfo.pool_swap_token_reserve)) {
            tokenAmount = lockedAmount.minus(poolInfo.pool_swap_token_reserve);
        }

        const result = await savePool(address, wrapAddress, params.collectionId, Number(params.poolId), ordersAmount, spotPrice, delta, tokenAmount, tab, fee, addNfts, removeNfts);
        setSessionId(result.sessionId);
    };

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('handleEditPool: success');
            setIsChanged(!isChanged);
            setAddNfts([]);
            setRemoveNfts([]);
        }
    }, [sessionId, hasPendingTransactions]);

    const isPoolChanged = () => {
        if (poolInfo) {
            if (
                fee != poolInfo.pool_trade_fee_percent ||
                !convertWeiToEsdt(poolInfo.pool_spot_price).eq(new BigNumber(spotPrice)) ||
                ordersAmount != poolInfo.pool_buy_order_count ||
                addNfts.length > 0 ||
                removeNfts.length > 0
            ) {
                return true;
            }

            if (poolInfo.pool_curve_type == PoolCurveTypeEnum.Linear && !convertWeiToEsdt(poolInfo.pool_linear_curve_step_amount).eq(new BigNumber(delta))) {
                return true;
            } else if (poolInfo.pool_curve_type == PoolCurveTypeEnum.Exponential && poolInfo.pool_exponential_curve_step_percent != delta) {
                return true;
            }
        }
        return false;
    };

    const canWithdrawFee = () => {
        if (poolInfo && convertWeiToEsdt(poolInfo.pool_accumulated_fee).gt(new BigNumber(0))) {
            return true;
        } else {
            return false;
        }
    };

    const canWithdrawAllLiquidity = () => {
        if (poolInfo && (poolInfo.pool_nft_token_reserve > 0 || poolInfo.pool_buy_order_count > 0)) {
            return true;
        } else {
            return false;
        }
    };

    const canClosePool = () => {
        if (poolInfo && poolInfo.pool_nft_token_reserve == 0 && poolInfo.pool_buy_order_count == 0 && convertWeiToEsdt(poolInfo.pool_accumulated_fee).eq(new BigNumber(0))) {
            return true;
        } else {
            return false;
        }
    };

    const handleWithdrawFee = async () => {
        // withdraw pool fee
        if (!params.collectionId || !params.poolId) return;
        if (canWithdrawFee()) {
            const gasLimit = 10000000;
            const result = await handleAmmPool('withdrawPoolFee', params.collectionId, Number(params.poolId), gasLimit);
            setSessionId(result.sessionId);
        }
    };

    const handleWithdrawAllLiquidity = async () => {
        // withdraw all liquidity
        if (!params.collectionId || !params.poolId || !poolInfo) return;
        if (canWithdrawAllLiquidity()) {
            const gasLimit = 30000000 + (1100000 * (poolInfo.pool_nft_token_reserve + 1));
            const result = await handleAmmPool('withdrawAllLiquidity', params.collectionId, Number(params.poolId), gasLimit);
            setSessionId(result.sessionId);
        }
    };

    const handleClosePool = async () => {
        // close pool
        if (!params.collectionId || !params.poolId || !poolInfo) return;
        if (canClosePool()) {
            const gasLimit = 30000000;
            const result = await handleAmmPool('closePool', params.collectionId, Number(params.poolId), gasLimit);
            setSessionId(result.sessionId);
        }
    };

    return (
        <div className='container-fluid amm-container' style={{ marginTop: '40px' }}>
            {poolLoading ? (
                <Spinner />
            ) : (
                <>
                    <div className='row'>
                        <div className='col-12 mt-5 text-center h2'>
                            Edit Pool
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 mt-3 text-center h4'>
                                Accumulated Fees: {poolInfo ? convertBigNumberValueToLocalString(convertWeiToEsdt(poolInfo.pool_accumulated_fee)) : 0} EGLD
                            </div>
                        </div>
                    </div>
                    <div className='row p-4'>
                        <div className='col-lg-5 col-md-5 col-sm-12 mb-3'>
                            <PoolPrice collectionId={params.collectionId} poolType={poolType} fee={fee} setFee={setFee} spotPrice={spotPrice} setSpotPrice={setSpotPrice} tab={tab} setTab={setTab} delta={delta} setDelta={setDelta} ordersAmount={ordersAmount} setOrdersAmount={setOrdersAmount} lockedAmount={lockedAmount} setLockedAmount={setLockedAmount} actionType={PoolActionType.PoolEdit} poolAmount={poolInfo && poolInfo.pool_swap_token_reserve} />
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12 mb-3'>
                            <PoolAsset collectionId={params.collectionId} poolType={poolType} ordersAmount={ordersAmount} setOrdersAmount={setOrdersAmount} userNfts={userNfts} poolNfts={poolNfts} actionType={PoolActionType.PoolEdit} setAddNfts={setAddNfts} setRemoveNfts={setRemoveNfts} rarity={rarity} />
                        </div>
                    </div>
                    <div className='row mt-2 mx-2'>
                        <div className='col-lg-3 col-md-3 com-sm-12 d-flex mb-2'>
                            <div className={isPoolChanged() ? 'eg-btn btn--primary2 capsule px-4 py-2 w-100' : 'eg-btn amm-pool-handle-button-disabled capsule px-4 py-2 w-100'} style={{ cursor: 'pointer' }} onClick={handleEditPool}>
                                save changes
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 com-sm-12 d-flex mb-2'>
                            <div className={canWithdrawFee() ? 'eg-btn btn--primary2 capsule px-4 py-2 w-100' : 'eg-btn amm-pool-handle-button-disabled capsule px-4 py-2 w-100'} style={{ cursor: 'pointer' }} onClick={handleWithdrawFee}>
                                withdraw fee
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 com-sm-12 d-flex mb-2'>
                            <div className={canWithdrawAllLiquidity() ? 'eg-btn btn--primary2 capsule px-4 py-2 w-100' : 'eg-btn amm-pool-handle-button-disabled capsule px-4 py-2 w-100'} style={{ cursor: 'pointer' }} onClick={handleWithdrawAllLiquidity}>
                                withdraw all liquidity
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 com-sm-12 d-flex mb-2'>
                            <div className={canClosePool() ? 'eg-btn btn--primary2 capsule px-4 py-2 w-100' : 'eg-btn amm-pool-handle-button-disabled capsule px-4 py-2 w-100'} style={{ cursor: 'pointer' }} onClick={handleClosePool}>
                                close pool
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
