import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Modal } from '@mui/material';
import { DataGrid, GridColDef, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { animated, useSpring } from 'react-spring';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import imageQuestionMarkInCircle from 'assets/img/question-mark-in-circle.png';
import { getNftsByIdFromApi } from 'z/api';
import {
  queryCommonSettings,
  queryViewLoansById,
  queryViewOpenedLoans,
  queryViewUser,
  sendRedeemLoan,
  sendRedeemLoans,
  sendRenewAndCashoutLoans,
  sendRenewLoans
} from 'z/elrond';
import { CommonSettingsType, LendUserType, LoanStatusEnum, LoanType, NftWithIndexType } from 'z/types';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, createNftId, ERROR_CONNECT_YOUR_WALLET, ERROR_DATA_NOT_LOADED, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, formatLocalDateTime, getImageUrl, MAX_PERCENTAGE, SECOND_TO_MILLISECONDS, sleep, toastError } from 'z/utils';
import { isMobile } from 'react-device-detect';
import Checkbox from '@mui/material/Checkbox';
import { isDev, MAX_BULK_LOAN_SIZE } from 'config';
import { getChainId } from 'z/elrond/sdkDappHelpers';

const tabs = ['Opened Loans', 'Graced Loans', 'Redeemed Loans', 'Liquidated Loans'];

const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 2,
  py: 3,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export const Loans = () => {
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address, balance } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const [tab, setTab] = useState(0);

  const [commonSettings, setCommonSettings] = useState<CommonSettingsType | undefined>();
  const [lendUser, setLendUser] = useState<LendUserType | undefined>();
  const [loans, setLoans] = useState<LoanType[]>([]);
  const [loansLoading, setLoansLoading] = useState<boolean>(false);
  const [activeLoans, setActiveLoans] = useState<string>('0');
  const [opendLoans, setOpendLoans] = useState<LoanType[]>([]);
  const [gracedLoans, setGracedLoans] = useState<LoanType[]>([]);
  const [redeemedLoans, setRedeemedLoans] = useState<LoanType[]>([]);
  const [liquidatedLoans, setLiquidatedLoans] = useState<LoanType[]>([]);
  const [nfts, setNfts] = useState<NftWithIndexType[]>([]);

  const [selectedLoan, setSelectedLoan] = useState<LoanType>();
  const [showRedeemLoanModal, setShowRedeemLoanModal] = useState<boolean>(false);

  const [selectedLoanIds, setSelectedLoanIds] = useState<any[]>([]);
  const [showRenewLoansModal, setShowRenewLoansModal] = useState<boolean>(false);
  const [renewFeeAmount, setRenewFeeAmount] = useState<string>('0');
  const [cashoutAmount, setCashoutAmount] = useState<string>('0');
  const [debtAmount, setDebtAmount] = useState<string>('0');
  const [isCashOut, setIsCashout] = useState<boolean>(true);
  const [liquidationFee, setLiquidationFee] = useState<string>('0');

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _commonSettings = await queryCommonSettings(apiAddress, address);
      // console.log('_commonSettings', _commonSettings);
      setCommonSettings(_commonSettings);
    })();
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions || !address) return;

    (async () => {
      const _lendUser = await queryViewUser(apiAddress, address);
      // console.log('_lendUser', _lendUser);

      // const _lendUser2 = await queryViewUser2(apiAddress, address);
      // _lendUser!.user_loan_ids =  _lendUser!.user_loan_ids.concat(_lendUser2!.user_loan_ids);
      // // console.log('_lendUser', _lendUser);
      setLendUser(_lendUser);
    })();
  }, [hasPendingTransactions, address]);

  useEffect(() => {
    if (hasPendingTransactions || !address) return;

    (async () => {
      // console.log('_loans', "started fetching");
      if (lendUser && lendUser.user_loan_ids.length > 0) {
        setLoansLoading(true);
        const loans = [];
        for (let i = 0; i < lendUser.user_loan_ids.length; i = i + 25) {
          const slice = lendUser.user_loan_ids.slice(i, i + 25);
          const _loans = await queryViewLoansById(apiAddress, slice);
          loans.push(..._loans);
        }
        loans.sort((a: LoanType, b: LoanType) => {
          return (a.loan_deadline_timestamp > b.loan_deadline_timestamp ? 1 : -1);
        });
        // console.log('loans: ', loans);
        setLoans(loans);
        setLoansLoading(false);

        const _opendLoans = loans.filter(loan => loan.loan_status === LoanStatusEnum.Opened);
        let activeLoansAmount = BigNumber(0);
        _opendLoans.map(v => activeLoansAmount = activeLoansAmount.plus(v.loan_borrow_amount));
        setActiveLoans(activeLoansAmount.toFixed());

        setOpendLoans(_opendLoans);
        setGracedLoans(loans.filter(loan => loan.loan_status === LoanStatusEnum.LiquidationGraced || loan.loan_status === LoanStatusEnum.ExpirationGraced));
        setRedeemedLoans(loans.filter(loan => loan.loan_status === LoanStatusEnum.Closed));
        setLiquidatedLoans(loans.filter(loan => loan.loan_status === LoanStatusEnum.Liquidated || loan.loan_status === LoanStatusEnum.Expired || loan.loan_status === LoanStatusEnum.TopUpClosed));

        // load NFTs from API
        if (loans.length > 0) {
          // console.log('loans: ', loans);
          const _nfts = await getNftsByIdFromApi(apiAddress, loans.map(v => createNftId(v.loan_collection_id, v.loan_nft_nonce)));
          // console.log('_nfts', _nfts);
          setNfts(_nfts);
        } else {
          setNfts([]);
        }
      } else {
        setLoans([]);
        setOpendLoans([]);
        setRedeemedLoans([]);
        setLiquidatedLoans([]);
        setNfts([]);
      }
    })();
  }, [lendUser, hasPendingTransactions]);

  useEffect(() => {
    setSelectedLoanIds([]);
  }, [tab]);

  const onClickRedeemLoan = async () => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (!commonSettings) {
      toastError(ERROR_DATA_NOT_LOADED);
      return;
    }
    if (!selectedLoan) {
      toastError('No Loan is selected');
      return;
    }
    if (BigNumber(selectedLoan.loan_debt_amount).plus(EXTRA_GAS_FEE_AMOUNT).comparedTo(balance) > 0) {
      toastError(ERROR_NOT_ENOUGH_BALANCE);
      return;
    }

    let debt_amount = selectedLoan.loan_debt_amount;
    if (tab === 1) {
      debt_amount = BigNumber(selectedLoan.loan_borrow_amount).multipliedBy(commonSettings.liquidation_fee).dividedBy(MAX_PERCENTAGE).plus(selectedLoan.loan_debt_amount).toFixed();
    }
    await sendRedeemLoan(chainID, address, debt_amount, selectedLoan.loan_id);

    //
    setShowRedeemLoanModal(false);
  };

  const handleSelectionModelChange = (ids: any[]) => {
    if (ids.length > MAX_BULK_LOAN_SIZE) {
      toastError(`Max bulk size is ${MAX_BULK_LOAN_SIZE} loans`);
      return;
    }

    setSelectedLoanIds(ids);
  };

  const onClickRenew = async () => {
    let fee = new BigNumber(0);
    let cashout = new BigNumber(0);
    let debt = new BigNumber(0);
    let _liquidationFee = new BigNumber(0);
    if (selectedLoanIds.length === 0) {
      const loanIds = [];
      if (tab === 0) {
        for (let i = 0; i < opendLoans.length; i++) {
          loanIds.push(opendLoans[i].loan_id);
          fee = fee.plus(BigNumber(opendLoans[i].loan_debt_amount).minus(opendLoans[i].loan_borrow_amount));
          cashout = cashout.plus(opendLoans[i].loan_cashout);
          debt = debt.plus(opendLoans[i].loan_debt_amount);

          if (i > MAX_BULK_LOAN_SIZE) break;
        }
      } else if (tab === 1) {
        for (let i = 0; i < gracedLoans.length; i++) {
          loanIds.push(gracedLoans[i].loan_id);
          fee = fee.plus(BigNumber(gracedLoans[i].loan_debt_amount).minus(gracedLoans[i].loan_borrow_amount));
          cashout = cashout.plus(gracedLoans[i].loan_cashout);
          debt = debt.plus(gracedLoans[i].loan_debt_amount);
          if (commonSettings) {
            _liquidationFee = _liquidationFee.plus(BigNumber(gracedLoans[i].loan_borrow_amount).multipliedBy(commonSettings.liquidation_fee).dividedBy(MAX_PERCENTAGE));
          }

          if (i > MAX_BULK_LOAN_SIZE) break;
        }
      }
      setSelectedLoanIds(loanIds);
    } else {
      for (let i = 0; i < selectedLoanIds.length; i++) {
        const loanId = selectedLoanIds[i];
        let _loan = opendLoans.filter(loan => loan.loan_id === loanId)[0];
        if (tab === 1) {
          _loan = gracedLoans.filter(loan => loan.loan_id === loanId)[0];
          if (commonSettings) {
            _liquidationFee = _liquidationFee.plus(BigNumber(_loan.loan_borrow_amount).multipliedBy(commonSettings.liquidation_fee).dividedBy(MAX_PERCENTAGE));
          }
        }
        fee = fee.plus(BigNumber(_loan.loan_debt_amount).minus(_loan.loan_borrow_amount));
        cashout = cashout.plus(_loan.loan_cashout);
        debt = debt.plus(_loan.loan_debt_amount);
      }
    }
    setRenewFeeAmount(fee.toFixed());
    setCashoutAmount(cashout.toFixed());
    setDebtAmount(debt.plus(_liquidationFee).toFixed());
    setLiquidationFee(_liquidationFee.toFixed());

    // open modal to review renew loans
    setShowRenewLoansModal(true);
  };

  const handleRenew = async () => {
    if (selectedLoanIds.length === 0 || BigNumber(renewFeeAmount).lte(0)) {
      toastError('No Loan is selected');
      return;
    }
    if (selectedLoanIds.length > MAX_BULK_LOAN_SIZE) {
      toastError(`Max bulk size is ${MAX_BULK_LOAN_SIZE} loans`);
      return;
    }
    if (isCashOut) {
      // renew and cashout
      if (BigNumber(renewFeeAmount).gt(cashoutAmount)) {
        if (BigNumber(renewFeeAmount).minus(cashoutAmount).plus(EXTRA_GAS_FEE_AMOUNT).gt(balance)) {
          toastError(ERROR_NOT_ENOUGH_BALANCE);
          return;
        }
        await sendRenewAndCashoutLoans(chainID, address, BigNumber(renewFeeAmount).minus(cashoutAmount).toFixed(), selectedLoanIds);
      } else {
        await sendRenewAndCashoutLoans(chainID, address, '0', selectedLoanIds);
      }
    } else {
      // renew
      if (BigNumber(renewFeeAmount).plus(EXTRA_GAS_FEE_AMOUNT).gt(balance)) {
        toastError(ERROR_NOT_ENOUGH_BALANCE);
        return;
      }
      await sendRenewLoans(chainID, address, renewFeeAmount, selectedLoanIds);
    }
    setShowRenewLoansModal(false);
  };

  const handleRedeem = async () => {
    if (selectedLoanIds.length === 0 || BigNumber(renewFeeAmount).lte(0)) {
      toastError('No Loan is selected');
      return;
    }
    if (selectedLoanIds.length > MAX_BULK_LOAN_SIZE) {
      toastError(`Max bulk size is ${MAX_BULK_LOAN_SIZE} loans`);
      return;
    }

    if (BigNumber(debtAmount).plus(EXTRA_GAS_FEE_AMOUNT).gt(balance)) {
      toastError(ERROR_NOT_ENOUGH_BALANCE);
      return;
    }

    await sendRedeemLoans(chainID, address, debtAmount, selectedLoanIds);
    setShowRenewLoansModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'loan_type',
      headerName: 'Loan Type',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 3,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'loan_status',
      headerName: 'Loan Status',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 3,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'nft-image',
      headerName: 'Image',
      width: isMobile ? 80 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        <div className='py-2'>
          <img
            style={{ width: '50px', height: '50px', borderRadius: '5px', objectFit: 'contain' }}
            src={!isDev ? getImageUrl(params.row.loan_collection_id, createNftId(params.row.loan_collection_id, params.row.loan_nft_nonce)) : nfts.length > 0 && nfts.length === loans.length ? nfts.filter((nft: NftWithIndexType) => { return nft.identifier == createNftId(params.row.loan_collection_id, params.row.loan_nft_nonce); })[0].url : imageQuestionMarkInCircle}
          />
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'index',
      headerName: 'NFT',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        createNftId(params.row.loan_collection_id, params.row.loan_nft_nonce)
      ),
    },
    {
      field: 'loan_borrow_amount',
      headerName: 'Borrowed',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        <div className='d-flex gap-2 align-items-center'>
          <img className='egld-badge' src={egld_white_logo} alt='egld' />
          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'loan_debt_amount',
      headerName: 'Debt',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        <div className='d-flex gap-2 align-items-center'>
          <img className='egld-badge' src={egld_white_logo} alt='egld' />
          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'loan_health_check',
      headerName: 'Health',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const health = commonSettings ? BigNumber(params.row.loan_nft_valuation).multipliedBy(commonSettings.liquidation_threshold).dividedBy(params.row.loan_debt_amount).dividedBy(MAX_PERCENTAGE) : BigNumber(1);
        return <div className='d-flex gap-2 align-items-center'>
          <span style={{ color: health.gte(1.5) ? 'green' : health.gte(1) ? 'orange' : 'red' }}>{convertBigNumberValueToLocalString(health, 2)}</span>
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'loan_open_timestamp',
      headerName: 'Opened',
      width: isMobile ? 200 : 0,
      flex: isMobile ? 0 : 4,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: params => formatLocalDateTime(params.value * SECOND_TO_MILLISECONDS),
    },
    {
      field: 'loan_deadline_timestamp',
      headerName: 'Deadline',
      width: isMobile ? 200 : 0,
      flex: isMobile ? 0 : 4,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: params => formatLocalDateTime((tab === 1 && commonSettings ? params.value + commonSettings.lend_grace_period : params.value) * SECOND_TO_MILLISECONDS),
    },
    {
      field: 'button',
      headerName: '',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        tab === 0 || tab === 1 ? (<button className='approval_but' onClick={() => {
          setSelectedLoan(params.row);
          setShowRedeemLoanModal(true);
        }}>Redeem</button>) : (<></>)
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 4,
      sortable: false,
      renderHeader: (params) => (
        <button className='renew_but' onClick={onClickRenew}>{selectedLoanIds.length === 0 ? 'Redeem/Renew All' : 'Redeem/Renew'}</button>
      ),
    },
  ];

  return (
    <>
      <div className='container ' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>My Loans</span>
              <span>Your Collateralized NFTs</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          <div className='row gy-2 gx-2 dashboard-egld-info' style={{ marginTop: '40px' }}>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Total Loans</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div
                    className=''>{commonSettings ? convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.accumulated_loan_amount), 2) : '-'}</div>
                  <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Total No.of Loans</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div
                    className=''>{commonSettings ? commonSettings.last_loan_id : '-'}</div>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>My Active Loans</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div
                    className=''>{convertBigNumberValueToLocalString(convertWeiToEsdt(activeLoans), 2)}</div>
                  <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>My Active No.of Loans</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div
                    className=''>{opendLoans ? opendLoans.length : '-'}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-center align-items-center main-tab-container' style={{ marginTop: '40px', width: '100%' }}>
            {tabs.map((row, index) => (
              <span className={`main-tab-individual text-white ${tab === index ? 'focus-bg-gradient' : ''}`} key={row} onClick={() => setTab(index)}>{row}</span>
            ))}
          </div>

          <div className='w-100' style={{ marginTop: '30px' }}>
            <DataGrid
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 20 },
                fontSize: '12px',
                '& .MuiDataGrid-columnSeparator': {
                  visibility: 'hidden',
                },
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: '400px'
                },
              }}
              autoHeight
              getRowHeight={() => 'auto'}
              // tab 0: show opened loans
              // tab 1: show graced loans
              // tab 2: show closed loans
              // tab 3: show liquidated loans
              rows={tab === 0 ? opendLoans : tab === 1 ? gracedLoans : tab === 2 ? redeemedLoans : liquidatedLoans}
              columns={columns}
              getRowId={(row) => row.loan_id}
              disableColumnMenu
              disableSelectionOnClick
              hideFooter
              loading={loansLoading}
              checkboxSelection={tab === 0 || tab === 1 ? true : false}
              onSelectionModelChange={(ids) => {
                handleSelectionModelChange(ids);
              }}
              selectionModel={selectedLoanIds}
            />
          </div>

          {
            selectedLoan && <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showRedeemLoanModal}
              onClose={() => setShowRedeemLoanModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showRedeemLoanModal}>
                <Box sx={fadeBoxStyle}>
                  <div className='d-flex justify-content-center mt-2'>
                    <img
                      className='detail-nft'
                      src={!isDev ? getImageUrl(selectedLoan.loan_collection_id, createNftId(selectedLoan.loan_collection_id, selectedLoan.loan_nft_nonce)) : nfts.length > 0 && nfts.length === loans.length ? nfts.filter((nft: NftWithIndexType) => { return nft.identifier == createNftId(selectedLoan.loan_collection_id, selectedLoan.loan_nft_nonce); })[0].url : imageQuestionMarkInCircle}
                    />
                  </div>

                  <div className='mt-4 d-flex flex-column p-2' style={{ rowGap: '10px' }}>
                    <div className='d-flex justify-content-between'>
                      <span className='text-color-gray'>NFT</span>
                      <span>{selectedLoan ? createNftId(selectedLoan.loan_collection_id, selectedLoan.loan_nft_nonce) : '?'}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <span className='text-color-gray'>Deadline</span>
                      <span>{formatLocalDateTime(selectedLoan.loan_deadline_timestamp * SECOND_TO_MILLISECONDS)}</span>
                    </div>

                    <div className='d-flex justify-content-between'>
                      <span className='text-color-gray'>Borrowed</span>
                      <div className='d-flex gap-2'>
                        <img className='egld-badge' src={egld_white_logo} alt='egld' />
                        <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(selectedLoan.loan_borrow_amount))}</span>
                      </div>
                    </div>

                    <div className='d-flex justify-content-between'>
                      <span className='text-color-gray'>Fee</span>
                      <div className='d-flex gap-2'>
                        <img className='egld-badge' src={egld_white_logo} alt='egld' />
                        <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(selectedLoan.loan_debt_amount).minus(selectedLoan.loan_borrow_amount)))}</span>
                      </div>
                    </div>

                    {tab === 1 && commonSettings && (
                      <div className='d-flex justify-content-between'>
                        <span className='text-color-gray'>Liquidation Fee</span>
                        <div className='d-flex gap-2'>
                          <img className='egld-badge' src={egld_white_logo} alt='egld' />
                          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(selectedLoan.loan_borrow_amount).multipliedBy(commonSettings.liquidation_fee).dividedBy(MAX_PERCENTAGE)))}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='debt-box mt-3'>
                    <span>Debt</span>
                    <div className='d-flex gap-2'>
                      <img className='egld-badge' src={egld_white_logo} alt='egld' />
                      <span>{tab === 1 && commonSettings ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(selectedLoan.loan_borrow_amount).multipliedBy(commonSettings.liquidation_fee).dividedBy(MAX_PERCENTAGE).plus(selectedLoan.loan_debt_amount))) : convertBigNumberValueToLocalString(convertWeiToEsdt(selectedLoan.loan_debt_amount))}</span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-center mt-4'>
                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickRedeemLoan}>
                      Redeem
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modal>
          }

          {
            selectedLoanIds.length > 0 && <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showRenewLoansModal}
              onClose={() => setShowRenewLoansModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={showRenewLoansModal}>
                <Box sx={fadeBoxStyle} style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll', overflowX: 'hidden' }} className='renew-loan-box scrollbar'>
                  <div className='mt-2'>
                    <table className='renew-loan-table'>
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>NFT</th>
                          <th>Borrowed</th>
                          <th>Nft Valuation</th>
                          <th>Liquidation Price</th>
                          {tab === 0 && (<th>Cashout</th>)}
                          <th>Deadline</th>
                          {tab === 1 && (<th>Expiration</th>)}
                          <th>Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedLoanIds.map((loanId, index) => {
                          let opendLoan = opendLoans.filter(loan => loan.loan_id === loanId)[0];
                          if (tab === 1) {
                            opendLoan = gracedLoans.filter(loan => loan.loan_id === loanId)[0];
                          }
                          return <tr key={index}>
                            {opendLoan && commonSettings && (<>
                              <td>
                                <img
                                  style={{ width: '50px', height: '50px', borderRadius: '5px', objectFit: 'contain' }}
                                  src={!isDev ? getImageUrl(opendLoan.loan_collection_id, createNftId(opendLoan.loan_collection_id, opendLoan.loan_nft_nonce)) : nfts.length > 0 && nfts.length === loans.length ? nfts.filter((nft: NftWithIndexType) => { return nft.identifier == createNftId(opendLoan.loan_collection_id, opendLoan.loan_nft_nonce); })[0].url : imageQuestionMarkInCircle}
                                />
                              </td>
                              <td>{createNftId(opendLoan.loan_collection_id, opendLoan.loan_nft_nonce)}</td>
                              <td className=''>
                                <div className='d-flex gap-2 justify-content-center'>
                                  <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                  <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(opendLoan.loan_borrow_amount))}</span>
                                </div>
                              </td>
                              <td className=''>
                                <div className='d-flex gap-2 justify-content-center'>
                                  <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                  <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(opendLoan.loan_nft_valuation))}</span>
                                </div>
                              </td>
                              <td className=''>
                                <div className='d-flex gap-2 justify-content-center'>
                                  <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                  <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(opendLoan.loan_liquidation_price))}</span>
                                </div>
                              </td>
                              {tab === 0 && (
                                <td className=''>
                                  <div className='d-flex gap-2 justify-content-center'>
                                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(opendLoan.loan_cashout))}</span>
                                  </div>
                                </td>
                              )}
                              <td>{formatLocalDateTime(opendLoan.loan_deadline_timestamp * SECOND_TO_MILLISECONDS)}</td>
                              {tab === 1 && (
                                <td>{formatLocalDateTime((opendLoan.loan_deadline_timestamp + commonSettings.lend_grace_period) * SECOND_TO_MILLISECONDS)}</td>
                              )}
                              <td className=''>
                                <div className='d-flex gap-2 justify-content-center'>
                                  <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                  <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(opendLoan.loan_debt_amount).minus(opendLoan.loan_borrow_amount)))}</span>
                                </div>
                              </td>
                            </>)}
                          </tr>;
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='renew-debt-box mt-3'>
                    {tab === 0 && (
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <span>Cashout</span>
                          <Checkbox
                            checked={isCashOut}
                            onChange={e => setIsCashout(e.target.checked)}
                            sx={{ '&': { padding: '0px', marginLeft: '3px' } }}
                          />
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                          <img className='egld-badge' src={egld_white_logo} alt='egld' />
                          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(cashoutAmount))}</span>
                        </div>
                      </div>
                    )}
                    <div className='d-flex justify-content-between mt-2'>
                      <span>Fee</span>
                      <div className='d-flex gap-2 align-items-center'>
                        <img className='egld-badge' src={egld_white_logo} alt='egld' />
                        <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(renewFeeAmount))}</span>
                      </div>
                    </div>
                    {tab === 1 && (
                      <div className='d-flex justify-content-between mt-2'>
                        <span>Liquidation Fee</span>
                        <div className='d-flex gap-2 align-items-center'>
                          <img className='egld-badge' src={egld_white_logo} alt='egld' />
                          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(liquidationFee))}</span>
                        </div>
                      </div>
                    )}
                    <div className='d-flex justify-content-between mt-2'>
                      <span>Debt</span>
                      <div className='d-flex gap-2 align-items-center'>
                        <img className='egld-badge' src={egld_white_logo} alt='egld' />
                        <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(debtAmount))}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3 mb-2'>
                    <div className='d-flex justify-content-center'>
                      <div className="eg-btn btn--primary2 capsule px-2 py-2 mx-2" style={{ cursor: 'pointer', width: '75px' }} onClick={handleRedeem}>
                        Redeem
                      </div>
                      {tab === 0 && (
                        <div className="eg-btn btn--primary2 capsule px-2 py-2 mx-2" style={{ cursor: 'pointer', width: '75px' }} onClick={handleRenew}>
                          Renew
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modal>
          }
        </div>
      </div>
    </>
  );
};
