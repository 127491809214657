import { Address, AddressValue, ArgSerializer, BigUIntValue, BooleanValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { LEND_GAS_LIMIT, JEWEL_LEND_V2_SC_ADDRESS, ENTER_FARM_GAS_LIMIT, ASH_TOKEN_ID, VEASH_FEE_ADDRESS, GOV_ACTION_GAS_LIMIT } from 'config';
import { convertEsdtToWei, EXTRA_GAS_FEE_AMOUNT, getTokenDecimal, getTokenTicker, YEAR_IN_SECONDS } from 'z/utils';
import BigNumber from 'bignumber.js';

export const openPosition = async (chainID: string, scAddress: string, address: string, farmId: number, tokenIds: string[], depositAmounts: string[], leverage: number, slippage: number, debtTokenId: string, wrapAddress: string, balance: string) => {
  const txs: any = [];
  const args: TypedValue[] = [
    new AddressValue(new Address(scAddress)),
  ];
  args.push(new U32Value(tokenIds.length));
  for (let i = 0; i < tokenIds.length; i++) {
    args.push(new TokenIdentifierValue(tokenIds[i]));
    args.push(new U64Value(0));

    if (getTokenTicker(tokenIds[i]) === 'EGLD') {
      // const egldAmount = BigNumber(depositAmounts[i]).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
      const egldAmount = BigNumber(depositAmounts[i]).gte(BigNumber(balance).minus(EXTRA_GAS_FEE_AMOUNT)) ? BigNumber(balance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed(0) : depositAmounts[i];
      args.push(new BigUIntValue(egldAmount));

      const data = new TransactionPayload('wrapEgld');

      const wrapTx = new Transaction({
        value: egldAmount,
        sender: new Address(address),
        receiver: new Address(wrapAddress),
        gasLimit: LEND_GAS_LIMIT,
        chainID: chainID,
        data,
      });
      txs.push(wrapTx);
    } else {
      args.push(new BigUIntValue(depositAmounts[i]));
    }
  }
  args.push(new StringValue('openPosition'));
  args.push(new U32Value(farmId));
  args.push(new TokenIdentifierValue(debtTokenId));
  args.push(new U64Value(leverage));
  args.push(new U64Value(slippage));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: ENTER_FARM_GAS_LIMIT,
    chainID: chainID,
    data,
  });
  txs.push(tx);

  await refreshAccount();
  const result = await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Farm Request',
      errorMessage: 'Error occured during Farm Request',
      successMessage: 'Farm Request Successful',
    }
  });

  return result;
};

export const closePosition = async (chainID: string, scAddress: string, address: string, positionId: number, slippage: number) => {
  const args: TypedValue[] = [
    new U64Value(positionId),
    new U64Value(slippage),
    new BooleanValue(true),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`closePosition@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: ENTER_FARM_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Close Position Request',
      errorMessage: 'Error occured during Close Position Request',
      successMessage: 'Close Position Request Successful',
    }
  });
};

export const partiallyClosePosition = async (chainID: string, scAddress: string, address: string, positionId: number, closePercent: number, repayPercent: number, slippage: number) => {
  const args: TypedValue[] = [
    new U64Value(positionId),
    new U64Value(closePercent),
    new U64Value(repayPercent),
    new U64Value(slippage),
    new BooleanValue(true),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`partiallyClosePosition@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: ENTER_FARM_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Partially Close Position Request',
      errorMessage: 'Error occured during Partially Close Position Request',
      successMessage: 'Partially Close Position Request Successful',
    }
  });
};

export const adjustPosition = async (chainID: string, scAddress: string, address: string, tokenIds: string[], depositAmounts: string[], positionId: number, borrowAmount: string, slippage: number, wrapAddress: string) => {
  const txs: any = [];
  const args: TypedValue[] = [
    new AddressValue(new Address(scAddress)),
  ];
  args.push(new U32Value(tokenIds.length));
  for (let i = 0; i < tokenIds.length; i++) {
    args.push(new TokenIdentifierValue(tokenIds[i]));
    args.push(new U64Value(0));

    if (getTokenTicker(tokenIds[i]) === 'EGLD') {
      const egldAmount = BigNumber(depositAmounts[i]).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
      args.push(new BigUIntValue(egldAmount));

      const data = new TransactionPayload('wrapEgld');

      const wrapTx = new Transaction({
        value: egldAmount,
        sender: new Address(address),
        receiver: new Address(wrapAddress),
        gasLimit: LEND_GAS_LIMIT,
        chainID: chainID,
        data,
      });
      txs.push(wrapTx);
    } else {
      args.push(new BigUIntValue(depositAmounts[i]));
    }
  }
  args.push(new StringValue('adjustPosition'));
  args.push(new U32Value(positionId));
  args.push(new BigUIntValue(borrowAmount));
  args.push(new U64Value(slippage));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: ENTER_FARM_GAS_LIMIT,
    chainID: chainID,
    data,
  });
  txs.push(tx);

  await refreshAccount();
  const result = await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Adjust Request',
      errorMessage: 'Error occured during Adjust Request',
      successMessage: 'Adjust Request Successful',
    }
  });

  return result;
};

export const adjustBorrowMore = async (chainID: string, scAddress: string, address: string, positionId: number, borrowAmount: string, slippage: number) => {
  const args: TypedValue[] = [
    new U64Value(positionId),
    new BigUIntValue(borrowAmount),
    new U64Value(slippage),
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`adjustPosition@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: ENTER_FARM_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Adjust Request',
      errorMessage: 'Error occured during Adjust Request',
      successMessage: 'Adjust Request Successful',
    }
  });

  return result;
};

export const manageStakeFarm = async (chainID: string, scAddress: string, address: string, functionName: string, amount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(ASH_TOKEN_ID),
    new BigUIntValue(convertEsdtToWei(amount, getTokenDecimal(ASH_TOKEN_ID))),
    new StringValue(functionName),
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: GOV_ACTION_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Stake Request',
      errorMessage: 'Error occured during Stake Request',
      successMessage: 'Increase Stake Successful',
    }
  });
};

export const increaseLockedTime = async (chainID: string, scAddress: string, address: string) => {
  const unlockTime = Math.floor(Date.now() / 1000 + YEAR_IN_SECONDS * 4); // unlock_time max 4 years
  const args: TypedValue[] = [
    new U64Value(unlockTime),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`govIncreaseUnlockTime@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: GOV_ACTION_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Increase Request',
      errorMessage: 'Error occured during Increase Request',
      successMessage: 'Increase Request Successful',
    }
  });
};

export const govWithdraw = async (chainID: string, scAddress: string, address: string) => {
  const args: TypedValue[] = [
    new AddressValue(new Address(VEASH_FEE_ADDRESS)),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`govWithdraw@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: GOV_ACTION_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Withdraw Request',
      errorMessage: 'Error occured during Withdraw Request',
      successMessage: 'Withdraw Request Successful',
    }
  });
};

export const govFeeClaim = async (chainID: string, scAddress: string, address: string) => {
  const args: TypedValue[] = [
    new AddressValue(new Address(VEASH_FEE_ADDRESS)),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`feeClaim@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: GOV_ACTION_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Claim Request',
      errorMessage: 'Error occured during Claim Request',
      successMessage: 'Claim Request Successful',
    }
  });
};

export const wrapEgld = async (chainID: string, scAddress: string, address: string, amount: string) => {
  const data = new TransactionPayload('wrapEgld');

  const tx = new Transaction({
    value: amount,
    sender: new Address(address),
    receiver: new Address(scAddress),
    gasLimit: LEND_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Wrap Request',
      errorMessage: 'Error occured during Wrap Request',
      successMessage: 'Wrap Request Successful',
    }
  });
};