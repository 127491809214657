import React, { FC } from 'react';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { COLLECTIONS_MAP } from 'data';
import { CommonSettingsType, NftWithIndexType } from 'z/types';
import {
    convertBigNumberValueToLocalString,
    convertSecondsToDays,
    convertSecondsToDaysWithWords,
    convertWeiToEsdt, getImageUrl,
    MAX_PERCENTAGE
} from 'z/utils';
import BigNumber from 'bignumber.js';
import {isDev} from "../../config";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface enterAmountProps {
  commonSettings: CommonSettingsType;
  selectedNft: NftWithIndexType;
  selectedNftPrice: string;
  borrowAmount: string;
  selectedBorrowPlanIndex: number;
  selectedNftCollectionIndex: number;
  liquidationPrice: string;
  repaymentDeadline: string;
  loanAmount: string;
  borrowAvailableAmount: string;
}

export const Review: FC<enterAmountProps> = ({
  commonSettings,
  selectedNft,
  selectedNftPrice,
  borrowAmount,
  selectedBorrowPlanIndex,
  selectedNftCollectionIndex,
  liquidationPrice,
  repaymentDeadline,
  loanAmount,
  borrowAvailableAmount,
}) => {
  const selectedCollection = COLLECTIONS_MAP[commonSettings.collection_ids[selectedNftCollectionIndex]];
//   const collateralRate = commonSettings.collection_collateral_rates[selectedNftCollectionIndex];
    return (
        <>
            <div className='d-flex flex-column' style={{ rowGap: '50px' }}>
                <div className='row gy-3 align-items-center' style={{ rowGap: '20px' }}>
                    <div className='col-lg-4 text-center'>
                        <LazyLoadImage visibleByDefault={true} style={{ width: '100%', objectFit: 'cover' }} src={isDev ? selectedNft.url : getImageUrl(selectedNft.collection, selectedNft.identifier)} alt={selectedNft.name} effect="blur" />
                    </div>

                    <div className='col-lg-8'>
                        <div className='row property-box'>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Collection</span>
                                    <span className='property-content'>{selectedCollection.name}</span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Token ID</span>
                                    <span className='property-content'>{selectedNft.identifier}</span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Token Name</span>
                                    <span className='property-content'>{selectedNft.name}</span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>NFT Valuation</span>
                                    <div className='d-flex align-items-center'>
                                        <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                        <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(selectedNftPrice))}</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Utilization rate</span>
                                    <span className='property-content'>29.60 %</span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Health factor</span>
                                    <span className='property-content'>2.69</span>
                                </div>
                            </div> */}
                            <div className='col-sm-4'>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Liquidation Price</span>
                                    <div className='d-flex align-items-center'>
                                        <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                        <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(loanAmount).multipliedBy(MAX_PERCENTAGE).dividedBy(commonSettings.liquidation_threshold).toFixed()))}</div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Max Borrow Period</span>
                                    <span className='property-content'>{convertSecondsToDaysWithWords(commonSettings.max_borrow_periods[selectedBorrowPlanIndex], 2)}</span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Interest Rate</span>
                                    <span className='property-content'>{commonSettings.borrow_rates[selectedBorrowPlanIndex] / 100}%</span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Available to Borrow</span>
                                    <div className='d-flex align-items-center'>
                                        <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                        <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(borrowAvailableAmount))}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Repayment Deadline</span>
                                    <span className='property-content' style={{ fontSize: '16px' }}>{repaymentDeadline}</span>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Loan Amount</span>
                                    <div className='d-flex'>
                                        <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                        <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(loanAmount))}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='nft-property'>
                                    <span className='property-name'>Borrow Amount</span>
                                    <div className='d-flex'>
                                        <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                        <div className='property-content' style={{ marginLeft: '8px' }}>{borrowAmount}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
