import React, { FC, useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Backdrop, Box, Modal } from '@mui/material';
import {
    useGetAccount,
    useGetNetworkConfig,
    useGetIsLoggedIn,
    useTrackTransactionStatus,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { Tooltip } from 'antd';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { animated, useSpring } from 'react-spring';
import { contracts } from 'config';
import { enterExchangeFarm } from 'z/elrond';
import { XexchangeFarmInfo, getXexchangePairInfo } from 'z/elrond/xexchange-api';
import {
    JewelExchangeFarmContext,
    JewelExchangeFarmBaseContext,
} from 'z/types';
import {
    BIGNUM_ZERO,
    convertBigNumberValueToLocalString,
    convertEsdtToWei,
    convertWeiToEsdt,
    ERROR_CONNECT_YOUR_WALLET,
    ERROR_NOT_ENOUGH_BALANCE,
    EXTRA_GAS_FEE_AMOUNT,
    getTokenDecimal,
    getTokenImage,
    getTokenTicker,
    parseBigNumber,
    toastError,
} from 'z/utils';
import { getChainId } from 'z/elrond/sdkDappHelpers';
const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

interface ExchangeFarmPoolProps {
    commonSetting: JewelExchangeFarmBaseContext,
    pool: JewelExchangeFarmContext,
    poolName: string,
    userTokens: any,
    exchangeFarmInfo: XexchangeFarmInfo | undefined,
}

export const ExchangeFarmPool: FC<ExchangeFarmPoolProps> = ({
    commonSetting,
    pool,
    poolName,
    userTokens,
    exchangeFarmInfo,
}) => {
    const navigate = useNavigate();
  const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
    const { address, balance, shard } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [tokenAmounts, setTokenAmounts] = useState<string[]>(['0', '0', '0']);

    const [showFarmModal, setShowFarmModal] = useState<boolean>(false);

    const handleShowModal = () => {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }
        setShowFarmModal(true);
    };

    const handleOnChange = async (index: number, value: string) => {
        const amounts: string[] = [];
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (index === i) {
                // if (value === '') {
                //     value = '0';
                // }
                amounts.push(value);
            } else {
                amounts.push(tokenAmounts[i]);
            }
        }
        setTokenAmounts(amounts);
    };

    const handleMax = async (index: number) => {
        const amounts: string[] = [];
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (index === i) {
                let maxAmount = '0';
                if (getTokenTicker(pool.pair_token_ids[i]) === 'EGLD') {
                    if (parseBigNumber(userTokens['EGLD'].balance).gt(EXTRA_GAS_FEE_AMOUNT)) {
                        maxAmount = parseBigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT).toFixed();
                    } else {
                        maxAmount = '0';
                    }
                } else {
                    maxAmount = userTokens[pool.pair_token_ids[i]].balance;
                }
                const tokenDecimals = getTokenDecimal(pool.pair_token_ids[index]);
                amounts.push(convertWeiToEsdt(maxAmount, tokenDecimals, tokenDecimals).toFixed());
            } else {
                amounts.push(tokenAmounts[i]);
            }
        }
        setTokenAmounts(amounts);
    };

    async function handleEnterFarm() {
        const tokenIds: string[] = [];
        const amounts: string[] = [];
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (parseBigNumber(tokenAmounts[i]).gt(0)) {
                // check balance
                if (pool.pair_token_ids[i] === 'EGLD' && parseBigNumber(userTokens['EGLD'].balance).lte(EXTRA_GAS_FEE_AMOUNT)) {
                    toastError(ERROR_NOT_ENOUGH_BALANCE);
                    return;
                }

                if (convertEsdtToWei(tokenAmounts[i], getTokenDecimal(pool.pair_token_ids[i])).gt(getTokenTicker(pool.pair_token_ids[i]) === 'EGLD' ? parseBigNumber(userTokens['EGLD'].balance).minus(EXTRA_GAS_FEE_AMOUNT) : userTokens[pool.pair_token_ids[i]].balance)) {
                    toastError(ERROR_NOT_ENOUGH_BALANCE);
                    return;
                }

                tokenIds.push(pool.pair_token_ids[i]);
                const value = convertEsdtToWei(tokenAmounts[i], getTokenDecimal(pool.pair_token_ids[i])).toFixed();
                amounts.push(value);
            }
        }

        const pairInfo = await getXexchangePairInfo(pool.pair_address);
        if (!pairInfo) {
            toastError('Querying pair info failed.');
            return;
        }
        let depositValue = BIGNUM_ZERO;
        let minTokenDecimals = 18;
        let minTokenPriceUSD = BIGNUM_ZERO;
        for (let i = 0; i < tokenAmounts.length; i++) {
            if (parseBigNumber(tokenAmounts[i]).gt(0)) {
                if (pool.pair_token_ids[i] === pairInfo.firstTokenId) {
                    depositValue = depositValue.plus(pairInfo.firstTokenPriceUSD.multipliedBy(tokenAmounts[i]));
                } else if (pool.pair_token_ids[i] === pairInfo.secondTokenId) {
                    depositValue = depositValue.plus(pairInfo.secondTokenPriceUSD.multipliedBy(tokenAmounts[i]));
                } else if (pool.pair_token_ids[i] == pairInfo.lpTokenId) {  // LP token
                    depositValue = depositValue.plus(pairInfo.lpTokenPriceUSD.multipliedBy(tokenAmounts[i]));
                } else {
                    console.error(`Bad pool token ${pool.pair_token_ids[i]}`);
                }
            }
        }

        if (pool.min_deposit_base_token == pairInfo.firstTokenId) {
            minTokenDecimals = pairInfo.firstTokenDecimals;
            minTokenPriceUSD = pairInfo.firstTokenPriceUSD;
        } else if (pool.min_deposit_base_token == pairInfo.secondTokenId) {
            minTokenDecimals = pairInfo.secondTokenDecimals;
            minTokenPriceUSD = pairInfo.secondTokenPriceUSD;
        }
        const minDepositValue = minTokenPriceUSD.multipliedBy(convertWeiToEsdt(pool.min_deposit_base_amount, minTokenDecimals, minTokenDecimals));
        // check min deposit amount
        if (depositValue.isLessThan(minDepositValue.multipliedBy(0.99))) {
            const errorMsg = `Please deposit more than ${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.min_deposit_base_amount, minTokenDecimals))} ${getTokenTicker(pool.min_deposit_base_token)}`;
            toastError(errorMsg);
            return;
        }


        let wrapAddress = contracts.Wrap0.address;
        if (shard === 1) {
            wrapAddress = contracts.Wrap1.address;
        } else if (shard === 2) {
            wrapAddress = contracts.Wrap2.address;
        }

        const result = await enterExchangeFarm(chainID, address, pool.farm_address, tokenIds, amounts, wrapAddress);

        setShowFarmModal(false);

        setSessionId(result.sessionId);
    }

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('Enter farm request was successful');
            navigate('/mypositions/xexchange');
        }
    }, [sessionId, hasPendingTransactions]);

    const firstIndex = getTokenTicker(pool.pair_token_ids[0]) == 'EGLD' ? 1 : 0;
    const secondIndex = firstIndex == 0 ? 1 : 0;

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 farm-container mobile-farm-container'>
                    <div className='col-lg-4 col-md-4 col-sm-4 align-self-center d-flex mt-2'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='combine-bage'>
                              <img src={getTokenImage(pool.pair_token_ids[firstIndex])} className='egld-image mx-2' />
                              <img src={getTokenImage(pool.pair_token_ids[secondIndex])} className='egld-image mx-2' />
                            </div>
                            <div>
                                <div>
                                    {`${getTokenTicker(pool.pair_token_ids[firstIndex])}-${getTokenTicker(pool.pair_token_ids[secondIndex])}`}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-4'>
                        <div className='d-flex align-items-center'>TVL</div>
                        <div>
                            <div className='d-flex justify-content-end'>
                                {`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.pair_token_amounts[firstIndex], getTokenDecimal(pool.pair_token_ids[firstIndex])))} ${getTokenTicker(pool.pair_token_ids[firstIndex])}`}
                            </div>
                            <div className='d-flex justify-content-end'>
                                {`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.pair_token_amounts[secondIndex], getTokenDecimal(pool.pair_token_ids[secondIndex])))} ${getTokenTicker(pool.pair_token_ids[secondIndex])}`}
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-between mt-2'>
                        <div className='d-flex'>
                            <div className='d-flex'>APR</div>
                            <Tooltip
                                placement="top"
                                title="Max Boosted APR"
                            >
                                <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                        <div className='avaliable-color'>{exchangeFarmInfo ? `${convertBigNumberValueToLocalString(exchangeFarmInfo.totalApr)} %` : '-'}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-between mt-2'>
                        <div className='d-flex align-items-center'>Leverage</div>
                        <div>1X</div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 align-self-center mt-4'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2 w-100" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 farm-container'>
                    <div className='col-lg-3 col-md-4 col-sm-4 align-self-center'>
                        <div className='d-flex align-items-center'>
                            <div className='combine-bage'>
                              <img src={getTokenImage(pool.pair_token_ids[firstIndex])} className='egld-image mx-2' />
                              <img src={getTokenImage(pool.pair_token_ids[secondIndex])} className='egld-image mx-2' />
                            </div>
                            <div>
                                <div>
                                    {`${getTokenTicker(pool.pair_token_ids[firstIndex])}-${getTokenTicker(pool.pair_token_ids[secondIndex])}`}
                                </div>
                                <div>{poolName}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 text-center align-self-center'>
                        <div className='d-flex justify-content-center'>
                            {`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.pair_token_amounts[firstIndex], getTokenDecimal(pool.pair_token_ids[firstIndex])))} ${getTokenTicker(pool.pair_token_ids[firstIndex])}`}
                        </div>
                        <div className='d-flex justify-content-center'>
                            {`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.pair_token_amounts[secondIndex], getTokenDecimal(pool.pair_token_ids[secondIndex])))} ${getTokenTicker(pool.pair_token_ids[secondIndex])}`}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-1 col-sm-1 text-center align-self-center'>
                        <div className='avaliable-color'>{exchangeFarmInfo ? `${convertBigNumberValueToLocalString(exchangeFarmInfo.totalApr)} %` : '-'}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 text-center align-self-center'>
                        <div>1X</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleShowModal}>
                                    Farm
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showFarmModal}
                onClose={() => setShowFarmModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showFarmModal}>
                    <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
                        <div className='d-flex'>
                            Warning: Please be aware of a potential large price impact when opening a large position. This is due to your position size compared to the underlying liquidity of the pool.
                        </div>
                        <div className='d-flex mt-4'>
                            How much would you like to add for farming?
                        </div>
                        {pool.pair_token_ids.map((item: string, index: number) => (
                            <div className='mt-2 borrow-form' key={index}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='currency-item align-items-center' style={{ width: '100%' }}>
                                        <img src={getTokenImage(item)} alt={item} />
                                        <div style={{ fontSize: '0.9rem' }}>{getTokenTicker(item) + (index + 1 === pool.pair_token_ids.length ? ' LP' : '')}</div>
                                    </div>
                                    <input type="number" value={tokenAmounts[index]} onChange={(e: any) => handleOnChange(index, e.target.value)} />
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className='d-flex'>
                                            <div className='d-flex align-items-center' style={{ fontSize: '0.9rem', marginRight: '3px' }}>{`Avail :`}</div>
                                            <div>
                                                <div style={{ fontSize: '0.9rem' }}>{`${userTokens ? convertBigNumberValueToLocalString(convertWeiToEsdt(getTokenTicker(item) === 'EGLD' ? userTokens['EGLD']?.balance : userTokens[item]?.balance, getTokenDecimal(item), getTokenDecimal(item)), isMobile ? 6 : getTokenDecimal(item)) : '-'} ${getTokenTicker(item) + (index + 1 === pool.pair_token_ids.length ? ' LP' : '')}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ minWidth: '21%', textAlign: 'right' }}>
                                        <button className='max-but' onClick={() => handleMax(index)}>MAX</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className='d-flex mt-4'>
                            Summary
                        </div> */}
                        {/* <div className='farm-summary mt-2'>
                            <div className='w-100 d-flex justify-content-between'>
                                <span>Leverage</span>
                                <div className='d-flex gap-2'>
                                    <span>{`${1}X`}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-2'>
                                <span>Yield Farm APY</span>
                                <div className='d-flex gap-2'>
                                    <span className='avaliable-color'>{`${10} %`}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between mt-2'>
                                <span>Deposit Amount (MIN)</span>
                                <div className='d-flex gap-2'>
                                    <img className='egld-badge' src={getTokenImage(pool.min_deposit_base_token)} alt={pool.min_deposit_base_token} />
                                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(pool.min_deposit_base_amount, getTokenDecimal(pool.min_deposit_base_token)))}</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex justify-content-between align-items-center mt-2'>
                                <span>Deposit Amount</span>
                                <div className='d-flex gap-2'>
                                    <img className='egld-badge' src={getTokenImage('EGLD')} alt={'EGLD'} />
                                    <span>{1}</span>
                                </div>
                            </div>
                        </div> */}
                        <div className='d-flex justify-content-center mt-4'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleEnterFarm}>
                                Farm
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};
