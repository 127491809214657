import { Address, ArgSerializer, BigUIntValue, BooleanValue, Transaction, TransactionPayload, TypedValue, U32Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { CLAIM_REWARDS_GAS_LIMIT, LEND_GAS_LIMIT, NFT_LENDING_SC_ADDRESS } from 'config';

export const sendLendEgld = async (chainID: string, address: string, lendAmount: string) => {
  const tx = new Transaction({
    value: lendAmount,
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: LEND_GAS_LIMIT,
    chainID: chainID,
    data: new TransactionPayload('lendEgld'),
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
        processingMessage: 'Processing Lend EGLD Request',
        errorMessage: 'Error occured during Lend EGLD Request',
        successMessage: 'Lend EGLD Request Successful',
    }
  });
};

export const sendWithdrawEgld = async (chainID: string, address: string, withdrawAmount: string) => {
  const args: TypedValue[] = [
    new BigUIntValue(withdrawAmount),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`withdrawEgld@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: LEND_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
        processingMessage: 'Processing Withdraw EGLD Request',
        errorMessage: 'Error occured during Withdraw EGLD Request',
        successMessage: 'Withdraw EGLD Request Successful',
    }
  });
};

export const sendClaimRewards = async (chainID: string, address: string, isCompound: boolean) => {
  const args: TypedValue[] = [
    new BooleanValue(isCompound),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`claimRewards@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: 60_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();

  const action = isCompound ? 'Compound Rewards' : 'Claim Rewards';
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
        processingMessage: `Processing ${action} Request`,
        errorMessage: `Error occured during ${action} Request`,
        successMessage: `Withdraw ${action} Successful`,
    }
  });
};
