import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetNetworkConfig, useGetPendingTransactions, useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { queryViewOwnPools } from 'z/elrond';
import { convertBigNumberValueToLocalString, convertWeiToEsdt } from 'z/utils';
import { NftCollection, NftCollectionPoolInfo } from 'z/types';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { AmmCollectionContext } from 'App';
import { CollectionLogo } from 'components/CollectionLogo';
import { PoolCard } from 'components/PoolCard';

export const MyPools = () => {

    const columns: GridColDef[] = [
        {
            field: 'pool_nft_token_id',
            headerName: 'collection',
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2 py-1 align-items-center'>
                    <CollectionLogo className='egld-badge' collectionId={params.value} />
                    <span>{params.value}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_type',
            headerName: 'pool type',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_curve_type',
            headerName: 'curve type',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_spot_price',
            headerName: 'spot price',
            flex: 1,
            renderCell: (params) => (
                <div className='d-flex gap-2 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_trade_fee_percent',
            headerName: 'fee',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_swap_token_reserve',
            headerName: 'EGLD balance',
            flex: 1,
            renderCell: (params) => (
                <div className='d-flex gap-2 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_buy_order_count',
            headerName: 'buy orders',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_nft_token_reserve',
            headerName: 'sell orders',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_accumulated_fee',
            headerName: 'accumulated fee',
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_delta',
            headerName: 'delta',
            flex: 1,
            renderCell: (params) => params.value.includes('%') ? (
                <div className='d-flex gap-2'>
                    <span>{params.value}</span>
                </div>
            ) : (
                <div className='d-flex gap-2 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_owner',
            headerName: 'owner',
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <span>{`${params.value.substring(0, 5)}...${params.value.slice(-5)}`}</span>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
    ];

    const navigate = useNavigate();

    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const { ammCollections } = useContext(AmmCollectionContext);

    const [ownPoolsLoading, setOwnPoolsLoading] = useState<boolean>(false);
    const [ownPools, setOwnPools] = useState<NftCollectionPoolInfo[]>([]);
    const [filterOwnPools, setFilterOwnPools] = useState<NftCollectionPoolInfo[]>([]);

    useEffect(() => {

        if (hasPendingTransactions || !isLoggedIn || ammCollections.length == 0) return;

        (async () => {
            setOwnPoolsLoading(true);
            const _ownPools = await queryViewOwnPools(apiAddress, address);
            // console.log('_ownPools: ', _ownPools);
            const items: NftCollectionPoolInfo[] = [];
            _ownPools.map((pool: NftCollectionPoolInfo) => {
                const info: NftCollection[] = ammCollections.filter((collectionInfo: NftCollection) => { return collectionInfo.nft_token_id == pool.pool_nft_token_id; });
                if (info.length > 0) {
                    items.push({
                        ...pool,
                        nft_collection_name: info[0].nft_collection_name,
                    });
                } else {
                    items.push(pool);
                }
            });
            setOwnPools(items);
            setFilterOwnPools(items);
            setOwnPoolsLoading(false);
        })();
    }, [hasPendingTransactions, ammCollections]);

    const handleOnChange = (e: any) => {
        const items = ownPools.filter((pool: NftCollectionPoolInfo) => { return pool.pool_nft_token_id.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()); });
        setFilterOwnPools(items);
    };

    const handleOnCellClick = (params: any) => {
        navigate(`/collection/${params.row.pool_nft_token_id}/${params.row.pool_id}`);
    };

    const handleCreatePool = () => {
        navigate('/create-pool');
    };

    return (
        <div className='container info-item amm-container' style={{ marginTop: '40px' }}>
            <div className='container'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='title'>
                        <span>My Pools</span>
                        <span>Manage your LP pools and earn reward</span>
                    </div>
                    <div className='egld-logo' >
                        <img src={egld_white_logo} />
                    </div>
                </div>
                {/*<div className='row'>*/}
                {/*    <div className='col-12 mt-5 text-center h2'>*/}
                {/*        My Pools*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='row mt-4' style={{justifyContent:"center"}}>
                    {/*<div className='col-lg-2 col-md-2 col-sm-12'></div>*/}
                    <div className='col-lg-4 col-md-8 col-sm-14'>
                        <TextField
                            fullWidth
                            label='Search by identifier'
                            InputProps={{
                                type: 'search',
                                style: { color: '#37b06f' },
                                startAdornment: <InputAdornment position='start'>
                                    <IconButton
                                        aria-label='search'
                                        edge='end'
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            onChange={handleOnChange}
                        />
                    </div>
                    {/*<div className='col-lg-2 col-md-2 col-sm-12'></div>*/}
                </div>
                <div className='row mt-4 mb-2'>
                    <div className='col-12 d-flex justify-content-center'>
                        <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleCreatePool}>
                            create pool
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-100 px-1' style={{ marginTop: '30px' }}>
                {isMobile || isTablet ? (
                    filterOwnPools?.map((item: any, index: number) => {
                        return <PoolCard key={index} item={item} />;
                    })
                ) : (
                    <DataGrid
                        sx={{
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            fontSize: '18px',
                            '& .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            // border: "1px solid #23303d",
                            '& .MuiDataGrid-virtualScroller': {
                                minHeight: '400px',
                                overflow: "hidden"
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: "14px",
                                lineHeight: "20px",
                                whiteSpace: "normal",
                            },
                            '&:hover:not(.Mui-disabled)': {
                                cursor: 'pointer'
                            },
                            '& .MuiDataGrid-columnHeader': {
                                color: '#939da7',
                            },
                            color: 'white',
                            fontFamily: 'Poppins',
                        }}
                        autoHeight
                        getRowHeight={() => 'auto'}
                        rows={filterOwnPools}
                        columns={columns}
                        getRowId={(row) => `collection-${row.pool_nft_token_id}-${row.pool_id}`}
                        disableColumnMenu
                        disableSelectionOnClick
                        // hideFooter
                        loading={ownPoolsLoading}
                        onCellClick={handleOnCellClick}
                    />
                )}
            </div>
        </div>
    );
};
