/* eslint-disable import/order */
import React, { createContext, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { Layout } from 'components';
import { apiTimeout, isDev, metamaskSnapWalletAddress, walletConnectV2ProjectId } from 'config';
import { PageNotFound, Unlock } from 'pages';
import { routeNames } from 'routes';
import { routes } from 'routes';
import { Environment } from 'config';
import { NftCollection, CollectionWithIndexType, PoolActivity } from 'z/types';
import { useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { queryViewCollections, queryViewCommonSettings } from 'z/elrond';
import { AMM_COLLECTION_IDS, CollectionSimpleType, COLLECTIONS_MAP } from 'data';
import BigNumber from 'bignumber.js';
import { convertWeiToEsdt } from 'z/utils';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#37b06f',
    }
  }
});

export const AmmCollectionContext = createContext<any>(undefined);

export const App = () => {
  const {
    network: { apiAddress }
  } = useGetNetworkConfig();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [ammCollections, setAmmCollections] = useState<NftCollection[]>([]);
  const [latestActivities, setLatestActivities] = useState<PoolActivity[]>([]);
  const [tvl, setTvl] = useState<BigNumber>(new BigNumber(0));
  const [nftVolume, setNftVolume] = useState<BigNumber>(new BigNumber(0));

  useEffect(() => {
    if (hasPendingTransactions || isDev) return;

    (async () => {
      setIsLoading(true);
      const _latestActivities: PoolActivity[] = [];
      const _collections = await queryViewCollections(apiAddress);
      _collections.sort((a: NftCollection, b: NftCollection) => convertWeiToEsdt(b.nft_collection_trading_volume).toNumber() - convertWeiToEsdt(a.nft_collection_trading_volume).toNumber());
      // console.log('_collections: ', _collections);
      // console.log('_latestActivities: ', _latestActivities);
      setLatestActivities(_latestActivities);
      const items: NftCollection[] = [];
      const collectionIds: string[] = [];
      let totalTvl = new BigNumber(0);
      let totalNftVolume = new BigNumber(0);
      for (let i = 0; i < _collections.length; i++) {
        const collection: NftCollection = _collections[i];
        if (COLLECTIONS_MAP[collection.nft_token_id]) {
          const collectionInfo: CollectionSimpleType = COLLECTIONS_MAP[collection.nft_token_id];
          items.push({
            ...collection,
            nft_collection_name: collectionInfo.name,
          });
          collectionIds.push(collection.nft_token_id);
        }
        totalTvl = totalTvl.plus(collection.nft_offer_tvl);
        totalNftVolume = totalNftVolume.plus(new BigNumber(collection.nft_floor_price).multipliedBy(collection.nft_listed_count));
      }
      setTvl(totalTvl);
      setNftVolume(totalNftVolume);
      for (let i = 0; i < AMM_COLLECTION_IDS.length; i++) {
        const collectionId = AMM_COLLECTION_IDS[i];
        if (!collectionIds.includes(collectionId)) {
          // console.log(collectionId);
          if (COLLECTIONS_MAP[collectionId]) {
            const collectionInfo: CollectionSimpleType = COLLECTIONS_MAP[collectionId];
            // console.log(collectionInfo);
            const collection: NftCollection = {
              nft_token_id: collectionId,
              nft_collection_name: collectionInfo.name,
              nft_listed_count: 0,
              nft_floor_price: '0',
              nft_best_offer_price: '0',
              nft_offer_tvl: '0',
              nft_collection_trading_volume: '0',
            };
            items.push(collection);
          }
        }
      }
      // console.log(items);
      setAmmCollections(items);
      setIsLoading(false);
    })();
  }, [hasPendingTransactions, isChanged]);

  return (
    <Router>
      <DappProvider
        environment={Environment}
        customNetworkConfig={{
          name: 'jewel-finance',
          apiTimeout,
          walletConnectV2ProjectId,
          metamaskSnapWalletAddress
        }}
        dappConfig={{
          shouldUseWebViewProvider:true,
          logoutRoute: routeNames.unlock
        }}
      >
        <ThemeProvider theme={theme}>
          <AmmCollectionContext.Provider value={{ isLoading, ammCollections, latestActivities, isChanged, setIsChanged, tvl, nftVolume }}>
            <Layout>
              <TransactionsToastList />
              <NotificationModal />
              <SignTransactionsModals className='custom-class-for-modals' />
              <Routes>
                <Route path={routeNames.home} element={<Navigate to={routeNames.dashboard} />} />
                <Route path={routeNames.unlock} element={<Unlock />} />
                {routes.map((route, index) => (
                  <Route
                    path={route.path}
                    key={'route-key-' + index}
                    element={(<route.component />)}
                  />
                ))}
                <Route path='*' element={<PageNotFound />} />
              </Routes>
            </Layout>
          </AmmCollectionContext.Provider>
        </ThemeProvider>
      </DappProvider>
    </Router>
  );
};
