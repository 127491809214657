export const SECOND_TO_MILLISECONDS = 1000;
export const EXTRA_GAS_FEE_AMOUNT = '10000000000000000'; // 0.01 EGLD in wei
export const EGLD_DECIMALS = 18;
export const MAX_PERCENTAGE = 100_00; // 100%
export const NFT_QUANTITY = 1;
export const HOUR_IN_SECONDS = 3600;
export const DAY_IN_SECONDS = 86400;
export const YEAR_IN_SECONDS = DAY_IN_SECONDS * 365;
export const ASH_LP_DECIMALS = 18;
export const FARM_MAX_PERCENT = 100_0000; // 100%
export const PERCENT_DENOMINATOR = 10000;
export const YEAR_IN_HOURS = 24 * 365;
