import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { NftCard, NftCardType } from 'components/NftCard';
import { NftRarity, NftWithAmmData, SwapTypeEnum } from 'z/types';

export const CollectionBuyTab = (props: any) => {

    return (
        <div className='container' style={{ maxHeight: '500px', overflowY: 'scroll'}}>
            <Row>
                {props.items?.map((item: NftWithAmmData, index: number) => {
                    // const nftRarity = props.rarity?.filter((rarity: NftRarity) => rarity.identifier === item.identifier);
                    // if (nftRarity.length > 0) {
                        return <Col lg={2} md={3} sm={6} xs={6} className='mt-3' key={index}>
                            <NftCard item={item} nftCardType={NftCardType.SwapNft} swapType={SwapTypeEnum.Sell} actionDisabled={props.actionDisabled} />
                            {/*<NftCard item={item} rarity={nftRarity[0]} nftCardType={NftCardType.SwapNft} swapType={SwapTypeEnum.Sell} actionDisabled={props.actionDisabled} />*/}
                        </Col>;
                    // }
                })}
            </Row>
        </div>
    );
};
