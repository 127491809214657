import React from 'react';
import { isMobile } from 'react-device-detect';
import {FaDiscord, FaGlobe, FaTelegram, FaTelegramPlane} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';

const footers = [
  {
    title: 'Overview',
    link: routeNames.dashboard,
    isBlank: false,
  },
  {
    title: 'NFT',
    link: routeNames.borrow,
    isBlank: false,
  },
  {
    title: 'Lend',
    link: routeNames.lend,
    isBlank: false,
  },
  {
    title: 'Farm',
    link: '/farm/ashswap',
    isBlank: false,
  },
  {
    title: 'Stake',
    link: '/stake/EGLD',
    isBlank: false,
  },
  {
    title: 'Gauge',
    link: routeNames.gauge,
    isBlank: false,
  },
  {
    title: 'Portfolio',
    link: '/mypositions/ashswap',
    isBlank: false,
  },
  {
    title: 'Docs',
    link: 'https://docs.jewelswap.io/',
    isBlank: true,
  },
  {
    title: 'Audit report',
    link: 'https://arda.run/audits/jewelswap',
    isBlank: true,
  },
];

export const Footer = () => {
  const navigate = useNavigate();

  const handleRouter = (path: string) => {
    navigate(path);
  };

  return (
    <div style={{ marginTop: '100px', color: 'white', marginBottom: '20px' }}>
      <div className='container'>
        <div className='row my-2'>
          <div className='col-lg-8 col-md-7 col-sm-12 col-12 d-flex align-items-center'>
            <div className='d-flex align-items-center' style={{ padding: '0', flexWrap: 'wrap', listStyle: 'none', gap: '7px' }}>
              {
                footers.map((item: any, index: number) => (
                  item.isBlank ? (
                    <a key={index} className='footer-text-color' href={item.link} target='_blank' rel='noreferrer' style={{ width: 'fit-content' }}>{item.title}</a>
                  ) : (
                    <div key={index} className='footer-text-color' onClick={() => handleRouter(item.link)} style={{ width: 'fit-content' }}>{item.title}</div>
                  )
                ))
              }
            </div>
          </div>
          <div className='col-lg-4 col-md-5 col-sm-12 col-12'>
            <div className={`d-flex gap-3 ${isMobile ? 'mt-2 justify-content-center' : 'justify-content-end'}`}>
              <a className='social-box' href="https://twitter.com/JewelSwapX" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill='white' height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
              </a>
              <a className='social-box' href="https://t.me/jewelswap" target="_blank" rel="noreferrer">
                <FaTelegramPlane />
              </a>
            </div>
          </div>

          {/* <div className='col-sm-12 mt-2 footer-align'>
            <p>
              Powered By {' '}
              <a href="https://twitter.com/ElrondPunks" className="egns-lab" target="_blank" rel="noreferrer">
                Epunks
              </a> & <a href="https://www.combased.io/" className="egns-lab" target="_blank" rel="noreferrer">
                Combased
              </a>
            </p>
          </div> */}
        </div>
      </div>
    </div >
  );
};
