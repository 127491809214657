import React, { useEffect, useState, FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Backdrop, Box, Modal, Checkbox, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InputNumber } from 'antd';
import { animated, useSpring } from 'react-spring';
import BigNumber from 'bignumber.js';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { closeHatomPosition, queryFarmGetEquivalent, queryViewAllOpenedPositionIds, queryViewJewelFarmBaseContextData, queryViewJewelLendContext, queryViewPositionsByIds, queryViewPositionsSafetyContextByIds } from 'z/elrond';
import { AshswapPool, CommonSettingsType, EsdtTokenPayment, JewelFarmBaseContext, JewelFarmBaseContextData, JewelFarmContext, JewelFarmContextData, JewelFarmPosition, JewelFarmPositionSafetyContext, JewelFarmPositionStateEnum, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelHatomPostionContext, JewelLendPool, LoanType } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, createNftId, ERROR_CONNECT_YOUR_WALLET, ERROR_DATA_NOT_LOADED, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, formatLocalDateTime, getAshswapPoolApr, getAshswapPoolTradingApr, getFarmApy, getFarmerRewardPercent, getFarmTokenIds, getIsStablePool, getJewelHatomPoolApy, getOldFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, PERCENT_DENOMINATOR, SECOND_TO_MILLISECONDS, sleep, toastError, YEAR_IN_HOURS } from 'z/utils';
import { isMobile } from 'react-device-detect';
import { ASH_TOKEN_ID, contracts, JEWEL_ASHSWAP_SC_ADDRESS, MAX_LEVERAGE, SLIPPAGE, STABLE_MAX_LEVERAGE } from 'config';
import { adjustBorrowMore, adjustPosition, closePosition, partiallyClosePosition } from 'z/elrond/sc/jewel-farm';
import { getChainId } from 'z/elrond/sdkDappHelpers';
const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 2,
  py: 2,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const PercentSlider = styled(Slider)({
  color: '#54F5B7',
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-markLabel': {
    top: '30px'
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: 'currentColor',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1,
    fontSize: 10,
    background: 'unset',
    padding: 0,
    width: 35,
    height: 35,
    color: 'black',
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#54F5B7',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const sliderMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100',
  },
];

const valueLabelFormat = (value: number) => {
  return `${value} %`;
};

interface HatomPositionsProps {
  commonSettings: JewelHatomFarmCommonContext | undefined,
  farms: JewelHatomFarmContext[],
  hatomMoneyMarkets: JewelHatomMoneyMarket[],
  hatomFormattedRewards: any[],
  isPositionsLoading: boolean,
  openedPositions: JewelHatomPostionContext[],
}

export const HatomPositions: FC<HatomPositionsProps> = ({
  commonSettings,
  farms,
  hatomMoneyMarkets,
  hatomFormattedRewards,
  isPositionsLoading,
  openedPositions,
}) => {
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address, balance, shard } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  const [showClosePositionModal, setShowClosePositionModal] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<JewelHatomPostionContext | undefined>();
  const [selectedFarm, setSelectedFarm] = useState<JewelHatomFarmContext | undefined>();
  const [isPartiallyClose, setIsPartiallyClose] = useState<boolean>(false);
  const [partiallyClosePercent, setPartiallyClosePercent] = useState<number>(0);
  const handleClosePosition = async () => {
    if (selectedPosition && selectedFarm) {
      const gasLimit = selectedFarm.hatom_loop_count > 0 ? 600_000_000 : 600_000_000;
      if (isPartiallyClose) {
        // partial close
        if (partiallyClosePercent == 0) return;
        await closeHatomPosition(chainID, address, selectedPosition.position_id, gasLimit, partiallyClosePercent * PERCENT_DENOMINATOR);
      } else {
        // close position
        await closeHatomPosition(chainID, address, selectedPosition.position_id, gasLimit);
      }
      setShowClosePositionModal(false);
    }
  };

  const handleOpenClosePositionModal = (item: JewelHatomPostionContext) => {
    setSelectedPosition(item);
    const farm = farms.filter((v: JewelHatomFarmContext) => v.hatom_money_market_address === item.position_hatom_mm_address);
    if (farm.length > 0) {
      setSelectedFarm(farm[0]);

      setIsPartiallyClose(false);
      setPartiallyClosePercent(0);
      setShowClosePositionModal(true);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'position_id',
      headerName: '#',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => (
        <div className='py-2'>
          {`Jewel #${params.value}`}
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_farm_id',
      headerName: 'Pool',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const farm = farms.filter((v: JewelHatomFarmContext) => v.hatom_money_market_address === params.row.position_hatom_mm_address);
        return <div className='py-2'>
          {farm.length > 0 ? getTokenTicker(farm[0].hatom_base_token_id) : 'SEGLD'}
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_farm_apy',
      headerName: 'APY',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const farm = farms.filter((v: JewelHatomFarmContext) => v.hatom_money_market_address === params.row.position_hatom_mm_address);
        return <div className='py-2'>{farm.length > 0 ? `${convertBigNumberValueToLocalString(getJewelHatomPoolApy(farm[0], hatomMoneyMarkets, hatomFormattedRewards, commonSettings ? commonSettings.fee_percents[0] + commonSettings.fee_percents[1] : 30_0000) * params.row.position_leverage_percent / PERCENT_DENOMINATOR / 100)}%` : '-'}</div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_state',
      headerName: 'State',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_value',
      headerName: 'Position Value',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        const farm = farms.filter((v: JewelHatomFarmContext) => v.hatom_money_market_address === params.row.position_hatom_mm_address);
        if (farm.length > 0) {
          const positionValue = BigNumber(farm[0].hatom_base_supply_amount).dividedBy(BigNumber(farm[0].hatom_farm_share).dividedBy(params.row.position_farm_share));
          return <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(params.row.position_deposit_payment.token_identifier)} alt='logo' />
            <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(positionValue, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))}</span>
          </div>;
        } else {
          return <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(params.row.position_deposit_payment.token_identifier)} alt='logo' />
            <span>-</span>
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_debt_amount',
      headerName: 'Debt Value',
      width: isMobile ? 200 : 0,
      flex: isMobile ? 0 : 5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const farm = farms.filter((v: JewelHatomFarmContext) => v.hatom_money_market_address === params.row.position_hatom_mm_address);
        if (farm.length > 0) {
          const debtValue = BigNumber(farm[0].hatom_debt_share).gt('0') && BigNumber(params.row.position_debt_share).gt('0') ? BigNumber(farm[0].hatom_base_debt_amount).dividedBy(BigNumber(farm[0].hatom_debt_share).dividedBy(params.row.position_debt_share)) : BigNumber('0');
          // return <div className='d-flex gap-2 align-items-center'>
          //   <img className='egld-badge' src={getTokenImage(params.row.position_deposit_payment.token_identifier)} alt='logo' />
          //   <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.row.position_jewel_debt_amount, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))}</span>
          // </div>;
          return <div className='text-center my-1'>
            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(debtValue.plus(params.row.position_jewel_debt_amount), getTokenDecimal(params.row.position_deposit_payment.token_identifier)))} ${getTokenTicker(params.row.position_deposit_payment.token_identifier)}`}</div>
            {
              BigNumber(params.row.position_jewel_debt_amount).gt('0') && (
                <div>{`JEWEL (${convertBigNumberValueToLocalString(convertWeiToEsdt(params.row.position_jewel_debt_amount, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))} ${getTokenTicker(params.row.position_deposit_payment.token_identifier)})`}</div>
              )
            }
            <div>{`HATOM (${convertBigNumberValueToLocalString(convertWeiToEsdt(debtValue, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))} ${getTokenTicker(params.row.position_deposit_payment.token_identifier)})`}</div>
          </div>;
        } else {
          return <div className='d-flex gap-2 align-items-center'>
            <img className='egld-badge' src={getTokenImage(params.row.position_deposit_payment.token_identifier)} alt='logo' />
            <span>-</span>
          </div>;
        }
      },
    },
    {
      field: 'deposit_value',
      headerName: 'Deposit Value',
      width: isMobile ? 150 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => {
        return <div className='d-flex gap-2 align-items-center'>
          <img className='egld-badge' src={getTokenImage(params.row.position_deposit_payment.token_identifier)} alt='logo' />
          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.row.position_deposit_payment.amount, getTokenDecimal(params.row.position_deposit_payment.token_identifier)))}</span>
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_debt_ratio',
      headerName: 'Debt Ratio',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const farm = farms.filter((v: JewelHatomFarmContext) => v.hatom_money_market_address === params.row.position_hatom_mm_address);
        if (farm.length > 0) {
          const positionValue = BigNumber(farm[0].hatom_base_supply_amount).dividedBy(BigNumber(farm[0].hatom_farm_share).dividedBy(params.row.position_farm_share));
          const debtValue = BigNumber(farm[0].hatom_debt_share).gt('0') && BigNumber(params.row.position_debt_share).gt('0') ? BigNumber(farm[0].hatom_base_debt_amount).dividedBy(BigNumber(farm[0].hatom_debt_share).dividedBy(params.row.position_debt_share)) : BigNumber('0');
          const debtRatio = debtValue.multipliedBy(100).dividedBy(positionValue);
          return <div className='d-flex gap-2 align-items-center'>
            <span>{convertBigNumberValueToLocalString(debtRatio)}%</span>
          </div>;
        } else {
          return <div className='d-flex gap-2 align-items-center'>
            <span>-%</span>
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_liquidation_threshold',
      headerName: 'Liquidation Threshold',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        return <div className='d-flex gap-2 align-items-center'>
          <span>90%</span>
        </div>;
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_safety_buffer',
      headerName: 'Safety Buffer',
      width: isMobile ? 120 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => {
        const farm = farms.filter((v: JewelHatomFarmContext) => v.hatom_money_market_address === params.row.position_hatom_mm_address);
        if (farm.length > 0) {
          const positionValue = BigNumber(farm[0].hatom_base_supply_amount).dividedBy(BigNumber(farm[0].hatom_farm_share).dividedBy(params.row.position_farm_share));
          const debtValue = BigNumber(farm[0].hatom_debt_share).gt('0') && BigNumber(params.row.position_debt_share).gt('0') ? BigNumber(farm[0].hatom_base_debt_amount).dividedBy(BigNumber(farm[0].hatom_debt_share).dividedBy(params.row.position_debt_share)) : BigNumber('0');
          const debtRatio = debtValue.multipliedBy(100).dividedBy(positionValue);
          return <div className='d-flex gap-2 align-items-center'>
            <span>{convertBigNumberValueToLocalString(BigNumber(90).minus(debtRatio))}%</span>
          </div>;
        } else {
          return <div className='d-flex gap-2 align-items-center'>
            <span>-%</span>
          </div>;
        }
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position_leverage_percent',
      headerName: 'Leverage Percentage',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 2,
      renderCell: (params) => (
        <div className='d-flex gap-2 align-items-center'>
          <span>{`${params.value / PERCENT_DENOMINATOR / 100}X`}</span>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'button',
      headerName: 'Action',
      width: isMobile ? 100 : 0,
      flex: isMobile ? 0 : 3,
      renderCell: (params) => (
        <div className='my-1'>
          <button className='approval_but' onClick={() => handleOpenClosePositionModal(params.row)}>Close</button>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  return (
    <>
      <div className='w-100' style={{ marginTop: '30px' }}>
        <DataGrid
          sx={{
            '& .MuiSvgIcon-root': { fontSize: 20 },
            fontSize: '12px',
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'hidden',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '400px'
            },
          }}
          autoHeight
          getRowHeight={() => 'auto'}
          rows={openedPositions}
          columns={columns}
          getRowId={(row) => row.position_id}
          disableColumnMenu
          disableSelectionOnClick
          hideFooter
          loading={isPositionsLoading}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showClosePositionModal}
        onClose={() => setShowClosePositionModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showClosePositionModal}>
          <Box sx={fadeBoxStyle} className='farm-modal scrollbar' style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }}>
            <div className='d-flex'>
              Please take note that when closing positions, for non-leverage positions, respective tokens collateral are deactivated from Hatom, withdrawn, and repaid to the user. For leverage positions, please be aware of potential high slippage fees when closing a large position. This is due to your position size compared to the underlying liquidity of the pool. Use Partially Close position, if available, to reduce slippage fees. JWLEGLD or JWLUSD is used to repay your debt so you may receive a minimal amount of JWLEGLD or JWLUSD after closing your position.
            </div>
            <div className='d-flex mt-4 justify-content-between gap-5'>
              <div className='d-flex align-items-center'>
                Initial Deposit Value
              </div>
              <div className='d-flex align-items-center text-end'>
                {selectedPosition ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(selectedPosition.position_deposit_payment.amount, getTokenDecimal(selectedPosition.position_deposit_payment.token_identifier)))} ${getTokenTicker(selectedPosition.position_deposit_payment.token_identifier)}` : '-'}
              </div>
            </div>
            <div className='d-flex mt-2 justify-content-between gap-5'>
              <div className='d-flex align-items-center'>
                Current Position Value
              </div>
              <div className='d-flex align-items-center text-end'>
                {selectedPosition && selectedFarm ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(selectedFarm.hatom_base_supply_amount).dividedBy(BigNumber(selectedFarm.hatom_farm_share).dividedBy(selectedPosition.position_farm_share)), getTokenDecimal(selectedPosition.position_deposit_payment.token_identifier)))} ${getTokenTicker(selectedPosition.position_deposit_payment.token_identifier)}` : '-'}
              </div>
            </div>
            {
              selectedFarm && selectedFarm.hatom_loop_count > 0 && (
                <>
                  <div className='d-flex mt-3'>
                    <Checkbox
                      checked={isPartiallyClose}
                      onChange={e => setIsPartiallyClose(e.target.checked)}
                      sx={{ '&': { padding: '0px', marginRight: '3px' } }}
                    />
                    <div>Partially Close</div>
                  </div>
                  {isPartiallyClose && (
                    <>
                      <div className='d-flex mt-2'>
                        What percentage of position value would you like to close?
                      </div>
                      <div className='d-flex justify-content-center mt-1'>
                        <div style={{ width: '100%', marginLeft: '10px' }}>
                          <PercentSlider
                            valueLabelDisplay="auto"
                            aria-label="pretto slider"
                            min={0}
                            max={100}
                            step={1}
                            marks={sliderMarks}
                            value={partiallyClosePercent}
                            valueLabelFormat={valueLabelFormat}
                            onChange={(e: any) => setPartiallyClosePercent(e.target.value)}
                          />
                        </div>
                        <div style={{ marginLeft: '20px' }}>
                          <InputNumber
                            className={`farm-leverage-input`}
                            min={0}
                            max={100}
                            step={25}
                            value={partiallyClosePercent}
                            formatter={(value) => `${value}%`}
                            parser={(value: any) => value!.replace('%', '')}
                            onChange={(value: any) => setPartiallyClosePercent(value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )
            }
            <div className='d-flex justify-content-center mt-4'>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleClosePosition}>
                Close Position
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
