import {
  Address,
  AddressType,
  AddressValue,
  ArgSerializer,
  BigUIntType,
  BigUIntValue,
  BinaryCodec,
  ContractFunction,
  Field,
  FieldDefinition,
  List,
  ResultsParser,
  SmartContract,
  StringValue,
  Struct,
  StructType,
  TokenIdentifierType,
  TokenIdentifierValue,
  TokenTransfer,
  Transaction,
  TransactionPayload,
  TransactionWatcher,
  TypedValue,
  U32Value,
  U64Type,
  U64Value,
} from "@multiversx/sdk-core/out";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { refreshAccount } from "@multiversx/sdk-dapp/utils";
import BigNumber from 'bignumber.js';
import {
  ENERGY_FACTORY_ADDRESS,
  JEWEL_EXCHANGE_SC_ADDRESS,
  LOCKED_TOKEN_WRAPPER_ADDRESS,
  isDev,
} from "config";
import {
  EsdtTokenPayment,
  JewelExchangeFarmBaseContext,
  JewelExchangeFarmContext,
  JewelExchangeLockOptionContext,
  JewelExchangeFarmTokenContext,
  JewelExchangeFarmStatsContext,
} from "z/types";
import { getTokenTicker } from "z/utils";
import { parseEsdtTokenPayment } from "../common";
import {
  apiNetworkProvider,
  jewelExchangeSmartContract,
} from "../elrond-provider";
import { mvxQuery } from "../provider";

// Jewel xExchange Farm
export const queryJewelExchangeFarmBaseContext = async (): Promise<JewelExchangeFarmBaseContext | undefined> => {
  try {
    const interaction = jewelExchangeSmartContract.methodsExplicit.viewBaseContext();
    const value = await mvxQuery(interaction);
    const decoded: JewelExchangeFarmBaseContext = {
      wrapped_farm_token: value.wrapped_farm_token.toString(),
      unstake_farm_token: value.unstake_farm_token.toString(),
      jwlxmex_token: value.jwlxmex_token.toString(),
      sjwlxmex_token: value.sjwlxmex_token.toString(),
      
      fee_percents: value.fee_percents.map((v: any) => v.toNumber()),
      fee_addresses: value.fee_addresses.map((v: any) => v.toString()),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const queryJewelExchangeFarmStatsContext = async (): Promise<JewelExchangeFarmStatsContext | undefined> => {
  try {
    const interaction = jewelExchangeSmartContract.methodsExplicit.viewStatsContext();
    const value = await mvxQuery(interaction);
    const decoded: JewelExchangeFarmStatsContext = {
      locked_xmex_reserve: value.locked_xmex_reserve.toFixed(),
      jwlxmex_supply: value.jwlxmex_supply.toFixed(),
      jwlxmex_pool_reserve: value.jwlxmex_pool_reserve.toFixed(),
      sjwlxmex_pool_reserve: value.sjwlxmex_pool_reserve.toFixed(),
      internal_locked_token: parseEsdtTokenPayment(value.internal_locked_token),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const queryJewelExchangeFarms = async (): Promise<JewelExchangeFarmContext[]> => {
  try {
    const interaction = jewelExchangeSmartContract.methodsExplicit.viewFarms();
    const values = await mvxQuery(interaction);
    const decoded: JewelExchangeFarmContext[] = values.map((value: any) => {
      return {
        farm_address: value.farm_address.toString(),
        farm_token_id: value.farm_token_id.toString(),
        farming_token_id: value.farming_token_id.toString(),
        // division_safety_constant: value.division_safety_constant.toFixed(),
        minimum_farming_epochs: value.minimum_farming_epochs.toNumber(),

        farm_token_payment: parseEsdtTokenPayment(value.farm_token_payment),
        farm_unstaked_reserve: value.farm_unstaked_reserve.toFixed(),
        farm_reward_reserve: value.farm_reward_reserve.toFixed(),
        farm_rps: value.farm_rps.toFixed(),
        
        pair_address: value.pair_address.toString(),
        pair_token_ids: value.pair_token_ids.map((v: any) => v.toString()),
        pair_token_amounts: value.pair_token_amounts.map((v: any) => v.toString()),

        min_deposit_base_token: value.min_deposit_base_token.toString(),
        min_deposit_base_amount: value.min_deposit_base_amount.toFixed(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const queryJewelExchangeLockOptions = async (): Promise<JewelExchangeLockOptionContext[]> => {
  try {
    const interaction = jewelExchangeSmartContract.methodsExplicit.getLockOptions();
    const values = await mvxQuery(interaction);
    const decoded: JewelExchangeLockOptionContext[] = values.map((value: any) => {
      return {
        lock_epochs: value.lock_epochs.toNumber(),
        penalty_start_percentage: value.penalty_start_percentage.toNumber(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelExchangeFarmTokenId = async (farm_address: string): Promise<string> => {
  try {
    const args: TypedValue[] = [
      new AddressValue(new Address(farm_address)),
    ];
    const interaction = jewelExchangeSmartContract.methodsExplicit.getFarmTokenId(args);
    const value = await mvxQuery(interaction);
    const decoded = value.toString();

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '';
  }
};

export const queryJewelExchangeFarmTokenContext = async (address: string, payments: EsdtTokenPayment[], attributes: any[]): Promise<JewelExchangeFarmTokenContext | undefined> => {
  try {
    const amounts = payments.map((v) => new BigUIntValue(v.amount));

    const attributesType = new StructType('WrappedFarmTokenAttributes', [
      new FieldDefinition('farm_address', '', new AddressType()),
      new FieldDefinition('token_rps', '', new BigUIntType()),
    ]);
    const esdtAttributes: Struct[] = [];
    for (const attribute of attributes) {
      esdtAttributes.push(
        new Struct(attributesType, [
          new Field(new AddressValue(new Address(attribute.farm_address)), 'farm_address'),
          new Field(new BigUIntValue(attribute.token_rps), 'token_rps'),
        ])
      );
    }

    const args: TypedValue[] = [
      List.fromItems(esdtAttributes),
      List.fromItems(amounts),
    ];
    const interaction = jewelExchangeSmartContract.methodsExplicit.viewFarmTokenContext(args);
    const values = await mvxQuery(interaction);

    const decoded: JewelExchangeFarmTokenContext = {
      user_reward_amounts_vec: values.user_reward_amounts_vec.map((item: any) => item.toFixed()),
      pair_token_payments_vec: values.pair_token_payments_vec.map((value: any) => value.map((item: any) => (
        {
          token_identifier: item.token_identifier.toString(),
          token_nonce: item.token_nonce.toNumber(),
          amount: item.amount.toFixed(),
        }
      )))
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};


export const enterExchangeFarm = async (chainID: string, address: string, farmAddress: string, tokenIds: string[], depositAmounts: string[], wrapAddress: string) => {
  const txs: any = [];
  const args: TypedValue[] = [
    new AddressValue(new Address(JEWEL_EXCHANGE_SC_ADDRESS)),
  ];
  args.push(new U32Value(tokenIds.length));
  for (let i = 0; i < tokenIds.length; i++) {
    args.push(new TokenIdentifierValue(tokenIds[i]));
    args.push(new U64Value(0));

    args.push(new BigUIntValue(depositAmounts[i]));
    if (getTokenTicker(tokenIds[i]) === 'EGLD') {

      const data = new TransactionPayload('wrapEgld');

      const wrapTx = new Transaction({
        value: depositAmounts[i],
        sender: new Address(address),
        receiver: new Address(wrapAddress),
        gasLimit: 10_000_000,
        chainID: chainID,
        data,
      });
      txs.push(wrapTx);
    }
  }
  args.push(new StringValue('enterFarm'));
  args.push(new AddressValue(new Address(farmAddress)));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: 150_000_000,
    chainID: chainID,
    data,
  });
  txs.push(tx);

  await refreshAccount();
  const result = await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Farm Request',
      errorMessage: 'Error occured during Farm Request',
      successMessage: 'Farm Request Successful',
    }
  });

  return result;
};

export const claimExchangeReward = async (chainID: string, address: string, collection: string, nonce: number, balance: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(collection),
    new U64Value(nonce),
    new BigUIntValue(balance),
    new AddressValue(new Address(JEWEL_EXCHANGE_SC_ADDRESS)),
    new StringValue('claimUserRewards'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: 60_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Claim Request',
      errorMessage: 'Error occured during Claim Request',
      successMessage: 'Claim Request Successful',
    }
  });
};

export const unstakeExchangeFarm = async (chainID: string, address: string, collection: string, nonce: number, balance: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(collection),
    new U64Value(nonce),
    new BigUIntValue(balance),
    new AddressValue(new Address(JEWEL_EXCHANGE_SC_ADDRESS)),
    new StringValue('unstakeFarm'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: 60_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unstake Request',
      errorMessage: 'Error occured during Unstake Request',
      successMessage: 'Unstake Request Successful',
    }
  });
};

export const unbondExchangeFarm = async (chainID: string, address: string, collection: string, nonce: number, balance: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(collection),
    new U64Value(nonce),
    new BigUIntValue(balance),
    new AddressValue(new Address(JEWEL_EXCHANGE_SC_ADDRESS)),
    new StringValue('unbondFarm'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: 60_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unbond Request',
      errorMessage: 'Error occured during Unbond Request',
      successMessage: 'Unbond Request Successful',
    }
  });
};

export const mintJwlxmex = async (address: string, payment: TokenTransfer) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(payment.tokenIdentifier),
    new U64Value(payment.nonce),
    new BigUIntValue(payment.amountAsBigInteger),
    new AddressValue(new Address(JEWEL_EXCHANGE_SC_ADDRESS)),
    new StringValue('mintJwlxmex'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: 60_000_000,
    chainID: isDev ? 'D' : '1',
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Mint Request',
      errorMessage: 'Error occured during Mint Request',
      successMessage: 'Mint Request Successful',
    }
  });
};

export const stakeJwlxmex = async (chainID: string, address: string, tokenId: string, balance: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(balance),
    new StringValue('stake'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_EXCHANGE_SC_ADDRESS),
    gasLimit: 20_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Stake Request',
      errorMessage: 'Error occured during Stake Request',
      successMessage: 'Stake Request Successful',
    }
  });
};

export const unstakeJwlxmex = async (chainID: string, address: string, tokenId: string, balance: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(balance),
    new StringValue('unstake'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_EXCHANGE_SC_ADDRESS),
    gasLimit: 20_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unstake Request',
      errorMessage: 'Error occured during Unstake Request',
      successMessage: 'Unstake Request Successful',
    }
  });
};

export const wrapXmex = async (address: string, payment: TokenTransfer): Promise<string> => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(payment.tokenIdentifier),
    new U64Value(payment.nonce),
    new BigUIntValue(payment.amountAsBigInteger),
    new AddressValue(new Address(LOCKED_TOKEN_WRAPPER_ADDRESS)),
    new StringValue('wrapLockedToken'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: 20_000_000,
    chainID: isDev ? 'D' : '1',
    data,
  });

  await refreshAccount();
  const { sessionId } =  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Wrap Request',
      errorMessage: 'Error occured during Wrap Request',
      successMessage: 'Wrap Request Successful',
    }
  });

  return sessionId;
};


export async function getWrapXmexResult(txHash: string): Promise<EsdtTokenPayment | undefined> {
  try {
    // tx must be completed
    // const transactionOnNetwork = await apiNetworkProvider.getTransaction(txHash);
    // const untypedBundle = new ResultsParser().parseUntypedOutcome(transactionOnNetwork);

    // tx can be imcomplete
    const watcher = new TransactionWatcher(apiNetworkProvider);
    const transactionOnNetwork = await watcher.awaitCompleted({ getHash: () => ({ hex: () => txHash }) });
    console.log('Tx Status:', transactionOnNetwork.status);
    const untypedBundle = new ResultsParser().parseUntypedOutcome(transactionOnNetwork);

    const typeDefinition = new StructType(
      "UnstakeTokenAttributes",
      [
        new FieldDefinition("token_identifier", "", new TokenIdentifierType()),
        new FieldDefinition("token_nonce", "", new U64Type()),
        new FieldDefinition("amount", "", new BigUIntType()),
      ]
    );
    const decoded = new BinaryCodec().decodeTopLevel(untypedBundle.values[0], typeDefinition).valueOf();

    return {
      token_identifier: decoded.token_identifier.toString(),
      token_nonce: decoded.token_nonce.toNumber(),
      amount: decoded.amount.toFixed(),
    };
  } catch (err) {
    console.error(err);
    return undefined;
  }
}


const EnergyType = new StructType(
  "Energy",
  [
    new FieldDefinition("amount", "", new BigUIntType()),
    new FieldDefinition("last_update_epoch", "", new U64Type()),
    new FieldDefinition("total_locked_tokens", "", new BigUIntType()),
  ]
);

export interface XexchangeEnergyEntry {
  amount: BigNumber,
  last_update_epoch: number,
  total_locked_tokens: BigNumber,
}

export async function getXexchangeEnergyEntry(targetAddress: string): Promise<XexchangeEnergyEntry> {
  const contract = new SmartContract({
    address: new Address(ENERGY_FACTORY_ADDRESS)
  });
  
  const query = contract.createQuery({
    func: new ContractFunction("getEnergyEntryForUser"),
    args: [new AddressValue(new Address(targetAddress))]
  });
  
  const queryResponse = await apiNetworkProvider.queryContract(query);
  const bundle = new ResultsParser().parseUntypedQueryResponse(queryResponse);
  const firstValue = bundle.values[0];
  const value = new BinaryCodec().decodeTopLevel(firstValue, EnergyType).valueOf();
  const decoded = {
    amount: value.amount,
    last_update_epoch: value.last_update_epoch.toNumber(),
    total_locked_tokens: value.total_locked_tokens,
  };

  return decoded;
}
