import React, { useEffect, useState, useContext } from 'react';
import { Tooltip } from 'antd';
import { useGetNetworkConfig, useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { InfoCircleOutlined } from "@ant-design/icons";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { isMobile } from 'react-device-detect';
import './index.scss';
import { getJewelPoints, getJewelReferralCode } from 'z/api';
import { JewelPoint } from 'z/types';
import { convertBigNumberValueToLocalString, formatAddress, toastSuccess } from 'z/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { JEWEL_APP_URL } from 'config';

export const Point = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [jewelPoints, setJewelPoints] = useState<JewelPoint[]>([]);
    const [userPoint, setUserPoint] = useState<JewelPoint | undefined>();
    const [userReferralCode, setUserReferralCode] = useState<string>('');
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const points = await getJewelPoints();
            setJewelPoints(points);

            if (isLoggedIn) {
                for (const point of points) {
                    if (point.address === address) {
                        setUserPoint(point);
                        break;
                    }
                }
            }

            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (!isLoggedIn) return;
        (async () => {
            const code = await getJewelReferralCode(address);
            console.log('referral_code: ', code);
            setUserReferralCode(code);
        })();
    }, [isLoggedIn]);

    const handleCopy = () => {
        toastSuccess('Copied!');
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Rank',
            width: isMobile ? 80 : 0,
            flex: isMobile ? 0 : 1,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value === 1 ? `🥇` : params.value === 2 ? `🥈` : params.value === 3 ? `🥉` : params.value}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'address',
            headerName: 'User',
            width: isMobile ? 120 : 0,
            flex: isMobile ? 0 : 2,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${formatAddress(params.value)}`}
                </div>
            ),
            // align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'lending_points',
            headerName: 'Lending Points',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value > 0 ? convertBigNumberValueToLocalString(params.value) : '-'}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'borrowing_points',
            headerName: 'Borrowing Points',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value > 0 ? convertBigNumberValueToLocalString(params.value) : '-'}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'farming_points',
            headerName: 'Farming Points',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value > 0 ? convertBigNumberValueToLocalString(params.value) : '-'}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'staking_points',
            headerName: 'Staking Points',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value > 0 ? convertBigNumberValueToLocalString(params.value) : '-'}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'voting_points',
            headerName: 'Voting Points',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value > 0 ? convertBigNumberValueToLocalString(params.value) : '-'}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'referral_points',
            headerName: 'Referral Points',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value > 0 ? convertBigNumberValueToLocalString(params.value) : '-'}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'total_points',
            headerName: 'Total Points',
            width: isMobile ? 150 : 0,
            flex: isMobile ? 0 : 3,
            renderCell: (params) => (
                <div className='py-2'>
                    {`${params.value > 0 ? convertBigNumberValueToLocalString(params.value) : '-'}`}
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
    ];

    return (
        <div className='container mt-5 point-container'>
            <div className='row mt-2'>
                <div className='d-flex justify-content-center h3'>Season 1</div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-3 col-md-3 col-sm-12 d-flex justify-content-center'></div>
                <div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-evenly'>
                    <div>
                        <div className='d-flex align-items-center'>
                            <div>Total Points</div>
                        </div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>
                            {userPoint ? convertBigNumberValueToLocalString(userPoint.total_points) : '-'}
                        </div>
                    </div>
                    <div>
                        <div className='d-flex align-items-center'>Global Rank</div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>{userPoint ? userPoint.id : '-'}</div>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12 d-flex justify-content-center'></div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-2 col-md-2 col-sm-6'>
                    <div className='m-2 point-amount'>
                        <div className='d-flex align-items-center'>
                            <div>Lending Points</div>
                            <Tooltip
                                placement="top"
                                title="Lenders get 1 Point for each $1 lent per day."
                            >
                                <InfoCircleOutlined className='p-1' />
                            </Tooltip>
                        </div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>{userPoint ? convertBigNumberValueToLocalString(userPoint.lending_points) : '-'}</div>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-6'>
                    <div className='m-2 point-amount'>
                        <div className='d-flex align-items-center'>
                            <div>Borrowing Points</div>
                            <Tooltip
                                placement="top"
                                title="Borrowers get 2 Points for each $1 borrowed per day."
                            >
                                <InfoCircleOutlined className='p-1' />
                            </Tooltip>
                        </div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>{userPoint ? convertBigNumberValueToLocalString(userPoint.borrowing_points) : '-'}</div>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-6'>
                    <div className='m-2 point-amount'>
                        <div className='d-flex align-items-center'>
                            <div>Farming Points</div>
                            <Tooltip
                                placement="top"
                                title="Farmers get 1 Point for each $1 farmed position per day."
                            >
                                <InfoCircleOutlined className='p-1' />
                            </Tooltip>
                        </div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>{userPoint ? convertBigNumberValueToLocalString(userPoint.farming_points) : '-'}</div>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-6'>
                    <div className='m-2 point-amount'>
                        <div className='d-flex align-items-center'>
                            <div>Staking Points</div>
                            <Tooltip
                                placement="top"
                                title="Stakers get 1 Point for each $1 staked per day."
                            >
                                <InfoCircleOutlined className='p-1' />
                            </Tooltip>
                        </div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>{userPoint ? convertBigNumberValueToLocalString(userPoint.staking_points) : '-'}</div>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-6'>
                    <div className='m-2 point-amount'>
                        <div className='d-flex align-items-center'>
                            <div>Voting Points</div>
                            <Tooltip
                                placement="top"
                                title="Ashswap veASH voters who vote for JWL farms get 1 Point for each 50 veASH."
                            >
                                <InfoCircleOutlined className='p-1' />
                            </Tooltip>
                        </div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>{userPoint ? convertBigNumberValueToLocalString(userPoint.voting_points) : '-'}</div>
                    </div>
                </div>
                <div className='col-lg-2 col-md-2 col-sm-6'>
                    <div className='m-2 point-amount'>
                        <div className='d-flex align-items-center'>
                            <div>Referral Points</div>
                            <Tooltip
                                placement="top"
                                title="Users get 10% of the total points of every referral."
                            >
                                <InfoCircleOutlined className='p-1' />
                            </Tooltip>
                        </div>
                        <div className='mt-2 d-flex gap-1 align-items-center'>{userPoint ? convertBigNumberValueToLocalString(userPoint.referral_points) : '-'}</div>
                    </div>
                </div>
            </div>
            {
                userReferralCode && (
                    <div className='row mt-5 justify-content-center'>
                        <div className='d-flex gap-2 justify-content-center copy-referral'>
                            <div>Copy referral link</div>
                            <CopyToClipboard text={`${JEWEL_APP_URL}?ref=${userReferralCode}`} onCopy={handleCopy}>
                                <ContentCopyIcon style={{ cursor: 'pointer' }} />
                            </CopyToClipboard>
                        </div>
                    </div>
                )
            }
            <DataGrid
                className='mt-5'
                sx={{
                    '& .MuiSvgIcon-root': { fontSize: 20 },
                    fontSize: '13px',
                    '& .MuiDataGrid-columnSeparator': {
                        visibility: 'hidden',
                    },
                }}
                autoHeight
                rowHeight={35}
                // getRowHeight={() => 'auto'}
                rows={jewelPoints}
                columns={columns}
                getRowId={(row) => row.id}
                disableColumnMenu
                disableSelectionOnClick
                pageSize={10}
                loading={isLoading}
            />
        </div>
    );
};
