import { Address, AddressValue, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { JEWEL_STABLE_SC_ADDRESS } from 'config';
import { NftWithIndexType } from 'z/types';
import { NFT_QUANTITY } from 'z/utils';

export const sendJewelStableMint = async (chainID: string, address: string, tokenId: string, mintAmount: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(mintAmount),
    new StringValue('mintJwlusd'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_STABLE_SC_ADDRESS),
    gasLimit: 300_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Mint Request',
      errorMessage: 'Error occured during Mint Request',
      successMessage: 'Mint Request Successful',
    }
  });
};

export const sendJewelStableRedeem = async (chainID: string, address: string, tokenId: string, redeemAmount: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(redeemAmount),
    new StringValue('redeemJwlusd'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_STABLE_SC_ADDRESS),
    gasLimit: 30_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Redeem Request',
      errorMessage: 'Error occured during Redeem Request',
      successMessage: 'Redeem Request Successful',
    }
  });
};

export const sendJewelStableUnbond = async (chainID: string, address: string, nfts: NftWithIndexType[]) => {
  const args: TypedValue[] = [
    new AddressValue(new Address(JEWEL_STABLE_SC_ADDRESS)),
  ];
  args.push(new U32Value(nfts.length));
  for (const nft of nfts) {
    args.push(new TokenIdentifierValue(nft.collection));
    args.push(new U64Value(nft.nonce));
    args.push(new U64Value(NFT_QUANTITY));
  }
  args.push(new StringValue('withdraw'));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  let gasLimit = 350_000_000 + 20_000_000 * nfts.length;
  if (gasLimit > 600_000_000) {
    gasLimit = 600_000_000;
  }

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: gasLimit,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unbond Request',
      errorMessage: 'Error occured during Unbond Request',
      successMessage: 'Unbond Request Successful',
    }
  });

  return result;
};

export const sendJewelStableStake = async (chainID: string, address: string, tokenId: string, stakeAmount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(stakeAmount),
    new StringValue('stakeJwlusd'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_STABLE_SC_ADDRESS),
    gasLimit: 20_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Stake Request',
      errorMessage: 'Error occured during Stake Request',
      successMessage: 'Stake Request Successful',
    }
  });
};

export const sendJewelStableUnstake = async (chainID: string, address: string, tokenId: string, unstakeAmount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(unstakeAmount),
    new StringValue('unstakeJwlusd'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_STABLE_SC_ADDRESS),
    gasLimit: 20_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unstake Request',
      errorMessage: 'Error occured during Unstake Request',
      successMessage: 'Unstake Request Successful',
    }
  });
};
