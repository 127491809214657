import { RouteType } from "@multiversx/sdk-dapp/types";
import { dAppName } from "config";
import { Dashboard } from "pages/Dashboard";
import { withPageTitle } from "./components/PageTitle";
import { BorrowEgld, Lend, Loans, Collections, Collection, Pool, MyPools, CreatePool, EditPool, Mortgage, AdminTopUp, AdminVeash, Farm, MyPositions, Stake, Gauge, JewelMoneyMarket, JewelGlobalMoneyMarket, Facilitate, Point, AdminXexchangeFarmEnergy } from "./pages";
import { Restake } from "pages/Restake";
// import { LendEgld } from "pages/Lend/Lend";

export const routeNames = {
  home: "/",
  borrow: "/borrow",
  lend: "/lend",
  payDown: "/down-payment-buy",
  loans: "/loans",
  unlock: "/unlock",
  dashboard: '/dashboard',
  collections: '/collections',
  collection: '/collection/:collectionId',
  pool: '/collection/:collectionId/:poolId',
  mypools: '/mypools',
  createPool: '/create-pool/*',
  editPool: '/collection/:collectionId/:poolId/edit',
  mortgage: '/mortgage',
  topUp: '/admin/top-up',
  veash: '/admin/veash',
  facilitate: '/admin/facilitate',
  adminXexchangeFarm: '/admin/xexchange-farm',
  farm: '/farm/:dex',
  mypositions: '/mypositions/:dex',
  restake: '/restake',
  stake: '/stake/:tokenId',
  gauge: '/gauge',
  moneyMarket: '/money-market/:type',
  globalMoneyMarket: '/money-market/global',
  point: '/point',
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.dashboard,
    title: "Overview",
    component: Dashboard,
    authenticatedRoute: true,
  },
  {
    path: routeNames.lend,
    title: "Lend",
    component: Lend,
    authenticatedRoute: true,
  },
  {
    path: routeNames.borrow,
    title: "Borrow Egld",
    component: BorrowEgld,
    authenticatedRoute: true,
  },
  {
    path: routeNames.loans,
    title: "Loans",
    component: Loans,
    authenticatedRoute: true,
  },
  {
    path: routeNames.collections,
    title: "Collections",
    component: Collections,
  },
  {
    path: routeNames.collection,
    title: "Collection",
    component: Collection,
  },
  {
    path: routeNames.pool,
    title: "Pool",
    component: Pool,
  },
  {
    path: routeNames.mypools,
    title: "MyPools",
    component: MyPools,
    authenticatedRoute: true,
  },
  {
    path: routeNames.createPool,
    title: "Create Pool",
    component: CreatePool,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editPool,
    title: "Edit Pool",
    component: EditPool,
    authenticatedRoute: true,
  },
  // {
  //   path: routeNames.mortgage,
  //   title: "Mortgage",
  //   component: Mortgage,
  //   authenticatedRoute: true,
  // },
  {
    path: routeNames.topUp,
    title: "Admin TopUp",
    component: AdminTopUp,
    authenticatedRoute: true,
  },
  {
    path: routeNames.veash,
    title: "Admin Veash",
    component: AdminVeash,
    authenticatedRoute: true,
  },
  {
    path: routeNames.facilitate,
    title: "Admin Facilitate",
    component: Facilitate,
    authenticatedRoute: true,
  },
  {
    path: routeNames.adminXexchangeFarm,
    title: "Admin xExchange Farm",
    component: AdminXexchangeFarmEnergy,
    authenticatedRoute: true,
  },
  {
    path: routeNames.farm,
    title: "Farm",
    component: Farm,
    authenticatedRoute: true,
  },
  {
    path: routeNames.mypositions,
    title: "Positions",
    component: MyPositions,
    authenticatedRoute: true,
  },
  {
    path: routeNames.stake,
    title: "Stake",
    component: Stake,
    authenticatedRoute: true,
  },
  {
    path: routeNames.restake,
    title: "Restake",
    component: Restake,
    authenticatedRoute: true,
  },
  {
    path: routeNames.gauge,
    title: "Gauge",
    component: Gauge,
    authenticatedRoute: true,
  },
  {
    path: routeNames.moneyMarket,
    title: "Money Market",
    component: JewelMoneyMarket,
    authenticatedRoute: true,
  },
  {
    path: routeNames.globalMoneyMarket,
    title: "Money Market",
    component: JewelGlobalMoneyMarket,
    authenticatedRoute: true,
  },
  {
    path: routeNames.point,
    title: "Point",
    component: Point,
    authenticatedRoute: true,
  },
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • JewelSwap ${dAppName}`
    : `JewelSwap ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});
