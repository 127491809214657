import { Address, AddressValue, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { JEWEL_HATOM_FARM_SC_ADDRESS } from 'config';
import { getTokenTicker, MAX_PERCENTAGE, PERCENT_DENOMINATOR } from 'z/utils';

export const openHatomPosition = async (chainID: string, address: string, tokenId: string, depositAmount: string, debtAmount: string, hatomMMAddress: string, wrapAddress: string, gasLimit: number) => {
  const txs: any = [];

  if (getTokenTicker(tokenId) === 'EGLD') {
    // warp tx
    const data = new TransactionPayload('wrapEgld');
    const wrapTx = new Transaction({
      value: depositAmount,
      sender: new Address(address),
      receiver: new Address(wrapAddress),
      gasLimit: 10_000_000,
      chainID: chainID,
      data,
    });
    txs.push(wrapTx);
  }

  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(depositAmount),
    new StringValue('openPosition'),
    new AddressValue(new Address(hatomMMAddress)), // mm_address
    new BigUIntValue(debtAmount), // position_jewel_lend_borrow_amount
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_HATOM_FARM_SC_ADDRESS),
    gasLimit: gasLimit,
    chainID: chainID,
    data,
  });
  txs.push(tx);
  
  await refreshAccount();
  const result = await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Farm Request',
      errorMessage: 'Error occured during Farm Request',
      successMessage: 'Farm Request Successful',
    }
  });

  return result;
};

export const closeHatomPosition = async (chainID: string, address: string, positionId: number, gasLimit: number, closePercent = 100 * PERCENT_DENOMINATOR) => {
  const args: TypedValue[] = [
    new U64Value(positionId),
    new U64Value(closePercent),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`closePosition@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_HATOM_FARM_SC_ADDRESS),
    gasLimit: gasLimit,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Close Position Request',
      errorMessage: 'Error occured during Close Position Request',
      successMessage: 'Close Position Request Successful',
    }
  });
};