import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InputNumber } from 'antd';
import { NftCard, NftCardType } from 'components/NftCard';
import { NftRarity, NftWithAmmData } from 'z/types';
import SadIcon from 'assets/img/sad-icon.png';
import { Tooltip } from "antd/lib";
import { InfoCircleOutlined } from "@ant-design/icons";

export enum PoolActionType {
    PoolCreate = "PoolCreate",
    PoolEdit = "PoolEdit",
}

export const PoolAsset = (props: any) => {
    const [addNfts, setAddNfts] = useState<NftWithAmmData[]>([]);
    const [removeNfts, setRemoveNfts] = useState<NftWithAmmData[]>([]);

    const handleAddNft = (item: NftWithAmmData, isAdded: boolean) => {
        if (isAdded) {
            const nfts = addNfts;
            nfts.push(item);
            setAddNfts(nfts);
            props.setAddNfts(nfts);
        } else {
            const nfts = addNfts.filter((nft: NftWithAmmData) => nft.nonce !== item.nonce);
            setAddNfts(nfts);
            props.setAddNfts(nfts);
        }
    };

    const handleRemoveNft = (item: NftWithAmmData, isRemoved: boolean) => {
        if (isRemoved) {
            const nfts = removeNfts;
            nfts.push(item);
            setRemoveNfts(nfts);
            props.setRemoveNfts(nfts);
        } else {
            const nfts = removeNfts.filter((nft: NftWithAmmData) => nft.nonce !== item.nonce);
            setRemoveNfts(nfts);
            props.setRemoveNfts(nfts);
        }
    };

    return (
        <div className='amm-assets-card'>
            <div className='h2'>Assets <span className='h4'>({props.collectionId})</span></div>
            {(props.poolType == 0 || props.poolType == 2) && (
                <>
                    <div className='h4 mt-4 mb-3'>Buy orders amount
                        <Tooltip
                            placement="top"
                            title="select how many NFTs would you like to buy. If set to 0, someone will be able to buy & sell that NFT. Essentially swapping."
                        >
                            <InfoCircleOutlined className='h6 mx-2' style={{ verticalAlign: '0.2em' }} />
                        </Tooltip>
                    </div>
                    <InputNumber min={0} addonAfter="NFTs" value={props.ordersAmount} onChange={(value: any) => props.setOrdersAmount(value)} />
                </>
            )}
            {(props.poolType == 0 || props.poolType == 1) && props.userNfts && (
                <>
                    <div className='h4 mt-4'>NFTs (wallet)</div>
                    {props.userNfts.length > 0 ? (
                        <Row className='mt-2 amm-assets-card-container'>
                            {props.userNfts.map((item: NftWithAmmData, index: number) => {
                               // const nftRarity = props.rarity?.filter((rarity: NftRarity) => rarity.identifier === item.identifier);
                                // if (nftRarity.length > 0) {
                                    return <Col lg={3} md={3} sm={6} xs={6} className='mt-3' key={index}>
                                        <NftCard item={item} nftCardType={NftCardType.AddNft} handleAddNft={handleAddNft} />
                                    </Col>;
                                // }
                            })}
                        </Row>
                    ) : (
                        <Row className='mt-5 mb-5'>
                            <div className='col-12 text-center'>
                                <img src={SadIcon} alt='no nft' className={'not-found-nfts'} />
                                <div className='h4 mt-1'>No NFTs available</div>
                            </div>
                        </Row>
                    )}
                </>
            )}
            {props.actionType == PoolActionType.PoolEdit && (
                <>
                    {props.poolType == 0 ? (
                        <hr className='mt-5 mb-5'></hr>
                    ) : (
                        <div className='mt-4'></div>
                    )}
                    <div className='h4'>NFTs (pool)</div>
                    {props.poolNfts && (
                        props.poolNfts.length > 0 ? (
                            <Row className='mt-2 amm-assets-card-container'>
                                {props.poolNfts?.map((item: NftWithAmmData, index: number) => {
                                    // const nftRarity = props.rarity?.filter((rarity: NftRarity) => rarity.identifier === item.identifier);
                                    // if (nftRarity.length > 0) {
                                        return <Col lg={3} md={3} sm={6} xs={6} className='mt-3' key={index}>
                                            <NftCard item={item} nftCardType={NftCardType.RemoveNft} handleRemoveNft={handleRemoveNft} />
                                        </Col>;
                                    // }
                                })}
                            </Row>
                        ) : (
                            <Row className='mt-5 mb-5'>
                                <div className='col-12 text-center'>
                                    <img className='not-found-nfts' src={SadIcon} alt='no nft' />
                                    <div className='h4 mt-2'>No NFTs available</div>
                                </div>
                            </Row>
                        )
                    )}
                </>
            )}
        </div>
    );
};
