import React, { useEffect, useState, useContext } from 'react';
import { InputNumber, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { checkSpotPrice, getLockedAmount, getSellingPrice, toastError } from 'z/utils';
import { PoolActionType } from './PoolAsset';
import { convertBigNumberValueToLocalString, convertWeiToEsdt } from 'z/utils';
import BigNumber from 'bignumber.js';
import { AmmCollectionContext } from 'App';
import { NftCollection, PoolTypeEnum } from 'z/types';

const tabs = ['linear curve', 'exponential curve'];
export const PoolPrice = (props: any) => {

    const { ammCollections } = useContext(AmmCollectionContext);

    const [collectionInfo, setCollectionInfo] = useState<NftCollection>();

    useEffect(() => {
        if (props.collectionId && props.collectionId.length > 0 && ammCollections && ammCollections.length > 0) {
            const info: NftCollection[] = ammCollections.filter((collectionInfo: NftCollection) => { return collectionInfo.nft_token_id == props.collectionId; });
            if (info.length > 0) {
                setCollectionInfo(info[0]);
            }
        }
    }, [ammCollections]);

    const [sellingPrice, setSellingPrice] = useState<number>(0);

    useEffect(() => {
        // tab : 0 -> Linear, tab: 1 -> exponential
        if (checkSpotPrice(props.spotPrice, props.delta, props.ordersAmount, props.tab) || props.ordersAmount == null || props.spotPrice == null || props.delta == null) {
            props.setOrdersAmount(props.ordersAmount);
        } else {
            let maxOrders = 0;
            while (checkSpotPrice(props.spotPrice, props.delta, maxOrders, props.tab)) {
                maxOrders ++;
            }
            maxOrders = maxOrders - 1;
            props.setOrdersAmount(maxOrders);
            if (maxOrders > 0) {
                toastError(`Maximum order amount is ${maxOrders}`);
            } else {
                toastError(`Invalid spot price. Reduce delta value.`);
            }
        }
        if (props.poolType != 1) {
            const lockedAmount: BigNumber = getLockedAmount(props.spotPrice, props.delta, props.ordersAmount, props.tab);
            props.setLockedAmount(lockedAmount);
        }
        const sellingPrice: number = getSellingPrice(props.spotPrice, props.delta, 1, props.tab);
        setSellingPrice(sellingPrice);

    }, [props.fee, props.spotPrice, props.delta, props.ordersAmount, props.tab]);

    return (
        <div className='amm-price-card'>
            <div className='h2'>Pricing</div>
            <div>
                {props.poolType == 0 && (
                    <>
                        <div className='h4 mt-3 text-white'>Fee   <Tooltip
                            placement="top"
                            title="how much you will receive from buys/sells in LPs"
                        >
                            <InfoCircleOutlined className='h6 mx-2' style={{ verticalAlign: '0.2em' }} />
                        </Tooltip>
                        </div>
                        <InputNumber
                            type="number"
                            min={0}
                            max={99.5}
                            addonAfter="%"
                            value={props.fee}
                            onChange={(value: any) => props.setFee(value)}
                        />
                    </>
                )}
                <h4 className='text-white h4 mt-3 mb-3'>
                    Spot price{collectionInfo && (` (current best offer: ${convertBigNumberValueToLocalString(convertWeiToEsdt(collectionInfo.nft_best_offer_price))} EGLD, current floor price: ${convertBigNumberValueToLocalString(convertWeiToEsdt(collectionInfo.nft_floor_price))} EGLD)`)}
                    <Tooltip
                        placement="top"
                        title="the starting price of your pool"
                    >
                        <InfoCircleOutlined className='h6 mx-2' style={{ verticalAlign: '0.2em' }} />
                    </Tooltip>
                </h4>
                <InputNumber
                    addonAfter="EGLD"
                    value={props.spotPrice}
                    step={0.1}
                    onChange={(value: any) => props.setSpotPrice(value)}
                />
                <h4 className='text-white h4 mt-3 mb-3'>
                    Bonding curve
                    <Tooltip
                        placement="top"
                        title="controls how your pool's price will change"
                    >
                        <InfoCircleOutlined className='h6 mx-2' style={{ verticalAlign: '0.2em' }} />
                    </Tooltip>
                </h4>
                <div className='main-tab-container w-100 mb-2'>
                    {tabs.map((row, index) => (
                        <span className={`main-tab-individual text-white ${props.tab === index ? 'focus-bg-gradient' : ''}`} key={row} onClick={() => { if (props.actionType == PoolActionType.PoolCreate) props.setTab(index); }}>{row}</span>
                    ))}
                </div>
                <h4 className='text-white h4 mb-3 mt-3'>
                    Delta
                    <Tooltip
                        placement="top"
                        title="how much your pool price changes with each sell/buy"
                    >
                        <InfoCircleOutlined className='h6 mx-2' style={{ verticalAlign: '0.2em' }} />
                    </Tooltip>
                </h4>
                <InputNumber
                    addonAfter={props.tab === 0 ? 'EGLD' : '%'}
                    min={0}
                    value={props.delta}
                    step={0.1}
                    onChange={(value: any) => props.setDelta(value)}
                />
            </div>
            <div className='mt-5'>
                {(props.poolType == 0 || props.poolType == 2) && (
                    <div className='w-100 d-flex justify-content-between'>
                        <span className='h6'>
                            starting buying price
                        </span>
                        <span className='h6'>
                            {(props.spotPrice - props.spotPrice * props.fee / 100).toFixed(3)} EGLD
                        </span>
                    </div>
                )}
                {(props.poolType == 0 || props.poolType == 1) && (
                    <>
                        <div className='w-100 d-flex justify-content-between'>
                            <span className='h6'>
                                starting selling price
                            </span>
                            <span className='h6'>
                                {(sellingPrice + sellingPrice * props.fee / 100).toFixed(3)} EGLD
                            </span>
                        </div>
                        {props.actionType == PoolActionType.PoolEdit && props.poolType == 1 && (
                            <div className='w-100 d-flex justify-content-between'>
                                <span className='h6'>
                                    locked amount in pool
                                </span>
                                <span className='h6'>
                                    {convertBigNumberValueToLocalString(convertWeiToEsdt(props.poolAmount))} EGLD
                                </span>
                            </div>
                        )}
                    </>
                )}
                {(props.poolType == 0 || props.poolType == 2) && (
                    <div className='w-100 d-flex justify-content-between'>
                        <span className='h6'>
                            to be locked into pool
                        </span>
                        <span className='h6'>
                            {convertBigNumberValueToLocalString(convertWeiToEsdt(props.lockedAmount))} EGLD
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};
