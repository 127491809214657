import { SECOND_TO_MILLISECONDS, DAY_IN_SECONDS, HOUR_IN_SECONDS, MAX_PERCENTAGE, YEAR_IN_SECONDS, ASH_LP_DECIMALS, FARM_MAX_PERCENT, PERCENT_DENOMINATOR, YEAR_IN_HOURS } from "./constant";
import BigNumber from 'bignumber.js';
import { XOXNO_IMAGE_URL } from "data";
import egld_white_logo from 'assets/img/coin/EGLD_white.png';
import usdc_logo from 'assets/img/coin/usdc.png';
import usdt_logo from 'assets/img/coin/usdt.png';
import busd_logo from 'assets/img/coin/busd.png';
import ash_logo from 'assets/img/coin/ash.png';
import unknown_logo from 'assets/img/unknown.png';
import one_logo from 'assets/img/coin/one.png';
import legld_logo from 'assets/img/coin/legld.png';
import mex_logo from 'assets/img/coin/mex.png';
import segld_logo from 'assets/img/coin/segld.png';
import jwlegld_logo from 'assets/img/coin/jwlegld.png';
import jwlash_logo from 'assets/img/coin/jwlash.png';
import jwlusd_logo from 'assets/img/coin/jwlusd.png';
import htm_logo from 'assets/img/coin/htm.png';
import jwlhtm_logo from 'assets/img/coin/jwlhtm.png';
import jwlmex_logo from 'assets/img/coin/jwlmex.png';
import hbusd_logo from 'assets/img/coin/hbusd.png';
import hegld_logo from 'assets/img/coin/hegld.png';
import hsegld_logo from 'assets/img/coin/hsegld.png';
import husdc_logo from 'assets/img/coin/husdc.png';
import husdt_logo from 'assets/img/coin/husdt.png';
import wdai_logo from 'assets/img/coin/wdai.png';
import wsdai_logo from 'assets/img/coin/wsdai.png';
import utk_logo from 'assets/img/coin/utk-logo.svg';
import jwl_utk_logo from 'assets/img/coin/jwlutk-logo.svg';
import wbtc_logo from 'assets/img/coin/wbtc.svg';
import weth_logo from 'assets/img/coin/weth.svg';
import epunks_logo from 'assets/img/coin/epunks.png';
import apusdc_logo from 'assets/img/coin/apusdc.png';
import xmex_logo from 'assets/img/coin/xmex.svg';
import jwlxmex_logo from 'assets/img/coin/jwlxmex.svg';
import tada_logo from 'assets/img/coin/tada.png';
import crt_logo from 'assets/img/coin/crt.svg';
import ith_logo from 'assets/img/coin/ith.svg';
import ride_logo from 'assets/img/coin/ride.svg';
import jwlbtc_logo from 'assets/img/coin/jwlbtc.svg';
import jwleth_logo from 'assets/img/coin/jwleth.svg';
import bhat_logo from 'assets/img/coin/bhat.svg';
import qwt_logo from 'assets/img/coin/qwt.svg';
import zpay_logo from 'assets/img/coin/zpay.svg';
import cyber_logo from 'assets/img/coin/cyber.svg';
import wtao_logo from 'assets/img/coin/wtao.svg';
import jwltao_logo from 'assets/img/coin/jwltao.svg';
import jwltada_logo from 'assets/img/coin/jwltada.svg';
import bober_logo from 'assets/img/coin/bober.svg';
import jwlapusdc_logo from 'assets/img/coin/jwlapusdc.svg';
import rsegld_logo from 'assets/img/coin/rsegld.svg';

import { AUTO_COMPOUND_CRON_JOB_TIME, HATOM_INITIAL_EXCHANGE_RATE, isDev, JEWEL_ASHSWAP_SC_DEPLOYED_TIMESTAMP, JEWEL_JWLASH_LEND_SC_DEPLOYED_TIMESTAMP, JEWEL_JWLEGLD_LEND_SC_DEPLOYED_TIMESTAMP, JEWEL_LIQUID_STAKE_SC_DEPLOYED_TIMESTAMP, JEWEL_SEGLD_LEND_SC_DEPLOYED_TIMESTAMP, JEWEL_WSDAI_LEND_SC_DEPLOYED_TIMESTAMP } from "config";
import { convertEsdtToWei, convertWeiToEsdt, getTokenDecimal, getTokenTicker } from "./token";
import { AshswapFarm, AshswapPool, ElrondStatsType, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelLendPool, JewelMoneyMarketPoolContext, NftWithIndexType } from "z/types";
import { AddressType, BigUIntType, BinaryCodec, FieldDefinition, StructType, U64Type } from "@multiversx/sdk-core/out";
import { convertSecondsToPrintDateTimeString, PrintDateTimeType } from "./time";
import DefiUtils from "defi-utils";
import { convertBigNumberValueToLocalString } from "./number";
import { queryGetEquivalent, queryJewelBorrowGetEquivalent } from "z/elrond";

export const getApr = (totalRewards: string, totalLendingAmount: string, epocStartTimestamp: number) => {
  // console.log(totalRewards);
  const totalRewardsValue = new BigNumber(totalRewards);
  const passedMilliseconds = Date.now() - epocStartTimestamp * SECOND_TO_MILLISECONDS;
  const passedHours = passedMilliseconds / HOUR_IN_SECONDS / SECOND_TO_MILLISECONDS;
  return totalRewardsValue.multipliedBy(8760).dividedBy(passedHours).dividedBy(totalLendingAmount);
};

export const getApy = (apr: BigNumber, compound = 12) => {
  const apy = ((1 + apr.toNumber() / compound) ** compound - 1) * 100;
  return apy;
  // return new BigNumber(new BigNumber(1).plus(apr.dividedBy(compound))).pow(compound).minus(1).multipliedBy(100);
};

export const getFarmerRewardPercent = (farm_shares_per_token: string[], borrow_shares_per_token: string[], total_farm_share: string, fee_collector_percent: number) => {
  let total_farmer_share = new BigNumber('0');
  for (let i = 0; i < farm_shares_per_token.length; i++) {
    total_farmer_share = new BigNumber(farm_shares_per_token[i]).minus(borrow_shares_per_token[i]).plus(total_farmer_share);
    total_farmer_share = new BigNumber(borrow_shares_per_token[i]).multipliedBy(fee_collector_percent).dividedBy(FARM_MAX_PERCENT).plus(total_farmer_share);
  }
  const farmerRewardPecentage = total_farmer_share.multipliedBy(FARM_MAX_PERCENT).dividedBy(total_farm_share).toFixed(0);

  return farmerRewardPecentage;
};

export const getOldFarmApy = (rewardPerDay: string, percent: string, stakedLpValue: string) => {
  const reward = new BigNumber(rewardPerDay).multipliedBy(percent).dividedBy(FARM_MAX_PERCENT);
  const apr = reward.multipliedBy(365).dividedBy(stakedLpValue);
  // const n = 8760;
  // const apy = Math.pow((apr.toNumber() / n) + 1, n) - 1;
  const apy = Math.pow(2.7183, apr.toNumber()) - 1;

  return apy;
};

export const getFarmApy = (apr: number, compound = 365) => {
  const apy = ((1 + apr / 100 / compound) ** compound - 1) * 100;
  if(apy>10000){
    return 0;
  }
  return apy;
  // const apy = new BigNumber(new BigNumber(1).plus(new BigNumber(apr).dividedBy(100).dividedBy(compound))).pow(compound).minus(1).multipliedBy(100).toNumber();

  // return apy;
};

export const getFarmApyJewelLpFarm = (farm_id:number, apr: number, compound = 365) => {
  // segld-egld
  if(farm_id==9){
    return 0;
  }
  const apy = ((1 + apr / 100 / compound) ** compound - 1) * 100;
  if(apy>10000){
    return 0;
  }
  return apy;
  // const apy = new BigNumber(new BigNumber(1).plus(new BigNumber(apr).dividedBy(100).dividedBy(compound))).pow(compound).minus(1).multipliedBy(100).toNumber();

  // return apy;
};

export const getImageUrl = (collection: string, tokenId: string) => {
  if (tokenId === 'EPUNKS-46b186-0866') {
    tokenId = 'EPUNKS-46b186-05d8';
  }
  if (collection == "UJWLEGLD-17e9e9") {
    return "https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/UJWLEGLD-17e9e9-single.png";
  }
  if (collection == "HLSR-374950") {
    return "https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/HLSR-374950-single.png";
  }
  return XOXNO_IMAGE_URL + collection + '/' + tokenId + '.avif';
};

export const getImageUrlWithNft = (collection: string, tokenId: string, nft: NftWithIndexType) => {
  if (tokenId === 'EPUNKS-46b186-0866') {
    tokenId = 'EPUNKS-46b186-05d8';
  }
  if (collection == "TRAVELER-51bdef") {
    return nft.url;
  }
  if (collection == "UJWLEGLD-17e9e9") {
    return "https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/UJWLEGLD-17e9e9-single.png";
  }
  if (collection == "HLSR-374950") {
    return "https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/HLSR-374950-single.png";
  }
  return XOXNO_IMAGE_URL + collection + '/' + tokenId + '.avif';
};

export const getTokenImage = (tokenId: string) => {
  if (tokenId === 'EGLD') {
    return egld_white_logo;
  }

  if (tokenId.includes('-')) {
    const tokenTicker = tokenId.split('-')[0];

    if (tokenTicker === 'WEGLD') return egld_white_logo;
    if (tokenTicker === 'USDT') return usdt_logo;
    if (tokenTicker === 'USDC') return usdc_logo;
    if (tokenTicker === 'BUSD') return busd_logo;
    if (tokenTicker === 'ASH') return ash_logo;
    if (tokenTicker === 'ALP') return unknown_logo;
    if (tokenTicker === 'ONE') return one_logo;
    if (tokenTicker === 'ONEWEGLD') return unknown_logo;
    if (tokenTicker === 'LEGLD') return legld_logo;
    if (tokenTicker === 'MEX') return mex_logo;
    if (tokenTicker === 'XMEX') return xmex_logo;
    if (tokenTicker === 'ELKMEX') return mex_logo;
    if (tokenTicker === 'SEGLD') return segld_logo;
    if (tokenTicker === 'JWLASH') return jwlash_logo;
    if (tokenTicker === 'JWLEGLD') return jwlegld_logo;
    if (tokenTicker === 'JWLUSD') return jwlusd_logo;
    if (tokenTicker === 'SJWLUSD') return jwlusd_logo;
    if (tokenTicker === 'HTM') return htm_logo;
    if (tokenTicker === 'JWLHTM') return jwlhtm_logo;
    if (tokenTicker === 'JWLMEX') return jwlmex_logo;
    if (tokenTicker === 'SJWLEGLD') return jwlegld_logo;
    if (tokenTicker === 'JIEGLD') return egld_white_logo;
    if (tokenTicker === 'JISEGLD') return segld_logo;
    if (tokenTicker === 'JIUSDT') return usdt_logo;
    if (tokenTicker === 'JIUSDC') return usdc_logo;
    if (tokenTicker === 'JIBUSD') return busd_logo;
    if (tokenTicker === 'HBUSD') return hbusd_logo;
    if (tokenTicker === 'HEGLD') return hegld_logo;
    if (tokenTicker === 'HSEGLD') return hsegld_logo;
    if (tokenTicker === 'HUSDC') return husdc_logo;
    if (tokenTicker === 'HUSDT') return husdt_logo;
    if (tokenTicker === 'WDAI') return wdai_logo;
    if (tokenTicker === 'WSDAI') return wsdai_logo;
    if (tokenTicker === 'UTK') return utk_logo;
    if (tokenTicker === 'JWLUTK') return jwl_utk_logo;
    if (tokenTicker === 'WSDAI') return wsdai_logo;
    if (tokenTicker === 'WBTC') return wbtc_logo;
    if (tokenTicker === 'WETH') return weth_logo;
    if (tokenTicker === 'EPUNKS') return epunks_logo;
    if (tokenTicker === 'APUSDC') return apusdc_logo;
    if (tokenTicker === 'JWLXMEX') return jwlxmex_logo;
    if (tokenTicker === 'TADA') return tada_logo;
    if (tokenTicker === 'CRT') return crt_logo;
    if (tokenTicker === 'RIDE') return ride_logo;
    if (tokenTicker === 'ITHEUM') return ith_logo;
    if (tokenTicker === 'JWLBTC') return jwlbtc_logo;
    if (tokenTicker === 'JWLETH') return jwleth_logo;
    if (tokenTicker === 'CYBER') return cyber_logo;
    if (tokenTicker === 'BHAT') return bhat_logo;
    if (tokenTicker === 'QWT') return qwt_logo;
    if (tokenTicker === 'ZPAY') return zpay_logo;
    if (tokenTicker === 'WTAO') return wtao_logo;
    if (tokenTicker === 'JWLTAO') return jwltao_logo;
    if (tokenTicker === 'JWLTADA') return jwltada_logo;
    if (tokenTicker === 'BOBER') return bober_logo;
    if (tokenTicker === 'JWLAPUSDC') return jwlapusdc_logo;
    if (tokenTicker === 'RSEGLD') return rsegld_logo;
  }
  return unknown_logo;
};

export const calcYieldBoost = (
  lpAmt: string,
  totalLP: string,
  ve: string,
  totalVe: string,
  farmSupply: string,
  existFarmTokenBal: string
) => {
  const base = new BigNumber(lpAmt).multipliedBy(0.4);
  const farmBase = new BigNumber(farmSupply).plus(base).minus(existFarmTokenBal);
  const _boosted = new BigNumber(totalVe).eq(0)
    ? lpAmt
    : new BigNumber(lpAmt)
      .multipliedBy(0.4)
      .plus(new BigNumber(totalLP).multipliedBy(0.6).multipliedBy(ve).div(totalVe));
  const boosted = BigNumber.min(_boosted, lpAmt);
  const farmBoost = new BigNumber(farmSupply).plus(boosted).minus(existFarmTokenBal);
  const yieldBoost = (
    Math.max(
      +boosted
        .multipliedBy(10 ** 20)
        .div(farmBoost)
        .div(base.multipliedBy(10 ** 20).div(farmBase)),
      1
    ) || 1
  );
  return yieldBoost === Number.POSITIVE_INFINITY ? 1 : yieldBoost;
};

export const getLiquidUnstakeTokenAttributes = (nft: NftWithIndexType) => {
  const newType = new StructType(
    "UnstakeTokenAttributes",
    [
      new FieldDefinition("unstake_epoch", "", new U64Type()),
      new FieldDefinition("unstake_amount", "", new BigUIntType()),
      new FieldDefinition("unbond_epoch", "", new U64Type()),
    ]
  );
  const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(nft.attributes, "base64"), newType).valueOf();

  return decodedAttributes;
};

export const getHatomUnbondTokenAttributes = (nft: NftWithIndexType) => {
  const newType = new StructType(
    "UndelegateAttributes",
    [
      new FieldDefinition("delegation_contract", "", new AddressType()),
      new FieldDefinition("egld_amount", "", new BigUIntType()),
      new FieldDefinition("shares", "", new BigUIntType()),
      new FieldDefinition("undelegate_epoch", "", new U64Type()),
      new FieldDefinition("unbond_epoch", "", new U64Type()),
    ]
  );
  const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(nft.attributes, "base64"), newType).valueOf();

  return decodedAttributes;
};

export const getUnstakeTokenAttributes = (nft: NftWithIndexType) => {
  const newType = new StructType(
    "UnstakeTokenAttributes",
    [
      new FieldDefinition("unstake_epoch", "", new U64Type()),
      new FieldDefinition("unstake_amount", "", new BigUIntType()),
      new FieldDefinition("unbond_epoch", "", new U64Type()),
    ]
  );
  const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(nft.attributes, "base64"), newType).valueOf();

  return decodedAttributes;
};

const UNBOND_DELAY_SECONDS = (23 * 60 + 59) * 60; // 23h 59m
export const getUnbondEndTimeString = (unbondEpoch: number, networkConfig: ElrondStatsType): string => {
  // console.log('getUnbondEndTimeString networkConfig.epoch unbondEpoch', networkConfig.epoch, unbondEpoch);
  // if (networkConfig.epoch >= unbondEpoch) {
  //   return convertSecondsToPrintDateTimeString(0);
  // }

  const leftSeconds = (networkConfig.roundsPerEpoch - networkConfig.roundsPassed + (unbondEpoch - networkConfig.epoch - 1) * networkConfig.roundsPerEpoch) * 6 + UNBOND_DELAY_SECONDS;

  return convertSecondsToPrintDateTimeString(leftSeconds > 0 ? leftSeconds : 0);
};

export function isUnbondable(unbondEpoch: number, networkConfig: ElrondStatsType): boolean {
  const leftSeconds = (networkConfig.roundsPerEpoch - networkConfig.roundsPassed + (unbondEpoch - networkConfig.epoch - 1) * networkConfig.roundsPerEpoch) * 6 + UNBOND_DELAY_SECONDS;
  return leftSeconds <= 0;
}

export const getIsStablePool = (farmId: number): boolean => {
  if (isDev) {
    if (farmId === 1 || farmId === 2 || farmId === 4 || farmId === 5) {
      return true;
    }

    return false;
  } else {
    if (farmId === 3 || farmId === 4 || farmId === 5 || farmId === 6 || farmId === 7 || farmId === 8 || farmId === 9 || farmId === 10 || farmId === 11 || farmId === 14 || farmId === 18 || farmId === 19 || farmId === 21 || farmId === 24 || farmId === 26 || farmId === 28 || farmId === 29 || farmId === 31 || farmId === 32 || farmId === 33) {
      return true;
    }

    return false;
  }
};

export const getUserGaugeUpdatableTimestamp = (lastVoteTimestamp: number, voteResetTimestamp: number): number => {
  return (lastVoteTimestamp + voteResetTimestamp) * SECOND_TO_MILLISECONDS;
};

export const getAshswapPoolTradingApr = (ashswapPools: AshswapPool[], dex_pool_address: string): number => {
  const pool = ashswapPools.filter((item: AshswapPool) => item.address === dex_pool_address);
  if (pool.length > 0) {
    return pool[0].apr;
  }
  return 0;
};

export const getAshswapPoolApr = (ash_base_apr: number, current_boost: number, trading_apr: number): number => {
  return ash_base_apr * 0.7 * current_boost + trading_apr * 0.7;
};

export const getHatomApy = (r: string): number => {
  // ((1 + r * 3600 * 24 / wad) ^ 365 - 1) * 100
  return ((new BigNumber(r).multipliedBy(3600 * 24).dividedBy(convertEsdtToWei(1, 18)).toNumber() + 1) ** 365 - 1) * 100;
};

export const getJewelHatomPoolApy = (farm: JewelHatomFarmContext, hatomMoneyMarkets: JewelHatomMoneyMarket[], hatomFormattedRewards: any[], feePercent = 300000): number => {
  let supplyApy = 0;
  let borrowApy = 0;
  let rewardApy = 0;
  for (const hatomMoneyMarket of hatomMoneyMarkets) {
    if (farm.hatom_money_market_address === hatomMoneyMarket.address) {
      if (hatomMoneyMarket.dailyStateHistory.length > 0) {
        const borrowRatePerSecond = hatomMoneyMarket.dailyStateHistory[0].moneyMarketState.borrowRatePerSecond;
        const supplyRatePerSecond = hatomMoneyMarket.dailyStateHistory[0].moneyMarketState.supplyRatePerSecond;

        borrowApy = getHatomApy(borrowRatePerSecond);
        supplyApy = getHatomApy(supplyRatePerSecond);
      }
      break;
    }
  }

  for (const hatomFormattedReward of hatomFormattedRewards) {
    if (farm.hatom_base_token_id === hatomFormattedReward.moneyMarket) {
      rewardApy = Number(hatomFormattedReward.apy);
      break;
    }
  }

  const hatom_borrow_percent = farm.hatom_borrow_percent / PERCENT_DENOMINATOR;
  const hatom_loop_count = farm.hatom_loop_count;
  const borrow_value = getJewelHatomPoolApyBorrowValue(hatom_borrow_percent, hatom_loop_count);
  // console.log(farm.hatom_base_token_id, borrow_value, supplyApy, rewardApy, borrowApy);

  const apy = (1 + borrow_value) * (supplyApy + rewardApy * (1 - feePercent / PERCENT_DENOMINATOR / 100)) - borrow_value * borrowApy;

  return apy;
};

export const getJewelFlashMintHatomPoolApy = (hatomMoneyMarketAddress: string, baseTokenId: string, hatomMoneyMarkets: JewelHatomMoneyMarket[], hatomFormattedRewards: any[], feePercent = 300000): number => {
  let supplyApy = 0;
  let rewardApy = 0;
  for (const hatomMoneyMarket of hatomMoneyMarkets) {
    if (hatomMoneyMarketAddress === hatomMoneyMarket.address) {
      if (hatomMoneyMarket.dailyStateHistory.length > 0) {
        const supplyRatePerSecond = hatomMoneyMarket.dailyStateHistory[0].moneyMarketState.supplyRatePerSecond;

        supplyApy = getHatomApy(supplyRatePerSecond);
      }
      break;
    }
  }

  for (const hatomFormattedReward of hatomFormattedRewards) {
    if (baseTokenId === hatomFormattedReward.moneyMarket) {
      rewardApy = Number(hatomFormattedReward.apy);
    }
  }

  const apy = supplyApy + rewardApy * (1 - feePercent / PERCENT_DENOMINATOR / 100);

  return apy;
};

export const getJewelHatomPoolApyBorrowValue = (hatom_borrow_percent: number, hatom_loop_count: number): number => {
  let val = 0;
  for (let i = 1; i <= hatom_loop_count; i++) {
    val += 1 / hatom_borrow_percent ** i;
  }

  return val * 100;
};

export const calcHatomLiquidStakingExchangeRate = (cashReserve: string, totalShares: string) => {
  if (totalShares === "0") {
    return HATOM_INITIAL_EXCHANGE_RATE;
  }

  return new DefiUtils(cashReserve)
    .multipliedBy(DefiUtils.WAD)
    .dividedBy(totalShares)
    .toFixed(0, DefiUtils.ROUND_DOWN);
};

export const calcHatomExchangeRate = ({
  cash,
  borrows,
  reserves,
  totalSupply,
}: any) => {
  const value = new DefiUtils(cash)
    .plus(borrows)
    .minus(reserves)
    .times(1e18)
    .div(totalSupply)
    .toFixed(0);

  return new DefiUtils(value).isNaN() ? "0" : value;
};

const calcHatomRateSimulate = (rate: any, timestamp: any) => {
  const currentDate = new Date();
  const currentDateInSeconds = currentDate.getTime() / 1000;
  const timestampInSeconds = new Date(timestamp).getTime() / 1000;

  return new DefiUtils(rate)
    .multipliedBy(currentDateInSeconds - timestampInSeconds)
    .dividedBy(`1e18`)
    .plus(1)
    .toString();
};

export const calcHatomSimulateExchangeRate = ({
  cash,
  borrows,
  reserves,
  totalSupply,
  rate,
  timestamp,
}: any) => {
  return new DefiUtils(
    calcHatomExchangeRate({ cash, borrows, reserves, totalSupply })
  )
    .multipliedBy(calcHatomRateSimulate(rate, timestamp))
    .toString();
};

const getRewardTokenAPY = (distributedDollarAmountPerDay: any, totalInUSD: any) => {
  if (totalInUSD === "0") {
    return "0";
  }

  return new DefiUtils(distributedDollarAmountPerDay)
    .dividedBy(totalInUSD)
    .multipliedBy(36500)
    .toString();
};

export const hatomFormatMarketRewardToken = ({
  rewardTokenItem,
  totalCollateralTokens,
  totalBorrows,
  hTokenExchangeRate,
  decimals,
  priceUSD,
}: any) => {
  const {
    borrowDistributedDollarAmountPerDay,
    supplyDistributedDollarAmountPerDay,
  } = rewardTokenItem;

  const totalBorrowsInUSD = new DefiUtils(totalBorrows)
    .toFullDecimals(decimals)
    .toUSD(priceUSD)
    .toString();

  const totalCollateralTokensInUSD = new DefiUtils(totalCollateralTokens)
    .toUnderlying(hTokenExchangeRate)
    .toFullDecimals(decimals)
    .toUSD(priceUSD)
    .toString();

  const borrowAPY = getRewardTokenAPY(
    borrowDistributedDollarAmountPerDay,
    totalBorrowsInUSD
  );

  const supplyAPY = getRewardTokenAPY(
    supplyDistributedDollarAmountPerDay,
    totalCollateralTokensInUSD
  );

  return {
    ...rewardTokenItem,
    borrowAPY,
    supplyAPY,
  };
};

export const hatomFormatRewards = ({
  rewardsBatch,
  tokenPricesMap,
  hTokenExchangeRateMap,
  totalCollateralTokensMap,
}: any) => {
  return rewardsBatch.map(
    ({
      id,
      speed: _speed,
      rewardsToken,
      moneyMarket: { underlying, hToken, dailyStateHistory },
      type,
    }: any) => {
      const batchId = id.split("-")?.[1] || "";
      const speed = new DefiUtils(_speed).toFullDecimals(18).toString();
      const rewardPriceUSD = tokenPricesMap[rewardsToken.symbol] || "0";
      const marketPriceUSD = tokenPricesMap[underlying.symbol] || "0";

      const speedPerDay = new DefiUtils(speed)
        .multipliedBy(DefiUtils.SECONDS_PER_DAY)
        .toString();

      const distributedDollarAmountPerDay = new DefiUtils(1)
        .multipliedBy(speedPerDay)
        .toFullDecimals(rewardsToken.decimals)
        .multipliedBy(rewardPriceUSD)
        .toString();

      const hTokenExchangeRate = hTokenExchangeRateMap[underlying.symbol];

      const totalCollateralTokens = totalCollateralTokensMap[hToken.id] || "0";

      const totalBorrows =
        dailyStateHistory?.[0]?.moneyMarketState.borrows || "0";

      const { borrowAPY, supplyAPY } = hatomFormatMarketRewardToken({
        rewardTokenItem: {
          borrowDistributedDollarAmountPerDay: distributedDollarAmountPerDay,
          supplyDistributedDollarAmountPerDay: distributedDollarAmountPerDay,
        },
        totalCollateralTokens,
        totalBorrows,
        hTokenExchangeRate,
        decimals: String(underlying.decimals),
        priceUSD: marketPriceUSD,
      });

      const moneyMarket = underlying.id;
      const reward = rewardsToken.id;

      return {
        moneyMarket,
        reward,
        batchId,
        apy: type === "Supply" ? supplyAPY : borrowAPY,
        name: `${moneyMarket} / ${reward} / ${batchId}`,
      };
    }
  );
};

export const calcHatomLiquidStakingAPY = (totalFee: any, delegationContracts: any) => {
  const sum1 = delegationContracts
    .reduce((prev: any, { totalDelegated, apr }: any) => {
      const aprWithFee = new DefiUtils(apr)
        .multipliedBy(new DefiUtils(100).minus(totalFee))
        .dividedBy(new DefiUtils(100))
        .toString();

      return prev.plus(new DefiUtils(aprWithFee).multipliedBy(totalDelegated));
    }, new DefiUtils("0"))
    .toString();

  const sum2 = delegationContracts
    .reduce(
      (prev: any, { totalDelegated }: any) => prev.plus(totalDelegated),
      new DefiUtils("0")
    )
    .toString();

  return new DefiUtils(sum1)
    .dividedBy(sum2)
    .dividedBy(100)
    .toAPY()
    .multipliedBy(100)
    .toString();
};

export const getLiquidStakeApy = (jwlegldPoolReserve: string, sjwlegldPoolReserve: string): BigNumber => {
  if (new BigNumber(sjwlegldPoolReserve).gt('0')) {
    const tokenAmount = convertEsdtToWei(1).multipliedBy(jwlegldPoolReserve).dividedBy(sjwlegldPoolReserve);
    const passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_LIQUID_STAKE_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
    const apy = tokenAmount.minus(convertEsdtToWei(1)).dividedBy(passedDays).multipliedBy(365).multipliedBy(100);

    return apy;
  }

  return new BigNumber('0');
};

export const getMoneyMarketType = (baseTokenId: string): string => {
  if (baseTokenId.substring(0, 3) === 'HTM') return 'jewel';
  if (baseTokenId.substring(0, 1) === 'H') return 'hatom';
  if (baseTokenId.substring(0, 1) === 'S') return 'jewel';
  if (baseTokenId.substring(0, 2) === 'JI') return 'jewel';
  if (baseTokenId.substring(0, 3) === 'ALP') return 'ashswap';
  return 'jewel';
};

export const getMoneyMarketSupplyApy = (
  baseTokenId: string,
  jewelLendPools: JewelLendPool[],
  hatomMoneyMarkets: JewelHatomMoneyMarket[],
  hatomFormattedRewards: any[],
  ashswapFarmId: number,
  ashswapFarms: AshswapFarm[],
): string => {
  let apy: any = '0';
  if (getMoneyMarketType(baseTokenId) === 'hatom') {
    // hatom
    let supplyApy = 0;
    let rewardApy = 0;

    for (const hatomMoneyMarket of hatomMoneyMarkets) {
      if (hatomMoneyMarket.hToken.id === baseTokenId) {
        if (hatomMoneyMarket.dailyStateHistory.length > 0) {
          const supplyRatePerSecond = hatomMoneyMarket.dailyStateHistory[0].moneyMarketState.supplyRatePerSecond;

          supplyApy = getHatomApy(supplyRatePerSecond);
        }
        break;
      }
    }

    for (const hatomFormattedReward of hatomFormattedRewards) {
      if (getTokenTicker(baseTokenId) === `H${getTokenTicker(hatomFormattedReward.moneyMarket)}`) {
        rewardApy = Number(hatomFormattedReward.apy);
        break;
      }
    }

    if (isDev) {
      rewardApy = 0;
    }

    apy = convertBigNumberValueToLocalString(supplyApy + rewardApy);
  } else if (getMoneyMarketType(baseTokenId) === 'ashswap') {
    // ashswap lp
    for (const farm of ashswapFarms) {
      if (farm.farm_id === ashswapFarmId) {
        apy = convertBigNumberValueToLocalString(getFarmApy(farm.apr, YEAR_IN_HOURS));
        break;
      }
    }
  } else {
    // jewel lend
    for (const pool of jewelLendPools) {
      if (baseTokenId === pool.ib_token_id) {
        if (pool.apy) {
          apy = pool.apy;
        }
        break;
      }
    }
  }
  return apy;
};

export const getMoneyMarketUtilizationPercent = (pool: JewelMoneyMarketPoolContext, equivalentAmount: string) : BigNumber => {
  let utilizationPercent = new BigNumber('100');
  if (new BigNumber(equivalentAmount).gt('0')) {
    utilizationPercent = new BigNumber(pool.borrowed_amount).multipliedBy(100).dividedBy(equivalentAmount);
  }

  return utilizationPercent;
};

export const getMoneyMarketBorrowApy = (pool: JewelMoneyMarketPoolContext | undefined, equivalentAmount: string) : BigNumber => {
  if (!pool) return new BigNumber('0');
  const utilizationPercent = getMoneyMarketUtilizationPercent(pool, equivalentAmount);

  const stop1 = 60; // 60%
  const stop2 = 90; // 90%

  if (utilizationPercent.lt(stop1)) {
    return new BigNumber('2').plus(utilizationPercent.multipliedBy(8).dividedBy(stop1));
  } else if (utilizationPercent.lt(stop2)) {
    return new BigNumber('10');
  } else {
    return new BigNumber('10').plus(new BigNumber('10').multipliedBy(utilizationPercent.minus(stop2)).dividedBy(100 - stop2));
  }
};

// jewel lend pool
export const getJewelLendPoolApy = (pool: JewelLendPool): BigNumber => {
  const tokenAmount = convertEsdtToWei(new BigNumber(1), getTokenDecimal(pool.deposit_token_id)).multipliedBy(pool.deposit_token_amount).dividedBy(pool.ib_token_amount).toFixed();

  let passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_ASHSWAP_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
  if (getTokenTicker(pool.deposit_token_id) === 'SEGLD') {
    passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_SEGLD_LEND_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
  }

  if (getTokenTicker(pool.deposit_token_id) === 'JWLEGLD') {
    passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_JWLEGLD_LEND_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
  }

  if (getTokenTicker(pool.deposit_token_id) === 'JWLASH') {
    passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_JWLASH_LEND_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
  }

  if (getTokenTicker(pool.deposit_token_id) === 'WSDAI') {
    passedDays = (Date.now() / SECOND_TO_MILLISECONDS - JEWEL_WSDAI_LEND_SC_DEPLOYED_TIMESTAMP) / DAY_IN_SECONDS;
  }

  const apy = new BigNumber(tokenAmount).minus(convertEsdtToWei(new BigNumber(1), getTokenDecimal(pool.deposit_token_id))).dividedBy(passedDays).multipliedBy(365).multipliedBy(100);

  return convertWeiToEsdt(apy, getTokenDecimal(pool.deposit_token_id));
};

// Jewel xExchange Farm
export const getExchangeWrappedFarmTokenAttributes = (nft: NftWithIndexType) => {
  const newType = new StructType(
    "UnstakeTokenAttributes",
    [
      new FieldDefinition("farm_address", "", new AddressType()),
      new FieldDefinition("token_rps", "", new BigUIntType()),
    ]
  );
  const value = new BinaryCodec().decodeTopLevel(Buffer.from(nft.attributes, "base64"), newType).valueOf();

  return {
    farm_address: value.farm_address.toString(),
    token_rps: value.token_rps.toFixed(),
  };
};

export const getExchangeUnstakeFarmTokenAttributes = (nft: NftWithIndexType) => {
  const newType = new StructType(
    "UnstakeTokenAttributes",
    [
      new FieldDefinition("farm_address", "", new AddressType()),
      new FieldDefinition("unstake_epoch", "", new U64Type()),
      new FieldDefinition("token_nonce", "", new U64Type()),
    ]
  );
  const value = new BinaryCodec().decodeTopLevel(Buffer.from(nft.attributes, "base64"), newType).valueOf();

  return {
    farm_address: value.farm_address.toString(),
    unstake_epoch: value.unstake_epoch.toNumber(),
    token_nonce: value.token_nonce.toNumber(),
  };
};

export const getAshswapPositionFarmId = (positionFarmId: number) => {
  if (positionFarmId === 21) return 14;
  if (positionFarmId === 23) return 15;
  if (positionFarmId === 24) return 16;
  if (positionFarmId === 25) return 17;
  if (positionFarmId === 26) return 18;
  if (positionFarmId === 27) return 19;
  if (positionFarmId === 28) return 20;
  if (positionFarmId === 29) return 21;
  if (positionFarmId === 30) return 22;
  if (positionFarmId === 31) return 23;
  if (positionFarmId === 32) return 24;
  if (positionFarmId === 33) return 25;
  return positionFarmId;
};

export const formatAddress = (address: string) => {
  return `${address.substring(0, 6)}...${address.substring(address.length - 6, address.length)}`;
};
