import { SmartContract } from "@multiversx/sdk-core/out";
import { NftType, CollectionType } from "@multiversx/sdk-dapp/types/tokens.types";
import { PrintDateTimeType } from "z/utils";

export enum ReturnResultStatusTypeEnum {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface ReturnResultType {
  status: ReturnResultStatusTypeEnum;
  message: string;
}

export interface NftWithIndexType extends NftType {
  index: number;
}

export interface CollectionWithIndexType extends CollectionType {
  index: number;
}

export interface NftWithAmmData extends NftType {
  pool_id: number,
  nft_token_id?: string,
  nft_token_nonce?: number,
  nft_token_amount: string,
  pool_spot_buy_price?: string,
  pool_spot_sell_price?: string,
  pool_trade_buy_price?: string,
  pool_trade_sell_price?: string,
}

export interface NftRarity {
  attributes: string,
  avifUrl: string,
  collection: string,
  creator: string,
  hasOffers: boolean,
  id: string,
  identifier: string,
  name: string,
  nonce: number,
  metadata: {
    rarity: {
      avgRarity: number,
      rank: number,
      rarityScore: number,
      rarityScoreNormed: number,
      statRarity: number,
      usedTraitsCount: number,
    }
  }
}

export interface ElrondStatsType {
  shards: number,
  blocks: number,
  accounts: number,
  transactions: number,
  refreshRate: number,
  epoch: number,
  roundsPassed: number,
  roundsPerEpoch: number,

  leftTime: PrintDateTimeType,
  passedTimePercentage: number,
}

export interface AshswapPool {
  address: string,
  apr: number,
  tvl: number,
  volume_usd: number,
}

export interface TvlContext {
  total: string,
  liquidStaking: string,
  lyf: string
  nftCollateral: string,
  nftLending: string,
}

// export interface JewelFarmTokenId {
//   token_id: string,
//   token_decimal: number,
//   token_image: any,
// }
// export interface JewelFarmPool {
//   sc_address: string,
//   token_ids: JewelFarmTokenId[],
// }