// export * from './backend/down-payment';
// export * from './backend/common';
// export * from './backend/borrow';
// export * from './backend/lend';
// export * from './backend/loans';
export * from './elrond-api';
export * from './xoxno-api';
export * from './ashswap-api';
export * from './external-api';
export * from './hatom-api';
export * from './jewel-api';

export const axiosConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json;charset=UTF-8',
  }
};
