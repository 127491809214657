import React from 'react';
import { useNavigate } from 'react-router-dom';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { CollectionLogo } from 'components/CollectionLogo';
import './index.scss';
import { convertBigNumberValueToLocalString, convertWeiToEsdt } from 'z/utils';

export const CollectionCard = (props: any) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`/collection/${props.item?.nft_token_id}`);
    };

    return (
        <div className='row mt-3' onClick={handleOnClick}>
            <div className='col-12'>
                <div className='amm-collection-mobile-card'>
                    <div className='d-flex align-items-center'>
                        <CollectionLogo className='amm-collection-mobile-card-logo' collectionId={props.item?.nft_token_id} />
                        <div className='h4 mx-3'>{props.item?.nft_collection_name}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                        <div>collection</div>
                        <div>{props.item?.nft_token_id}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>listings</div>
                        <div>{props.item?.nft_listed_count}</div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>floor price</div>
                        <div className='d-flex gap-1'>
                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                            <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_floor_price))}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>best offer</div>
                        <div className='d-flex gap-1'>
                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                            <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_best_offer_price))}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-1'>
                        <div>offer TVL</div>
                        <div className='d-flex gap-1'>
                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                            <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_offer_tvl))}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TradedColllectionsCard = (props: any) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`/collection/${props.item?.nft_token_id}`);
    };
    return (
        <div className='amm-collection-mobile-card' onClick={handleOnClick} style={{ cursor: 'pointer' }}>
            <CollectionLogo className='amm-most-traded-collections-card-logo' collectionId={props.item?.nft_token_id} />
            <div className='text-white h6 mt-3 text-center' style={{ fontWeight: "bold" }}>{props.item.nft_token_id}</div>
            {/* <div>listings: {props.item?.nft_listed_count}</div>
            <div>floor price</div>
            <div className='mt-1'>
                <div className='d-flex gap-1 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_floor_price))}</div>
                </div>
            </div>
            <div>best offer</div>
            <div className='mt-1'>
                <div className='d-flex gap-1 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_best_offer_price))}</div>
                </div>
            </div> */}
            <div className='d-flex justify-content-between mt-1'>
                <div>listings</div>
                <div>{props.item?.nft_listed_count}</div>
            </div>
            <div className='d-flex justify-content-between mt-1'>
                <div>floor price</div>
                <div className='d-flex gap-1 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_floor_price))}</div>
                </div>
            </div>
            <div className='d-flex justify-content-between mt-1'>
                <div>best offer</div>
                <div className='d-flex gap-1 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_best_offer_price))}</div>
                </div>
            </div>
            <div className='d-flex justify-content-between mt-1'>
                <div>offer TVL</div>
                <div className='d-flex gap-1 align-items-center'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(props.item?.nft_offer_tvl))}</div>
                </div>
            </div>
        </div >
    );
};
