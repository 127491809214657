import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, Modal } from '@mui/material';
import { DataGrid, GridColDef, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { useGetAccount, useGetIsLoggedIn, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { animated, useSpring } from 'react-spring';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import imageQuestionMarkInCircle from 'assets/img/question-mark-in-circle.png';
import { getNftsByIdFromApi } from 'z/api';
import { queryCommonSettings, queryJewelFacilitatorCommonContext, queryViewLoans, queryViewLoansById, queryViewUser, sendRedeemLoan, sendRenewLoans, sendTopUp } from 'z/elrond';
import { CommonSettingsType, JewelFacilitatorCommonContext, LendUserType, LoanStatusEnum, LoanType, NftWithIndexType } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, createNftId, ERROR_CONNECT_YOUR_WALLET, ERROR_DATA_NOT_LOADED, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, formatLocalDateTime, getImageUrl, getTokenDecimal, getTokenTicker, SECOND_TO_MILLISECONDS, sleep, toastError } from 'z/utils';
import { isMobile } from 'react-device-detect';
import { ADMIN_ADDRESS, isDev, JEWEL_FACILITATOR_SC_ADDRESS, JWLEGLD_TOKEN_ID, JWLUSD_TOKEN_ID } from 'config';
import { routeNames } from 'routes';
import { Dropdown } from 'react-bootstrap';

import { Address, ArgSerializer, BigUIntValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';

import './index.scss';
import { getChainId } from 'z/elrond/sdkDappHelpers';

export const Facilitate = () => {
  const navigate = useNavigate();
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address, balance } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  useEffect(() => {
    if (!ADMIN_ADDRESS.includes(address)) {
      navigate(routeNames.dashboard);
    }
  }, [address]);

  const [commonContext, setCommonContext] = useState<JewelFacilitatorCommonContext | undefined>();
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const context = await queryJewelFacilitatorCommonContext();
      // console.log('commonContext: ', context);
      setCommonContext(context);
    })();
  }, [hasPendingTransactions]);

  const [targetTokenId, setTargetTokenId] = useState<string>(JWLEGLD_TOKEN_ID);
  const [targetAmount, setTargetAmount] = useState<number>(0);

  const handleFacilitate = async () => {
    if (targetTokenId === '' || targetAmount <= 0) return;

    const tokenId = targetTokenId.trim();
    const amount = convertEsdtToWei(targetAmount).toFixed(0);
    // console.log(tokenId, amount);

    const args: TypedValue[] = [
      new TokenIdentifierValue(tokenId),
      new BigUIntValue(amount)
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`facilitate@${argumentsString}`);

    const tx = new Transaction({
      value: '0',
      sender: new Address(address),
      receiver: new Address(JEWEL_FACILITATOR_SC_ADDRESS),
      gasLimit: 100_000_000,
      chainID: chainID,
      data,
    });

    await refreshAccount();
    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Facilitate',
        errorMessage: 'Error occured during Facilitate',
        successMessage: 'Facilitate Successful',
      }
    });
  };

  const handleUnfacilitate = async () => {
    if (targetTokenId === '' || targetAmount <= 0) return;

    const tokenId = targetTokenId.trim();
    const amount = convertEsdtToWei(targetAmount).toFixed(0);
    // console.log(tokenId, amount);

    const args: TypedValue[] = [
      new TokenIdentifierValue(tokenId),
      new BigUIntValue(amount)
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`unfacilitate@${argumentsString}`);

    const tx = new Transaction({
      value: '0',
      sender: new Address(address),
      receiver: new Address(JEWEL_FACILITATOR_SC_ADDRESS),
      gasLimit: 100_000_000,
      chainID: chainID,
      data,
    });

    await refreshAccount();
    await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Unfacilitate',
        errorMessage: 'Error occured during Unfacilitate',
        successMessage: 'Unfacilitate Successful',
      }
    });
  };

  return (
    <>
      <div className='container facilitate' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>Admin</span>
              <span>Facilitate</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          {
            commonContext && (
              <div className='row gy-2 gx-2 dashboard-egld-info mb-5' style={{ marginTop: '40px' }}>
                <div className='col-sm-6'>
                  <div className='d-flex justify-content-between dashboard-info'>
                    <span>JWLEGLD Amount</span>
                    <div className='d-flex justify-content-center dashboard-info-token'>
                      <div className=''>{convertBigNumberValueToLocalString(convertWeiToEsdt(commonContext.jwlegld_facilitated_amount))}</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='d-flex justify-content-between dashboard-info'>
                    <span>JWLUSD Amount</span>
                    <div className='d-flex justify-content-center dashboard-info-token'>
                      <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(commonContext.jwlusd_facilitated_amount))}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          <hr />
          <div className='mt-5 d-flex justify-content-center'>
            <div style={{ width: 'fit-content' }}>
              <div className='d-flex gap-3 align-items-center'>
                <div>Target Token Id:</div>
                {/* <input type="text" value={targetTokenId} onChange={(e: any) => setTargetTokenId(e.target.value)} /> */}
                <Dropdown>
                  <Dropdown.Toggle id='dropdown-basic-button' className='d-flex'>
                    <div className='d-flex align-items-center'>
                      <div>{getTokenTicker(targetTokenId)}</div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className='d-flex align-items-center' onClick={() => setTargetTokenId(JWLEGLD_TOKEN_ID)}>
                      <div>{getTokenTicker(JWLEGLD_TOKEN_ID)}</div>
                    </Dropdown.Item>
                    <Dropdown.Item className='d-flex align-items-center' onClick={() => setTargetTokenId(JWLUSD_TOKEN_ID)}>
                      <div>{getTokenTicker(JWLUSD_TOKEN_ID)}</div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className='d-flex gap-3 align-items-center mt-2' style={{ width: 'fit-content' }}>
                <div style={{ marginRight: '2px' }}>Target Amount:</div>
                <input type="number" value={targetAmount} onChange={(e: any) => setTargetAmount(e.target.value)} />
              </div>
            </div>
          </div>
          <div className='row mt-4 mb-2'>
            <div className={`col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-center`}>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleFacilitate}>
                Facilitate
              </div>
            </div>
            <div className={`col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-center`}>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleUnfacilitate}>
                Unfacilitate
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
