import { Address, AddressValue, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { JEWEL_FLASH_MINT_SC_ADDRESS } from 'config';
import { getTokenTicker } from 'z/utils';

export const openJewelFlashMintPosition = async (chainID: string, address: string, farmId: number, tokenId: string, depositAmount: string, leverage: number) => {
  const txs: any[] = [];
  if (getTokenTicker(tokenId) === 'EGLD') {
    const args: TypedValue[] = [
      new U32Value(farmId),
      new U64Value(leverage),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const dataString = `openPosition@${argumentsString}`;
    const data = new TransactionPayload(dataString);

    const tx = new Transaction({
      value: depositAmount,
      sender: new Address(address),
      receiver: new Address(JEWEL_FLASH_MINT_SC_ADDRESS),
      gasLimit: 400_000_000,
      chainID: chainID,
      data,
    });
    txs.push(tx);
  } else {
    const args: TypedValue[] = [
      new TokenIdentifierValue(tokenId),
      new BigUIntValue(depositAmount),
      new StringValue('openPosition'),
      new U32Value(farmId),
      new U64Value(leverage),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

    const tx = new Transaction({
      value: '0',
      sender: new Address(address),
      receiver: new Address(JEWEL_FLASH_MINT_SC_ADDRESS),
      gasLimit: 600_000_000,
      chainID: chainID,
      data,
    });
    txs.push(tx);
  }

  await refreshAccount();
  const result = await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Farm Request',
      errorMessage: 'Error occured during Farm Request',
      successMessage: 'Farm Request Successful',
    }
  });

  return result;
};

export const closeJewelFlashMintPosition = async (chainID: string, address: string, positionId: number) => {
  const args: TypedValue[] = [
    new U64Value(positionId),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`closePosition@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_FLASH_MINT_SC_ADDRESS),
    gasLimit: 250_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Close Position Request',
      errorMessage: 'Error occured during Close Position Request',
      successMessage: 'Close Position Request Successful',
    }
  });
};

export const openJewelLpFarmPosition = async (chainID: string, address: string, farmId: number, tokenId: string, depositAmount: string, leverage: number) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(tokenId),
    new BigUIntValue(depositAmount),
    new StringValue('openLpPosition'),
    new U32Value(farmId),
    new U64Value(leverage),
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_FLASH_MINT_SC_ADDRESS),
    gasLimit: 600_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Farm Request',
      errorMessage: 'Error occured during Farm Request',
      successMessage: 'Farm Request Successful',
    }
  });

  return result;
};

export const closeJewelLpFarmPosition = async (chainID: string, address: string, positionId: number) => {
  const args: TypedValue[] = [
    new U64Value(positionId),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`closeLpPosition@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_FLASH_MINT_SC_ADDRESS),
    gasLimit: 400_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Close Position Request',
      errorMessage: 'Error occured during Close Position Request',
      successMessage: 'Close Position Request Successful',
    }
  });
};