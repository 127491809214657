import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, createNftId, getImageUrl } from 'z/utils';
import { SwapTypeEnum } from 'z/types';
import moment from 'moment';
import egld_white_logo from 'assets/img/EGLD_white.svg';

export const CollectionActivityTab = (props: any) => {
    const columns: GridColDef[] = [
        {
            field: 'pool_nft_token_id',
            headerName: 'item',
            width: 300,
            renderCell: (params) => (
                <div className='d-flex gap-2 align-items-center'>
                    <img className='amm-activity-nft-badge' src={getImageUrl(params.value, createNftId(params.value, params.row.pool_activity_token_nonce))} alt={params.row.pool_activity_token_nonce} />
                    <span>{createNftId(params.value, params.row.pool_activity_token_nonce)}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_activity_type',
            headerName: 'action',
            width: 150,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <span>
                        {params.value == SwapTypeEnum.Buy ? (
                            <div style={{color: '#ff3225'}}>Sell</div>
                        ) : (
                            <div style={{color: '#25ff6f'}}>Buy</div>
                        )}
                    </span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_activity_caller_address',
            headerName: 'user',
            width: 300,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <span>{`${params.value.substring(0, 7)}...${params.value.slice(-7)}`}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_activity_price',
            headerName: 'price',
            width: 200,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_activity_timestamp',
            headerName: 'when',
            width: 300,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <span>{moment.unix(params.value).fromNow()}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        }
    ];
    return (
        <div className='container' style={{ marginTop: '40px' }}>
            <DataGrid
                sx={{
                    '& .MuiSvgIcon-root': { fontSize: 28 },
                    fontSize: '18px',
                    '& .MuiDataGrid-columnSeparator': {
                        visibility: 'hidden',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        minHeight: '400px'
                    },
                    '&:hover:not(.Mui-disabled)': {
                        cursor: 'pointer'
                    },
                    fontFamily: 'Poppins',
                }}
                autoHeight
                getRowHeight={() => 'auto'}
                rows={props.activities}
                columns={columns}
                getRowId={(row) => `activity-${row.index}`}
                disableColumnMenu
                disableSelectionOnClick
                // hideFooter
                loading={props.activityLoading}
            />
        </div>
    );
};
