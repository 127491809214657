import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Box, Modal } from '@mui/material';
import { DataGrid, GridColDef, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { useGetAccount, useGetIsLoggedIn, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { animated, useSpring } from 'react-spring';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import imageQuestionMarkInCircle from 'assets/img/question-mark-in-circle.png';
import { getNftsByIdFromApi } from 'z/api';
import { queryCommonSettings, queryViewLoans, queryViewLoansById, queryViewUser, sendRedeemLoan, sendRenewLoans, sendTopUp } from 'z/elrond';
import { CommonSettingsType, LendUserType, LoanStatusEnum, LoanType, NftWithIndexType } from 'z/types';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, createNftId, ERROR_CONNECT_YOUR_WALLET, ERROR_DATA_NOT_LOADED, ERROR_NOT_ENOUGH_BALANCE, EXTRA_GAS_FEE_AMOUNT, formatLocalDateTime, getImageUrl, SECOND_TO_MILLISECONDS, sleep, toastError } from 'z/utils';
import { isMobile } from 'react-device-detect';
import { ADMIN_ADDRESS, isDev } from 'config';
import { routeNames } from 'routes';
import { getChainId } from 'z/elrond/sdkDappHelpers';

export const AdminTopUp = () => {
  const navigate = useNavigate();
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address, balance } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  // useEffect(() => {
  //   if (address != ADMIN_ADDRESS) {
  //     navigate(routeNames.dashboard);
  //   }
  // }, [address]);

  // const [commonSettings, setCommonSettings] = useState<CommonSettingsType | undefined>();
  // const [lendUser, setLendUser] = useState<LendUserType | undefined>();
  // const [loans, setLoans] = useState<LoanType[]>([]);
  const [loansLoading, setLoansLoading] = useState<boolean>(false);
  const [liquidatedLoans, setLiquidatedLoans] = useState<LoanType[]>([]);
  const [nfts, setNfts] = useState<NftWithIndexType[]>([]);
  const [topUpEgld, setTopUpEgld] = useState<string>('0');
  const [selectedLoanIds, setSelectedLoanIds] = useState<any[]>([]);

  // useEffect(() => {
  //   if (hasPendingTransactions) return;

  //   (async () => {
  //     const _commonSettings = await queryCommonSettings(apiAddress, address);
  //     console.log('_commonSettings', _commonSettings);
  //     setCommonSettings(_commonSettings);
  //   })();
  // }, [hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions || !address) return;

    (async () => {
      setLoansLoading(true);
      const _loans = await queryViewLoans(apiAddress);
      // console.log('_loans', _loans);
      const _liquidatedLoans = _loans.filter(loan => loan.loan_status === LoanStatusEnum.Liquidated || loan.loan_status === LoanStatusEnum.Expired);
      // console.log('_liquidatedLoans', _liquidatedLoans);
      setLiquidatedLoans(_liquidatedLoans);
      setLoansLoading(false);

      let topUpAmount = new BigNumber(0);
      _liquidatedLoans.map(v => { topUpAmount = topUpAmount.plus(v.loan_borrow_amount); });
      setTopUpEgld(topUpAmount.toFixed());

      // load NFTs from API
      if (_liquidatedLoans.length > 0) {
        // console.log('loans: ', loans);
        const _nfts = await getNftsByIdFromApi(apiAddress, _liquidatedLoans.map(v => createNftId(v.loan_collection_id, v.loan_nft_nonce)));
        // console.log('_nfts', _nfts);
        setNfts(_nfts);
      } else {
        setNfts([]);
      }
    })();
  }, [hasPendingTransactions, address]);

  const handleTopUp = async () => {
    if (selectedLoanIds.length > 0) {
      let topUpAmount = new BigNumber(0);
      for (let i = 0; i < selectedLoanIds.length; i++) {
        const selectedLoan = liquidatedLoans.filter(loan => loan.loan_id === selectedLoanIds[i]);
        if (selectedLoan.length > 0) {
          topUpAmount = topUpAmount.plus(selectedLoan[0].loan_borrow_amount);
        }
      }

      if (topUpAmount.gt(0)) {
        // send topup
        await sendTopUp(chainID, address, topUpAmount.toFixed(), selectedLoanIds);
        setSelectedLoanIds([]);
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'loan_type',
      headerName: 'Loan Type',
      width: 180,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'loan_status',
      headerName: 'Loan Status',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'nft-image',
      headerName: 'Image',
      width: 80,
      renderCell: (params) => (
        <div className='py-2'>
          <img
            style={{ width: '50px', height: '50px', borderRadius: '5px', objectFit: 'contain' }}
            src={!isDev ? getImageUrl(params.row.loan_collection_id, createNftId(params.row.loan_collection_id, params.row.loan_nft_nonce)) : nfts.length > 0 && nfts.length === liquidatedLoans.length ? nfts.filter((nft: NftWithIndexType) => { return nft.identifier == createNftId(params.row.loan_collection_id, params.row.loan_nft_nonce); })[0].url : imageQuestionMarkInCircle}
          />
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'index',
      headerName: 'NFT',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        createNftId(params.row.loan_collection_id, params.row.loan_nft_nonce)
      ),
    },
    {
      field: 'loan_borrow_amount',
      headerName: 'Borrowed',
      width: 100,
      renderCell: (params) => (
        <div className='d-flex gap-2'>
          <img className='egld-badge' src={egld_white_logo} alt='egld' />
          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'loan_debt_amount',
      headerName: 'Debt',
      width: 100,
      renderCell: (params) => (
        <div className='d-flex gap-2'>
          <img className='egld-badge' src={egld_white_logo} alt='egld' />
          <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
        </div>
      ),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'loan_open_timestamp',
      headerName: 'Opened',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: params => formatLocalDateTime(params.value * SECOND_TO_MILLISECONDS),
    },
    {
      field: 'loan_deadline_timestamp',
      headerName: 'Deadline',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: params => formatLocalDateTime(params.value * SECOND_TO_MILLISECONDS),
    },
  ];

  return (
    <>
      <div className='container ' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>Admin</span>
              <span>Liquidated Loans</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          <div className='row gy-2 gx-2 dashboard-egld-info mb-5' style={{ marginTop: '40px' }}>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>TopUp</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div
                    className=''>{convertBigNumberValueToLocalString(convertWeiToEsdt(topUpEgld), 2)}</div>
                  <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Balance</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div
                    className=''>{isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(balance), 2) : '0'}</div>
                  <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-4 mb-2'>
            <div className='col-12 d-flex justify-content-center'>
              <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleTopUp}>
                TopUp EGLD
              </div>
            </div>
          </div>
          <div className='w-100' style={{ marginTop: '30px' }}>
            <DataGrid
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28 },
                fontSize: '15px',
                '& .MuiDataGrid-columnSeparator': {
                  visibility: 'hidden',
                },
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: '400px'
                },
              }}
              autoHeight
              getRowHeight={() => 'auto'}
              rows={liquidatedLoans}
              columns={columns}
              getRowId={(row) => row.loan_id}
              disableColumnMenu
              disableSelectionOnClick
              hideFooter
              loading={loansLoading}
              checkboxSelection
              onSelectionModelChange={(ids) => {
                setSelectedLoanIds(ids);
              }}
              selectionModel={selectedLoanIds}
            />
          </div>
        </div>
      </div>
    </>
  );
};
