import { Address, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { LEND_GAS_LIMIT, JEWEL_LEND_V2_SC_ADDRESS, UNWRAP_GAS_LIMIT } from 'config';
import { getTokenTicker } from 'z/utils';

export const sendJewelLend = async (chainID: string, address: string, lendTokenId: string, lendAmount: string, wrapAddress: string) => {
  const txs: any = [];
  if (getTokenTicker(lendTokenId) === 'EGLD') {

    const data = new TransactionPayload('wrapEgld');

    const wrapTx = new Transaction({
      value: lendAmount,
      sender: new Address(address),
      receiver: new Address(wrapAddress),
      gasLimit: LEND_GAS_LIMIT,
      chainID: chainID,
      data,
    });

    txs.push(wrapTx);
  }

  const args: TypedValue[] = [
    new TokenIdentifierValue(lendTokenId),
    new BigUIntValue(lendAmount),
    new StringValue('lenderDeposit'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_LEND_V2_SC_ADDRESS),
    gasLimit: LEND_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  txs.push(tx);

  await refreshAccount();
  await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Lend Request',
      errorMessage: 'Error occured during Lend Request',
      successMessage: 'Lend Request Successful',
    }
  });
};

export const sendJewelWithdraw = async (chainID: string, address: string, ibTokenId: string, ibTokenAmount: string, depositTokenId: string, outAmount: string, unWrapAddress: string, nonce: number) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(ibTokenId),
    new BigUIntValue(ibTokenAmount),
    new StringValue('lenderWithdraw'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_LEND_V2_SC_ADDRESS),
    gasLimit: LEND_GAS_LIMIT,
    chainID: chainID,
    data,
  });
  const txs = [tx];

  if (getTokenTicker(depositTokenId) === 'EGLD') {
    const args: TypedValue[] = [
      new TokenIdentifierValue(depositTokenId),
      new BigUIntValue(outAmount),
      new StringValue('unwrapEgld'),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

    const wrapTx = new Transaction({
      value: '0',
      sender: new Address(address),
      receiver: new Address(unWrapAddress),
      gasLimit: UNWRAP_GAS_LIMIT,
      chainID: chainID,
      nonce: nonce + 1,
      data,
    });

    txs.push(wrapTx);
  }


  await refreshAccount();
  await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Withdraw Request',
      errorMessage: 'Error occured during Withdraw Request',
      successMessage: 'Withdraw Request Successful',
    }
  });
};
