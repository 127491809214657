import { Address, AddressValue, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value, VariadicValue } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { BORROW_GAS_LIMIT, DOWNPAYMENT_GAS_LIMIT, NFT_LENDING_SC_ADDRESS } from 'config';
import { NFT_QUANTITY } from 'z/utils';

export const sendBorrowEgld = async (chainID: string, address: string, collectionId: string, nftNonces: number[], borrowPlanId: number, borrowAmount: string, expectedDebtAmount: string) => {
  const args: TypedValue[] = [
    new AddressValue(new Address(NFT_LENDING_SC_ADDRESS)),
  ];
  args.push(new U32Value(nftNonces.length));
  for (let i = 0; i < nftNonces.length; i++) {
    args.push(new TokenIdentifierValue(collectionId));
    args.push(new U64Value(nftNonces[i]));
    args.push(new U64Value(NFT_QUANTITY));
  }
  args.push(new StringValue('borrowEgld'));
  args.push(new TokenIdentifierValue(collectionId));
  args.push(new U32Value(borrowPlanId));
  args.push(new BigUIntValue(borrowAmount));
  args.push(new BigUIntValue(expectedDebtAmount));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  let gasLimit = BORROW_GAS_LIMIT * nftNonces.length;
  if (gasLimit > 600_000_000) {
    gasLimit = 600_000_000;
  }
  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: gasLimit,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Borrow EGLD Request',
      errorMessage: 'Error occured during Borrow EGLD Request',
      successMessage: 'Borrow EGLD Request Successful',
    }
  });

  return result;
};

export const sendRedeemLoan = async (chainID: string, address: string, paymentAmount: string, loanId: number) => {
  const args: TypedValue[] = [
    new U32Value(loanId),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`repayLoan@${argumentsString}`);
  
  const tx = new Transaction({
    value: paymentAmount,
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: BORROW_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Redeem Loan Request',
      errorMessage: 'Error occured during Redeem Loan Request',
      successMessage: 'Redeem Loan Request Successful',
    }
  });
};

export const sendRenewLoans = async (chainID: string, address: string, paymentAmount: string, loanIds: number[]) => {
  const ids: TypedValue[] = loanIds.map(v => new U32Value(v));
  const args: TypedValue[] = [
    VariadicValue.fromItems(...ids)
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`renewLoans@${argumentsString}`);

  const tx = new Transaction({
    value: paymentAmount,
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: BORROW_GAS_LIMIT * ids.length,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Renew Loans Request',
      errorMessage: 'Error occured during Renew Loans Request',
      successMessage: 'Renew Loans Request Successful',
    }
  });
};

export const sendRedeemLoans = async (chainID: string, address: string, paymentAmount: string, loanIds: number[]) => {
  const ids: TypedValue[] = loanIds.map(v => new U32Value(v));
  const args: TypedValue[] = [
    VariadicValue.fromItems(...ids),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`repayLoans@${argumentsString}`);

  const tx = new Transaction({
    value: paymentAmount,
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: BORROW_GAS_LIMIT * ids.length,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Redeem Loans Request',
      errorMessage: 'Error occured during Redeem Loans Request',
      successMessage: 'Redeem Loans Request Successful',
    }
  });
};

export const sendRenewAndCashoutLoans = async (chainID: string, address: string, paymentAmount: string, loanIds: number[]) => {
  const ids: TypedValue[] = loanIds.map(v => new U32Value(v));
  const args: TypedValue[] = [
    VariadicValue.fromItems(...ids),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`renewCashoutLoans@${argumentsString}`);

  const tx = new Transaction({
    value: paymentAmount,
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: BORROW_GAS_LIMIT * ids.length,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Renew & Cashout Loans Request',
      errorMessage: 'Error occured during Renew & Cashout Loans Request',
      successMessage: 'Renew & Cashout Loans Request Successful',
    }
  });
};

export const sendTopUp = async (chainID: string, address: string, paymentAmount: string, loanIds: number[]) => {
  const ids: TypedValue[] = loanIds.map(v => new U32Value(v));
  const args: TypedValue[] = [
    VariadicValue.fromItems(...ids),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`topUpEgld@${argumentsString}`);

  const tx = new Transaction({
    value: paymentAmount,
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: BORROW_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing TopUp Egld',
      errorMessage: 'Error occured during TopUp Egld',
      successMessage: 'TopUp Egld Successful',
    }
  });
};

// downpayment
export const sendBuyNftWithDownpayment = async (chainID: string, address: string, collectionId: string, nftNonce: number, nftPrice: string, auctionId: number, borrowPlanId: number, paymentAmount: string, expectedDebtAmount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(collectionId),
    new U64Value(nftNonce),
    new BigUIntValue(nftPrice),
    new U32Value(auctionId),
    new U32Value(borrowPlanId),
    new BigUIntValue(expectedDebtAmount)
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`buyWithDownPaymentFromXoxno@${argumentsString}`);

  const tx = new Transaction({
    value: paymentAmount,
    sender: new Address(address),
    receiver: new Address(NFT_LENDING_SC_ADDRESS),
    gasLimit: DOWNPAYMENT_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Mortgage',
      errorMessage: 'Error occured during Mortgage',
      successMessage: 'Mortgage Successful',
    }
  });

  return result;
};

export const sendBuyWithNft = async (chainID: string, address: string, nftNonces: number[], collectionId: string, nftNonce: number, nftPrice: string, auctionId: number, borrowPlanId: number, paymentAmount: string) => {

  const args: TypedValue[] = [
    new AddressValue(new Address(NFT_LENDING_SC_ADDRESS)),
  ];
  args.push(new U32Value(nftNonces.length));
  for (let i = 0; i < nftNonces.length; i++) {
    args.push(new TokenIdentifierValue(collectionId));
    args.push(new U64Value(nftNonces[i]));
    args.push(new U64Value(NFT_QUANTITY));
  }
  args.push(new StringValue('buyWithNftFromXoxno'));
  args.push(new TokenIdentifierValue(collectionId));
  args.push(new U64Value(nftNonce));
  args.push(new BigUIntValue(nftPrice));
  args.push(new U32Value(auctionId));
  args.push(new U32Value(borrowPlanId));
  args.push(new U64Value(nftNonces.length + 1));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  let gasLimit = DOWNPAYMENT_GAS_LIMIT * nftNonces.length;
  if (gasLimit > 600_000_000) {
    gasLimit = 600_000_000;
  }

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: gasLimit,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Mortgage',
      errorMessage: 'Error occured during Mortgage',
      successMessage: 'Mortgage Successful',
    }
  });

  return result;
};
