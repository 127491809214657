import { Address, ArgSerializer, BigUIntValue, BooleanValue, EnumValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { JEWEL_BORROW_SC_ADDRESS, WEGLD_TOKEN_ID } from 'config';
import { MoneyMarketPoolTypeEnum } from 'z/types';
import { getTokenTicker } from 'z/utils';

export const sendJewelMoneyMarketSupply = async (chainID: string, address: string, selectedTokenId: string, baseTokenId: string, supplyAmount: string, debtTokenId: string, isHatomBaseToken: boolean, wrapAddress: string) => {
  const txs: any = [];
  if (getTokenTicker(selectedTokenId) === 'EGLD') {
    // wrap egld
    const data = new TransactionPayload('wrapEgld');

    const wrapTx = new Transaction({
      value: supplyAmount,
      sender: new Address(address),
      receiver: new Address(wrapAddress),
      gasLimit: 10_000_000,
      chainID: chainID,
      data,
    });
    txs.push(wrapTx);
  }

  const args: TypedValue[] = [
    new TokenIdentifierValue(getTokenTicker(selectedTokenId) === 'EGLD' ? WEGLD_TOKEN_ID : selectedTokenId),
    new BigUIntValue(supplyAmount),
    new StringValue('addSupply'),
    new TokenIdentifierValue(baseTokenId),
    new TokenIdentifierValue(debtTokenId),
    new BooleanValue(isHatomBaseToken),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BORROW_SC_ADDRESS),
    gasLimit: 150_000_000,
    chainID: chainID,
    data,
  });
  txs.push(tx);

  await refreshAccount();
  await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Supply Request',
      errorMessage: 'Error occured during Supply Request',
      successMessage: 'Supply Request Successful',
    }
  });
};

export const sendJewelMoneyMarketWithdraw = async (chainID: string, address: string, baseTokenId: string, debtTokenId: string, withdrawAmount: string, isHatomBaseToken: boolean) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(baseTokenId),
    new TokenIdentifierValue(debtTokenId),
    new BigUIntValue(withdrawAmount),
    new BooleanValue(isHatomBaseToken),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`removeSupply@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BORROW_SC_ADDRESS),
    gasLimit: 450_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Withdraw Request',
      errorMessage: 'Error occured during Withdraw Request',
      successMessage: 'Withdraw Request Successful',
    }
  });
};

export const sendJewelMoneyMarketBorrow = async (chainID: string, address: string, baseTokenId: string, debtTokenId: string, borrowAmount: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(baseTokenId),
    new TokenIdentifierValue(debtTokenId),
    new BigUIntValue(borrowAmount),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`borrow@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BORROW_SC_ADDRESS),
    gasLimit: 70_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Borrow Request',
      errorMessage: 'Error occured during Borrow Request',
      successMessage: 'Borrow Request Successful',
    }
  });
};

export const sendJewelMoneyMarketRepay = async (chainID: string, address: string, debtTokenId: string, repayAmount: string, baseTokenId: string) => {
  const args: TypedValue[] = [
    new TokenIdentifierValue(debtTokenId),
    new BigUIntValue(repayAmount),
    new StringValue('repay'),
    new TokenIdentifierValue(baseTokenId),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_BORROW_SC_ADDRESS),
    gasLimit: 60_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Repay Request',
      errorMessage: 'Error occured during Repay Request',
      successMessage: 'Repay Request Successful',
    }
  });
};

