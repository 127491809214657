import axios from "axios";
import { HATOM_API_GRAPHQL_URL } from "config";
import DefiUtils from "defi-utils";
import { gql, GraphQLClient } from "graphql-request";
import { calcHatomLiquidStakingExchangeRate, calcHatomSimulateExchangeRate } from "z/utils";

export const client = new GraphQLClient(HATOM_API_GRAPHQL_URL, {
    method: "GET",
    jsonSerializer: {
        parse: JSON.parse,
        stringify: JSON.stringify,
    },
});

export async function getHatomMoneyMarket() {
    try {
        const url = HATOM_API_GRAPHQL_URL;
        const params = 'query=query%20Protocol_QueryProtocol%20()%20%40cacheControl(maxAge%3A%2010)%20%7B%20queryLiquidStaking%20%7B%20state%20%7B%20cashReserve%20totalShares%20%7D%20%7D%20queryMoneyMarket%20%7B%20address%20collateralFactor%20supported%20liquidationIncentive%20underlying%20%7B%20id%20decimals%20name%20symbol%20%7D%20hToken%20%7B%20id%20decimals%20name%20symbol%20%7D%20dailyStateHistory(first%3A%202%20order%3A%20%7B%20desc%3A%20day%20%7D)%20%7B%20moneyMarketState%20%7B%20cash%20borrows%20reserves%20totalSupply%20timestamp%20supplyRatePerSecond%20borrowRatePerSecond%20%7D%20%7D%20%7D%20%7D&variables=%7B%7D';
        const { data } = await axios.get(`${url}?${params}`);
        return data;
    } catch (e) {
        console.log(e);
        // toastError(ERROR_MVX_API_FAILED);
    }
}

export async function getHatomControllerAddress() {
    const query = gql`
      query QueryController {
        queryController {
          id
        }
      }
    `;

    const variables = {};

    const response: any = await client.request(query, variables);

    return response.queryController?.[0].id || "";
}

export async function getHatomRewardsBatch() {
    const query = gql`
    query QueryRewardsBatch {
      queryRewardsBatchState {
        id
        type
        speed
        rewardsToken {
          id
          decimals
          symbol
        }
        moneyMarket {
          dailyStateHistory(first: 1, order: { desc: day }) {
            moneyMarketState {
              borrows
            }
          }
          hToken {
            id
          }
          underlying {
            id
            decimals
            symbol
          }
        }
      }
    }
  `;

    const variables = {};

    const response: any = await client.request(query, variables);

    return response.queryRewardsBatchState;
}

export async function getHatomTokenPrices() {
    const query = gql`
    query QueryTokenPrices {
      queryLiquidStaking {
        state {
          cashReserve
          totalShares
        }
      }
      queryToken {
        id
        symbol
        dailyPriceHistory(first: 1, order: { desc: day }) {
          quote {
            priceInEgld
            timestamp
          }
          price {
            price
            timestamp
          }
        }
      }
    }
  `;

    const variables = {};

    const { queryToken, queryLiquidStaking }: any = await client.request(
        query,
        variables
    );

    const liquidStakingExchangeRate = calcHatomLiquidStakingExchangeRate(
        queryLiquidStaking?.[0]?.state?.cashReserve,
        queryLiquidStaking?.[0]?.state?.totalShares
    );

    const queryTokenPopulated = queryToken
        .filter(
            ({ dailyPriceHistory, symbol }: any) =>
                dailyPriceHistory.length > 0 || symbol === "EGLD"
        )
        .map((tokenItem: any) => {
            const filteredToken = tokenItem.dailyPriceHistory;

            const priceEgld = filteredToken?.[0]?.quote?.priceInEgld || "0";

            let dailyPriceInEgld = "0";

            if (tokenItem.symbol == "EGLD") {
                dailyPriceInEgld = "1";
            } else if (tokenItem.symbol == "SEGLD") {
                dailyPriceInEgld = new DefiUtils(1)
                    .toUnderlying(liquidStakingExchangeRate)
                    .toString();
            } else {
                dailyPriceInEgld = priceEgld;
            }

            const dailyPriceInUSD = filteredToken?.[0]?.price?.price || "0";

            return {
                ...tokenItem,
                dailyPriceInEgld,
                dailyPriceInUSD,
            };
        });

    const itemEgldInUSD = queryTokenPopulated.find(
        ({ symbol }: any) => symbol === "EGLD"
    );
    const itemEgldInUSDC = queryTokenPopulated.find(
        ({ symbol }: any) => symbol === "USDC"
    );

    const agregatorEGLDInUSD = new DefiUtils(
        itemEgldInUSD?.dailyPriceInUSD || "0"
    )
        .toFullDecimals(18)
        .toString();

    const priceHistoryEGLDInUSDC =
        new DefiUtils(1)
            .dividedBy(itemEgldInUSDC?.dailyPriceInEgld || 0)
            .toString() || "0";

    const usdcPriceInEgld = new DefiUtils(agregatorEGLDInUSD).isZero()
        ? priceHistoryEGLDInUSDC
        : agregatorEGLDInUSD;

    const egldInUsdc = usdcPriceInEgld !== "0" ? usdcPriceInEgld : "0";

    return queryTokenPopulated.reduce(
        (prev: any, { dailyPriceInEgld, dailyPriceInUSD, symbol }: any) => {
            const priceUSD =
                !new DefiUtils(egldInUsdc).isEqualTo("0") ||
                    !new DefiUtils(dailyPriceInEgld).isEqualTo("0")
                    ? new DefiUtils(egldInUsdc).multipliedBy(dailyPriceInEgld).toString()
                    : "0";

            const value = !new DefiUtils(dailyPriceInUSD).isZero()
                ? new DefiUtils(dailyPriceInUSD).toFullDecimals(18).toString()
                : priceUSD;

            return {
                ...prev,
                [symbol]: value,
            };
        },
        {}
    );
}

export async function getHTokenExchangeRate() {
    const query = gql`
    query QueryHTokenExchangeRate {
      queryMoneyMarket {
        underlying {
          id
          decimals
          name
          symbol
        }
        hToken {
          id
          decimals
          name
          symbol
        }
        dailyStateHistory(first: 1, order: { desc: day }) {
          moneyMarketState {
            cash
            borrows
            reserves
            totalSupply
            timestamp
            supplyRatePerSecond
            borrowRatePerSecond
          }
        }
      }
    }
  `;

    const variables = {};

    const response: any = await client.request(query, variables);

    return response.queryMoneyMarket.reduce(
        (prev: any, current: any) => ({
            ...prev,
            [current.underlying.symbol]: calcHatomSimulateExchangeRate({
                cash: current.dailyStateHistory?.[0]?.moneyMarketState?.cash || "0",
                borrows:
                    current.dailyStateHistory?.[0]?.moneyMarketState?.borrows || "0",
                reserves:
                    current.dailyStateHistory?.[0]?.moneyMarketState?.reserves || "0",
                totalSupply:
                    current.dailyStateHistory?.[0]?.moneyMarketState?.totalSupply || "0",
                rate:
                    current.dailyStateHistory?.[0]?.moneyMarketState
                        ?.supplyRatePerSecond || "0",
                timestamp:
                    current.dailyStateHistory?.[0]?.moneyMarketState?.timestamp ||
                    new Date().toISOString(),
            }),
        }),
        {}
    );
}

export async function getHatomLiquidStakingApyInfo() {
    const query = gql`
    query QueryLiquidStakingApyInfo {
      queryLiquidStaking {
        totalFee
      }
      queryDelegationContract {
        id
        totalDelegated
        apr
        serviceFee
      }
    }
  `;

    const variables = {};

    const response = await client.request(query, variables);

    return response;
}
