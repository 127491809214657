import React from 'react';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import clsx from 'clsx';
import { Navbar as BsNavbar, NavItem, Nav, NavDropdown } from 'react-bootstrap';
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import logo from 'assets/img/logo.png';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { ADMIN_ADDRESS, isDev, RADIX_DAPP_LINK, SOLANA_DAPP_LINK, STAKING_PROVIDER_ADDRESS, SUI_DAPP_LINK } from 'config';
import { Dropdown } from 'react-bootstrap';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import solana_logo from 'assets/img/solana.svg';
import radix_logo from 'assets/img/radix.svg';
import sui_logo from 'assets/img/sui_logo.png';
import './index.scss';

export const Navbar = () => {
  const { address, balance } = useGetAccount();
  const isLoggedIn = useGetIsLoggedIn();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  const handleRouter = (path: string) => {
    navigate(path);
  };

  return (
    <BsNavbar className='EPunk-Header' expand='lg' collapseOnSelect>
      <div className='container-fluid container' style={{ marginTop: "16px" }}>
        <a
          className='d-flex align-items-center navbar-brand mr-0'
          href={"https://jewelswap.io/"}
        >
          <img src={logo} style={{ maxWidth: "200px", width: "200px", height: 'auto' }} />
          {/* <span className='dapp-name text-white'>{dAppName}</span> */}
        </a>

        <BsNavbar.Toggle aria-controls='responsive-navbar-nav' style={{ background: "transparent", border: 'none', fontSize: '1.5rem', color: 'white' }} >
          <BsFillMenuButtonWideFill />
        </BsNavbar.Toggle >

        <BsNavbar.Collapse id='responsive-navbar-nav' className='nav-menu-wrap'>
          <Nav className='ml-auto'>
            {/*<Link to="https://jewelswap.webflow.io/" className={clsx('custom-navbar-button', location.pathname == routeNames.dashboard && 'custom-navbar-button-selected')}>*/}
            {/*  About*/}
            {/*</Link>*/}
            <Link to={routeNames.dashboard} className={clsx('custom-navbar-button', location.pathname == routeNames.dashboard && 'custom-navbar-button-selected')}>
              Overview
            </Link>
            <NavDropdown
              title='NFT'
              className={clsx('custom-navbar-button', location.pathname == routeNames.collections || location.pathname == routeNames.borrow || location.pathname == routeNames.mortgage ? 'custom-navbar-button-selected' : '')}
            >
              <NavDropdown.Item onClick={() => handleRouter(routeNames.collections)}>Collections</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(routeNames.borrow)}>Borrow</NavDropdown.Item>
              {/*<NavDropdown.Item onClick={() => handleRouter(routeNames.mortgage)}>Mortgage</NavDropdown.Item>*/}
            </NavDropdown>
            <Link to={routeNames.lend} className={clsx('custom-navbar-button', location.pathname == routeNames.lend && 'custom-navbar-button-selected')}>
              Lend
            </Link>
            <NavDropdown
              title='Farm'
              className={clsx('custom-navbar-button', location.pathname == '/farm/ashswap' || location.pathname == '/farm/onedex' || location.pathname == '/farm/hatom' || location.pathname == '/farm/jewelswap' || location.pathname == '/farm/xexchange' ? 'custom-navbar-button-selected' : '')}
            >
              <NavDropdown.Item onClick={() => handleRouter(`/farm/ashswap`)}>Ashswap</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/farm/onedex`)}>OneDex</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/farm/hatom`)}>Hatom</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/farm/jewelswap`)}>JewelSwap</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/farm/xexchange`)}>xExchange</NavDropdown.Item>
            </NavDropdown>
            <Link to={routeNames.restake} className={clsx('custom-navbar-button', location.pathname == routeNames.restake && 'custom-navbar-button-selected')}>
              Restake
            </Link>
            <NavDropdown
              title='Stake'
              className={clsx('custom-navbar-button', location.pathname == '/stake/ASH' || location.pathname == '/stake/EGLD' || location.pathname == '/stake/HTM' || location.pathname == '/stake/MEX' || location.pathname == '/stake/JWLUSD' || location.pathname == '/stake/UTK' || location.pathname == '/stake/ITHEUM' || location.pathname == '/stake/XMEX' || location.pathname == '/stake/WBTC' || location.pathname == '/stake/WETH' ? 'custom-navbar-button-selected' : '')}
            >
              <NavDropdown.Item onClick={() => handleRouter(`/stake/EGLD`)}>EGLD</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/ASH`)}>ASH</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/HTM`)}>HTM</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/MEX`)}>MEX</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/JWLUSD`)}>JWLUSD</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/UTK`)}>UTK</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/ITHEUM`)}>ITHEUM</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/TADA`)}>TADA</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/XMEX`)}>XMEX</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/WBTC`)}>WBTC</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/WETH`)}>WETH</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/WTAO`)}>WTAO</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/stake/APUSDC`)}>APUSDC</NavDropdown.Item>
            </NavDropdown>
            <Link to={routeNames.gauge} className={clsx('custom-navbar-button', location.pathname == routeNames.gauge && 'custom-navbar-button-selected')}>
              Gauge
            </Link>
            {/* <Link to='/money-market/jewel' className={clsx('custom-navbar-button', (location.pathname == '/money-market/jewel' || location.pathname == '/money-market/hatom' || location.pathname == '/money-market/ashswap' || location.pathname == '/money-market/global') && 'custom-navbar-button-selected')}>
              Money Market
            </Link> */}
            <NavDropdown
              title='Money Market'
              className={clsx('custom-navbar-button', (location.pathname == '/money-market/jewel' || location.pathname == '/money-market/hatom' || location.pathname == '/money-market/ashswap' || location.pathname == '/money-market/global') && 'custom-navbar-button-selected')}
            >
              <NavDropdown.Item onClick={() => handleRouter(`/money-market/global`)}>Global Pool</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(`/money-market/jewel`)}>Isolated Pool</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title='Portfolio'
              className={clsx('custom-navbar-button', location.pathname.includes('/mypositions') || location.pathname == routeNames.loans || location.pathname == routeNames.mypools ? 'custom-navbar-button-selected' : '')}
            >
              <NavDropdown.Item onClick={() => handleRouter('/mypositions/ashswap')}>My Positions</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(routeNames.loans)}>My Loans</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleRouter(routeNames.mypools)}>My Pools</NavDropdown.Item>
            </NavDropdown>
            <Link to={routeNames.point} className={clsx('custom-navbar-button', location.pathname == routeNames.point && 'custom-navbar-button-selected')}>
              Leaderboard
            </Link>
            <a href="https://docs.jewelswap.io/" className={clsx('custom-navbar-button')} target='_blank' rel='noreferrer'>
              Docs
            </a>
            {ADMIN_ADDRESS.includes(address) && (
              <NavDropdown
                title='Admin'
                className={clsx('custom-navbar-button', location.pathname == routeNames.topUp || location.pathname == routeNames.veash || location.pathname == routeNames.facilitate ? 'custom-navbar-button-selected' : '')}
              >
                <NavDropdown.Item onClick={() => handleRouter(routeNames.topUp)}>TopUp</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleRouter(routeNames.veash)}>Veash</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleRouter(routeNames.facilitate)}>Facilitate</NavDropdown.Item>
                <a className='dropdown-item' href={`https://explorer.multiversx.com/providers/${STAKING_PROVIDER_ADDRESS}`} target='_blank' rel="noreferrer">
                  Staking Provider
                </a>
                <NavDropdown.Item onClick={() => handleRouter(routeNames.adminXexchangeFarm)}>xExchange Farm</NavDropdown.Item>
              </NavDropdown>
            )}
            <Dropdown>
              <Dropdown.Toggle id='dropdown-blockchain-button' className='d-flex'>
                <div className='blockchain'>
                  <img src={egld_white_logo} alt='multiversx' width={25} height={25}></img>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className='d-flex align-items-center blockchain-items'>
                  <img src={egld_white_logo} alt='multiversx' width={25} height={25}></img>
                  <div className='mx-1'>MultiversX</div>
                </Dropdown.Item>
                <Dropdown.Item href={SOLANA_DAPP_LINK}>
                  <a href={SOLANA_DAPP_LINK} target="_blank" rel="noreferrer" className='d-flex align-items-center blockchain-items'>
                    <img src={solana_logo} alt='solana' width={25} height={25}></img>
                    <div className='mx-1'>Solana</div>
                  </a>
                </Dropdown.Item>

                <Dropdown.Item href={RADIX_DAPP_LINK}>
                  <a href={RADIX_DAPP_LINK} target="_blank" rel="noreferrer" className='d-flex align-items-center blockchain-items'>
                    <img src={radix_logo} alt='radix' width={25} height={25}></img>
                    <div className='mx-1'>Radix</div>
                  </a>
                  </Dropdown.Item>

                  <Dropdown.Item href={SUI_DAPP_LINK}>
                  <a href={SUI_DAPP_LINK} target="_blank" rel="noreferrer" className='d-flex align-items-center blockchain-items'>
                    <img src={sui_logo} alt='sui' width={25} height={25}></img>
                    <div className='mx-1'>SUI</div>
                  </a>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {isLoggedIn ? (
              <NavItem>
                <button className='btn btn-link' onClick={handleLogout}>
                  <div className="eg-btn btn--primary2 capsule btn--md" style={{ cursor: 'pointer' }}>Disconnect</div>
                </button>
              </NavItem>
            ) : (
              <Nav.Link href={routeNames.unlock}>
                <div className="eg-btn btn--primary2 capsule btn--md" style={{ cursor: 'pointer' }}>Connect</div>
              </Nav.Link>
            )}
          </Nav>
        </BsNavbar.Collapse>
      </div>
    </BsNavbar>
  );
};
