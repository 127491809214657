import React, { useEffect, useState } from 'react';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { useParams, useNavigate } from 'react-router-dom';
import { AshStake } from './AshStake';
import { LiquidStake } from './LiquidStake';
import { JewelStableStake } from './JewelStableStake';
import {HtmStake} from "./HtmStake";
import {MexStake} from "./MexStake";
import {UtkStake} from "./UtkStake";
import {ItheumStake} from "./ItheumStake";
import {TadaStake} from "./TadaStake";
import { XmexStake } from './XmexStake';
import { BtcStake } from './BtcStake';
import { EthStake } from './EthStake';
import { TaoStake } from './TaoStake';
import { ApusdcStake } from './ApusdcStake';

export const Stake = ({ isOverview = false }) => {
    const { tokenId } = useParams();
    const [stakeType, setStakeType] = useState<string>('');
    useEffect(() => {
        if (tokenId) {
            setStakeType(tokenId);
        } else {
            if (isOverview) {
                setStakeType('EGLD');
            } else {
                setStakeType('ASH');
            }
        }
    }, [tokenId]);

    const navigate = useNavigate();
    const handleRouter = (token: string) => {
        if (isOverview) {
            setStakeType(token);
        } else {
            navigate(`/stake/${token}`);
        }
    };

    return (
        <div className={isOverview ? '' : 'container'} style={{ marginTop: '40px' }}>
            <div className={isOverview ? '' : 'info-item'}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='title'>
                        <span>Stake</span>
                    </div>
                    {
                        !isOverview && (
                            <div className='egld-logo'>
                                <img src={egld_white_logo} />
                            </div>
                        )
                    }
                </div>
                <div className='d-flex mt-1 mb-4 align-items-center w-100 overflow-auto' style={{ height: '40px' }}>
                    <div className='mx-2 h-100 d-flex align-items-center' style={{ fontSize: '17px' }}>Token:</div>
                    <div className='d-flex ml-3'>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'EGLD' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('EGLD')}>EGLD</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'ASH' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('ASH')}>ASH</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'HTM' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('HTM')}>HTM</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'MEX' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('MEX')}>MEX</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'JWLUSD' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('JWLUSD')}>JWLUSD</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'UTK' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('UTK')}>UTK</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'ITHEUM' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('ITHEUM')}>ITHEUM</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'TADA' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('TADA')}>TADA</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'XMEX' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('XMEX')}>XMEX</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'WBTC' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('WBTC')}>WBTC</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'WETH' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('WETH')}>WETH</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'WTAO' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('WTAO')}>WTAO</div>
                        <div className={`mx-2 farm-dex-tab ${stakeType === 'APUSDC' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('APUSDC')}>APUSDC</div>
                    </div>
                </div>
                {
                    stakeType === 'ASH' && (
                        <AshStake />
                    )
                }
                {
                    stakeType === 'EGLD' && (
                        <LiquidStake />
                    )
                }
                {
                    stakeType === 'HTM' && (
                        <HtmStake />
                    )
                }
                {
                    stakeType === 'MEX' && (
                        <MexStake />
                    )
                }
                {
                    stakeType === 'JWLUSD' && (
                        <JewelStableStake />
                    )
                }

                {
                    stakeType === 'UTK' && (
                        <UtkStake />
                    )
                }
                {
                    stakeType === 'ITHEUM' && (
                        <ItheumStake />
                    )
                }
                {
                    stakeType === 'TADA' && (
                        <TadaStake />
                    )
                }
                {
                    stakeType === 'XMEX' && (
                        <XmexStake />
                    )
                }
                {
                    stakeType === 'WBTC' && (
                        <BtcStake />
                    )
                }
                {
                    stakeType === 'WETH' && (
                        <EthStake />
                    )
                }
                {
                    stakeType === 'WTAO' && (
                        <TaoStake />
                    )
                }
                {
                    stakeType === 'APUSDC' && (
                        <ApusdcStake />
                    )
                }
            </div>
        </div >
    );
};
