import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { queryHatomFarmCommonContext, queryHatomViewAllOpenedPositionIds, queryHatomViewFarms, queryHatomViewPositions, queryHatomViewPostionByIds, queryJewelExchangeFarmBaseContext, queryJewelExchangeFarms, queryJewelExchangeFarmTokenContext, queryJewelFlashMintCommonContext, queryJewelFlashMintViewAllOpenedLpPositionIds, queryJewelFlashMintViewAllOpenedPositionIds, queryJewelFlashMintViewFarms, queryJewelFlashMintViewLpFarms, queryJewelFlashMintViewLpPostionByIds, queryJewelFlashMintViewPostionByIds, queryLiquidStakStatsContext, queryOnedexFarmBaseContext, queryOnedexViewAllOpenedPostionIds, queryOnedexViewFarms, queryOnedexViewPostionByIds, queryViewAllOpenedPositionIds, queryViewJewelFarmBaseContext, queryViewJewelFarmBaseContextData, queryViewJewelFarmData, queryViewJewelFarms, queryViewJewelLendContext, queryViewPositionsByIds, queryViewPositionsSafetyContextByIds } from 'z/elrond';
import { AshswapFarm, AshswapFarmCommonSetting, AshswapPool, ElrondStatsType, EsdtTokenPayment, JewelExchangeFarmBaseContext, JewelExchangeFarmContext, JewelExchangeFarmTokenContext, JewelExchangePositionContext, JewelExchangePositionStateEnum, JewelFarmBaseContext, JewelFarmBaseContextData, JewelFarmContext, JewelFarmContextData, JewelFarmPosition, JewelFarmPositionSafetyContext, JewelFlashMintCommonContext, JewelFlashMintFarmContext, JewelFlashMintPositionContext, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelHatomPostionContext, JewelLendPool, JewelLpFarmContext, JewelOnedexFarmBaseContext, JewelOnedexFarmContext, JewelOnedexPostionContext, JewelswapFarm, NftWithIndexType } from 'z/types';
import { ASH_TOKEN_ID, isDev } from 'config';
import { JewelAshswapPositions } from './JewelAshswapPositions';
import { OnedexPositions } from './OnedexPositions';
import { calcHatomLiquidStakingAPY, FARM_MAX_PERCENT, getExchangeUnstakeFarmTokenAttributes, getExchangeWrappedFarmTokenAttributes, getLiquidStakeApy, hatomFormatRewards } from 'z/utils';
import { getAshswapCommonSetting, getAshswapFarms, getAshswapPools, getElrondStatsFromApi, getExchangePairs, getHatomControllerAddress, getHatomLiquidStakingApyInfo, getHatomMoneyMarket, getHatomRewardsBatch, getHatomTokenPrices, getHTokenExchangeRate, getJewelswapFarms, getNftsByCollectionFromApi, getNftsByCollectionsFromApi, getOnedexPairs, getTokenPriceFromAshswap, getUserTokenBalance } from 'z/api';
import { PROTOCOL_ESDT_TOKENS } from 'data';
import { HatomPositions } from './HatomPositions';
import DefiUtils from 'defi-utils';
import { JewelPositions } from './JewelPositions';
import { AshswapPositions } from './AshswapPositions';
import { JewelExchangePositions } from './JewelExchangePositions';
import { XexchangeFarmInfo, getXexchangeFarmsInfo, getXexchangePairInfo } from 'z/elrond/xexchange-api';
import { getChainId } from 'z/elrond/sdkDappHelpers';
export const MyPositions = () => {
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address, balance, shard } = useGetAccount();
  // const address = 'erd126rkz9fle5uc9l6z8hqzsfkvy50zy4a4me2748drg0re0pqx6gdq4vzz9z';
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();

  const { dex } = useParams();
  const [dexType, setDexType] = useState<string>('ashswap');
  useEffect(() => {
    if (dex) {
      setDexType(dex);
    } else {
      setDexType('ashswap');
    }
  }, [dex]);

  const navigate = useNavigate();
  const handleRouter = (dex: string) => {
    navigate(`/mypositions/${dex}`);
  };

  const [lendPools, setLendPools] = useState<JewelLendPool[]>([]);

  // TODO get ashswap farms(mainnet) from api
  const [ashswapCommonSetting, setAshswapCommonSetting] = useState<AshswapFarmCommonSetting | undefined>();
  const [ashswapFarms, setAshswapFarms] = useState<AshswapFarm[]>([]);
  const [ashswapTokens, setAshswapTokens] = useState<any>();
  useEffect(() => {
    if (isDev) return;

    (async () => {
      const commonSetting = await getAshswapCommonSetting();
      if (commonSetting) {
        // console.log('ashswap-commonSetting: ', commonSetting);
        setAshswapCommonSetting(commonSetting);
      }

      const _tokens = await getTokenPriceFromAshswap();
      // console.log('ashswap-tokens: ', _tokens);
      setAshswapTokens(_tokens);
    })();
  }, []);
  useEffect(() => {
    if (isDev) return;

    (async () => {
      const farms = await getAshswapFarms();
      // console.log('ashswap-farms: ', farms);
      setAshswapFarms(farms);
    })();
  }, []);

  // TODO get ashswap positions(devnet) from sc
  const [ashswapPools, setAshswapPools] = useState<AshswapPool[]>([]);
  const [jewelAshswapCommonSettings, setJewelAshswapCommonSettings] = useState<JewelFarmBaseContext | undefined>();
  const [jewelAshswapFarms, setJewelAshswapFarms] = useState<JewelFarmContext[]>([]);
  const [jewelAshswapFarmsData, setJewelAshswapFarmsData] = useState<JewelFarmContextData[]>([]);
  const [isAshswapPositionsLoading, setIsAshswapPositionsLoading] = useState<boolean>(false);
  const [ashswapOpenedPositions, setAshswapOpenedPositions] = useState<JewelFarmPosition[]>([]);
  const [ashswapPositionSafety, setAshswapPositionSafety] = useState<JewelFarmPositionSafetyContext[]>([]);
  const [ashPrice, setAshPrice] = useState<number>(0);

  useEffect(() => {
    if (hasPendingTransactions || !isLoggedIn || !isDev) return;

    (async () => {
      setIsAshswapPositionsLoading(true);

      const _ashswapPools = await getAshswapPools();
      // console.log('_ashswapPools: ', _ashswapPools);
      setAshswapPools(_ashswapPools);

      const _commonSettings = await queryViewJewelFarmBaseContext();
      // console.log('ashswapCommonSettings', _commonSettings);
      setJewelAshswapCommonSettings(_commonSettings);

      const _farms = await queryViewJewelFarms(apiAddress);
      setJewelAshswapFarms(_farms);

      const _farmData: JewelFarmContextData[] = [];
      if (_commonSettings) {
        const _tokens = _commonSettings.ashswap_tokens;
        for (const farm of _farms) {
          const _farmPool = await queryViewJewelFarmData(apiAddress, farm, _tokens);
          if (_farmPool) {
            _farmData.push(_farmPool);
          }
        }
      }
      setJewelAshswapFarmsData(_farmData);

      if (_commonSettings) {
        setAshPrice(_commonSettings.ashswap_tokens.data.tokens.filter((token: any) => token.id === ASH_TOKEN_ID)[0]['price']);
      }

      if (isLoggedIn) {
        const openedPositionIds = await queryViewAllOpenedPositionIds(apiAddress, address);
        //
        if (openedPositionIds.length > 0) {
          const _positions: JewelFarmPosition[] = await queryViewPositionsByIds(apiAddress, openedPositionIds);
          // console.log('ashswapOpendPositions: ', _positions);

          const positionSafety = await queryViewPositionsSafetyContextByIds(apiAddress, openedPositionIds);
          // console.log('ashswapPositionSafety: ', positionSafety);

          setAshswapPositionSafety(positionSafety);
          setAshswapOpenedPositions(_positions);
        } else {
          setAshswapPositionSafety([]);
          setAshswapOpenedPositions([]);
        }
      }

      setIsAshswapPositionsLoading(false);

    })();
  }, [hasPendingTransactions, isLoggedIn]);

  // TODO get ashswap positions(mainnet) from api
  useEffect(() => {
    if (isDev) return;

    (async () => {
      if (isLoggedIn) {
        setIsAshswapPositionsLoading(true);

        const openedPositionIds = await queryViewAllOpenedPositionIds(apiAddress, address);
        //
        if (openedPositionIds.length > 0) {
          const _positions: JewelFarmPosition[] = await queryViewPositionsByIds(apiAddress, openedPositionIds);
          // console.log('ashswapOpendPositions: ', _positions);

          const positionSafety = await queryViewPositionsSafetyContextByIds(apiAddress, openedPositionIds);
          // console.log('ashswapPositionSafety: ', positionSafety);

          setAshswapPositionSafety(positionSafety);
          setAshswapOpenedPositions(_positions);
        } else {
          setAshswapPositionSafety([]);
          setAshswapOpenedPositions([]);
        }

        setIsAshswapPositionsLoading(false);
      }
    })();
  }, [hasPendingTransactions, isLoggedIn]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _lendCommonSettings = await queryViewJewelLendContext(apiAddress);
      // console.log('_lendCommonSettings', _lendCommonSettings);
      if (_lendCommonSettings) {
        setLendPools(_lendCommonSettings.lendPool);
      }
    })();
  }, [hasPendingTransactions]);

  const [userTokens, setUserTokens] = useState<any>();
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const userTokensBalance: any = [];
      if (isLoggedIn) {
        const balances = await getUserTokenBalance(apiAddress, address, PROTOCOL_ESDT_TOKENS);
        // console.log('balances ', balances);

        userTokensBalance['EGLD'] = {
          balance: balance,
        };

        for (const item of balances) {
          userTokensBalance[item.identifier] = {
            balance: item.balance,
          };
        }
      }

      for (const item of PROTOCOL_ESDT_TOKENS) {
        if (!userTokensBalance[item]) {
          userTokensBalance[item] = {
            balance: '0',
          };
        }
      }

      // console.log('userTokensBalance: ', userTokensBalance);
      setUserTokens(userTokensBalance);
    })();
  }, [hasPendingTransactions, isLoggedIn]);

  // onedex
  const [isOnedexPositionsLoading, setIsOnedexPositionsLoading] = useState<boolean>(false);
  const [onedexFarmCommonSettings, setOnedexFarmCommonSettings] = useState<JewelOnedexFarmBaseContext | undefined>();
  const [onedexFams, setOnedexFarms] = useState<JewelOnedexFarmContext[]>([]);
  const [onedexOpenedPositions, setOnedexOpenedPositions] = useState<JewelOnedexPostionContext[]>([]);

  useEffect(() => {
    if (hasPendingTransactions || !isLoggedIn) return;

    (async () => {
      setIsOnedexPositionsLoading(true);
      const _onedexPairs = await getOnedexPairs();
      // console.log('onedex-pairs: ', _onedexPairs);

      const _exchangePairs = await getExchangePairs();
      // console.log('xexchange-pairs: ', _exchangePairs);

      const _onedexFarmCommonSettings = await queryOnedexFarmBaseContext();
      console.log('_onedexFarmCommonSettings', _onedexFarmCommonSettings);
      if (_onedexFarmCommonSettings) {
        setOnedexFarmCommonSettings(_onedexFarmCommonSettings);
      }

      const _onedexFarms = await queryOnedexViewFarms(apiAddress, _onedexPairs, _exchangePairs);
      console.log('_onedexFarms', _onedexFarms);
      setOnedexFarms(_onedexFarms);

      const _openedPositionIds = await queryOnedexViewAllOpenedPostionIds(address);
    console.log('onedex-openedPositionIds: ', _openedPositionIds);

      if (_openedPositionIds.length > 0) {
        const _openedPositions = await queryOnedexViewPostionByIds(_openedPositionIds);
        // console.log('onedex-positions', _openedPositions);
        setOnedexOpenedPositions(_openedPositions);
      } else {
        setOnedexOpenedPositions([]);
      }

      setIsOnedexPositionsLoading(false);

    })();
  }, [hasPendingTransactions, isLoggedIn]);

  const [isHatomPositionsLoading, setIsHatomPositionsLoading] = useState<boolean>(false);
  const [hatomFarmCommonSettings, setHatomFarmCommonSettings] = useState<JewelHatomFarmCommonContext | undefined>();
  const [hatomFarms, setHatomFarms] = useState<JewelHatomFarmContext[]>([]);
  const [hatomOpenedPositions, setHatomOpenedPositions] = useState<JewelHatomPostionContext[]>([]);
  const [hatomMoneyMarkets, setHatomMoneyMarkets] = useState<JewelHatomMoneyMarket[]>([]);
  const [hatomFormattedRewards, setHatomFormattedRewards] = useState<any[]>([]);
  useEffect(() => {
    if (hasPendingTransactions || !isLoggedIn) return;

    (async () => {
      setIsHatomPositionsLoading(true);

      const _hatomFarmCommonSettings = await queryHatomFarmCommonContext();
      // console.log('_hatomFarmCommonSettings', _hatomFarmCommonSettings);
      if (_hatomFarmCommonSettings) {
        setHatomFarmCommonSettings(_hatomFarmCommonSettings);
      }

      const _hatomFarms = await queryHatomViewFarms();
      // console.log('_hatomFarms: ', _hatomFarms);
      setHatomFarms(_hatomFarms);

      // get opened position ids of user
      const _openedPositionIds = await queryHatomViewAllOpenedPositionIds(address);
      // console.log('_openedPositionIds: ', _openedPositionIds);

      // get positions
      if (_openedPositionIds.length > 0) {
        const _positions = await queryHatomViewPostionByIds(_openedPositionIds);
        // console.log('_positions: ', _positions);
        setHatomOpenedPositions(_positions);
      } else {
        setHatomOpenedPositions([]);
      }

      setIsHatomPositionsLoading(false);
    })();
  }, [hasPendingTransactions, isLoggedIn]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      // reward apy

      const [
        controllerAddress,
        rewardsBatch,
        tokenPricesMap,
        hTokenExchangeRateMap,
      ] = await Promise.all([
        getHatomControllerAddress(),
        getHatomRewardsBatch(),
        getHatomTokenPrices(),
        getHTokenExchangeRate(),
      ]);
      // console.log('controllerAddress: ', controllerAddress);
      // console.log('rewardsBatch: ', rewardsBatch);
      // console.log('tokenPricesMap: ', tokenPricesMap);
      // console.log('hTokenExchangeRateMap: ', hTokenExchangeRateMap);

      if (!controllerAddress) return;
      const tokenIds: string[] = [];
      tokenIds.push(...rewardsBatch.map(({ moneyMarket }: any) => moneyMarket.hToken.id));
      const accountTokens = await getUserTokenBalance(
        apiAddress,
        controllerAddress,
        tokenIds
      );
      const hTokensIdsMap: any = accountTokens.reduce(
        (prev, { name, balance }) => ({
          ...prev,
          [`${name.replace("Hatom", "H")}-`]: balance,
        }),
        {}
      );
      const wrappedIdsMap: any = accountTokens.reduce(
        (prev, { name, balance }) => ({
          ...prev,
          [`${name.replace("Wrapped", "")}-`]: balance,
        }),
        {}
      );

      const totalCollateralTokensMap = tokenIds.reduce((prev, currentIdenfitier) => {
        const balanceByIdentifier = accountTokens.find(
          ({ identifier }) => identifier === currentIdenfitier
        )?.balance;
        const balanceByAsset = accountTokens.find(({ assets }) =>
          assets?.pngUrl?.includes(currentIdenfitier)
        )?.balance;
        const balanceByHToken: any =
          hTokensIdsMap[`${currentIdenfitier.split("-")?.[0] || ""}-`];

        const balanceByWrapped: any =
          wrappedIdsMap[`${currentIdenfitier.split("-")?.[0] || ""}-`];

        return {
          ...prev,
          [currentIdenfitier]:
            balanceByIdentifier ||
            balanceByAsset ||
            balanceByHToken ||
            balanceByWrapped ||
            "0",
        };
      }, {});
      // console.log('totalCollateralTokensMap: ', totalCollateralTokensMap);

      const formattedRewards = hatomFormatRewards({
        rewardsBatch,
        tokenPricesMap,
        hTokenExchangeRateMap,
        totalCollateralTokensMap,
      });
      // console.log('formattedRewards: ', formattedRewards);
      setHatomFormattedRewards(formattedRewards);
    })();
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      // supply, borrow apy
      const _hatomMoneyMarket = await getHatomMoneyMarket();
      if (_hatomMoneyMarket) {
        // console.log('_hatomMoneyMarket: ', _hatomMoneyMarket.data.queryMoneyMarket);
        setHatomMoneyMarkets(_hatomMoneyMarket.data.queryMoneyMarket);
      }
    })();
  }, [hasPendingTransactions]);

  const [isJewelFlashMintLoading, setIsFlashMintLoading] = useState<boolean>(false);
  const [jewelFlashMintCommonSettings, setJewelFlashMintCommonSettings] = useState<JewelFlashMintCommonContext | undefined>();
  const [jewelFlashMintFarms, setJewelFlashMintFarms] = useState<JewelFlashMintFarmContext[]>([]);
  const [jewelOpenedPositions, setJewelOpenedPositions] = useState<JewelFlashMintPositionContext[]>([]);
  const [jewelLpFarms, setJewelLpFarms] = useState<JewelLpFarmContext[]>([]);
  const [jewelswapFarms, setJewelswapFarms] = useState<JewelswapFarm[]>([]);
  const [jewelLpFarmPositionSafety, setJewelLpFarmPositionSafety] = useState<JewelFarmPositionSafetyContext[]>([]);
  const [liquidStakeApy, setLiquidStakeApy] = useState<string>('0');
  useEffect(() => {
    if (hasPendingTransactions || !isLoggedIn) return;

    (async () => {
      setIsFlashMintLoading(true);

      const _jewelFlashMintCommonSettings = await queryJewelFlashMintCommonContext();
      if (_jewelFlashMintCommonSettings) {
        setJewelFlashMintCommonSettings(_jewelFlashMintCommonSettings);
      }

      const _jewelFlashMintFarms = await queryJewelFlashMintViewFarms();
      setJewelFlashMintFarms(_jewelFlashMintFarms);

      // get opened position ids of user
      const _openedPositionIds = await queryJewelFlashMintViewAllOpenedPositionIds(address);

      // get positions
      let positions: JewelFlashMintPositionContext[] = [];
      if (_openedPositionIds.length > 0) {
        const _positions = await queryJewelFlashMintViewPostionByIds(_openedPositionIds, _jewelFlashMintFarms);
        positions = positions.concat(_positions);
      }

      const _jewelLpFarms = await queryJewelFlashMintViewLpFarms();
      // console.log('_jewelLpFarms: ', _jewelLpFarms);
      setJewelLpFarms(_jewelLpFarms);

      const _openedLpPositionIds = await queryJewelFlashMintViewAllOpenedLpPositionIds(address);

      if (_openedLpPositionIds.length > 0) {
        const _positions = await queryJewelFlashMintViewLpPostionByIds(_openedLpPositionIds, _openedPositionIds.length);
        positions = positions.concat(_positions);

        const positionSafety = await queryViewPositionsSafetyContextByIds(apiAddress, _openedLpPositionIds);
        setJewelLpFarmPositionSafety(positionSafety);
      } else {
        setJewelLpFarmPositionSafety([]);
      }

      // console.log('positions: ', positions);
      setJewelOpenedPositions(positions);

      setIsFlashMintLoading(false);
    })();
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _jewelswapFarms = await getJewelswapFarms();
      setJewelswapFarms(_jewelswapFarms);
    })();
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _liquidStakeStatsContext = await queryLiquidStakStatsContext();
      // console.log('_liquidStakeStatsContext', _liquidStakeStatsContext);

      if (_liquidStakeStatsContext) {

        const apy = getLiquidStakeApy(_liquidStakeStatsContext.jwlegld_pool_reserve, _liquidStakeStatsContext.sjwlegld_pool_reserve);
        setLiquidStakeApy(apy.toFixed(0));
      }
    })();
  }, [hasPendingTransactions]);

  // Jewel xExchange Farm
  const [isExchangeFarmLoading, setIsExchangeFarmLoading] = useState<boolean>(false);
  const [exchangeFarmCommonSettings, setExchangeFarmCommonSettings] = useState<JewelExchangeFarmBaseContext | undefined>();
  const [exchangeFarms, setExchangeFarms] = useState<JewelExchangeFarmContext[]>([]);
  const [networkConfig, setNetworkConfig] = useState<ElrondStatsType>();
  const [userActivePositions, setUserActivePositions] = useState<JewelExchangePositionContext[]>([]);
  const [userUnstakePositions, setUserUnstakePositions] = useState<JewelExchangePositionContext[]>([]);
  const [userUnbondPositions, setUserUnbondPositions] = useState<JewelExchangePositionContext[]>([]);
  const [exchangeFarmTokenContext, setExchangeFarmTokenContext] = useState<JewelExchangeFarmTokenContext | undefined>();
  const [exchangeFarmsInfo, setExchangeFarmsInfo] = useState<XexchangeFarmInfo[]>([]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      setIsExchangeFarmLoading(true);

      const exchange_farms = await queryJewelExchangeFarms();
      // console.log('exchange_farms: ', exchange_farms);
      setExchangeFarms(exchange_farms);

      const config = await getElrondStatsFromApi();
      if (config) {
        // console.log(config);
        setNetworkConfig(config);
      }

      const exchange_base_context = await queryJewelExchangeFarmBaseContext();
      // console.log('exchange_base_context', exchange_base_context);
      if (exchange_base_context) {
        setExchangeFarmCommonSettings(exchange_base_context);

        if (isLoggedIn) {
          const activePositions: JewelExchangePositionContext[] = [];
          const unstakePositions: JewelExchangePositionContext[] = [];
          const unbondPositions: JewelExchangePositionContext[] = [];
          const nfts = await getNftsByCollectionsFromApi(apiAddress, address, [exchange_base_context.wrapped_farm_token, exchange_base_context.unstake_farm_token]);

          const queryPayments: EsdtTokenPayment[] = [];
          const queryAttributes: any[] = [];
          for (let i = 0; i < nfts.length; i++) {
            const nft = nfts[i];
            queryPayments.push({
              token_identifier: nft.collection,
              token_nonce: nft.nonce,
              amount: nft.balance,
            });

            if (nft.collection === exchange_base_context.wrapped_farm_token) {
              // wrapped
              const attribute = getExchangeWrappedFarmTokenAttributes(nft);
              // console.log(attribute);
              // payments.push({
              //   token_identifier: nft.collection,
              //   token_nonce: nft.nonce,
              //   amount: nft.balance
              // });
              // attributes.push(attribute);

              activePositions.push({
                ...nft,
                farm_address: attribute.farm_address.toString(),
                farm_apy: 0,
                position_state: JewelExchangePositionStateEnum.Active,
                deposit_value: '0',
                unstake_epoch: 0,
                contextIndex: i,
              });

              queryAttributes.push(attribute);
            } else {
              // unstake
              const attribute = getExchangeUnstakeFarmTokenAttributes(nft);
              // console.log(attribute);

              const farm = exchange_farms.filter((item: JewelExchangeFarmContext) => item.farm_address === attribute.farm_address.toString());
              if (config && farm.length > 0) {
                if (config.epoch >= (Number(attribute.unstake_epoch) + farm[0].minimum_farming_epochs)) {
                  unbondPositions.push({
                    ...nft,
                    farm_address: attribute.farm_address.toString(),
                    farm_apy: 0,
                    position_state: JewelExchangePositionStateEnum.Unbond,
                    deposit_value: '0',
                    unstake_epoch: attribute.unstake_epoch,
                    contextIndex: i,
                  });
                } else {
                  unstakePositions.push({
                    ...nft,
                    farm_address: attribute.farm_address.toString(),
                    farm_apy: 0,
                    position_state: JewelExchangePositionStateEnum.Unstake,
                    deposit_value: '0',
                    unstake_epoch: attribute.unstake_epoch,
                    contextIndex: i,
                  });
                }

                queryAttributes.push({
                  farm_address: attribute.farm_address.toString(),
                  token_rps: '0',
                });
              }
            }
          }

          if (queryPayments.length > 0) {
            const farmTokenContexts = await queryJewelExchangeFarmTokenContext(address, queryPayments, queryAttributes);
            // console.log(farmTokenContexts);
            setExchangeFarmTokenContext(farmTokenContexts);
          }
          setUserActivePositions(activePositions);
          setUserUnstakePositions(unstakePositions);
          setUserUnbondPositions(unbondPositions);
        }
      }

      setIsExchangeFarmLoading(false);
    })();
  }, [hasPendingTransactions]);

  useEffect(() => {
    (async () => {
      const _farms = await queryJewelExchangeFarms();
      const _farmsInfo = await getXexchangeFarmsInfo();
      
      // filter listed farms
      const _filteredFarmsInfo = [];
      for (const farmInfo of _farmsInfo) {
        const _farm = _farms.find((farm) => farm.farm_address == farmInfo.address);
        if (_farm) {
          const _pairInfo = await getXexchangePairInfo(_farm.pair_address);
          if (_pairInfo) {
            farmInfo.totalApr = farmInfo.maxBoostedApr + _pairInfo.feesAPR;
          }
          _filteredFarmsInfo.push(farmInfo);
        }
      }
      setExchangeFarmsInfo(_farmsInfo);
    })();
  }, []);

  return (
    <>
      <div className='container ' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>My Positions</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          <div className='d-flex mt-3 mb-1 align-items-center w-100 overflow-auto' style={{ height: '40px' }}>
            <div className='mx-2 h-100 d-flex align-items-center' style={{ fontSize: '17px' }}>DEX:</div>
            <div className='d-flex ml-3'>
              <div className={`mx-2 farm-dex-tab ${dexType === 'ashswap' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('ashswap')}>Ashswap</div>
              <div className={`mx-2 farm-dex-tab ${dexType === 'onedex' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('onedex')}>OneDex</div>
              <div className={`mx-2 farm-dex-tab ${dexType === 'hatom' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('hatom')}>Hatom</div>
              <div className={`mx-2 farm-dex-tab ${dexType === 'jewelswap' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('jewelswap')}>JewelSwap</div>
              <div className={`mx-2 farm-dex-tab ${dexType === 'xexchange' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('xexchange')}>xExchange</div>
            </div>
          </div>

          {
            userTokens && dexType === 'ashswap' && isDev && (
              <JewelAshswapPositions
                lendPools={lendPools}
                userTokens={userTokens}
                ashswapPools={ashswapPools}
                commonSettings={jewelAshswapCommonSettings}
                farms={jewelAshswapFarms}
                farmsData={jewelAshswapFarmsData}
                isPositionsLoading={isAshswapPositionsLoading}
                openedPositions={ashswapOpenedPositions}
                positionSafety={ashswapPositionSafety}
                ashPrice={ashPrice}
              />
            )
          }

          {
            userTokens && dexType === 'ashswap' && !isDev && (
              <AshswapPositions
                commonSettings={ashswapCommonSetting}
                farms={ashswapFarms}
                lendPools={lendPools}
                userTokens={userTokens}
                openedPositions={ashswapOpenedPositions}
                positionSafety={ashswapPositionSafety}
                ashswapTokens={ashswapTokens}
                isPositionsLoading={isAshswapPositionsLoading}
              />
            )
          }

          {
            dexType === 'onedex' && (
              <OnedexPositions
                commonSettings={onedexFarmCommonSettings}
                farms={onedexFams}
                isPositionsLoading={isOnedexPositionsLoading}
                openedPositions={onedexOpenedPositions}
              />
            )
          }

          {
            dexType === 'hatom' && (
              <HatomPositions
                commonSettings={hatomFarmCommonSettings}
                farms={hatomFarms}
                hatomMoneyMarkets={hatomMoneyMarkets}
                hatomFormattedRewards={hatomFormattedRewards}
                isPositionsLoading={isHatomPositionsLoading}
                openedPositions={hatomOpenedPositions}
              />
            )
          }

          {
            dexType === 'jewelswap' && (
              <JewelPositions
                commonSettings={jewelFlashMintCommonSettings}
                farms={jewelFlashMintFarms}
                lpFarms={jewelLpFarms}
                jewelswapFarms={jewelswapFarms}
                hatomMoneyMarkets={hatomMoneyMarkets}
                liquidStakeApy={liquidStakeApy}
                hatomFormattedRewards={hatomFormattedRewards}
                isPositionsLoading={isJewelFlashMintLoading}
                openedPositions={jewelOpenedPositions}
                jewelLpFarmPositionSafety={jewelLpFarmPositionSafety}
              />
            )
          }

          {
            networkConfig && dexType === 'xexchange' && (
              <JewelExchangePositions
                commonSettings={exchangeFarmCommonSettings}
                farms={exchangeFarms}
                networkConfig={networkConfig}
                userActivePositions={userActivePositions}
                userUnstakePositions={userUnstakePositions}
                userUnbondPositions={userUnbondPositions}
                isPositionsLoading={isExchangeFarmLoading}
                farmTokenContext={exchangeFarmTokenContext}
                exchangeFarmsInfo={exchangeFarmsInfo}
              />
            )
          }
        </div>
      </div>
    </>
  );
};
