import React, { useEffect, useState } from 'react';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { animated, useSpring } from 'react-spring';
import { Backdrop, Box, InputAdornment, InputBase, Modal, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import { isMobile } from 'react-device-detect';
import { FarmPool } from 'components/FarmPool';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import BigNumber from 'bignumber.js';
import {
    jewelStakeSmartContract,
    queryStakeBaseContext,
    queryStakeViewAllEpochs,
    queryStakeViewUserContext,
    queryStakeViewUserEpochs,
    queryViewJewelFarmBaseContextData,
    queryViewJewelLendContext,
    sendJewelClaimReward,
    sendJewelLock,
    sendJewelStake,
    sendJewelUnstake
} from 'z/elrond';
import { JewelFarmBaseContextData, JewelFarmContextData, JewelLendPool, JewelStakeBaseContext, JewelStakeEpochContext, JewelStakeUserContext, JewelStakeUserEpochContext } from 'z/types';
import { Spinner } from 'components/Spinner';
import { StakePool } from 'components/StakePool';
import { StakePoolHeader } from 'components/StakePoolHeader';
import { StakeInput } from './StakeInput';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertSecondsToPrintDateTimeString, convertWeiToEsdt, ERROR_CONNECT_YOUR_WALLET, ERROR_INVALID_VALUE, ERROR_NOT_ENOUGH_BALANCE, ERROR_VALUE_CANNOT_BE_NEGATIVE, getApr, getApy, getFarmApy, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, parseBigNumber, SECOND_TO_MILLISECONDS, toastError } from 'z/utils';
import ash_logo from 'assets/img/coin/ash.png';

import './index.scss';
import { getUserTokenBalance, getXexchangeTokenPrice } from 'z/api';
import {ASH_TOKEN_ID, JEWEL_STAKE_SC_ADDRESS} from 'config';
import { getChainId } from 'z/elrond/sdkDappHelpers';
export const AshStake = () => {

    const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
    const { address, balance } = useGetAccount();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [commonSettings, setCommonSettings] = useState<JewelStakeBaseContext>();
    const [currentEpochContext, setCurrentEpochContext] = useState<JewelStakeEpochContext>();
    const [epochAshRewardAmount, setEpochAshRewardAmount] = useState<string>('0');
    const [allEpochs, setAllEpochs] = useState<JewelStakeEpochContext[]>([]);
    const [userContext, setUserContext] = useState<JewelStakeUserContext>();
    const [userEpochs, setUserEpochs] = useState<JewelStakeUserEpochContext[]>([]);
    const [userAshBalance, setUserAshBalance] = useState<string>('0');
    const [userJwlashBalance, setUserJwlashBalance] = useState<string>('0');
    const [userAccumulatedRewardTokens, setUserAccumulatedRewardTokens] = useState<string[]>([]);
    const [userAccumulatedRewardAmounts, setUserAccumulatedRewardAmounts] = useState<string[]>([]);
    const [userClaimableRewardEpochs, setUserClaimableRewardEpochs] = useState<number[]>([]);
    const [apy, setApy] = useState<string>('-');

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const [inputAmount, setInputAmount] = useState<string>('0');
    const [amountError, setAmountError] = useState<string>('');
    const [stakeInputAmount, setStakeInputAmount] = useState<string>('0');
    const [stakeAmountError, setStakeAmountError] = useState<string>('');
    const [unstakeInputAmount, setUnstakeInputAmount] = useState<string>('0');
    const [unstakeAmountError, setUnstakeAmountError] = useState<string>('');
    const [isStakeAfterLock, setIsStakeAfterLock] = useState<boolean>(true);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            setIsLoading(true);
            const _commonSettings = await queryStakeBaseContext(jewelStakeSmartContract);
            // console.log('_commonSettings', _commonSettings);

            if (_commonSettings) {
                setCommonSettings(_commonSettings);

                const _allEpochs: JewelStakeEpochContext[] = await queryStakeViewAllEpochs(jewelStakeSmartContract);
                // console.log('_allEpochs: ', _allEpochs);
                setAllEpochs(_allEpochs);

                const epochContexts = _allEpochs.filter((item: JewelStakeEpochContext) => item.epoch_id === _commonSettings.current_epoch_id);
                if (epochContexts.length > 0) {
                    // console.log('_currentEpochContext: ', epochContexts[0]);
                    setCurrentEpochContext(epochContexts[0]);

                    // epoch rewards
                    let epochAshRewards = new BigNumber('0');
                    for (let i = 0; i < epochContexts[0].epoch_reward_tokens.length; i++) {
                        if (getTokenTicker(epochContexts[0].epoch_reward_tokens[i]) === 'ASH' || getTokenTicker(epochContexts[0].epoch_reward_tokens[i]) === 'JWLASH') {
                            epochAshRewards = epochAshRewards.plus(epochContexts[0].epoch_reward_amounts[i]);
                        }
                    }
                    setEpochAshRewardAmount(epochAshRewards.toFixed(0));

                    const _apr = getApr(epochAshRewards.toFixed(0), _commonSettings.total_stake_amount, epochContexts[0].epoch_start_timestamp);
                    const _apy = getApy(_apr, 52);
                    setApy(convertBigNumberValueToLocalString(_apy, 2));
                }

                if (isLoggedIn) {
                    // ash token balance
                    const _tokenBalances = await getUserTokenBalance(apiAddress, address, [_commonSettings.ash_token, _commonSettings.jwlash_token]);
                    // console.log('_tokenBalances: ', _tokenBalances);

                    let _ashAmount = '0';
                    let _jwlashAmount = '0';
                    for (let i = 0; i < _tokenBalances.length; i++) {
                        const _tokenBalance = _tokenBalances[i];
                        if (_tokenBalance.identifier === _commonSettings.ash_token) {
                            if (_tokenBalance.balance) _ashAmount = _tokenBalance.balance;
                        }

                        if (_tokenBalance.identifier === _commonSettings.jwlash_token) {
                            if (_tokenBalance.balance) _jwlashAmount = _tokenBalance.balance;
                        }
                    }

                    setUserAshBalance(_ashAmount);
                    setUserJwlashBalance(_jwlashAmount);
                }
            }
            setIsLoading(false);
        })();
    }, [hasPendingTransactions, address]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            if (isLoggedIn) {
                const _userContext = await queryStakeViewUserContext(address, jewelStakeSmartContract);
                // console.log('_userContext: ', _userContext);
                if (_userContext) {
                    setUserContext(_userContext);
                }

                const _userEpochs: JewelStakeUserEpochContext[] = await queryStakeViewUserEpochs(address, jewelStakeSmartContract);
                // console.log('_userEpochs: ', _userEpochs);
                setUserEpochs(_userEpochs);

                const _userRewardTokens: string[] = [];
                const _userRewardAmounts: string[] = [];
                const _userClaimableEpochs = [];
                for (let i = 0; i < _userEpochs.length; i++) {
                    const _userEpoch = _userEpochs[i];

                    if (_userEpoch.claimable && !_userEpoch.claimed) {
                        for (let j = 0; j < _userEpoch.rewards_tokens.length; j++) {
                            const _epochRewardAmount = _userEpoch.rewards_amounts[j];
                            if (new BigNumber(_epochRewardAmount).gt('0')) {
                                _userClaimableEpochs.push(_userEpoch.epoch_id);
                                break;
                            }
                        }

                        for (let j = 0; j < _userEpoch.rewards_tokens.length; j++) {
                            const _epochRewardToken = _userEpoch.rewards_tokens[j];
                            const _epochRewardAmount = _userEpoch.rewards_amounts[j];

                            if (_userRewardTokens.includes(_epochRewardToken)) {
                                const index = _userRewardTokens.indexOf(_epochRewardToken);
                                _userRewardAmounts[index] = new BigNumber(_userRewardAmounts[index]).plus(_epochRewardAmount).toFixed();
                            } else {
                                _userRewardTokens.push(_epochRewardToken);
                                _userRewardAmounts.push(_epochRewardAmount);
                            }
                        }
                    }
                }

                setUserAccumulatedRewardTokens(_userRewardTokens);
                setUserAccumulatedRewardAmounts(_userRewardAmounts);
                setUserClaimableRewardEpochs(_userClaimableEpochs);
            }
        })();
    }, [hasPendingTransactions, address]);

    let lockReleaseDate: Date | undefined;
    if (commonSettings && userContext) {
        lockReleaseDate = new Date((userContext.user_last_stake_timestamp + commonSettings.lock_period) * SECOND_TO_MILLISECONDS);
    }

    const handleExpand = () => {
        cleanAmount();

        setIsExpanded(!isExpanded);
    };

    const cleanAmount = () => {
        setInputAmount('0');
        setAmountError('');
        setStakeInputAmount('0');
        setStakeAmountError('');
        setUnstakeInputAmount('0');
        setUnstakeAmountError('');
    };

    const onLock = async () => {
        if (!commonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (new BigNumber(inputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (amountError) {
            toastError(amountError);
            return;
        }

        await sendJewelLock(chainID, address, commonSettings.ash_token, convertEsdtToWei(inputAmount, getTokenDecimal(commonSettings.ash_token)).toFixed(), isStakeAfterLock, JEWEL_STAKE_SC_ADDRESS);

        cleanAmount();

    };

    const onStake = async () => {
        if (!commonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (new BigNumber(stakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (stakeAmountError) {
            toastError(stakeAmountError);
            return;
        }

        await sendJewelStake(chainID, address, commonSettings.jwlash_token, convertEsdtToWei(stakeInputAmount, getTokenDecimal(commonSettings.jwlash_token)).toFixed(), JEWEL_STAKE_SC_ADDRESS);

        cleanAmount();
    };

    const onUnstake = async () => {
        if (!commonSettings) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (lockReleaseDate && new Date() < lockReleaseDate) {
            toastError(`You cannot unstake before ${lockReleaseDate.toLocaleString()}`);
            return;
        }

        if (new BigNumber(unstakeInputAmount).comparedTo(0) <= 0) {
            toastError(ERROR_INVALID_VALUE);
            return;
        }

        if (unstakeAmountError) {
            toastError(unstakeAmountError);
            return;
        }

        await sendJewelUnstake(chainID, address, convertEsdtToWei(unstakeInputAmount, getTokenDecimal(commonSettings.jwlash_token)).toFixed(), JEWEL_STAKE_SC_ADDRESS);

        cleanAmount();

    };

    const onClaim = async () => {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (userClaimableRewardEpochs.length == 0) {
            return;
        }

        await sendJewelClaimReward(chainID, address, userClaimableRewardEpochs, JEWEL_STAKE_SC_ADDRESS);

    };

    const passedMilliseconds = currentEpochContext ? Date.now() - currentEpochContext.epoch_start_timestamp * SECOND_TO_MILLISECONDS : 0;
    const leftMilliseconds = commonSettings && currentEpochContext ? Math.max((currentEpochContext.epoch_end_timestamp) * SECOND_TO_MILLISECONDS - Date.now(), 0) : 0;
    const progressBarFullPercentage = currentEpochContext ? passedMilliseconds / ((currentEpochContext.epoch_end_timestamp - currentEpochContext.epoch_start_timestamp) * SECOND_TO_MILLISECONDS) * 100 : 0;

    return (
        <div>
            <div className='d-flex mt-2'>
                {`Convert ASH to JWLASH. By staking JWLASH, you're earning a share of the LP's boosted ASH earnings.`}
            </div>
            <div className='d-flex mt-1'>
                <span>
                    {`You may stake and unstake JWLASH tokens, but not convert them back to ASH at JewelSwap. A dynamic % will also be sent for LP creation. You can now swap ASH-JWLASH at `}
                    <a href='https://app.ashswap.io/swap/' target='_blank' rel='noreferrer'>Ashswap.</a>
                </span>
            </div>
            <div className='text-center mt-4' style={{ color: 'white' }}>
                <h4 className='text-center text-primary'>Epoch {commonSettings ? commonSettings.current_epoch_id : '-'}</h4>
                <h6 className='mt-4 mb-3'>{commonSettings ? convertSecondsToPrintDateTimeString(leftMilliseconds / SECOND_TO_MILLISECONDS) : '-'} left until next rewards distribution</h6>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuenow={progressBarFullPercentage} aria-valuemin={0} aria-valuemax={100} style={{ width: `${progressBarFullPercentage}%` }} />
                </div>
                <div className={`d-flex mt-3 ${isMobile ? 'justify-content-between' : 'justify-content-center'}`}>
                    <div className={isMobile ? '' : `mx-3 align-self-center`}>Epoch Rewards:</div>
                    <div className='align-self-center'>
                        {
                            currentEpochContext ? (
                                currentEpochContext.epoch_reward_tokens.map((token: string, index: number) => {
                                    if (getTokenTicker(token) !== 'ASH') {
                                        if (getTokenTicker(token) === 'JWLASH') {
                                            return <div className='' key={index}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(epochAshRewardAmount, getTokenDecimal(token)))} ${getTokenTicker(token)}`}</div>;
                                        } else {
                                            return <div className='' key={index}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(currentEpochContext.epoch_reward_amounts[index], getTokenDecimal(token)))} ${getTokenTicker(token)}`}</div>;
                                        }
                                    }
                                })
                            ) : '-'
                        }
                    </div>
                </div>
            </div>
            {/* <StakePoolHeader /> */}
            {isLoading ? (
                <div className='mt-5'>
                    <Spinner />
                </div>
            ) : (
                <div className='row mt-3' style={{ justifyContent: "center" }}>
                    <Accordion
                        expanded={isExpanded}
                    >
                        <AccordionSummary
                            className={`col-12`}
                            sx={{ cursor: 'auto !important' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={ash_logo} className='egld-image mx-2' alt='ash'></img>
                                        <div>
                                            <div>{`ASH`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-lg-1 col-md-1 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-3' : 'align-self-center'}`}>
                                    <div>{`APY`}</div>
                                    <div className='avaliable-color'>{`${apy} %`}</div>
                                </div>
                                <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-1' : 'align-self-center'}`}>
                                    <div>{`TVL`}</div>
                                    <div className=''>{commonSettings ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.total_stake_amount, getTokenDecimal(commonSettings.ash_token)))} ${getTokenTicker(commonSettings.ash_token)}` : '-'}</div>
                                </div>
                                <div className={`col-lg-4 col-md-4 col-sm-12 align-self-center`}>
                                    <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                        <div className='d-flex align-items-center'>Your balance.</div>
                                        <div className='text-end'>
                                            <div>{commonSettings ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(userAshBalance, getTokenDecimal(commonSettings.ash_token)))} ${getTokenTicker(commonSettings.ash_token)}` : '-'}</div>
                                            <div>{commonSettings ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(userJwlashBalance, getTokenDecimal(commonSettings.jwlash_token)))} ${getTokenTicker(commonSettings.jwlash_token)}` : '-'}</div>
                                        </div>
                                    </div>
                                    <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                        <div>Staked balance.</div>
                                        <div>{commonSettings && userContext ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(userContext.user_stake_amount, getTokenDecimal(commonSettings.jwlash_token)))} ${getTokenTicker(commonSettings.jwlash_token)}` : '-'}</div>
                                    </div>
                                    <div className={`d-flex justify-content-between align-items-center ${isMobile ? 'mt-1' : ''}`}>
                                        <div className='d-flex align-items-center'>Expected Rewards.</div>
                                        <div className='text-end'>
                                            {currentEpochContext && userContext && commonSettings ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(new BigNumber(epochAshRewardAmount).multipliedBy(userContext.user_stake_amount).dividedBy(commonSettings.total_stake_amount), getTokenDecimal(commonSettings.jwlash_token)))} ${getTokenTicker(commonSettings.jwlash_token)}` : '-'}
                                        </div>
                                        {/* <div className='text-end'>
                                                {userAccumulatedRewardTokens.length > 0 ? (
                                                    userAccumulatedRewardTokens.map((token: string, index: number) => (
                                                        <div key={index} className='mt-1'>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userAccumulatedRewardAmounts[index], getTokenDecimal(token)))} ${getTokenTicker(token)}`}</div>
                                                    ))
                                                ) : '-'}
                                            </div> */}
                                    </div>
                                </div>
                                <div className='col-lg-1 col-md-1 col-se-12'></div>
                                <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                    <div
                                        className="px-2 py-2 d-flex justify-content-center align-items-center"
                                        style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }} onClick={handleExpand}
                                    >
                                        <div className='d-flex'>Stake / Unstake</div>
                                        {isExpanded ? (
                                            <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                        ) : (
                                            <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            className='row'
                        >
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className='d-flex'>
                                    {`Avail ASH. Balance: ${commonSettings ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(userAshBalance, getTokenDecimal(commonSettings.ash_token)))} ${getTokenTicker(commonSettings.ash_token)}` : '-'}`}
                                </div>
                                {isMobile && (
                                    <>
                                        <StakeInput
                                            userBalance={userAshBalance}
                                            inputAmount={inputAmount}
                                            amountError={amountError}
                                            setInputAmount={setInputAmount}
                                            setAmountError={setAmountError}
                                            isCheckBox={true}
                                            isStakeAfterLock={isStakeAfterLock}
                                            setIsStakeAfterLock={setIsStakeAfterLock}
                                            isUnstake={false}
                                            lockReleaseDate={lockReleaseDate}
                                            tokenId={commonSettings ? commonSettings.jwlash_token : ''}
                                        />
                                        <div className={`d-flex mt-3`}>
                                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onLock}>
                                                {isStakeAfterLock ? 'Convert & Stake' : 'Convert'}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={`col-lg-3 col-md-3 col-sm-12 ${isMobile ? 'mt-4' : ''}`}>
                                <div className='d-flex'>
                                    {`Avail JWLASH. Balance: ${commonSettings ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(userJwlashBalance, getTokenDecimal(commonSettings.jwlash_token)))} ${getTokenTicker(commonSettings.jwlash_token)}` : '-'}`}
                                </div>
                                {isMobile && (
                                    <>
                                        <StakeInput
                                            userBalance={userJwlashBalance}
                                            inputAmount={stakeInputAmount}
                                            amountError={stakeAmountError}
                                            setInputAmount={setStakeInputAmount}
                                            setAmountError={setStakeAmountError}
                                            isCheckBox={false}
                                            isStakeAfterLock={isStakeAfterLock}
                                            setIsStakeAfterLock={setIsStakeAfterLock}
                                            isUnstake={false}
                                            lockReleaseDate={lockReleaseDate}
                                            tokenId={commonSettings ? commonSettings.jwlash_token : ''}
                                        />
                                        <div className={`d-flex mt-3`}>
                                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onStake}>
                                                {`Stake`}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={`col-lg-3 col-md-3 col-sm-12 ${isMobile ? 'mt-4' : ''}`}>
                                <div className='d-flex'>
                                    {`Staked JWLASH. Balance: ${commonSettings && userContext ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(userContext.user_stake_amount, getTokenDecimal(commonSettings.jwlash_token)))} ${getTokenTicker(commonSettings.jwlash_token)}` : '-'}`}
                                </div>
                                {isMobile && (
                                    <>
                                        <StakeInput
                                            userBalance={userContext ? userContext.user_stake_amount : '0'}
                                            inputAmount={unstakeInputAmount}
                                            amountError={unstakeAmountError}
                                            setInputAmount={setUnstakeInputAmount}
                                            setAmountError={setUnstakeAmountError}
                                            isCheckBox={false}
                                            isStakeAfterLock={isStakeAfterLock}
                                            setIsStakeAfterLock={setIsStakeAfterLock}
                                            isUnstake={true}
                                            lockReleaseDate={lockReleaseDate}
                                            tokenId={commonSettings ? commonSettings.jwlash_token : ''}
                                        />
                                        <div className='d-flex mt-3'>
                                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onUnstake}>
                                                {`Unstake`}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {isMobile && (<hr className='mt-3'></hr>)}
                            <div className={`col-lg-3 col-md-3 col-sm-12 ${isMobile ? 'mt-4' : ''}`}>
                                <div className='d-flex justify-content-center'>
                                    <h4>{`Total Rewards`}</h4>
                                </div>
                                {isMobile && (
                                    <>
                                        <div className='text-center mt-2'>
                                            {userAccumulatedRewardTokens.length > 0 ? (
                                                userAccumulatedRewardTokens.map((token: string, index: number) => (
                                                    <div key={index} className='mt-1'>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userAccumulatedRewardAmounts[index], getTokenDecimal(token)))} ${getTokenTicker(token)}`}</div>
                                                ))
                                            ) : (
                                                <div className='mt-1'>{`-`}</div>
                                            )}
                                        </div>
                                        <div className='d-flex justify-content-center mt-4'>
                                            <div className={`px-4 py-2 ${userAccumulatedRewardAmounts.length > 0 && new BigNumber(userAccumulatedRewardAmounts[0]).gt('0') ? 'eg-btn btn--primary2 capsule' : 'btn-disalbe'}`} style={{ cursor: 'pointer' }} onClick={onClaim}>
                                                {`Claim`}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {!isMobile && (
                                <>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <StakeInput
                                            userBalance={userAshBalance}
                                            inputAmount={inputAmount}
                                            amountError={amountError}
                                            setInputAmount={setInputAmount}
                                            setAmountError={setAmountError}
                                            isCheckBox={true}
                                            isStakeAfterLock={isStakeAfterLock}
                                            setIsStakeAfterLock={setIsStakeAfterLock}
                                            isUnstake={false}
                                            lockReleaseDate={lockReleaseDate}
                                            tokenId={commonSettings ? commonSettings.jwlash_token : ''}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <StakeInput
                                            userBalance={userJwlashBalance}
                                            inputAmount={stakeInputAmount}
                                            amountError={stakeAmountError}
                                            setInputAmount={setStakeInputAmount}
                                            setAmountError={setStakeAmountError}
                                            isCheckBox={false}
                                            isStakeAfterLock={isStakeAfterLock}
                                            setIsStakeAfterLock={setIsStakeAfterLock}
                                            isUnstake={false}
                                            lockReleaseDate={lockReleaseDate}
                                            tokenId={commonSettings ? commonSettings.jwlash_token : ''}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <StakeInput
                                            userBalance={userContext ? userContext.user_stake_amount : '0'}
                                            inputAmount={unstakeInputAmount}
                                            amountError={unstakeAmountError}
                                            setInputAmount={setUnstakeInputAmount}
                                            setAmountError={setUnstakeAmountError}
                                            isCheckBox={false}
                                            isStakeAfterLock={isStakeAfterLock}
                                            setIsStakeAfterLock={setIsStakeAfterLock}
                                            isUnstake={true}
                                            lockReleaseDate={lockReleaseDate}
                                            tokenId={commonSettings ? commonSettings.jwlash_token : ''}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                        <div className='text-center mt-2'>
                                            {userAccumulatedRewardTokens.length > 0 ? (
                                                userAccumulatedRewardTokens.map((token: string, index: number) => (
                                                    <div key={index} className='mt-1'>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userAccumulatedRewardAmounts[index], getTokenDecimal(token)))} ${getTokenTicker(token)}`}</div>
                                                ))
                                            ) : (
                                                <div className='mt-1'>{`-`}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                        <div className={`d-flex`}>
                                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onLock}>
                                                {isStakeAfterLock ? 'Convert & Stake' : 'Convert'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                        <div className='d-flex'>
                                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onStake}>
                                                {`Stake`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                        <div className='d-flex'>
                                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onUnstake}>
                                                {`Unstake`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 mt-2'>
                                        <div className='d-flex justify-content-center'>
                                            <div className={`px-4 py-2 ${userAccumulatedRewardAmounts.length > 0 && new BigNumber(userAccumulatedRewardAmounts[0]).gt('0') ? 'eg-btn btn--primary2 capsule' : 'btn-disalbe'}`} style={{ cursor: 'pointer' }} onClick={onClaim}>
                                                {`Claim`}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
        </div>
    );
};
