import React, { useEffect, useState, useContext } from 'react';
import { useGetNetworkConfig, useGetPendingTransactions, useGetAccount } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { LATEST_LOAN_SHOW_SIZE, TOP_TRADE_COLLECTIONS_COUNT, NFT_AMM_SC_ADDRESS } from 'config';
import { queryCommonSettings, queryViewLatestLoans } from 'z/elrond';
import { CommonSettingsType, LendUserDataType, LoanType, NftCollection, PoolActivity, SwapTypeEnum, TvlContext } from 'z/types';
import { SECOND_TO_MILLISECONDS } from 'z/utils/constant';
import { convertBigNumberValueToLocalString } from 'z/utils/number';
import { convertSecondsToPrintDateTimeString } from 'z/utils/time';
import { convertWeiToEsdt, createNftId } from 'z/utils/token';
import { getApr, getApy, sleep } from "../../z/utils";
import { AmmCollectionContext } from 'App';
import { TradedColllectionsCard } from 'components/CollectionCard';
import { getTransactions, getTvl } from 'z/api';
import { Stake } from 'pages/Stake';

// const tabs = ['Latest Borrowers', 'Latest Transactions'];
const tabs = ['Latest Borrowers'];
export const Dashboard = () => {
    const {
        network: { apiAddress },
    } = useGetNetworkConfig();
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [tvl, setTvl] = useState<TvlContext>();
    useEffect(() => {
        (async () => {
            const _tvl = await getTvl();
            if (_tvl) {
                // console.log('tvl: ', _tvl);
                setTvl(_tvl);
            }
        })();
    }, []);

    // const { tvl, nftVolume, ammCollections, latestActivities } = useContext(AmmCollectionContext);

    // const [filterCollections, setFilterCollections] = useState<NftCollection[]>(ammCollections);
    // useEffect(() => {
    //     const collections: NftCollection[] = [];
    //     let count = ammCollections.length;
    //     if (ammCollections.length > TOP_TRADE_COLLECTIONS_COUNT) {
    //         count = TOP_TRADE_COLLECTIONS_COUNT;
    //     }
    //     for (let i = 0; i < count; i++) {
    //         collections.push(ammCollections[i]);
    //     }
    //     setFilterCollections(collections);
    // }, [ammCollections]);

    // const [tab, setTab] = useState<number>(0);
    // const [commonSettings, setCommonSettings] = useState<CommonSettingsType | undefined>();
    // const [latestLoans, setLatestLoans] = useState<LoanType[]>([]);
    // const [latestLenders, setLatestLenders] = useState<LendUserDataType[]>([]);
    // const [apy, setApy] = useState<string>('-');

    // useEffect(() => {
    //     if (hasPendingTransactions) return;

    //     (async () => {
    //         const _commonSettings = await queryCommonSettings(apiAddress, address);
    //         // console.log('_commonSettings', _commonSettings);
    //         setCommonSettings(_commonSettings);
    //         if (_commonSettings) {
    //             const _apr = getApr(_commonSettings.lend_rewards_pool_amount, _commonSettings.total_lending_amount, _commonSettings.lend_epoch_start_timestamp);
    //             const _apy = getApy(_apr);
    //             setApy(convertBigNumberValueToLocalString(_apy, 2));
    //             // const _lenders = _commonSettings.lend_user_data;
    //             // _lenders.sort((a: LendUserDataType, b: LendUserDataType) => b.user_last_lend_timestamp - a.user_last_lend_timestamp);
    //             // // console.log('_lenders: ', _lenders);
    //             // let count = LATEST_LOAN_SHOW_SIZE;
    //             // if (count > _lenders.length) {
    //             //     count = _lenders.length;
    //             // }
    //             // const items: LendUserDataType[] = [];
    //             // for (let i = 0; i < count; i++) {
    //             //     items.push(_lenders[i]);
    //             // }
    //             // setLatestLenders(items);
    //         }
    //     })();
    //     (async () => {
    //         let _latestLoans = await queryViewLatestLoans(apiAddress, LATEST_LOAN_SHOW_SIZE);
    //         // console.log('_latestLoans', _latestLoans);
    //         _latestLoans = _latestLoans.reverse();
    //         setLatestLoans(_latestLoans);
    //     })();
    // }, [hasPendingTransactions]);

    // const [backendSettings, setBackendSettings] = useState<BackendSettingsType | null>(null);
    // const [commonSettings, setcommonSettings] = useState<commonSettingsType | null>(null);
    // const [latestLoanActions, setLatestLoanActions] = useState<LoanActionType[]>([]);
    // useEffect(() => {
    //     (async () => {
    //         const _backendSettings = await getSettingsFromBackend();
    //         console.log('_backendSettings', _backendSettings);
    //         setBackendSettings(_backendSettings);
    //     })();
    //     (async () => {
    //         const _commonSettings = await getStatsFromBackend();
    //         console.log('_commonSettings', _commonSettings);
    //         setcommonSettings(_commonSettings);
    //     })();
    //     (async () => {
    //         const _latestLoanActions = await getLatestLoanActionsFromBackend();
    //         console.log('_latestLoanActions', _latestLoanActions);
    //         setLatestLoanActions(_latestLoanActions);
    //     })();
    // }, []);

    return (
        <>
            <div className='container' style={{ marginTop: '40px' }}>
                <div className='info-item'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='dashboard-title'>
                            <span>TVL</span>
                            <span>{`$${tvl ? convertBigNumberValueToLocalString(tvl.total) : '-'}`}</span>
                        </div>
                        <div className='egld-logo'>
                            <img src={egld_white_logo} />
                        </div>
                    </div>

                    <div className='row gy-2 gx-2 dashboard-egld-info' style={{ marginTop: '40px' }}>
                        <div className='col-sm-6'>
                            <div className='d-flex justify-content-between dashboard-info'>
                                <span>Liquid Staking</span>
                                <div className='d-flex justify-content-center dashboard-info-token'>
                                    <div className=''>{`$${tvl ? convertBigNumberValueToLocalString(tvl.liquidStaking) : '-'}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='d-flex justify-content-between dashboard-info'>
                                <span>Leveraged Yield Farming</span>
                                <div className='d-flex justify-content-center dashboard-info-token'>
                                    <div className=''>{`$${tvl ? convertBigNumberValueToLocalString(tvl.lyf) : '-'}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='d-flex justify-content-between dashboard-info'>
                                <span>NFT Lending</span>
                                <div className='d-flex justify-content-center dashboard-info-token'>
                                    <div className=''>{`$${tvl ? convertBigNumberValueToLocalString(tvl.nftLending) : '-'}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='d-flex justify-content-between dashboard-info'>
                                <span>NFT Collateral</span>
                                <div className='d-flex justify-content-center dashboard-info-token'>
                                    <div className=''>{`$${tvl ? convertBigNumberValueToLocalString(tvl.nftCollateral) : '-'}`}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Stake isOverview={true} />


                    {/* <div className='row gy-1 gx-1'>
                        <div className='col-12 latest-transactions-title'>MOST TRADED</div>
                        {filterCollections?.map((item: NftCollection, index: number) => {
                            return <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 my-1' key={index}><TradedColllectionsCard item={item} /></div>;
                        })}
                    </div> */}

                    {/* <div className='main-tab-container' style={{ marginTop: '40px', width: '100%' }}>
                        {tabs.map((row, index) => (
                            <span className={`main-tab-individual text-white ${tab === index ? 'focus-bg-gradient' : ''}`} key={row} onClick={() => setTab(index)}>{row}</span>
                        ))}
                    </div> */}
                    {/* <div className='' style={{ minHeight: '300px', marginTop: '30px' }}>
                        {tab === 0 && (
                            <table className="latest-transactions-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Lender</th>
                                        <th scope="col">Collateral</th>
                                        <th scope="col">Loan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        latestLoans.length > 0 && latestLoans.map((loan, index) => (
                                            <tr key={`l-l-a-${index}`}>
                                                <td>{index + 1}</td>
                                                <td>{convertSecondsToPrintDateTimeString((Date.now() - loan.loan_open_timestamp * SECOND_TO_MILLISECONDS) / SECOND_TO_MILLISECONDS)} ago</td>
                                                <td>{`${loan.loan_owner.substring(0, 5)}...${loan.loan_owner.slice(-5)}`}</td>
                                                <td>{createNftId(loan.loan_collection_id, loan.loan_nft_nonce)}</td>
                                                <td>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(loan.loan_borrow_amount), 2)} EGLD`}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        )}
                        {tab === 1 && (
                           <table className="latest-transactions-table">
                               <thead>
                                   <tr>
                                       <th scope="col">#</th>
                                       <th scope="col">Time</th>
                                       <th scope="col">User</th>
                                       <th scope="col">Amount</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   {
                                       latestLenders.length > 0 && latestLenders.map((lender, index) => (
                                           <tr key={`l-l-a-${index}`}>
                                               <td>{index + 1}</td>
                                               <td>{convertSecondsToPrintDateTimeString((Date.now() - lender.user_last_lend_timestamp * SECOND_TO_MILLISECONDS) / SECOND_TO_MILLISECONDS)} ago</td>
                                               <td>{`${lender.user_address.substring(0, 5)}...${lender.user_address.slice(-5)}`}</td>
                                               <td>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(lender.user_lend_amount), 2)} EGLD`}</td>
                                           </tr>
                                       ))
                                   }
                               </tbody>
                           </table>
                        )}
                        {tab === 1 && (
                            <table className="latest-transactions-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Item</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        latestActivities.length > 0 && latestActivities.map((activity: PoolActivity, index: number) => (
                                            <tr key={`l-l-a-${index}`}>
                                                <td>{index + 1}</td>
                                                <td>{createNftId(activity.pool_nft_token_id, activity.pool_activity_token_nonce)}</td>
                                                <td>{convertSecondsToPrintDateTimeString((Date.now() - activity.pool_activity_timestamp * SECOND_TO_MILLISECONDS) / SECOND_TO_MILLISECONDS)} ago</td>
                                                <td>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(activity.pool_activity_price), 2)} EGLD`}</td>
                                                {activity.pool_activity_type == SwapTypeEnum.Buy ? (
                                                    <td style={{color: '#ff3225'}}>Sell</td>
                                                ) : (
                                                    <td style={{color: '#25ff6f'}}>Buy</td>
                                                )}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        )}
                        {
                            latestLoans.length === 0 && <div className='no-recent-loans'>Fetching loans...</div>
                        }
                    </div> */}
                </div>
            </div>
        </>
    );
};
