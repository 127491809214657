import { MAX_PERCENTAGE } from "./constant";
import BigNumber from 'bignumber.js';
import { EGLD_DECIMALS } from "./constant";

export const getLockedAmount = (spotPrice: number, delta: number, ordersAmount: number, tab: number) => {
    if (ordersAmount < 1) return new BigNumber(0);
    const orders = ordersAmount.toFixed(0);
    if (tab === 0) {
        // Linear
        const expectedAmount = new BigNumber(orders).multipliedBy(new BigNumber(spotPrice)).multipliedBy(Math.pow(10, EGLD_DECIMALS));
        const deltaAmount = new BigNumber(orders).multipliedBy(new BigNumber(delta)).multipliedBy(new BigNumber((Number(orders) - 1) / 2)).multipliedBy(Math.pow(10, EGLD_DECIMALS));
        return expectedAmount.minus(deltaAmount);
    } else if (tab === 1) {
        // exponential
        const deltaPercentage = delta * 100;
        const totalPercent = MAX_PERCENTAGE + deltaPercentage;
        const lockedAmount = new BigNumber(spotPrice).multipliedBy(new BigNumber(totalPercent).pow(orders).minus(new BigNumber(MAX_PERCENTAGE).pow(orders))).multipliedBy(Math.pow(10, EGLD_DECIMALS)).dividedBy(new BigNumber(totalPercent).pow(ordersAmount - 1)).dividedBy(deltaPercentage);
        return lockedAmount;
    }
    return new BigNumber(0);
};

export const getSellingPrice = (spotPrice: number, delta: number, ordersAmount: number, tab: number) => {
    if (tab === 0) {
        // Linear
        const expectedAmount = ordersAmount * spotPrice + delta;
        return expectedAmount;
    } else if (tab === 1) {
        // exponential
        const expectedAmount = spotPrice + spotPrice * delta / 100;
        return expectedAmount;
    }
    return 0;
};

export const checkSpotPrice = (spotPrice: number, delta: number, ordersAmount: number, tab: number) => {
    if (tab === 0) {
        // Linear
        const deltaAmount = new BigNumber(delta).multipliedBy(new BigNumber(ordersAmount));
        if (new BigNumber(spotPrice).gte(deltaAmount)) {
            return true;
        } else {
            return false;
        }
    } else {
        // exponential
        // const deltaPercentage = delta * 100;
        // const totalPercent = MAX_PERCENTAGE + deltaPercentage;
        // const price = new BigNumber(spotPrice).multipliedBy(new BigNumber(MAX_PERCENTAGE).pow(ordersAmount).dividedBy(new BigNumber(totalPercent).pow(ordersAmount)));
        // if (price.gte(new BigNumber(0))) {
        //     return true;
        // } else {
        //     return false;
        // }
        return true;
    }
};