import { Address, AddressValue, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { LEND_GAS_LIMIT, JEWEL_ONEDEX_FARM_SC_ADDRESS, JEWEL_ONEDEX_FARM_GAS_LIMIT } from 'config';
import { getTokenTicker } from 'z/utils';

export const openOnedexPosition = async (chainID: string, address: string, farmId: number, tokenIds: string[], depositAmounts: string[], slippage: number, wrapAddress: string) => {
  const txs: any = [];
  const args: TypedValue[] = [
    new AddressValue(new Address(JEWEL_ONEDEX_FARM_SC_ADDRESS)),
  ];
  args.push(new U32Value(tokenIds.length));
  for (let i = 0; i < tokenIds.length; i++) {
    args.push(new TokenIdentifierValue(tokenIds[i]));
    args.push(new U64Value(0));

    if (getTokenTicker(tokenIds[i]) === 'EGLD') {
      args.push(new BigUIntValue(depositAmounts[i]));

      const data = new TransactionPayload('wrapEgld');

      const wrapTx = new Transaction({
        value: depositAmounts[i],
        sender: new Address(address),
        receiver: new Address(wrapAddress),
        gasLimit: LEND_GAS_LIMIT,
        chainID: chainID,
        data,
      });
      txs.push(wrapTx);
    } else {
      args.push(new BigUIntValue(depositAmounts[i]));
    }
  }
  args.push(new StringValue('openPosition'));
  args.push(new U32Value(farmId));
  args.push(new U64Value(slippage));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: JEWEL_ONEDEX_FARM_GAS_LIMIT,
    chainID: chainID,
    data,
  });
  txs.push(tx);

  await refreshAccount();
  const result = await sendTransactions({
    transactions: txs,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Farm Request',
      errorMessage: 'Error occured during Farm Request',
      successMessage: 'Farm Request Successful',
    }
  });

  return result;
};

export const closeOnedexPosition = async (chainID: string, address: string, positionId: number, slippage: number) => {
  const args: TypedValue[] = [
    new U64Value(positionId),
    new U64Value(slippage),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`closePosition@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_ONEDEX_FARM_SC_ADDRESS),
    gasLimit: JEWEL_ONEDEX_FARM_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Close Position Request',
      errorMessage: 'Error occured during Close Position Request',
      successMessage: 'Close Position Request Successful',
    }
  });
};