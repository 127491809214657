import { NftEnumType } from "@multiversx/sdk-dapp/types/tokens.types";
import { isDev } from "config";
import istari_logo from 'assets/img/sp/istari.png';
import validblocks_logo from 'assets/img/sp/validblocks.png';
import xoxno_logo from 'assets/img/sp/xoxno.jpg';
import mgstaking_logo from 'assets/img/sp/mgstaking.jpg';
import christstake_logo from 'assets/img/sp/christstake.jpg';
import tortuga_logo from 'assets/img/sp/tortuga.png';
import ofero_logo from 'assets/img/sp/ofero.jpg';
import binance_logo from 'assets/img/sp/binance.jpg';
import colombia_logo from 'assets/img/sp/colombia.png';
import entity_logo from 'assets/img/sp/entity.png';
import helios_logo from 'assets/img/sp/helios.jpg';
import moonlorian_logo from 'assets/img/sp/moonlorian.png';
import risa_logo from 'assets/img/sp/risa.png';
import thetrans_logo from 'assets/img/sp/thetrans.jpg';
import vapor_logo from 'assets/img/sp/vapor.png';
import wavenode_logo from 'assets/img/sp/wavenode.png';
import inception from 'assets/img/sp/inception.png';
import carpathians from 'assets/img/sp/carpathian.jpeg';
import astrarizon from 'assets/img/sp/astrarizon.jpeg';
import exception_labs from 'assets/img/sp/exception_labs.jpg';
import titan_stake from 'assets/img/sp/titan_stake.svg';
import pulsar from 'assets/img/sp/pulsar.svg';
export interface NftInfoType {
  nonce: number,
  name: string,
  url: string,
}

export interface CollectionSimpleType {
  logo: string,
  name: string,
  type: NftEnumType,
}

export const PAGE_SIZE = 12;
export const XOXNO_IMAGE_URL = 'https://media.xoxno.com/nftmedia/';
export const XOXNO_API_URL = isDev ? 'https://api-dev.xoxno.com/searchNFTs/' : 'https://api.xoxno.com/searchNFTs/';
export const XOXNO_FILTER = `{"filters":{"marketplace":["XO"],"onSale":true,"auctionTypes":["Nft","SftOnePerPayment"],"tokens":["EGLD"],"attributes":[],"range":{"min":0,"max":9007199254740991,"type":"saleInfoNft/min_bid_short"}},"name":"","orderBy":["saleInfoNft/min_bid_short asc"],"collection":"%s","top":${PAGE_SIZE},"select":["metadata/rarity/rank","metadata/attributes","name","onSale","saleInfoNft","royalties","identifier","collection","url","nonce","originalMedia/contentType","wasProcessed","avifUrl","webpUrl","type"],"skip":%d}`;

export const PROTOCOL_ESDT_TOKENS = isDev ? [
  // lend
  'ASH-e3d1b7',
  'JIASH-2fe483',
  'USDT-58d5d0',
  'JIUSDT-dd4e2c',
  'BUSD-866948',
  'JIBUSD-38ac64',
  'WEGLD-a28c59',
  'JIEGLD-411f50',
  'USDC-350c4e',
  'JIUSDC-59ae88',
  'SEGLD-f94c36',
  'JISEGLD-f34c65',
  // 'JWLASH-8bfcd2',
  // 'JIJWLASH-c51215',
  'JWLEGLD-e4b8d3',
  'JIJWLEGLD-394762',
  // 'SEGLD-80807d',
  // 'JISEGLD2-f3905c',
  'HTM-23a1da',
  'JWLHTM-3ed083',
  'EPUNKS-50d907',
  'JWLUSD-3d1fab',
  // ashswap farm
  // 'ALP-0e6b1c', // USDT-ASH lp
  // 'FARM-dd9f39', // USDT-ASH farm
  // 'ALP-6b7c94', // BUSD-WEGLD lp
  // 'FARM-249abc', // BUSD-WEGLD farm
  // 'ALP-9b7a73', // USDC-USDT-BUSD lp
  // 'FARM-0fc134', // USDC-USDT-BUSD farm
  // 'ALP-bf3d53', // WEGLD-SEGLD lp
  // 'FARM-103481', // WEGLD-SEGLD farm
  // 'ALP-9471de', // ASH-JWLASH lp
  // 'FARM-6f7051', // ASH-JWLASH farm
  'ALP-097c45', // WEGLD-JWLEGLD lp
  'FARM-c0c56e', // WEGLD-JWLEGLD farm
  'ALP-3763b9', // SEGLD-JWLEGLD lp
  'FARM-a2ddfd', // SEGLD-JWLEGLD farm
  'ALP-7f4b3f', // USDC-JWLUSD lp
  'FARM-3f45f0', // USDC-JWLUSD farm
  'ALP-987b7e', // JWLEGLD-EPUNKS lp
  'FARM-5a3904', // JWLEGLD-EPUNKS farm
  'ALP-a2b238', // HTM-JWLHTM lp
  'FARM-9ca257', // HTM-JWLHTM farm

  // USDC-USDT
  // onedex farm
  // 'ONE-8976c8',
  // 'ONEWEGLD-d19c97',
  // 'LEGLD-3e7182',
  // 'LEGLDWEGLD-51c54b',
  // 'USDTUSDC-642f75',
  // hatom farm
  'UTK-14d57d',
  'WBTC-05fd5b',
  'WETH-bbe4ab',
  // xExchange farm
  // 'MEX-4638e4',
  // jewel borrow
  'HEGLD-077ade',
  'HSEGLD-95d0d6',
  'HUSDC-665caa',
  'HUSDT-b00128',
  'HBUSD-bb0971',

  // xExchange Farm
  'EGLDMEX-95c6d5', // EGLD-MEX LP
  'EGLDUSDC-ac1a30', // EGLD-USDC LP
] : [
  // lend
  'ASH-a642d1',
  'JIASH-a6ae7a',
  'USDT-f8c08c',
  'JIUSDT-bcef75',
  'BUSD-40b57e',
  'JIBUSD-9d7082',
  'WEGLD-bd4d79',
  'JIEGLD-f6f180',
  'USDC-c76f1f',
  'JIUSDC-6e2818',
  'SEGLD-3ad2d0',
  'JISEGLD-424b25',
  'JWLASH-f362b9',
  'JIJWLASH-33e2cc',
  'JWLEGLD-023462',
  'JIJWLEGLD-85483a',
  'HTM-f51d55',
  'JWLHTM-8e3cd5',
  'MEX-455c57',
  'JWLMEX-ef8788',
  'WSDAI-277fee',
  'JISDAI-2435c6',
  'JWLUSD-62939e',
  "UTK-2f80e9",
  "JWLUTK-2a518c",
  "EPUNKS-dc0f59",

  "WDAI-9eeb54",

  // ashswap farm
  'ALP-2d0cf8', // USDT-ASH lp
  'FARM-e5ffde', // USDT-ASH farm
  'ALP-5f9191', // BUSD-WEGLD lp
  'FARM-9ed1f9', // BUSD-WEGLD farm
  'ALP-afc922', // USDC-USDT-BUSD lp
  'FARM-795466', // USDC-USDT-BUSD farm
  'ALP-0fe50a', // WEGLD-SEGLD lp
  'FARM-ccefc2', // WEGLD-SEGLD farm
  'ALP-f7dee1', // ASH-JWLASH lp
  'FARM-83c131', // ASH-JWLASH farm
  'ALP-713ae8', // WEGLD-JWLEGLD lp
  'FARM-b637f0', // WEGLD-JWLEGLD farm
  'ALP-2265f4', // SEGLD-JWLEGLD lp token
  'FARM-e1e69d', // SEGLD-JWLEGLD farm token
  'ALP-0ed700', // HTM-JWLHTM lp token
  'FARM-2f6e3d',
  'ALP-deda92', // HTM-JWLHTM lp token
  'FARM-d7ceeb',// HTM-JWLHTM farm token,
  'ALP-fe21d9', // USDC-JWLUSD lp token
  'FARM-5537a8',// USDC-JWLUSDfarm token,
  'ALP-20179e', //UTK-JWLUTK lp
  'FARM-0cb2e7',// UTK-JWLUTK farm
  "ALP-0f46fa", //JWLEGLD-EPUNKS LP
  'FARM-263009',  //JWLEGLD-EPUNKS FARM
  "ALP-487964", //JWLUSD-WSDAI LP
  'FARM-4e19fe',  //WLUSD-WSDAI FARM
  'ALP-19d6c0',
  'FARM-49c09d',

  'ALP-8d8415', //
  'FARM-f8b769',

  'ALP-45512a', //USDT/WDAI/JWLUSD LP
  'FARM-8d830a', //USDT/WDAI/JWLUSD FARM

  'ALP-25b383', //JWLUSD/JWLEGLD LP
  'FARM-94106f',//JWLUSD/JWLEGLD FARM

  'APUSDC-1ac537',
  'ALP-1d3ebc', // APUSDC/USDC LP
  'FARM-2b67c3', // APUSDC/USDC FARM.
  "WTAO-4f5363",
  'ALP-2ff298', //usdc-tao lp
  'FARM-b19aec', //usdc-tao farm

  "JWLBTC-c80796",
  "WBTC-5349b3",
  'ALP-ba9b1b', //usdc-tao lp
  'FARM-a64de7', //usdc-tao farm
  "JWLETH-e458bc",
  "WETH-b4ca29",
  'ALP-e05567', //usdc-tao lp
  'FARM-951d17',

  'JWLXMEX-7df4db',
    'ALP-be468d',
    'FARM-9e84a8',
    'ALP-a82580',
    'ALP-5b3202',
    'FARM-eab571',
    'FARM-a97c7a',
    'JWLAPUSDC-31969e',
    'JWLTAO-692bcc',
    'RSEGLD-881ddd',
    'ALP-c18005',
    'FARM-dee175',



  //jwlxmex-jwlmex
  //usdc-tao farm





  // onedex farm
  'ONE-f9954f',
  'ONEWEGLD-892244',
  'LEGLD-d74da9',
  'LEGLDWEGLD-6e9848',
  'USDCUSDT-4e4c0e',
  'USDCWEGLD-0fbbea',
  'USDTWEGLD-75de4a',

  // hatom farm
  'WBTC-5349b3',
  'WETH-b4ca29',

  // xExchange Farm
  'TADA-5c032c',
  'RIDE-7d18e9',
  'ITHEUM-df6f26',
  'CRT-52decf',
  'ZPAY-247875',
  'BHAT-c1fde3',
  'QWT-46ac01',
  'CYBER-489c1c',
  'BOBER-9eb764',
  'EGLDMEX-0be9e5', // EGLD-MEX LP
  'EGLDUSDC-594e5e', // EGLD-USDC LP
  'ASHWEGLD-38545c', // ASH-EGLD LP
  'HTMWEGLD-8156b5', // HTM-EGLD LP
  'UTKWEGLD-c960d1', // UTK-EGLD LP
  'TADAWEGLD-25b16b', // TADA-EGLD LP
  'EGLDRIDE-7bd51a', // RIDE-EGLD LP
  'ITHWEGLD-1adc53', // ITHEUM-EGLD LP
  'CRTWEGLD-1fac3f', // CRT-EGLD LP
  'WBTCWEGLD-c5554e', // WBTC-EGLD LP
  'WETHWEGLD-6d9c24', // WETH-EGLD LP
  'ZPAYWEGLD-34e5c1', // ZAPY-EGLD LP
  'BHATWEGLD-f45935', // BHAT-EGLD LP
  'QWTWEGLD-3eff55', // QWT-EGLD LP
  'CYBERWEGLD-45a866', // CYBER-EGLD LP
  'USDTWEGLD-1c2c31', // USDT-EGLD LP
  'WTAOWEGLD-c18d48', // WTAO-EGLD LP
  'BOBERWEGLD-ed1865', // BOBER-EGLD LP
];

export const AMM_COLLECTION_IDS = isDev ? [
  'GRDF-dfbc67',
  'CITY-47279e',
  'FCS-12ed15',
] : [
  'EPUNKS-46b186',
  'EAPES-8f3c1f',
  'COMBEYS-bc640d',
  'GSPACEAPE-08bc2b',
  'ETHOS-205078',
  'UGP-e71806',
  'SFITLEGEND-5da9dd',
  'DRIFTERS-efd96c',
  'SOULZ-9406f0',
  'GIANTS-93cadd',
  'COW-cd463d',
  'BAXC-cdf74d',
  'GNOGONS-73222b',
  'QUACK-f01e02',
  'BHAGENTS-3e9010',
  'MAIARPUNKS-3db7e8',
  'CITYNFT-26cded',
  'EPUNKSDAO-cd4a0a',
  'QWTCARDS-17f15b',
  'QWTCOINS-27203b',
  'ZOIDSTERS-9121a9',
  'MAFIA-bd0abc',
  'GUARDIAN-3d6635',
  'MOS-b9b4b2',
  'GNOGEN-8156fb',
  'SRB-61daf7',
  'NFTIM-4586ac',
  'INNOVATOR-fca3a7',
  'OAG-0eaf3b',
  'HSWOLF-76e8bf',
  'LIONS-d7a901',
  'LIGHTNINGB-496265',
  'VTWO-8d2640',
  'FTOONS-b68710',
  'OGS-3f1408',
  'BRICKS-8b0bcb',
  'WHALEYS-24a32a',
  'RAJI-c0017b',
  'SUBJECTX-2c184d',
  'DCLUB-b0ddcb',
  'EVLDS-7e056e',
];

export const COLLECTIONS_MAP: Record<string, CollectionSimpleType> = isDev ? {
  'GRDF-dfbc67': {
    logo: 'https://media.xoxno.com/collectionprofile/GRDF-dfbc67/profilePicture.webp',
    name: 'GRDF',
    type: NftEnumType.NonFungibleESDT,
  },
  'CITY-47279e': {
    logo: 'https://media.xoxno.com/collectionprofile/CITY-47279e/profilePicture.webp',
    name: 'Cyber',
    type: NftEnumType.SemiFungibleESDT,
  },
  'FCS-12ed15': {
    logo: 'https://media.xoxno.com/collectionprofile/FCS-12ed15/profilePicture.webp',
    name: 'FCS',
    type: NftEnumType.NonFungibleESDT,
  },
  'EAPES-aa2fdf': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/EAPES-8f3c1f.webp',
    name: 'ElrondApes',
    type: NftEnumType.NonFungibleESDT,
  },
  'EVLDS-6570b5': {
    logo: 'https://media.xoxno.com/collectionprofile/COMBEYS-bc640d/profilePicture.webp',
    name: 'Evoload',
    type: NftEnumType.SemiFungibleESDT,
  },
} : {
  'EPUNKS-46b186': {
    logo: 'https://media.xoxno.com/collectionprofile/EPUNKS-46b186/profilePicture.webp',
    name: 'ElrondPunks',
    type: NftEnumType.NonFungibleESDT,
  },
  'EAPES-8f3c1f': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/EAPES-8f3c1f.webp',
    name: 'ElrondApes',
    type: NftEnumType.NonFungibleESDT,
  },
  'COMBEYS-bc640d': {
    logo: 'https://media.xoxno.com/collectionprofile/COMBEYS-bc640d/profilePicture.webp',
    name: 'Combeys',
    type: NftEnumType.NonFungibleESDT,
  },
  'GSPACEAPE-08bc2b': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/GSPACEAPE-08bc2b.webp',
    name: 'GenesisSpaceApes',
    type: NftEnumType.NonFungibleESDT,
  },
  'CEA-2d29f9': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/CEA-2d29f9.webp',
    name: 'GenCantinaElrondApes',
    type: NftEnumType.NonFungibleESDT,
  },
  'SFITLEGEND-5da9dd': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/SFITLEGEND-5da9dd.webp',
    name: 'SFITLEGENDS',
    type: NftEnumType.NonFungibleESDT,
  },
  'BAXC-cdf74d': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/BAXC-cdf74d.webp',
    name: 'Bored Ape X Club',
    type: NftEnumType.NonFungibleESDT,
  },
  'GNOGONS-73222b': {
    logo: 'https://media.elrond.com/nfts/asset/QmdCEkyaafqZgmeydXfyWmB71qhHf1WSyGmRtv1SzDFq31/3784.png',
    name: 'GNOGONS',
    type: NftEnumType.NonFungibleESDT,
  },
  'MAFIA-bd0abc': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/MAFIA-bd0abc.webp',
    name: 'MAFIA',
    type: NftEnumType.NonFungibleESDT
  },
  'COW-cd463d': {
    logo: 'https://media.xoxno.com/collectionprofile/COW-cd463d/profilePicture.webp',
    name: 'CowCow',
    type: NftEnumType.NonFungibleESDT,
  },

  'DRIFTERS-efd96c': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/DRIFTERS-efd96c.webp',
    name: 'DRIFTERS',
    type: NftEnumType.NonFungibleESDT,
  },
  'SOULZ-9406f0': {
    logo: 'https://media.xoxno.com/collectionprofile/SOULZ-9406f0/profilePicture.webp',
    name: 'SOULZ OF ZION',
    type: NftEnumType.NonFungibleESDT,
  },
  'GIANTS-93cadd': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/GIANTS-93cadd.webp',
    name: 'Giants',
    type: NftEnumType.NonFungibleESDT,
  },
  'UGP-e71806': {
    logo: 'https://media.xoxno.com/collectionprofile/UGP-e71806/profilePicture.webp',
    name: 'uPark Genesis Pass - UGP',
    type: NftEnumType.NonFungibleESDT,
  },
  'BHAGENTS-3e9010': {
    logo: 'https://media.xoxno.com/collectionprofile/BHAGENTS-3e9010/profilePicture.webp',
    name: 'BHAGENTS',
    type: NftEnumType.NonFungibleESDT,
  },
  'QUACK-f01e02': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/QUACK-f01e02.webp',
    name: 'ElrondDucks',
    type: NftEnumType.NonFungibleESDT,
  },
  'MAIARPUNKS-3db7e8': {
    logo: 'https://aero.mypinata.cloud/ipfs/QmR6H6ei2kj7WeaMv8b6tEEhZJLMgsGU9wsXFGDgM6WZDn/1636296449.png',
    name: 'MaiarPunks',
    type: NftEnumType.NonFungibleESDT,
  },
  'ETHOS-205078': {
    logo: 'https://media.xoxno.com/collectionprofile/ETHOS-205078/profilePicture.webp',
    name: 'ETHOS by Calileo',
    type: NftEnumType.NonFungibleESDT,
  },
  'CITYNFT-26cded': {
    logo: 'https://media.xoxno.com/collectionprofile/CITYNFT-26cded/profilePicture.webp',
    name: 'CityNFT',
    type: NftEnumType.SemiFungibleESDT,
  },
  'QWTCOINS-27203b': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/qwat.png',
    name: 'QoWattCoins',
    type: NftEnumType.SemiFungibleESDT,
  },
  'QWTCARDS-17f15b': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/qwat.png',
    name: 'QoWattCards',
    type: NftEnumType.SemiFungibleESDT,
  },
  'EPUNKSDAO-cd4a0a': {
    logo: 'https://media.xoxno.com/collectionprofile/EPUNKSDAO-cd4a0a/profilePicture.webp',
    name: 'EPUNKSDAO',
    type: NftEnumType.SemiFungibleESDT,
  },
  'ZOIDSTERS-9121a9': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/ZOIDSTERS-9121a9.webp',
    name: 'Zoidsters',
    type: NftEnumType.NonFungibleESDT,
  },
  'GUARDIAN-3d6635': {
    logo: 'https://media.xoxno.com/collectionprofile/GUARDIAN-3d6635/profilePicture.webp',
    name: 'Aquaverse',
    type: NftEnumType.NonFungibleESDT,
  },
  'MOS-b9b4b2': {
    logo: 'https://media.xoxno.com/collectionprofile/MOS-b9b4b2/profilePicture.webp',
    name: 'Stramosi',
    type: NftEnumType.NonFungibleESDT,
  },
  'GNOGEN-8156fb': {
    logo: 'https://media.xoxno.com/collectionprofile/GNOGEN-8156fb/profilePicture.webp',
    name: 'EMIDASGENESIS',
    type: NftEnumType.NonFungibleESDT,
  },
  'SRB-61daf7': {
    logo: 'https://media.xoxno.com/collectionprofile/SRB-61daf7/profilePicture.webp',
    name: 'SuperRareBears',
    type: NftEnumType.NonFungibleESDT,
  },
  'INNOVATOR-fca3a7': {
    logo: 'https://media.elrond.com/nfts/asset/QmQrdQyYSPPqGTQFrADjVA3pZUHQgkgdEddHAWRbMgR196/325.png',
    name: 'Drifters [INNOVATORS]',
    type: NftEnumType.NonFungibleESDT,
  },
  'OAG-0eaf3b': {
    logo: 'https://media.xoxno.com/collectionprofile/OAG-0eaf3b/profilePicture.webp',
    name: 'OddApesGang',
    type: NftEnumType.NonFungibleESDT,
  },
  'NFTIM-4586ac': {
    logo: 'https://media.xoxno.com/collectionprofile/NFTIM-4586ac/profilePicture.webp',
    name: 'NFTim',
    type: NftEnumType.NonFungibleESDT,
  },
  'HSWOLF-76e8bf': {
    logo: 'https://media.xoxno.com/collectionprofile/HSWOLF-76e8bf/profilePicture.webp',
    name: 'High Street Wolf Society',
    type: NftEnumType.NonFungibleESDT,
  },
  'LIONS-d7a901': {
    logo: 'https://media.xoxno.com/collectionprofile/LIONS-d7a901/profilePicture.webp',
    name: 'ElrondLionsClub',
    type: NftEnumType.NonFungibleESDT,
  },
  'LIGHTNINGB-496265': {
    logo: 'https://media.xoxno.com/collectionprofile/LIGHTNINGB-496265/profilePicture.webp',
    name: 'Lightning Bolts',
    type: NftEnumType.NonFungibleESDT,
  },
  'VTWO-8d2640': {
    logo: 'https://media.xoxno.com/collectionprofile/VTWO-8d2640/profilePicture.webp',
    name: 'VALIDATORSV2',
    type: NftEnumType.NonFungibleESDT,
  },
  'FTOONS-b68710': {
    logo: 'https://media.xoxno.com/collectionprofile/FTOONS-b68710/profilePicture.webp',
    name: 'Fightoons',
    type: NftEnumType.NonFungibleESDT,
  },
  'OGS-3f1408': {
    logo: 'https://media.xoxno.com/collectionprofile/OGS-3f1408/profilePicture.webp',
    name: 'SubcarpatiOGs',
    type: NftEnumType.NonFungibleESDT,
  },
  'BRICKS-8b0bcb': {
    logo: 'https://media.xoxno.com/collectionprofile/BRICKS-8b0bcb/profilePicture.webp',
    name: 'MetaBricks',
    type: NftEnumType.NonFungibleESDT,
  },
  'WHALEYS-24a32a': {
    logo: 'https://media.xoxno.com/collectionprofile/WHALEYS-24a32a/profilePicture.webp',
    name: 'Whaleys',
    type: NftEnumType.NonFungibleESDT,
  },
  'RAJI-c0017b': {
    logo: 'https://media.xoxno.com/collectionprofile/RAJI-c0017b/profilePicture.webp',
    name: 'DriftersRaji',
    type: NftEnumType.NonFungibleESDT,
  },

  'SUBJECTX-2c184d': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/SUBJECTX-2c184d.webp',
    name: 'SubjectX',
    type: NftEnumType.NonFungibleESDT,
  },


  'DCLUB-b0ddcb': {
    logo: 'https://media.xoxno.com/collectionprofile/DCLUB-b0ddcb/profilePicture.webp',
    name: 'Divergent Club',
    type: NftEnumType.NonFungibleESDT,
  },

  'CITIZENNFT-b4408a': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/CITIZENNFT-b4408a.webp',
    name: 'CitizenNFT',
    type: NftEnumType.SemiFungibleESDT,
  },

  'EVLDS-7e056e': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/EVLDS-7e056e.webp',
    name: 'EvoloadSFT',
    type: NftEnumType.SemiFungibleESDT,
  },
  'ONEDEX-df4fac': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/ONEDEX-df4fac.webp',
    name: 'OneDexRoyalMember',
    type: NftEnumType.SemiFungibleESDT,
  },
  'UJWLEGLD-17e9e9': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/UJWLEGLD-17e9e9.png',
    name: 'UJWLEGLD',
    type: NftEnumType.NonFungibleESDT,
  },
  'HLSR-374950': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/HLSR-374950.png',
    name: 'Hatom Unbond NFT',
    type: NftEnumType.NonFungibleESDT,
  },
  'TRAVELER-51bdef': {
    logo: 'https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/TRAVELER-51bdef.webp',
    name: 'Home X',
    type: NftEnumType.NonFungibleESDT,
  },
};

export const FARM_POOL_TOKENS = isDev ? [
  ['WEGLD-a28c59', 'JWLEGLD-e4b8d3'],
  ['SEGLD-f94c36', 'JWLEGLD-e4b8d3'],
  ['JWLEGLD-e4b8d3', 'EPUNKS-50d907'],
  ['USDC-350c4e', 'JWLUSD-3d1fab'],
  ['HTM-23a1da', 'JWLHTM-3ed083'],
  // ['USDT-188935', 'BUSD-632f7d', 'USDC-8d4068'],
  // ['WEGLD-d7c6bb', 'SEGLD-90b353'],
  // ['ASH-4ce444', 'JWLASH-8bfcd2'],
  // ['WEGLD-d7c6bb', 'JWLEGLD-761a4f'],
] : [
  ['USDT-f8c08c', 'ASH-a642d1'],
  ['BUSD-40b57e', 'WEGLD-bd4d79'],
  ['USDT-f8c08c', 'BUSD-40b57e', 'USDC-c76f1f'],
  ['SEGLD-3ad2d0', 'WEGLD-bd4d79'],
  ['ASH-a642d1', 'JWLASH-f362b9'],
  ['WEGLD-bd4d79', 'JWLEGLD-023462'],
  ['SEGLD-3ad2d0', 'JWLEGLD-023462'],
  ['HTM-f51d55', 'JWLHTM-8e3cd5'],
  ['MEX-455c57', 'JWLMEX-ef8788'],
  ['USDC-c76f1f', 'JWLUSD-62939e'],
  ['UTK-2f80e9', 'JWLUTK-2a518c'],
  ['JWLEGLD-023462', 'EPUNKS-dc0f59'],
  ['JWLUSD-62939e', 'WSDAI-277fee'],
  ['USDC-c76f1f', 'USDT-f8c08c'],
  ['USDC-c76f1f', 'WEGLD-bd4d79'],
  ['USDT-f8c08c', 'WDAI-9eebf54', 'JWLUSD-62939e'],
  ['JWLUSD-62939e', 'JWLEGLD-023462'],
  ['APUSDC-1ac537', 'USDC-c76f1f'],
  ['USDC-c76f1f', 'WTAO-4f5363'],
  ['WBTC-5349b3', 'JWLBTC-c80796'],
  ['WETH-b4ca29', 'JWLETH-e458bc'],
  ['JWLXMEX-7df4db', 'JWLMEX-ef8788'],
  ['JWLTAO-692bcc', 'WTAO-4f5363'],
  ['APUSDC-1ac537', 'JWLAPUSDC-31969e'],
  ['RSEGLD-881ddd', 'WEGLD-bd4d79']

];

export const MAX_1_LEVERAGE_FARM_POOL_ID = [8, 10, 11, 12, 13];

export const SP_METADATA: any = isDev ? {
  // 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqx0llllsdx93z0': {
  //   name: 'Staking Agency',
  //   color: '#006666',
  //   logo: istari_logo
  // },
  // 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqr0llllsj732py': {
  //   name: 'Helios Staking',
  //   color: '#008080',
  //   logo: istari_logo
  // },
  // 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqf0lllls98s48j': {
  //   name: 'Istari',
  //   color: '#66b2b2',
  //   logo: istari_logo
  // },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqp8lllls9jsunl': {
    name: 'Staking Agency',
    color: '#006666',
    logo: istari_logo
  },
} : {
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqrhlllls062tu4': {
    name: 'iVerse Vision',
    color: '#40bf7b',
    logo: istari_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq90llllslwfcr3': {
    name: 'Valid Blocks',
    color: '#5e20e5',
    logo: validblocks_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqg8llllsqra25h': {
    name: 'XOXNO',
    color: '#53bf40',
    logo: xoxno_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9lllllsf3mp40': {
    name: 'MGStaking',
    color: '#bf4040',
    logo: mgstaking_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq98lllls54qqg7': {
    name: 'Christstake',
    color: '#4066bf',
    logo: christstake_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqdhllllsfymgpz': {
    name: 'Tortuga Staking',
    color: '#40b5bf',
    logo: tortuga_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqs0llllsk20gh7': {
    name: 'Ofero Staking',
    color: '#bf8e40',
    logo: ofero_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqc0llllsayxegu': {
    name: 'Binance Staking',
    color: '#1ef026',
    logo: binance_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqx0llllsdx93z0': {
    name: 'Helios Staking',
    color: '#f9a243',
    logo: helios_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqj0lllls2a8xw2': {
    name: 'Moonlorian Stake',
    color: '#ec223a',
    logo: moonlorian_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqehllllswleld8': {
    name: 'Vapor Republic',
    color: '#00ffff',
    logo: vapor_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqql0lllls7t6vjr': {
    name: 'Entity',
    color: '#166bf7',
    logo: entity_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqd0lllls5qqfun': {
    name: 'Wavenode',
    color: '#14e499',
    logo: wavenode_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqallllls5rqmaf': {
    name: 'Colombia Staking',
    color: '#ffc700',
    logo: colombia_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqp98llllsf2q3q5': {
    name: 'TheTransylvanianTrader',
    color: '#7a60ff',
    logo: thetrans_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqthllllsy5r6rh': {
    name: 'Risa Staking',
    color: '#2502fd',
    logo: risa_logo
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq00llllsghg898': {
    name: 'Inception-Network',
    color: '#a102fd',
    logo: inception
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqy0lllls33dlmm': {
    name: 'Carpathian Stake',
    color: '#dcfd02',
    logo: carpathians
  },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqpg8llllsauamua': {
    name: 'Pulsar',
    color: '#ea0451',
    logo: pulsar
  },
  // 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqpphllllswjz5dt': {
  //   name: 'Exception Labs',
  //   color: '#12ea9c',
  //   logo: exception_labs
  // },
  'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqr8llllse9cj2t': {
    name: 'TitanStake',
    color: '#e9f687',
    logo: titan_stake
  },
};

export const SP_COLORS = ['#b2d8d8', '#66b2b2', '#008080', '#006666'];
