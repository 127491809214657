import React, { FC } from 'react';
import { TextField } from '@mui/material';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { COLLECTIONS_MAP } from 'data';
import { CommonSettingsType, NftRarity, NftWithIndexType } from 'z/types';
import {
    convertBigNumberValueToLocalString,
    convertWeiToEsdt,
    getImageUrl,
    getImageUrlWithNft,
    MAX_PERCENTAGE
} from 'z/utils';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import BigNumber from 'bignumber.js';
import xoxno_logo from 'assets/img/marketplaces/xoxnox.png';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ReactVideoPlay, VideoSourceType } from 'react-video-play';
import 'react-video-play/public/css/react-video-play.css';
import { isDev } from 'config';
import unknown_logo from 'assets/img/unknown.png';
import { NftEnumType } from '@multiversx/sdk-dapp/types/tokens.types';
// import { LazyImage } from 'react-lazy-media';


interface PropsType {
    commonSettings: CommonSettingsType | undefined;
    selectedNftCollectionIndex: number;
    setSelectedNftCollectionIndex: (e: number) => void;
    visibleNfts: NftWithIndexType[],
    nftLoading: number;
    selectedNftIndex: number;
    setSelectedNftIndex: (e: number) => void;
    handleSelectedNft: (e: number) => void;
    nftPrices: string[],
    nftPricesLoading: number;
    searchKey: string;
    setSearchkey: (e: string) => void;
    pageCounts: number;
    page: number;
    handlePage: (e: number) => void;
    rarity: NftRarity[],
}

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #54F5B7;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #54F5B7;
    }
  }
`;
export const SelectNft: FC<PropsType> = ({
    commonSettings,
    selectedNftCollectionIndex,
    setSelectedNftCollectionIndex,
    visibleNfts,
    nftLoading,
    selectedNftIndex,
    setSelectedNftIndex,
    handleSelectedNft,
    nftPrices,
    nftPricesLoading,
    searchKey,
    setSearchkey,
    pageCounts,
    page,
    handlePage,
    rarity,
}) => {

    const handlePageChange = (e: any, pageNumber: number) => {
        handlePage(pageNumber);
    };

    const BLACKLISTED_COLLECTIONS = ['EPUNKS-46b186', 'EAPES-8f3c1f','UJWLEGLD-17e9e9', 'HLSR-374950'];
    const isCollectionBlacklistedForMortgage = (collectionId: string)=>{
        return BLACKLISTED_COLLECTIONS.includes(collectionId);
    };

    return (
        <>
            <div className='row' style={{ rowGap: '15px' }}>
                <div className='col-lg-3'>
                    <div className='text-center text-color-gray' style={{ fontSize: '1.5rem' }}>Collections</div>
                    <div className='collection-list mt-4'>
                        {
                            commonSettings && commonSettings.collection_ids.length > 0 && commonSettings && commonSettings.collection_ids.length > 0 && commonSettings.collection_ids.
                            map((collectionId: string, index: number) => {
                                const collection = COLLECTIONS_MAP[collectionId];
                                // console.log(collection);
                                // console.log(index);
                                if(isCollectionBlacklistedForMortgage(collectionId)) {
                                    return (
                                        <div
                                            key={`nft-c-${index}`}
                                            className={`d-flex align-items-center gap-2 p-2 ${selectedNftCollectionIndex === index ? 'collection-focus' : ''}`}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => setSelectedNftCollectionIndex(index)}
                                        >
                                            <LazyLoadImage className='rounded-circle mx-2' width="40" height="40"
                                                           src={collection?.logo} alt={collectionId} effect="blur"/>
                                            <div className='collection-title'>{collection?.name}</div>
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                </div>

                <div className='col-lg-9'>
                    <div className='text-center text-color-gray' style={{ fontSize: '1.5rem' }}>NFTs</div>
                    {/* <div className='scrollbar mt-4' style={{ maxHeight: '768px', overflowY: 'scroll', overflowX: 'hidden', padding: '0 8px' }}> */}

                    <div className='d-flex justify-content-center mt-4'>
                        {/*<TextField*/}
                        {/*    label="Search by identifier"*/}
                        {/*    InputProps={{*/}
                        {/*        type: 'search',*/}
                        {/*    }}*/}
                        {/*    value={searchKey}*/}
                        {/*    onChange={e => setSearchkey(e.target.value)}*/}
                        {/*/>*/}
                        <WhiteBorderTextField
                            label='Search by identifier'
                            InputProps={{
                                type: 'search',
                                style: { color: '#54F5B7', borderColor: '#54F5B7' },
                                startAdornment: <InputAdornment position='start'>
                                    <IconButton
                                        aria-label='search'
                                        edge='end'
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            onChange={e => setSearchkey(e.target.value)}
                        />
                    </div>

                    <div className='scrollbar mt-4'>
                        <div className='row nft-select-box'>
                            {
                                nftLoading === 1 ?
                                    (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status" />
                                        </div>
                                    )
                                    : visibleNfts.length > 0 ? visibleNfts.map((nft: any, index: number) => {
                                        // const nftRarity = rarity?.filter((rarity: NftRarity) => rarity.identifier === nft.identifier);
                                        // if (nftRarity.length > 0 || (isDev && nftRarity.length >= 0)) {
                                            return (
                                                <div className='col-6 col-md-4 col-lg-3 p-2' key={`b-s-n-${index}`}>
                                                    <div className='mortgage-nft-group' onClick={() => handleSelectedNft(nft.index)}>
                                                        <div className='mortgage-nft-card'>
                                                            <div className='text-center mortgage-nft-buy-card-1'>
                                                                <div className='mb-1 h2'>Buy</div>
                                                                <div className='mb-1 h3'>for</div>
                                                                <div className='d-flex gap-2 justify-content-center'>
                                                                    <div className='h2'>{commonSettings && nft.saleInfoNft.max_bid ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(nft.saleInfoNft.max_bid).minus(BigNumber.min(BigNumber(nft.saleInfoNft.max_bid).multipliedBy(commonSettings.collection_collateral_rates[selectedNftCollectionIndex]).div(MAX_PERCENTAGE), commonSettings.collection_max_borrow_amounts[selectedNftCollectionIndex])))) : '-'}</div>
                                                                    <img className='egld-badge mt-1' src={egld_white_logo} />
                                                                </div>
                                                            </div>
                                                            <div className='mortgage-nft-buy-card-2'>
                                                                <div className='mortgage-nft-buy-card-image'>
                                                                    {nft.url?.includes('.mp4') ? (
                                                                        <ReactVideoPlay
                                                                            sources={[
                                                                                {
                                                                                    name: '720p',
                                                                                    source: [{
                                                                                        source: nft.url,
                                                                                        type: VideoSourceType.video_mp4
                                                                                    }]
                                                                                }
                                                                            ]}
                                                                            poster={isDev ? unknown_logo : getImageUrl(nft.collection, nft.identifier)}
                                                                            enableAdv={false}
                                                                            enableSlider={false}
                                                                            autoplay={false}
                                                                            muted={true}
                                                                            controls={false}
                                                                        />
                                                                    ) : (
                                                                        <LazyLoadImage style={{ width: '100%', objectFit: 'cover' }} src={isDev ? nft.url : getImageUrlWithNft(nft.collection, nft.identifier, nft)} alt={nft.name} effect="blur" />
                                                                    )}
                                                                    <img className='mortgage-nft-buy-card-image-2' src={xoxno_logo} />
                                                                </div>
                                                                <div className='mt-2'>{nft.name}</div>
                                                                {COLLECTIONS_MAP[nft.collection].type === NftEnumType.SemiFungibleESDT && (
                                                                    <div>{`Quantity: ${nft.saleInfoNft.quantity}`}</div>
                                                                )}
                                                                {/*{!isDev && (<div className='mt-1'>{`Rank: ${nftRarity[0].metadata.rarity.rank}`}</div>)}*/}
                                                                <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                                    <div className='d-flex gap-2 align-items-center'>
                                                                        <img className='egld-badge' src={egld_white_logo} />
                                                                        <span>
                                                                            {nft.saleInfoNft.max_bid ? convertBigNumberValueToLocalString(convertWeiToEsdt(nft.saleInfoNft.max_bid)) : '-'}
                                                                            {/* {
                                                                    nftPricesLoading === 1 ?(
                                                                        <div className="d-flex justify-content-center">
                                                                            <div className="spinner-border" role="status" />
                                                                        </div>
                                                                    ) : (
                                                                        nftPrices.length > nft.index ? convertBigNumberValueToLocalString(convertWeiToEsdt(nftPrices[nft.index])) : '-'
                                                                    )
                                                                } */}
                                                                        </span>
                                                                    </div>
                                                                    {/* <div className="eg-btn btn--primary2 capsule px-4 py-1" style={{ cursor: 'pointer' }} onClick={() => handleSelectedNft(nft.index)}>
                                                                        Buy
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        // }
                                    }) : <div className='text-center text-color-gray' style={{ fontSize: '1.25rem', marginTop: '1rem' }}>No Result</div>
                            }
                        </div>
                    </div>

                    {visibleNfts.length > 0 && (
                        <div className='row mt-5'>
                            <Stack spacing={1} alignItems="center">
                                <Pagination count={pageCounts} page={page} onChange={handlePageChange} size={isMobile ? 'small' : 'medium'} className='mortgage-nft-pagination' />
                            </Stack>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
