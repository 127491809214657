import React, { useEffect, useState } from 'react';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { JEWEL_EXCHANGE_SC_ADDRESS } from 'config';
import { getElrondStatsFromApi } from 'z/api';
import { XexchangeEnergyEntry, getXexchangeEnergyEntry } from 'z/elrond';
import { ElrondStatsType } from 'z/types';
import { convertBigNumberValueToLocalString, convertWeiToEsdt } from 'z/utils';

export const AdminXexchangeFarmEnergy = () => {
  const [energyEntry, setEnergyEntry] = useState<XexchangeEnergyEntry>();
  const [elrondStats, setElrondStats] = useState<ElrondStatsType>();

  useEffect(() => {
    (async () => {
      const _energyEntry = await getXexchangeEnergyEntry(JEWEL_EXCHANGE_SC_ADDRESS);
      setEnergyEntry(_energyEntry);
    })();
    (async () => {
      const _elrondStats = await getElrondStatsFromApi();
      setElrondStats(_elrondStats);
    })();
  }, []);

  return (
    <>
      <div className='container ' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>Admin</span>
              <span>xExchange Farm</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>
          <div className='row gy-2 gx-2 dashboard-egld-info mb-5' style={{ marginTop: '40px' }}>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Energy</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{energyEntry ? convertBigNumberValueToLocalString(convertWeiToEsdt(energyEntry.amount)) : '-'}</div>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Locked XMEX Amount</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{energyEntry ? convertBigNumberValueToLocalString(convertWeiToEsdt(energyEntry.total_locked_tokens)) : '- '}</div>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Last Lock Epoch</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{energyEntry ? energyEntry.last_update_epoch : '-'}</div>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='d-flex justify-content-between dashboard-info'>
                <span>Current Epoch</span>
                <div className='d-flex justify-content-center dashboard-info-token'>
                  <div className=''>{elrondStats ? elrondStats.epoch : '-'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
