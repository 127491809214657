import axios from "axios";
import { JEWEL_BACKEND_API } from "config";
import { TvlContext } from "z/types";

export async function getTvl() {
    try {
        const { data } = await axios.get<TvlContext>(`${JEWEL_BACKEND_API}/tvl`);

        return data;
    } catch (e) {
        // toastError(ERROR_XOXNO_API_FAILED);
    }
    return null;
}
