import React, { FC, useEffect, useState } from 'react';
import egld_logo from 'assets/img/coin/EGLD_white.png';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, getMmTokenTicker, getMoneyMarketBorrowApy, getMoneyMarketSupplyApy, getMoneyMarketType, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, PERCENT_DENOMINATOR } from 'z/utils';
import { AshswapFarm, JewelHatomMoneyMarket, JewelLendPool, JewelMoneyMarketPoolContext, JewelMoneyMarketUserPoolContext, MoneyMarketPoolTypeEnum } from 'z/types';
import { queryJewelBorrowGetEquivalent } from 'z/elrond';
import { FARM_POOL_TOKENS } from 'data';
import { ISOLATED_BORROW_POOL_LIQUIDATION_PERCENT, JEWEL_BORROW_LIQUIDATION_TOKENS } from 'config';

interface PositionProps {
    pool: JewelMoneyMarketUserPoolContext,
    handleOpenModal: (type: boolean, mm_pool: JewelMoneyMarketPoolContext, selected_token_id: string, equivalentAmount: string) => void,
    isSupplied: boolean,
    jewelMoneyMarketPools: JewelMoneyMarketPoolContext[],
    jewelLendPools: JewelLendPool[],
    hatomMoneyMarkets: JewelHatomMoneyMarket[],
    hatomFormattedRewards: any[],
    liquidStakeApy: string,
    stableStakeApy: string,
    ashswapFarms: AshswapFarm[],
}

export const Position: FC<PositionProps> = ({
    pool,
    handleOpenModal,
    isSupplied,
    jewelMoneyMarketPools,
    jewelLendPools,
    hatomMoneyMarkets,
    hatomFormattedRewards,
    liquidStakeApy,
    stableStakeApy,
    ashswapFarms,
}) => {

    const [equivalentAmount, setEquivalentAmount] = useState<string>('0');
    const [jewelMoneyMarketPool, setJewelMoneyMarketPool] = useState<JewelMoneyMarketPoolContext | undefined>();
    useEffect(() => {
        if (!pool || jewelMoneyMarketPools.length === 0) return;
        (async () => {
            for (let i = 0; i < jewelMoneyMarketPools.length; i++) {
                if (jewelMoneyMarketPools[i].base_token_id === pool.base_token_id && jewelMoneyMarketPools[i].debt_token_id === pool.debt_token_id) {
                    const amount = await queryJewelBorrowGetEquivalent(pool.base_token_id, pool.debt_token_id, convertEsdtToWei(1, getTokenDecimal(pool.base_token_id)).toFixed(0));
                    setEquivalentAmount(amount);
                    setJewelMoneyMarketPool(jewelMoneyMarketPools[i]);

                    break;
                }
            }
        })();
    }, [pool, jewelMoneyMarketPools]);

    return (
        <>
            {isMobile ? (
                jewelMoneyMarketPool && (
                    <div className='row position-container mobile-position-container'>
                        <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex'>
                            <div className='d-flex justify-content-center align-items-center'>
                                {
                                    getMoneyMarketType(pool.base_token_id) === 'ashswap' ? (
                                        isSupplied ? (
                                            <div className='farm-container'>
                                                <div className='combine-bage'>
                                                    <img src={getTokenImage(FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][0])} className='egld-image' alt={FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][0]}></img>
                                                    <img src={getTokenImage(FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][1])} className='egld-image' alt={FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][1]}></img>
                                                </div>
                                            </div>
                                        ) : (
                                            <img src={getTokenImage(pool.debt_token_id)} className='egld-image mx-2' alt={pool.debt_token_id}></img>
                                        )
                                    ) : (
                                        <img src={getTokenImage(isSupplied ? pool.base_token_id : pool.debt_token_id)} className='egld-image mx-2' alt={isSupplied ? pool.base_token_id : pool.debt_token_id}></img>
                                    )
                                }
                                <div>
                                    <div>{`${getTokenTicker(isSupplied ? pool.base_token_id : pool.debt_token_id)} ${isSupplied ? '' : `(${getTokenTicker(pool.base_token_id)})`}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12 d-flex justify-content-between align-items-center mt-1'>
                            <div style={{ width: '30%' }}>{isSupplied ? 'Supplied' : 'Borrowed'}</div>
                            {
                                JEWEL_BORROW_LIQUIDATION_TOKENS.includes(pool.base_token_id) ? (
                                    <div style={{ width: '60%', textAlign: 'right' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)))} ${getTokenTicker(isSupplied ? pool.base_token_id : pool.debt_token_id)} ${!isSupplied && (jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlegld || jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlusd) ? '' : `(= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getTokenTicker(pool.debt_token_id)})`}`}</div>
                                ) : (
                                    <div style={{ width: '60%', textAlign: 'right' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)))} ${getTokenTicker(isSupplied ? pool.base_token_id : pool.debt_token_id)} ${!isSupplied && (jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlegld || jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlusd) ? '' : `(= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getMmTokenTicker(getTokenTicker(pool.base_token_id))})`}`}</div>
                                )
                            }
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                            <div>APY</div>
                            <div className='avaliable-color'>{isSupplied ? `${getTokenTicker(pool.base_token_id) === 'SJWLEGLD' ? liquidStakeApy : getTokenTicker(pool.base_token_id) === 'SJWLUSD' ? stableStakeApy : getMoneyMarketSupplyApy(pool.base_token_id, jewelLendPools, hatomMoneyMarkets, hatomFormattedRewards, jewelMoneyMarketPool.ashswap_farm_id, ashswapFarms)} %` : `-${convertBigNumberValueToLocalString(jewelMoneyMarketPool.instant_apr / MAX_PERCENTAGE)} %`}</div>
                        </div>
                        {
                            !isSupplied && (
                                <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                                    <div className='d-flex'>
                                        <div>Health Factor</div>
                                        <Tooltip
                                            placement="top"
                                            title="The health factor is calculated from user's collateral balance multiplied by current liquidation threshold for the user specific asset, divided by 100, divided by user's borrow balance/fees. Positions with health factor <1 will trigger liquidation."
                                        >
                                            <InfoCircleOutlined style={{ fontSize: '14px', cursor: 'pointer', marginTop: '3px', marginLeft: '5px' }} />
                                        </Tooltip>
                                    </div>
                                    <div>{convertBigNumberValueToLocalString(convertWeiToEsdt(pool.user_supplied_amount, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).multipliedBy(ISOLATED_BORROW_POOL_LIQUIDATION_PERCENT).dividedBy(100 * PERCENT_DENOMINATOR).dividedBy(pool.user_debt_amount), 2)}</div>
                                </div>
                            )
                        }
                        <div className='col-lg-2 col-md-2 col-sm-12 align-self-center mt-3'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(true, jewelMoneyMarketPool, jewelMoneyMarketPool.base_token_id, equivalentAmount)}>
                                        {isSupplied ? 'Supply More' : 'Borrow More'}
                                    </div>
                                </div>
                                <div className='col-12 d-flex justify-content-center mt-2'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(false, jewelMoneyMarketPool, jewelMoneyMarketPool.base_token_id, equivalentAmount)}>
                                        {isSupplied ? 'Withdraw' : 'Repay'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                jewelMoneyMarketPool && (
                    <div className='row position-container'>
                        <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                            <div className='d-flex align-items-center'>
                                {
                                    getMoneyMarketType(pool.base_token_id) === 'ashswap' ? (
                                        isSupplied ? (
                                            <div className='farm-container'>
                                                <div className='combine-bage'>
                                                    <img src={getTokenImage(FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][0])} className='egld-image' alt={FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][0]}></img>
                                                    <img src={getTokenImage(FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][1])} className='egld-image' alt={FARM_POOL_TOKENS[jewelMoneyMarketPool.ashswap_farm_id - 1][1]}></img>
                                                </div>
                                            </div>
                                        ) : (
                                            <img src={getTokenImage(pool.debt_token_id)} className='egld-image mx-2' alt={pool.debt_token_id}></img>
                                        )
                                    ) : (
                                        <img src={getTokenImage(isSupplied ? pool.base_token_id : pool.debt_token_id)} className='egld-image mx-2' alt={isSupplied ? pool.base_token_id : pool.debt_token_id}></img>
                                    )
                                }
                                <div>
                                    <div>{`${getTokenTicker(isSupplied ? pool.base_token_id : pool.debt_token_id)} ${isSupplied ? '' : `(${getTokenTicker(pool.base_token_id)})`}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 d-flex align-items-center justify-content-center'>
                            {
                                JEWEL_BORROW_LIQUIDATION_TOKENS.includes(pool.base_token_id) ? (
                                    <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)))} ${getTokenTicker(isSupplied ? pool.base_token_id : pool.debt_token_id)} ${!isSupplied && (jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlegld || jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlusd) ? '' : `(= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getTokenTicker(pool.debt_token_id)})`}`}</div>
                                ) : (
                                    <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)))} ${getTokenTicker(isSupplied ? pool.base_token_id : pool.debt_token_id)} ${!isSupplied && (jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlegld || jewelMoneyMarketPool.borrow_pool_type === MoneyMarketPoolTypeEnum.Sjwlusd) ? '' : `(= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(isSupplied ? pool.user_supplied_amount : pool.user_debt_amount, getTokenDecimal(isSupplied ? pool.base_token_id : pool.debt_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getMmTokenTicker(getTokenTicker(pool.base_token_id))})`}`}</div>
                                )
                            }
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-12 d-flex align-items-center justify-content-center'>
                            <div className='avaliable-color'>{isSupplied ? `${getTokenTicker(pool.base_token_id) === 'SJWLEGLD' ? liquidStakeApy : getTokenTicker(pool.base_token_id) === 'SJWLUSD' ? stableStakeApy : getMoneyMarketSupplyApy(pool.base_token_id, jewelLendPools, hatomMoneyMarkets, hatomFormattedRewards, jewelMoneyMarketPool.ashswap_farm_id, ashswapFarms)} %` : `-${convertBigNumberValueToLocalString(jewelMoneyMarketPool.instant_apr / MAX_PERCENTAGE)} %`}</div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center'>
                            {
                                !isSupplied && (
                                    <div className='avaliable-color'>{convertBigNumberValueToLocalString(convertWeiToEsdt(pool.user_supplied_amount, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).multipliedBy(ISOLATED_BORROW_POOL_LIQUIDATION_PERCENT).dividedBy(100 * PERCENT_DENOMINATOR).dividedBy(pool.user_debt_amount), 2)}</div>
                                )
                            }
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                            <div className='row'>
                                <div className='col-6 d-flex justify-content-center'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(true, jewelMoneyMarketPool, jewelMoneyMarketPool.base_token_id, equivalentAmount)}>
                                        {isSupplied ? 'Supply More' : 'Borrow More'}
                                    </div>
                                </div>
                                <div className='col-6 d-flex justify-content-center'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(false, jewelMoneyMarketPool, jewelMoneyMarketPool.base_token_id, equivalentAmount)}>
                                        {isSupplied ? 'Withdraw' : 'Repay'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};
