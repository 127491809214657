import React, { FC, useEffect, useState } from 'react';
import egld_logo from 'assets/img/coin/EGLD_white.png';
import { isMobile } from 'react-device-detect';
import { AshswapFarm, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelLendPool, JewelMoneyMarketPoolContext } from 'z/types';
import { convertBigNumberValueToLocalString, convertEsdtToWei, convertWeiToEsdt, getHatomBaseTokenId, getMmTokenTicker, getMoneyMarketBorrowApy, getMoneyMarketSupplyApy, getMoneyMarketType, getMoneyMarketUtilizationPercent, getTokenDecimal, getTokenImage, getTokenTicker, MAX_PERCENTAGE, PERCENT_DENOMINATOR } from 'z/utils';
import BigNumber from 'bignumber.js';
import { queryJewelBorrowGetEquivalent } from 'z/elrond';
import { FARM_POOL_TOKENS } from 'data';
import { JEWEL_BORROW_LIQUIDATION_TOKENS } from 'config';

interface EsdtPoolProps {
    pool: JewelMoneyMarketPoolContext,
    userTokens: any,
    handleOpenSupplyModal: (is_supply: boolean, mm_pool: JewelMoneyMarketPoolContext, selected_token_id: string) => void,
    handleOpenBorrowModal: (is_borrow: boolean, mm_pool: JewelMoneyMarketPoolContext) => void,
    jewelLendPools: JewelLendPool[],
    hatomMoneyMarkets: JewelHatomMoneyMarket[],
    hatomFormattedRewards: any[],
    liquidStakeApy: string,
    stableStakeApy: string,
    mmType: string,
    ashswapFarms: AshswapFarm[],
}

export const EsdtPool: FC<EsdtPoolProps> = ({
    pool,
    userTokens,
    handleOpenSupplyModal,
    handleOpenBorrowModal,
    jewelLendPools,
    hatomMoneyMarkets,
    hatomFormattedRewards,
    liquidStakeApy,
    stableStakeApy,
    mmType,
    ashswapFarms,
}) => {
    const [equivalentAmount, setEquivalentAmount] = useState<string>('0');
    useEffect(() => {
        if (!pool) return;
        (async () => {
            const amount = await queryJewelBorrowGetEquivalent(pool.base_token_id, pool.debt_token_id, convertEsdtToWei(1, getTokenDecimal(pool.base_token_id)).toFixed(0));
            setEquivalentAmount(amount);
        })();
    }, [pool]);

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 lend-pool-container mobile-lend-pool-container'>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex'>
                        {
                            getMoneyMarketType(pool.base_token_id) === 'ashswap' ? (
                                FARM_POOL_TOKENS[pool.ashswap_farm_id - 1] && (
                                    <div className='d-flex justify-content-center align-items-center farm-container'>
                                        <div className='combine-bage'>
                                            <img src={getTokenImage(FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][0])} className='egld-image mx-2' alt={FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][0]}></img>
                                            <img src={getTokenImage(FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][1])} className='egld-image mx-2' alt={FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][1]}></img>
                                        </div>
                                        <div>{getTokenTicker(pool.base_token_id)}</div>
                                    </div>
                                )
                            ) : (
                                <div className='d-flex justify-content-center align-items-center farm-container'>
                                    {
                                        pool.hatom_base_token_id ? (
                                            <div className='combine-bage'>
                                                <img src={getTokenImage(pool.base_token_id)} className='egld-image mx-2' alt={pool.base_token_id}></img>
                                                <img src={getTokenImage(pool.hatom_base_token_id)} className='egld-image mx-2' alt={pool.hatom_base_token_id}></img>
                                            </div>
                                        ) : (
                                            <img src={getTokenImage(pool.base_token_id)} className='egld-image mx-2' alt={pool.base_token_id}></img>
                                        )
                                    }
                                    <div>
                                        {
                                            pool.hatom_base_token_id ? (
                                                <div>{`${getTokenTicker(pool.base_token_id)} | ${getTokenTicker(pool.hatom_base_token_id)}`}</div>
                                            ) : (
                                                <div>{getTokenTicker(pool.base_token_id)}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-3'>
                        <div>Supply APY:</div>
                        <div className='avaliable-color'>{`${getTokenTicker(pool.base_token_id) === 'SJWLEGLD' ? liquidStakeApy : getTokenTicker(pool.base_token_id) === 'SJWLUSD' ? stableStakeApy : getMoneyMarketSupplyApy(pool.base_token_id, jewelLendPools, hatomMoneyMarkets, hatomFormattedRewards, pool.ashswap_farm_id, ashswapFarms)} %`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div>Borrow APY:</div>
                        <div className='avaliable-color'>{`-${pool ? convertBigNumberValueToLocalString(pool.instant_apr / MAX_PERCENTAGE) : ''} %`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Total Supply</div>
                        {
                            JEWEL_BORROW_LIQUIDATION_TOKENS.includes(pool.base_token_id) ? (
                                <div className='text-end'>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getTokenTicker(pool.debt_token_id)})`}</div>
                            ) : (
                                <div className='text-end'>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getMmTokenTicker(getTokenTicker(pool.base_token_id))})`}</div>
                            )
                        }
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Total Borrowed</div>
                        <div className='text-end'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.borrowed_amount, getTokenDecimal(pool.debt_token_id)))} / ${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.max_debt_cap, getTokenDecimal(pool.debt_token_id)))} ${getTokenTicker(pool.debt_token_id)}`}</div>
                            <div>{`${convertBigNumberValueToLocalString(BigNumber(pool.borrowed_amount).multipliedBy(100).dividedBy(pool.max_debt_cap))}% utilized`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Your Balance</div>
                        <div className='text-end'>
                            {
                                JEWEL_BORROW_LIQUIDATION_TOKENS.includes(pool.base_token_id) ? (
                                    <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getTokenTicker(pool.debt_token_id)})`}</div>
                                ) : (
                                    <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getMmTokenTicker(getTokenTicker(pool.base_token_id))})`}</div>
                                )
                            }
                            {
                                pool.hatom_base_token_id && (
                                    <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.hatom_base_token_id].balance, getTokenDecimal(pool.hatom_base_token_id)))} ${getTokenTicker(pool.hatom_base_token_id)}`}</div>
                                )
                            }
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center mt-2'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenSupplyModal(true, pool, pool.base_token_id)}>
                                    Supply
                                </div>
                            </div>
                            <div className='col-12 d-flex justify-content-center mt-2'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenBorrowModal(true, pool)}>
                                    Borrow
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 lend-pool-container'>
                    <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                        {
                            getMoneyMarketType(pool.base_token_id) === 'ashswap' ? (
                                FARM_POOL_TOKENS[pool.ashswap_farm_id - 1] && (
                                    <div className='d-flex align-items-center farm-container'>
                                        <div className='combine-bage'>
                                            <img src={getTokenImage(FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][0])} className='egld-image mx-2' alt={FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][0]}></img>
                                            <img src={getTokenImage(FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][1])} className='egld-image mx-2' alt={FARM_POOL_TOKENS[pool.ashswap_farm_id - 1][1]}></img>
                                        </div>
                                        <div>{getTokenTicker(pool.base_token_id)}</div>
                                    </div>
                                )
                            ) : (
                                <div className='d-flex align-items-center farm-container'>
                                    {
                                        pool.hatom_base_token_id ? (
                                            <div className='combine-bage'>
                                                <img src={getTokenImage(pool.base_token_id)} className='egld-image mx-2' alt={pool.base_token_id}></img>
                                                <img src={getTokenImage(pool.hatom_base_token_id)} className='egld-image mx-2' alt={pool.hatom_base_token_id}></img>
                                            </div>
                                        ) : (
                                            <img src={getTokenImage(pool.base_token_id)} className='egld-image mx-2' alt={pool.base_token_id}></img>
                                        )
                                    }
                                    <div>
                                        {
                                            pool.hatom_base_token_id ? (
                                                <div>{`${getTokenTicker(pool.base_token_id)} | ${getTokenTicker(pool.hatom_base_token_id)}`}</div>
                                            ) : (
                                                <div>{getTokenTicker(pool.base_token_id)}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                        <div className='avaliable-color'>{`${getTokenTicker(pool.base_token_id) === 'SJWLEGLD' ? liquidStakeApy : getTokenTicker(pool.base_token_id) === 'SJWLUSD' ? stableStakeApy : getMoneyMarketSupplyApy(pool.base_token_id, jewelLendPools, hatomMoneyMarkets, hatomFormattedRewards, pool.ashswap_farm_id, ashswapFarms)} %`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                        <div className='avaliable-color'>{`-${pool ? convertBigNumberValueToLocalString(pool.instant_apr / MAX_PERCENTAGE) : ''} %`}</div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12 d-flex'>
                        <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                            {
                                JEWEL_BORROW_LIQUIDATION_TOKENS.includes(pool.base_token_id) ? (
                                    <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getTokenTicker(pool.debt_token_id)})`}</div>
                                ) : (
                                    <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getMmTokenTicker(getTokenTicker(pool.base_token_id))})`}</div>
                                )
                            }
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.borrowed_amount, getTokenDecimal(pool.debt_token_id)))} / ${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.max_debt_cap, getTokenDecimal(pool.debt_token_id)))} ${getTokenTicker(pool.debt_token_id)}`}</div>
                            <div>{`${convertBigNumberValueToLocalString(BigNumber(pool.borrowed_amount).multipliedBy(100).dividedBy(pool.max_debt_cap))}% utilized`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 text-end align-self-center'>
                        {
                            JEWEL_BORROW_LIQUIDATION_TOKENS.includes(pool.base_token_id) ? (
                                <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getTokenTicker(pool.debt_token_id)})`}</div>
                            ) : (
                                <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)))} ${getTokenTicker(pool.base_token_id)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(convertWeiToEsdt(userTokens[pool.base_token_id].balance, getTokenDecimal(pool.base_token_id)).multipliedBy(equivalentAmount).toFixed(0), 18))} ${getMmTokenTicker(getTokenTicker(pool.base_token_id))})`}</div>
                            )
                        }
                        {
                            pool.hatom_base_token_id && (
                                <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.hatom_base_token_id].balance, getTokenDecimal(pool.hatom_base_token_id)))} ${getTokenTicker(pool.hatom_base_token_id)}`}</div>
                            )
                        }
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenSupplyModal(true, pool, pool.base_token_id)}>
                                    Supply
                                </div>
                            </div>
                            <div className='col-12 d-flex justify-content-center mt-2'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenBorrowModal(true, pool)}>
                                    Borrow
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
