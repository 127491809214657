import React, { useEffect, useState, useContext } from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { NftCollection } from 'z/types';
import { AmmCollectionContext } from 'App';
import { CollectionsTab } from 'components/CollectionsTab';
import egld_white_logo from "../../assets/img/EGLD_white.svg";

export const Collections = () => {

    const { isLoading, ammCollections } = useContext(AmmCollectionContext);

    const [filterCollections, setFilterCollections] = useState<NftCollection[]>(ammCollections);
    useEffect(() => {
        setFilterCollections(ammCollections);
    }, [ammCollections]);

    const handleOnChange = (e: any) => {
        const items = ammCollections.filter((collection: NftCollection) => { return collection.nft_collection_name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()); });
        setFilterCollections(items);
    };

    return (
        <div className='container amm-container' style={{ marginTop: '40px' }}>
            <div className='info-item'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='title'>
                        <span>NFT Collections</span>
                        <span>Trade the NFTs of the most popular collections</span>
                    </div>
                    <div className='egld-logo' >
                        <img src={egld_white_logo} />
                    </div>
                </div>
                {/*<div className='row'>*/}
                {/*    <div className='col-12 mt-5 text-center h2'>*/}
                {/*        Collections*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='row mt-4'>
                    <div className='col-lg-2 col-md-2 col-sm-12'></div>
                    <div className='col-lg-8 col-md-8 col-sm-12'>
                        <TextField
                            fullWidth
                            label='Search by name'
                            InputProps={{
                                type: 'search',
                                style: { color: '#37b06f' },
                                startAdornment: <InputAdornment position='start'>
                                    <IconButton
                                        aria-label='search'
                                        edge='end'
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12'></div>
                </div>
                <CollectionsTab collections={filterCollections} isLoading={isLoading} />
            </div>
        </div>
    );
};
