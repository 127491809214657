import React, { FC, useEffect } from 'react';
import { TextField } from '@mui/material';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { COLLECTIONS_MAP } from 'data';
import { CommonSettingsType, NftRarity, NftWithIndexType } from 'z/types';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, getImageUrl } from 'z/utils';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ReactVideoPlay, VideoSourceType } from 'react-video-play';
import 'react-video-play/public/css/react-video-play.css';
import { isDev } from 'config';
import unknown_logo from 'assets/img/unknown.png';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import StickyBox from "react-sticky-box";

interface PropsType {
    commonSettings: CommonSettingsType | undefined;
    selectedNftCollectionIndex: number;
    setSelectedNftCollectionIndex: (e: number) => void;
    visibleNfts: NftWithIndexType[],
    nftLoading: number;
    selectedNftIndex: number;
    setSelectedNftIndex: (e: number) => void;
    nftPrices: string[],
    nftPricesLoading: number;
    searchKey: string;
    setSearchkey: (e: string) => void;
    rarity: NftRarity[],
    selectedNfts: number[],
    handleAddNft: (e: number) => void;
    handleRemoveNft: (e: number) => void;
    activeStep: number,
    onClickBorrow: () => void;
    onClickNextButton: () => void;
}

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: #54F5B7;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #54F5B7;
    }
  }
`;
const BLACKLISTED_COLLECTIONS = ['CITY-47279e','EPUNKS-46b186', 'EAPES-8f3c1f','UJWLEGLD-17e9e9', 'HLSR-374950', 'EAPES-8f3c1f', 'EAPES-aa2fdf'];
const isCollectionBlacklistedForMortgage = (collectionId: string)=>{
    return BLACKLISTED_COLLECTIONS.includes(collectionId);
};

export const SelectNft: FC<PropsType> = ({
    commonSettings,
    selectedNftCollectionIndex,
    setSelectedNftCollectionIndex,
    visibleNfts,
    nftLoading,
    selectedNftIndex,
    setSelectedNftIndex,
    nftPrices,
    nftPricesLoading,
    searchKey,
    setSearchkey,
    rarity,
    selectedNfts,
    handleAddNft,
    handleRemoveNft,
    activeStep,
    onClickBorrow,
    onClickNextButton,
}) => {
    return (
        <>
            <div className='row' style={{ rowGap: '15px' }}>
                <div className='col-lg-3'>
                    <div className='text-center text-color-gray' style={{ fontSize: '1.5rem' }}>Collections</div>
                    <div className='collection-list mt-4'>
                        {
                            commonSettings && commonSettings.collection_ids.length > 0 && commonSettings.collection_ids.map((collectionId: string, index: number) => {
                                const collection = COLLECTIONS_MAP[collectionId];
                                if(isCollectionBlacklistedForMortgage(collectionId)) {
                                return (
                                    <div
                                        key={`nft-c-${index}`}
                                        className={`d-flex align-items-center gap-2 p-2 ${selectedNftCollectionIndex === index ? 'collection-focus' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setSelectedNftCollectionIndex(index)}
                                    >
                                        <LazyLoadImage className='rounded-circle mx-2' width="40" height="40" src={collection?.logo} alt={collectionId} effect="blur" />
                                        <div className='collection-title'>{collection?.name}</div>
                                    </div>
                                );
                                }
                            })
                        }
                    </div>
                </div>

                <div className='col-lg-9'>
                    <div className='text-center text-color-gray' style={{ fontSize: '1.5rem' }}>NFTs - Select one and click next for single asset NFT borrow or Select multiple for bulk borrow.</div>
                    {/* <div className='scrollbar mt-4' style={{ maxHeight: '768px', overflowY: 'scroll', overflowX: 'hidden', padding: '0 8px' }}> */}

                    <div className='scrollbar mt-4'>
                        <div className='row nft-select-box'>
                            <div className='col-lg-1 col-md-1 col-sm-12'></div>
                            <div className='col-lg-8 col-md-8 col-sm-12 text-center mb-3'>
                                <WhiteBorderTextField
                                    label='Search by identifier'
                                    InputProps={{
                                        type: 'search',
                                        style: { color: '#54F5B7', borderColor: '#54F5B7' },
                                        startAdornment: <InputAdornment position='start'>
                                            <IconButton
                                                aria-label='search'
                                                edge='end'
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    fullWidth
                                    onChange={e => setSearchkey(e.target.value)}
                                />
                            </div>
                            <StickyBox offsetTop={20} style={{ zIndex: 9999, alignSelf: 'center' }} className='col-lg-3 col-md-3 col-sm-12 mb-3'>
                                {selectedNfts.length > 1 ? (
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button className="step-button eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickBorrow}>
                                            Borrow
                                        </button>
                                    </div>
                                ) : (
                                    activeStep < 1 && (
                                        <div className='d-flex justify-content-end gap-3'>
                                            <button className="step-button eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickNextButton}>
                                                {'Next >'}
                                            </button>
                                        </div>
                                    )
                                )}
                            </StickyBox>
                            {
                                nftLoading === 1 ?
                                    (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status" />
                                        </div>
                                    )
                                    : visibleNfts.length > 0 ? visibleNfts.map((nft: NftWithIndexType, index: number) => {
                                        // const nftRarity = rarity?.filter((rarity: NftRarity) => rarity.identifier === nft.identifier);
                                        // if (nftRarity.length > 0 || (isDev && nftRarity.length >= 0)) {
                                            return (
                                                <div className='col-6 col-md-4 col-lg-3 p-2' key={`b-s-n-${index}`}>
                                                    <div className={`nft-card ${selectedNftIndex === nft.index ? 'selected' : ''}`} onClick={() => setSelectedNftIndex(nft.index)}>
                                                        {nft.url?.includes('.mp4') ? (
                                                            <ReactVideoPlay
                                                                sources={[
                                                                    {
                                                                        name: '720p',
                                                                        source: [{
                                                                            source: nft.url,
                                                                            type: VideoSourceType.video_mp4
                                                                        }]
                                                                    }
                                                                ]}
                                                                poster={isDev ? unknown_logo : getImageUrl(nft.collection, nft.identifier)}
                                                                enableAdv={false}
                                                                enableSlider={false}
                                                                autoplay={false}
                                                                muted={true}
                                                                controls={false}
                                                            />
                                                        ) : (
                                                            <LazyLoadImage className='mt-2 nft-card-image' style={{ width: '100%', objectFit: 'cover' }} src={isDev ? nft.url : getImageUrl(nft.collection, nft.identifier)} alt={nft.name} effect="blur" />
                                                        )}
                                                        <div className='mt-2'>{nft.name}</div>
                                                        {/*{!isDev && (<div className='mt-1'>{`Rank: ${nftRarity[0].metadata.rarity.rank}`}</div>)}*/}
                                                        <div className='mt-1 d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex gap-2 align-items-center'>
                                                                <img className='egld-badge' src={egld_white_logo} />
                                                                <span>
                                                                    {nftPricesLoading === 1 ? (
                                                                        <div className="d-flex justify-content-center">
                                                                            <div className="spinner-border" role="status" />
                                                                        </div>
                                                                    ) : (
                                                                        nftPrices.length > nft.index ? convertBigNumberValueToLocalString(convertWeiToEsdt(nftPrices[nft.index])) : '-'
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {selectedNfts.includes(nft.index) ? (
                                                                <RemoveCircleOutlineIcon style={{ cursor: 'pointer', fontSize: '26px' }} onClick={() => handleRemoveNft(nft.index)} />
                                                            ) : (
                                                                <AddCircleOutlineIcon style={{ cursor: 'pointer', fontSize: '26px' }} onClick={() => handleAddNft(nft.index)} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        // }
                                    }) : <div className='text-center text-color-gray' style={{ fontSize: '1.25rem', marginTop: '1rem' }}>No Result</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
