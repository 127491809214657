import axios from "axios";
import { JEWEL_BACKEND_API } from "config";
import { JewelPoint } from "z/types";
import { AshswapFarm, AshswapFarmCommonSetting, AshswapPosition, JewelswapFarm } from "z/types/farm";
import { JewelHistory } from "z/types/history";
import { ERROR_JEWEL_API_FAILED, toastError } from "z/utils";

export async function getAshswapCommonSetting(): Promise<AshswapFarmCommonSetting | undefined> {
    try {
        const url = `${JEWEL_BACKEND_API}/farms/ashswap/setting`;
        const { data } = await axios.get<AshswapFarmCommonSetting>(url);

        return data;
    } catch (e) {
        console.log('getAshswapCommonSetting: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return undefined;
}

export async function getAshswapFarms(): Promise<AshswapFarm[]> {
    try {
        const url = `${JEWEL_BACKEND_API}/farms/ashswap/farms`;
        const { data } = await axios.get<AshswapFarm[]>(url);

        return data;
    } catch (e) {
        console.log('getAshswapFarms: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return [];
}

export async function getAshswapPositions(address: string): Promise<AshswapPosition[]> {
    try {
        const url = `${JEWEL_BACKEND_API}/farms/ashswap/positions/${address}`;
        const { data } = await axios.get<AshswapPosition[]>(url);

        return data;
    } catch (e) {
        console.log('getAshswapPositions: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return [];
}

export async function getJewelswapFarms(): Promise<JewelswapFarm[]> {
    try {
        const url = `${JEWEL_BACKEND_API}/farms/jewelswap/farms`;
        const { data } = await axios.get<JewelswapFarm[]>(url);

        return data;
    } catch (e) {
        console.log('getJewelswapFarms: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return [];
}

export async function getJewelPoints(): Promise<JewelPoint[]> {
    try {
        const url = `${JEWEL_BACKEND_API}/point`;
        const { data } = await axios.get<JewelPoint[]>(url);

        return data;
    } catch (e) {
        console.log('getJewelswapFarms: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return [];
}

export async function getJewelReferralCode(address: string): Promise<string> {
    try {
        const url = `${JEWEL_BACKEND_API}/point/referral/${address}`;
        const { data } = await axios.get<string>(url);

        return data;
    } catch (e) {
        console.log('getJewelReferralCode: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return '';
}

export async function addJewelReferringUser(address: string, code: string) {
    try {
        const url = `${JEWEL_BACKEND_API}/point/referral/${address}`;
        await axios.post(url, {
            code: code,
        });
    } catch (e) {
        console.log('addJewelReferringUser: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
}

export async function getJewelLendAprs(): Promise<JewelHistory[]> {
    try {
        const url = `${JEWEL_BACKEND_API}/history/interest-bearing-tokens/aprs/lastday`;
        const { data } = await axios.get<JewelHistory[]>(url);

        return data;
    } catch (e) {
        console.log('getJewelLendAprs: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return [];
}

export async function getJewelHatomAprs(): Promise<JewelHistory[]> {
    try {
        const url = `${JEWEL_BACKEND_API}/history/jewel-hatom-farm/aprs/lastday`;
        const { data } = await axios.get<JewelHistory[]>(url);

        return data;
    } catch (e) {
        console.log('getJewelLendAprs: ', e);
        // toastError(ERROR_JEWEL_API_FAILED);
    }
    return [];
}