import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {Tabs, Tooltip} from 'antd';
import { isMobile, isTablet } from 'react-device-detect';
import { TabButton } from 'components/TabButton/TabButton';
import { CollectionBuyTab } from 'components/CollectionBuyTab';
import { CollectionSellTab } from 'components/CollectionSellTab';
import { CollectionActivityTab } from 'components/CollectionActivityTab';
import { CollectionPoolsTab } from 'components/CollectionPoolsTab';
import { queryViewCollectionPools, queryViewCollectionNfts, queryViewCollectionActivities, queryViewCollectionData } from 'z/elrond';
import { useGetNetworkConfig, useGetPendingTransactions, useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { NftCollectionPoolInfo, NftWithAmmData, PoolActivity, NftCollection, NftRarity } from 'z/types';
import {convertBigNumberValueToLocalString, convertWeiToEsdt, sleep} from 'z/utils';
import { getAmmNftsByCollectionFromApi, getRarityFromXoxno } from 'z/api';
import BigNumber from 'bignumber.js';
import { AmmCollectionContext } from 'App';
import { NFT_AMM_SC_ADDRESS } from 'config';
import { Spinner } from 'components/Spinner';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { CollectionLogo } from 'components/CollectionLogo';
import {InfoCircleOutlined} from "@ant-design/icons";

const { TabPane } = Tabs;

export const Collection = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const navigate = useNavigate();
    const params = useParams();

    const { ammCollections } = useContext(AmmCollectionContext);

    const [collectionInfo, setCollectionInfo] = useState<NftCollection>();
    const [listedCount, setListedCount] = useState<number>(0);
    const [offerTvl, setOfferTvl] = useState<BigNumber>(new BigNumber(0));
    const [poolsLoading, setPoolsLoading] = useState<boolean>(false);
    const [poolNfts, setPoolNfts] = useState<NftWithAmmData[]>([]);
    const [userNfts, setUserNfts] = useState<NftWithAmmData[]>([]);
    const [pools, setPools] = useState<NftCollectionPoolInfo[]>([]);
    const [activityLoading, setActivityLoading] = useState<boolean>(false);
    const [activities, setActivities] = useState<PoolActivity[]>([]);

    const [rarity, setRarity] = useState<NftRarity[]>([]);

    useEffect(() => {
        if (params.collectionId && params.collectionId.length > 0 && ammCollections && ammCollections.length > 0) {
            const info: NftCollection[] = ammCollections.filter((collectionInfo: NftCollection) => { return collectionInfo.nft_token_id == params.collectionId; });
            if (info.length > 0) {
                setCollectionInfo(info[0]);
            }
        }
    }, [ammCollections]);

    useEffect(() => {

        if (hasPendingTransactions) return;

        (async () => {
            if (params.collectionId && params.collectionId.length > 0) {
                setPoolsLoading(true);

                const [_pools, _colletionNfts] = await queryViewCollectionData(apiAddress, params.collectionId);
                let totalNftCount = 0;
                let tvl = new BigNumber(0);
                _pools.map((pool: NftCollectionPoolInfo) => {
                    totalNftCount += Number(pool.pool_nft_token_reserve);
                    tvl = tvl.plus(pool.pool_swap_token_reserve);
                });
                setPools(_pools);
                setListedCount(totalNftCount);
                setOfferTvl(tvl);

                // console.log('_colletionNfts: ', _colletionNfts);
                const _nfts = await getAmmNftsByCollectionFromApi(apiAddress, NFT_AMM_SC_ADDRESS, params.collectionId, true, _colletionNfts);
                // console.log('_nfts: ', _nfts);
                setPoolNfts(_nfts);

                if (isLoggedIn) {
                    const _userNfts = await getAmmNftsByCollectionFromApi(apiAddress, address, params.collectionId, false);
                    // console.log('_userNfts: ', _userNfts);
                    setUserNfts(_userNfts);
                }

                const _rarity: NftRarity[] = await getRarityFromXoxno(params.collectionId);
                // console.log('_rarity: ', _rarity);
                setRarity(_rarity);
                setPoolsLoading(false);
            }
        })();
        (async () => {
            if (params.collectionId && params.collectionId.length > 0) {
                setActivityLoading(true);
                const _activities = await queryViewCollectionActivities(apiAddress, params.collectionId);
                // console.log('_activities: ', _activities);
                setActivities(_activities);
                setActivityLoading(false);
            }
        })();
    }, [hasPendingTransactions]);

    return (
        <div className='container amm-container' style={{ marginTop: '70px' }}>
            {poolsLoading ? (
                <Spinner />
            ) : (
                <>
                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                        {'< Back'}
                    </div>
                    <div className='row mt-5'>
                        <div className='col-lg-3 col-md-3 col-sm-12 d-flex justify-content-center'>
                            <CollectionLogo className='amm-logo' collectionId={collectionInfo?.nft_token_id} />
                        </div>
                        <div className='col-lg-9 col-md-9 col-sm-12'>
                            <div className='container'>
                                <div className='row mt-2'>
                                    <div className='col-12 m-2'>
                                        <div className='d-flex align-items-center'>
                                        <h3>{collectionInfo?.nft_collection_name} ({collectionInfo?.nft_token_id})
                                        </h3>
                                            <Tooltip
                                                placement="top"
                                                title="NFTs traded exclude royalties and traders are encouraged to pay royalties direct to projects should they wish to enjoy the benefits of the project."
                                            >
                                                <InfoCircleOutlined className='h4 p-1' />
                                            </Tooltip>

                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <div className='d-flex align-items-center'>
                                                <div>floor price</div>
                                                <Tooltip
                                                    placement="top"
                                                    title="Price of the cheapest NFT listed best offer. Price include fees."
                                                >
                                                    <InfoCircleOutlined className='p-1' />
                                                </Tooltip>
                                            </div>
                                            <div className='mt-2 d-flex gap-1 align-items-center'>
                                                <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                                {collectionInfo && convertBigNumberValueToLocalString(convertWeiToEsdt(collectionInfo.nft_floor_price))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <div className='d-flex align-items-center'>
                                                <div>best offer</div>
                                                <Tooltip
                                                    placement="top"
                                                    title="Value of the highest collection offer. Offer include fees."
                                                >
                                                    <InfoCircleOutlined className='p-1' />
                                                </Tooltip>
                                            </div>
                                            <div className='mt-2 d-flex gap-1 align-items-center'>
                                                <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                                {collectionInfo && convertBigNumberValueToLocalString(convertWeiToEsdt(collectionInfo.nft_best_offer_price))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <div className='d-flex align-items-center'>
                                                <div>offer TVL</div>
                                                <Tooltip
                                                    placement="top"
                                                    title="Total amount of EGLD locked in collection"
                                                >
                                                    <InfoCircleOutlined className='p-1' />
                                                </Tooltip>
                                            </div>
                                            <div className='mt-2 d-flex gap-1 align-items-center'>
                                                <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                                {collectionInfo && convertBigNumberValueToLocalString(convertWeiToEsdt(offerTvl))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>listed</span>
                                            <div className='mt-2'>{listedCount}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4 mb-2'>
                        <div className='col-12 d-flex justify-content-center'>
                            <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={() => navigate(`/create-pool/${params.collectionId}`)}>
                                create pool
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-12'>
                            <Tabs defaultActiveKey="buy" centered={isMobile || isTablet ? false : true}>
                                <TabPane
                                    key="buy"
                                    tab={<TabButton title="buy" data={poolNfts.length} />}
                                >
                                    <CollectionBuyTab items={poolNfts} rarity={rarity} />
                                </TabPane>
                                <TabPane
                                    key="sell"
                                    tab={<TabButton title="sell" data={userNfts.length} />}
                                >
                                    <CollectionSellTab items={userNfts} rarity={rarity} bestOffer={collectionInfo?.nft_best_offer_price} />
                                </TabPane>
                                <TabPane key="activity" tab="activity">
                                    <CollectionActivityTab activities={activities} activityLoading={activityLoading} collectionId={params.collectionId} />
                                </TabPane>
                                <TabPane
                                    key="pools"
                                    tab={<TabButton title="pools" data={pools.length} />}
                                >
                                    <CollectionPoolsTab pools={pools} poolsLoading={poolsLoading} collectionId={params.collectionId} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
