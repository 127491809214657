import React from 'react';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const LendPoolHeader = (props: any) => {
    return (
        <>
            {!isMobile && (
                <div className='row mt-5' style={{ justifyContent: "center" }}>
                    <div className={`d-flex align-items-center justify-content-center ${props.isApr ? 'col-lg-2 col-md-2 col-sm-2' : 'col-lg-3 col-md-3 col-sm-3'}`}>
                        <div>Pool</div>
                    </div>
                    <div className={`d-flex justify-content-center align-items-center ${props.isApr ? 'col-lg-2 col-md-2 col-sm-2' : 'col-lg-1 col-md-1 col-sm-1'}`}>
                        <div className='d-flex'>
                            <div>{`APY${props.isApr ? ' / APR(24H)' : ''}`}</div>
                            <Tooltip
                                placement='top'
                                title='Real Yield APY'
                            >
                                <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 d-flex'>
                        {
                            props.isFarmLend ? (
                                <div className='col-lg-6 col-md-6 col-sm-6 text-center align-items-center'>
                                    <div>Total Supply /</div>
                                    <div>Max Borrow</div>
                                </div>
                            ) : (
                                <div className='col-lg-6 col-md-6 col-sm-6 text-center align-items-center'>
                                    <div>Total Supply</div>
                                </div>
                            )
                        }
                        <div className='col-lg-6 col-md-6 col-sm-6 d-flex align-items-center justify-content-center'>
                            <div>Total Borrowed</div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 d-flex align-items-center justify-content-center'>
                        <div>Utilization</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 d-flex align-items-center justify-content-end'>
                        <div>Your Balance</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2'></div>
                </div>
            )}
        </>
    );
};
