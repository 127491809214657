import React from 'react';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  MetamaskProxyButton,
  
} from '@multiversx/sdk-dapp/UI';
import extensionLogo from 'assets/img/wallet/extension.svg';
import ledgerLogo from 'assets/img/wallet/ledger.svg';
import maiarLogo from 'assets/img/wallet/maiar.svg';
import webWalletLogo from 'assets/img/wallet/web-wallet.svg';
import metamaskLogo from 'assets/img/wallet/metamask.svg';
import { AuthRedirectWrapper } from 'components';
import { walletConnectV2ProjectId } from 'config';
import { routeNames } from 'routes';

import { MetamaskLoginButton } from '@multiversx/sdk-dapp/UI/metamask/MetamaskLoginButton/MetamaskLoginButton';

const UnlockPage = () => {
  const commonProps = {
    nativeAuth: true, // optional
    callbackRoute: routeNames.home,
  };

  return (
    <div className='home d-flex flex-fill align-items-center'>
      <div className='m-auto' data-testid='unlockPage'>
        <div className='my-4 text-center info-item'>
          <div className='card-body py-4 px-2 px-sm-2 mx-lg-4' style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <WalletConnectLoginButton
              loginButtonText={'xPortal App'}
              {...commonProps}
              {...(walletConnectV2ProjectId
                ? {
                  isWalletConnectV2: true
                }
                : {})}
              className='wallet-login'
            >
              <div className='wallet-content'>
                <div className='wallet-icon'>
                  <img src={maiarLogo} alt='xPortal app' />
                </div>
                {'xPortal app'}
              </div>
            </WalletConnectLoginButton>

            <ExtensionLoginButton
              loginButtonText={'DeFi Wallet'}
              className='wallet-login'
              {...commonProps}
            >
              <div className='wallet-content'>
                <div className='wallet-icon'>
                  <img src={extensionLogo} alt='Extension' />
                </div>
                {'DeFi Wallet'}
              </div>
            </ExtensionLoginButton>

            <LedgerLoginButton
              loginButtonText={'Ledger'}
              className='wallet-login'
              {...commonProps}
            >
              <div className='wallet-content'>
                <div className='wallet-icon'>
                  <img src={ledgerLogo} alt='Ledger' />
                </div>
                {'Ledger'}
              </div>
            </LedgerLoginButton>

            <WebWalletLoginButton
              loginButtonText={'Elrond Web wallet'}
              className='wallet-login'
              {...commonProps}
            >
              <div className='wallet-content'>
                <div className='wallet-icon'>
                <img src={webWalletLogo} alt='Web Wallet' />
                </div>
                {'Web Wallet'}
              </div>
            </WebWalletLoginButton>

            <MetamaskProxyButton
              loginButtonText='MetaMask Proxy'
              className='wallet-login'
              {...commonProps}>
                <div className='wallet-content'>
                <div className='wallet-icon'>
                <img src={metamaskLogo} alt='MetaMask Proxy' />
                </div>
                {'MetaMask Proxy'}
              </div>
              </MetamaskProxyButton>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export const Unlock = () => (
  <AuthRedirectWrapper>
    <UnlockPage />
  </AuthRedirectWrapper>
);
