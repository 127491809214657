import React, { FC } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { COLLECTIONS_MAP } from 'data';
import { CommonSettingsType, NftWithIndexType } from 'z/types';
import {
    convertBigNumberValueToLocalString,
    convertSecondsToDays,
    convertSecondsToDaysWithWords,
    convertWeiToEsdt,
    getImageUrl,
    MAX_PERCENTAGE
} from 'z/utils';
import { isDev } from 'config';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BigNumber from 'bignumber.js';

interface PropsType {
    commonSettings: CommonSettingsType;
    selectedNft: NftWithIndexType;
    selectedNftPrice: string;
    borrowAmount: string;
    onChangeBorrowAmount: (e: string) => void;
    onMaxBorrowAmount: () => void;
    selectedBorrowPlanIndex: number;
    setSelectedBorrowPlanIndex: (e: number) => void;
    selectedNftCollectionIndex: number;
    liquidationPrice: string;
    repaymentDeadline: string;
    loanAmount: string;
    borrowAvailableAmount: string;
    borrowAmountError: string;
}

export const EnterAmount: FC<PropsType> = ({
    commonSettings,
    selectedNft,
    selectedNftPrice,
    borrowAmount,
    onChangeBorrowAmount,
    onMaxBorrowAmount,
    selectedBorrowPlanIndex,
    setSelectedBorrowPlanIndex,
    selectedNftCollectionIndex,
    liquidationPrice,
    repaymentDeadline,
    loanAmount,
    borrowAvailableAmount,
    borrowAmountError,
}) => {
    const selectedCollection = COLLECTIONS_MAP[commonSettings.collection_ids[selectedNftCollectionIndex]];
    const collateralRate = commonSettings.collection_collateral_rates[selectedNftCollectionIndex];
    return (
        <>
            <div className='d-flex flex-column' style={{ rowGap: '50px' }}>
                {
                    <div className='row gy-3 align-items-center' style={{ rowGap: '20px' }}>
                        <div className='col-lg-4 text-center'>
                            <LazyLoadImage style={{ width: '100%', objectFit: 'cover' }} src={isDev ? selectedNft.url : getImageUrl(selectedNft.collection, selectedNft.identifier)} alt={selectedNft.name} effect="blur" />
                        </div>

                        <div className='col-lg-8'>
                            <div className='row property-box'>
                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Collection Name</span>
                                        <span className='property-content'>{selectedCollection.name}</span>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Token ID</span>
                                        <span className='property-content'>{selectedNft.identifier}</span>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Token Name</span>
                                        <span className='property-content'>{selectedNft.name}</span>
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Collateral Rate</span>
                                        <span className='property-content'>{collateralRate / 100}%</span>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Platform Max Borrow</span>
                                        <div className='d-flex align-items-center'>
                                            <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                            <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings.collection_max_borrow_amounts[selectedNftCollectionIndex]))}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Platform Liquidation Rate</span>
                                        <span className='property-content'>{commonSettings.global_liquidation_rate / 100}%</span>
                                    </div>
                                </div> */}

                                <div className='col-sm-4'></div>
                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>NFT Valuation</span>
                                        <div className='d-flex align-items-center'>
                                            <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                            <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(selectedNftPrice))}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Utilization rate</span>
                                        <span className='property-content'>29.60 %</span>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Health factor</span>
                                        <span className='property-content'>2.69</span>
                                    </div>
                                </div> */}
                                <div className='col-sm-4'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Liquidation Price</span>
                                        <div className='d-flex align-items-center'>
                                            <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                            <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(loanAmount).multipliedBy(MAX_PERCENTAGE).dividedBy(commonSettings.liquidation_threshold).toFixed()))}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='text-color-gray gap-2 d-flex flex-column'>
                                {/* <h4>Select a plan</h4> */}
                                <div>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select a Plan</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedBorrowPlanIndex.toString()}
                                                label="Select a Plan"
                                                onChange={(e) => setSelectedBorrowPlanIndex(Number(e.target.value))}
                                            >
                                                {
                                                    commonSettings.borrow_rates.length > 0 ? commonSettings.borrow_rates.map((_, index) => {
                                                        return (
                                                            <MenuItem key={`b-p-${index}`} value={index}>{convertSecondsToDaysWithWords(commonSettings.max_borrow_periods[index], 2)} - Interest Rate: {commonSettings.borrow_rates[index] / 100}%</MenuItem>
                                                        );
                                                    })
                                                        : (
                                                            <div className="d-flex justify-content-center">
                                                                <div className="spinner-border" role="status" />
                                                            </div>
                                                        )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>

                                {/* <div className='d-flex justify-content-between'>
                                    <span>Max Borrow Period</span>
                                    <span style={{ fontSize: '1rem', color: 'white' }}>{borrowPlans.length > 0 && selectedBorrowPlanIndex >= 0 && convertSecondsToDays(borrowPlans[selectedBorrowPlanIndex].max_borrow_period)} days</span>
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <span>Interest Rate</span>
                                    <span style={{ fontSize: '1rem', color: 'white' }}>{borrowPlans.length > 0 && selectedBorrowPlanIndex >= 0 && borrowPlans[selectedBorrowPlanIndex].interest_rate}%</span>
                                </div> */}
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='row property-box text-center'>
                                <div className='col-sm-6'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Max Borrow Period</span>
                                        <span className='property-content'>{convertSecondsToDaysWithWords(commonSettings.max_borrow_periods[selectedBorrowPlanIndex], 2)}</span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Interest Rate</span>
                                        <span className='property-content'>{commonSettings.borrow_rates[selectedBorrowPlanIndex] / 100}%</span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Repayment Deadline</span>
                                        <span className='property-content' style={{ fontSize: '16px' }}>{repaymentDeadline}</span>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='nft-property'>
                                        <span className='property-name'>Loan Amount</span>
                                        <div className='d-flex justify-content-center'>
                                            <img className='egld-badge' src={egld_white_logo} alt="EGLD" />
                                            <div className='property-content' style={{ marginLeft: '8px' }}>{convertBigNumberValueToLocalString(convertWeiToEsdt(loanAmount))}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='borrow-form' >
                                <div className='d-flex justify-content-between'>
                                    <div className='borrow-coin-logo'>
                                        <img src={egld_white_logo} alt="EGLD" />
                                        <span>EGLD</span>
                                    </div>
                                    <input type="number" className="borrow-input" value={borrowAmount} onChange={(e) => onChangeBorrowAmount(e.target.value)} />
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span style={{ fontSize: '0.85rem' }}>{`Available to Borrow: ${convertBigNumberValueToLocalString(convertWeiToEsdt(borrowAvailableAmount))} EGLD`}</span>
                                    <button className='max-but' onClick={onMaxBorrowAmount}>MAX</button>
                                </div>
                                {borrowAmountError && (
                                    <div className='d-flex justify-content-end text-danger mt-1' style={{ fontSize: '.8rem' }}>
                                        {borrowAmountError}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};
