import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import unknown_logo from 'assets/img/unknown.png';
import { isDev } from 'config';

export const CollectionLogo = (props: any) => {

    const { collectionId, className } = props;

    useEffect(() => {
        try {
            if (collectionId == "GNOGONS-73222b") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmdCEkyaafqZgmeydXfyWmB71qhHf1WSyGmRtv1SzDFq31/3784.png`);
            }
            else if (collectionId == "QWTCOINS-27203b") {
                setLogoUrl(`https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/qwat.png`);
            }
            else if (collectionId == "QWTCARDS-17f15b") {
                setLogoUrl(`https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/qwat.png`);
            }
            else if (collectionId == "GUARDIAN-3d6635") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmZNd9qktA8z31ZtkdwgEozF2iW1B6P29sZxxdvWYgqeXn/9071.png`);
            }
            else if (collectionId == "BRICKS-8b0bcb") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmWZaMNkUxVS2p4J4hwL494HJVTwSG7BbeXtPFHLDJwKdJ/1922.jpg`);
            }
            else if (collectionId == "SRB-61daf7") {
                setLogoUrl(`https://rare.mypinata.cloud/ipfs/QmQxAVDQ9vpbiK32UiwwCACCGhJBmZS49VgavnCFvssmtr/5635.png`);
            }
            else if (collectionId == "OGS-3f1408") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmXEDuYmTTGyoSiv5AxTdeLBttbvFEqZme8vxeY9B6XNLm/247.png`);
            }
            else if (collectionId == "LIGHTNINGB-496265") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmV1SYpotkBszahut92fL1J9sLioYLrGx5Eezv7m31MiE9/3339.png`);
            }
            else if (collectionId == "INNOVATOR-fca3a7") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmQrdQyYSPPqGTQFrADjVA3pZUHQgkgdEddHAWRbMgR196/325.png`);
            }
            else if (collectionId == "WHALEYS-24a32a") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmYKGKToZUv4kdfFD5n6wmjDXwaCbHywS6Bs9q7FdgG2WZ`);
            }
            else if (collectionId == "MOS-b9b4b2") {
                setLogoUrl(`https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/6584.png`);
            }
            else if (collectionId == "MAIARPUNKS-3db7e8") {
                setLogoUrl(`https://media.elrond.com/tokens/asset/MAIARPUNKS-3db7e8/logo.svg`);
            }
            else {
                setLogoUrl(`https://jewelswap-app.s3.amazonaws.com/nfts_collection_profiles/${collectionId}.webp`);
            }
        } catch (e) { }
    }, [collectionId]);

    const [logoUrl, setLogoUrl] = useState<string>(unknown_logo);
    return (
        <>
            {className === 'collection-image mx-2' ? (
                <img className={className} src={isDev ? unknown_logo : logoUrl} alt={collectionId} />
            ) : (
                <LazyLoadImage className={className} src={isDev ? unknown_logo : logoUrl} alt={collectionId} effect="blur" />
            )}
        </>
    );
};
