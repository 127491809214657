import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, getTokenDecimal, getTokenImage, getTokenTicker, PERCENT_DENOMINATOR } from 'z/utils';
import { JewelGlobalPoolCommonContext, JewelGlobalPoolContext, JewelGlobalUserPoolContext } from 'z/types';
import BigNumber from 'bignumber.js';

interface GlobalPositionProps {
    pool: JewelGlobalUserPoolContext,
    isSupplied: boolean,
    jewelGlobalCommonContext: JewelGlobalPoolCommonContext | undefined,
    jewelGlobalPools: JewelGlobalPoolContext[],
    handleOpenModal: (type: boolean, mm_pool: JewelGlobalPoolContext) => void,
}

export const GlobalPosition: FC<GlobalPositionProps> = ({
    pool,
    isSupplied,
    jewelGlobalCommonContext,
    jewelGlobalPools,
    handleOpenModal,
}) => {

    const [jewelGlobalPool, setJewelGlobalPool] = useState<JewelGlobalPoolContext | undefined>();
    useEffect(() => {
        if (!pool || jewelGlobalPools.length === 0) return;
        (async () => {
            for (let i = 0; i < jewelGlobalPools.length; i++) {
                if (jewelGlobalPools[i].token_id === pool.token_id) {
                    setJewelGlobalPool(jewelGlobalPools[i]);

                    break;
                }
            }
        })();
    }, [pool, jewelGlobalPools]);

    return (
        <>
            {isMobile ? (
                jewelGlobalPool && (
                    <div className='row position-container mobile-position-container'>
                        <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={getTokenImage(pool.token_id)} className='egld-image mx-2' alt={pool.token_id}></img>
                                <div>
                                    <div>{`${getTokenTicker(pool.token_id)}}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12 d-flex justify-content-between align-items-center mt-1'>
                            <div style={{ width: '30%' }}>{isSupplied ? 'Supplied' : 'Borrowed'}</div>
                            <div style={{ width: '60%', textAlign: 'right' }}>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(isSupplied ? pool.supplied_amount : pool.debt_amount, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                            <div>APY:</div>
                            <div className='avaliable-color'>{isSupplied ? `${jewelGlobalCommonContext && BigNumber(pool.supplied_amount).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(jewelGlobalPool.borrow_apr, 4).multipliedBy(100 - jewelGlobalCommonContext.treasury_percent / PERCENT_DENOMINATOR).dividedBy(100).multipliedBy(BigNumber(jewelGlobalPool.debt_amount).dividedBy(jewelGlobalPool.supplied_amount))) : '-'} %` : `-${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelGlobalPool.borrow_apr, 4))} %`}</div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12 align-self-center mt-3'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(true, jewelGlobalPool)}>
                                        {isSupplied ? 'Supply More' : 'Borrow More'}
                                    </div>
                                </div>
                                <div className='col-12 d-flex justify-content-center mt-2'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(false, jewelGlobalPool)}>
                                        {isSupplied ? 'Withdraw' : 'Repay'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                jewelGlobalPool && (
                    <div className='row position-container'>
                        <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                            <div className='d-flex align-items-center'>
                                <img src={getTokenImage(pool.token_id)} className='egld-image mx-2' alt={pool.token_id}></img>
                                <div>
                                    <div>{`${getTokenTicker(pool.token_id)}`}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 d-flex align-items-center justify-content-center'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(isSupplied ? pool.supplied_amount : pool.debt_amount, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center'>
                            <div className='avaliable-color'>{isSupplied ? `${jewelGlobalCommonContext  && BigNumber(pool.supplied_amount).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(jewelGlobalPool.borrow_apr, 4).multipliedBy(100 - jewelGlobalCommonContext.treasury_percent / PERCENT_DENOMINATOR).dividedBy(100).multipliedBy(BigNumber(jewelGlobalPool.debt_amount).dividedBy(jewelGlobalPool.supplied_amount))) : '-'} %` : `-${convertBigNumberValueToLocalString(convertWeiToEsdt(jewelGlobalPool.borrow_apr, 4))} %`}</div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 align-self-center'>
                            <div className='row'>
                                <div className='col-6 d-flex justify-content-center'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(true, jewelGlobalPool)}>
                                        {isSupplied ? 'Supply More' : 'Borrow More'}
                                    </div>
                                </div>
                                <div className='col-6 d-flex justify-content-center'>
                                    <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenModal(false, jewelGlobalPool)}>
                                        {isSupplied ? 'Withdraw' : 'Repay'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};
