import React, { useEffect } from 'react';
import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Toaster } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { addJewelReferringUser } from 'z/api';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = useGetIsLoggedIn();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const ref = searchParams.get('ref');
    if (ref) {
      // save to local storage
      localStorage.setItem('jewel-ref', ref);
    }

    const referralCode = localStorage.getItem('jewel-ref');

    if (isLoggedIn && referralCode) {
      // post request to api
      (async () => {
        await addJewelReferringUser(address, referralCode);
      })();
    }
  }, [searchParams, isLoggedIn]);

  return (
    <div className='d-flex flex-column flex-fill wrapper valar-main'>
      <Navbar />
      <main className='d-flex flex-column flex-grow-1'>
        {/* <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        > */}
        {children}
        {/* </AuthenticatedRoutesWrapper> */}
        <Toaster />
        <Footer />
      </main>
    </div>
  );
};
