import { TypedValue, Address, AddressValue, BigUIntValue, BytesValue, ArgSerializer, TransactionPayload, U32Value, U64Value } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { NFT_AMM_SC_ADDRESS, WEGLD_TOKEN_ID } from 'config';
import { NftWithAmmData } from 'z/types';
import { convertEsdtToWei, EGLD_DECIMALS } from 'z/utils';
import { NFT_QUANTITY } from 'z/utils';
import { MAX_GAS_LIMIT } from '../constants';
import { refreshAccount, sendTransactions } from '../sdkDappHelpers';

export const createPool = async (
    address: string, 
    wrapAddress: string, 
    collectionId: string,
    poolType: number, 
    ordersAmount: number, 
    spotPrice: number, 
    delta: number, 
    lockedAmount: BigNumber, 
    tab: number, 
    fee: number, 
    addNfts: NftWithAmmData[],
) => {
    if (ordersAmount == null || ordersAmount < 0) ordersAmount = 0;
    if (delta == null || delta < 0) delta = 0;
    if (fee == null || fee < 0) fee = 0;
    if (lockedAmount.lte(new BigNumber(0))) {
        lockedAmount = new BigNumber(0);
    } else {
        lockedAmount = new BigNumber(lockedAmount.toFixed(0));
    }

    if (ordersAmount > 0 && !lockedAmount.gt(new BigNumber(0))) return;

    const paymentTokenNonce: any = 0;
    const args: TypedValue[] = [
        new AddressValue(new Address(NFT_AMM_SC_ADDRESS)),
    ];
    if (lockedAmount.gt(new BigNumber(0))) {
        args.push(new U32Value(addNfts.length + 1));
        args.push(BytesValue.fromUTF8(WEGLD_TOKEN_ID));
        args.push(new U64Value(paymentTokenNonce));
        args.push(new BigUIntValue(lockedAmount));
    } else {
        args.push(new U32Value(addNfts.length));
    }

    let count = 0;
    for (let i = 0; i < addNfts.length; i++) {
        args.push(BytesValue.fromUTF8(addNfts[i].collection));
        args.push(new U64Value(addNfts[i].nonce));
        const balance = Number(addNfts[i].nft_token_amount);
        count += balance;
        args.push(new BigUIntValue(balance.valueOf()));
    }
    args.push(BytesValue.fromUTF8('createPool'));
    args.push(BytesValue.fromUTF8(collectionId));
    args.push(BytesValue.fromUTF8('EGLD'));
    args.push(new U32Value(EGLD_DECIMALS));
    args.push(new U32Value(Math.floor(fee * 100)));
    args.push(new BigUIntValue(convertEsdtToWei(spotPrice, EGLD_DECIMALS).valueOf()));
    args.push(new U32Value(tab));
    if (tab === 0) {
        args.push(new BigUIntValue(convertEsdtToWei(delta, EGLD_DECIMALS).valueOf()));
    } else if (tab === 1) {
        args.push(new U32Value(Math.floor(delta * 100)));
    }
    args.push(new U32Value(ordersAmount));
    args.push(new BigUIntValue(count.valueOf()));
    args.push(new U32Value(poolType));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

    let gasLimit = 90000000 + 1500 * data.length() + 1100000 * (addNfts.length + 1);
    if (gasLimit > MAX_GAS_LIMIT) {
        gasLimit = MAX_GAS_LIMIT;
    }
    const tx: any = [
        {
            receiver: address,
            gasLimit: gasLimit,
            value: 0,
            data: data.toString(),
        }
    ];

    if (lockedAmount.gt(new BigNumber(0))) {
        const paymentTx = {
            receiver: wrapAddress,
            gasLimit: 6000000,
            value: lockedAmount,
            data: 'wrapEgld',
        };
        tx.unshift(paymentTx);
    }

    await refreshAccount();
    const result = await sendTransactions({
        transactions: tx,
    });

    return result;

};

export const savePool = async (
    address: string, 
    wrapAddress: string, 
    collectionId: string, 
    poolId: number, 
    ordersAmount: number, 
    spotPrice: number, 
    delta: number, 
    lockedAmount: BigNumber, 
    tab: number, 
    fee: number, 
    addNfts: NftWithAmmData[], 
    removeNfts: NftWithAmmData[]
) => {
    if (ordersAmount == null || ordersAmount < 0) ordersAmount = 0;
    if (delta == null || delta < 0) delta = 0;
    if (fee == null || fee < 0) fee = 0;
    if (lockedAmount.lte(new BigNumber(0))) {
        lockedAmount = new BigNumber(0);
    } else {
        lockedAmount = new BigNumber(lockedAmount.toFixed(0));
    }

    if (lockedAmount.eq(new BigNumber(0)) && addNfts.length == 0) {
        // function call
        const args: TypedValue[] = [
            BytesValue.fromUTF8(collectionId),
            new U32Value(poolId),
        ];
        args.push(new U32Value(Math.floor(fee * 100)));
        args.push(new BigUIntValue(convertEsdtToWei(spotPrice, EGLD_DECIMALS).valueOf()));
        if (tab === 0) {
            args.push(new BigUIntValue(convertEsdtToWei(delta, EGLD_DECIMALS).valueOf()));
        } else if (tab === 1) {
            args.push(new U32Value(Math.floor(delta * 100)));
        }
        args.push(new U32Value(ordersAmount));
        for (let i = 0; i < removeNfts.length; i++) {
            args.push(BytesValue.fromUTF8(removeNfts[i].collection));
            args.push(new U64Value(removeNfts[i].nonce));
            args.push(new BigUIntValue(removeNfts[i].nft_token_amount));
        }

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = `editPool@${argumentsString}`;

        let gasLimit = 18000000 + 1500 * data.length + 1100000 * (removeNfts.length + 1);
        if (gasLimit > MAX_GAS_LIMIT) {
            gasLimit = MAX_GAS_LIMIT;
        }

        const tx = {
            receiver: NFT_AMM_SC_ADDRESS,
            gasLimit: gasLimit,
            value: 0,
            data: data,
        };

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        return result;
    } else {
        // token transfer
        const paymentTokenNonce: any = 0;
        const args: TypedValue[] = [
            new AddressValue(new Address(NFT_AMM_SC_ADDRESS)),
        ];
        if (lockedAmount.gt(new BigNumber(0))) {
            args.push(new U32Value(addNfts.length + 1));
            args.push(BytesValue.fromUTF8(WEGLD_TOKEN_ID));
            args.push(new U64Value(paymentTokenNonce));
            args.push(new BigUIntValue(lockedAmount));
        } else {
            args.push(new U32Value(addNfts.length));
        }

        for (let i = 0; i < addNfts.length; i++) {
            args.push(BytesValue.fromUTF8(addNfts[i].collection));
            args.push(new U64Value(addNfts[i].nonce));
            args.push(new BigUIntValue(addNfts[i].nft_token_amount));
        }
        args.push(BytesValue.fromUTF8('editPool'));
        args.push(BytesValue.fromUTF8(collectionId));
        args.push(new U32Value(poolId));
        args.push(new U32Value(Math.floor(fee * 100)));
        args.push(new BigUIntValue(convertEsdtToWei(spotPrice, EGLD_DECIMALS).valueOf()));
        if (tab === 0) {
            args.push(new BigUIntValue(convertEsdtToWei(delta, EGLD_DECIMALS).valueOf()));
        } else if (tab === 1) {
            args.push(new U32Value(Math.floor(delta * 100)));
        }
        args.push(new U32Value(ordersAmount));
        for (let i = 0; i < removeNfts.length; i++) {
            args.push(BytesValue.fromUTF8(removeNfts[i].collection));
            args.push(new U64Value(removeNfts[i].nonce));
            args.push(new BigUIntValue(removeNfts[i].nft_token_amount));
        }

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

        let gasLimit = 90000000 + 1500 * data.length() + 1100000 * (addNfts.length + removeNfts.length + 1);
        if (gasLimit > MAX_GAS_LIMIT) {
            gasLimit = MAX_GAS_LIMIT;
        }
        const tx: any = [
            {
                receiver: address,
                gasLimit: gasLimit,
                value: 0,
                data: data.toString(),
            }
        ];

        if (lockedAmount.gt(new BigNumber(0))) {
            const paymentTx = {
                receiver: wrapAddress,
                gasLimit: 6000000,
                value: lockedAmount,
                data: 'wrapEgld',
            };
            tx.unshift(paymentTx);
        }

        await refreshAccount();
        const result = await sendTransactions({
            transactions: tx,
        });

        return result;
    }

};

export const handleAmmPool = async (functionName: string, collectionId: string, poolId: number, gasLimit: number) => {
    const args: TypedValue[] = [
        BytesValue.fromUTF8(collectionId),
        new U32Value(poolId),
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `${functionName}@${argumentsString}`;

    const tx = {
        receiver: NFT_AMM_SC_ADDRESS,
        gasLimit: gasLimit,
        value: 0,
        data: data,
    };

    await refreshAccount();
    const result = await sendTransactions({
        transactions: tx,
    });

    return result;
};

export const swapEgldForNft = async (nft: NftWithAmmData, price: string) => {
    if (nft.pool_id < 1) return;
    const args: TypedValue[] = [
        BytesValue.fromUTF8(nft.collection),
        new U32Value(nft.pool_id),
        new U32Value(NFT_QUANTITY),
        BytesValue.fromUTF8(nft.collection),
        new U64Value(nft.nonce),
        new BigUIntValue(NFT_QUANTITY)
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `swapEgldForNft@${argumentsString}`;

    const tx = {
        receiver: NFT_AMM_SC_ADDRESS,
        gasLimit: 60000000,
        value: price,
        data: data,
    };

    await refreshAccount();
    const result = await sendTransactions({
        transactions: tx,
    });

    return result;
};

export const swapNftForEgld = async (nft: NftWithAmmData, address: string) => {
    const args: TypedValue[] = [
        BytesValue.fromUTF8(nft.collection),
        new U64Value(nft.nonce),
        new BigUIntValue(NFT_QUANTITY),
        new AddressValue(new Address(NFT_AMM_SC_ADDRESS)),
        BytesValue.fromUTF8('swapNftForEgld'),
        BytesValue.fromUTF8(nft.collection),
        new U32Value(NFT_QUANTITY)
    ];
    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`ESDTNFTTransfer@${argumentsString}`);

    const tx = {
        receiver: address,
        gasLimit: 60000000,
        value: 0,
        data: data.toString(),
    };

    await refreshAccount();
    const result = await sendTransactions({
        transactions: tx,
    });

    return result;
};
