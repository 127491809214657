import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Modal } from '@mui/material';
import { animated, useSpring } from 'react-spring';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useTrackTransactionStatus, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { EPunkStepper } from 'components/Elements/stepper';
import { getNftsByCollectionFromApi, getRarityFromXoxno } from 'z/api';
import {farmAbiRegistry, queryCommonSettings, queryComputeNftPrices, sendBorrowEgld} from 'z/elrond';
import { CommonSettingsType, NftRarity, NftWithIndexType } from 'z/types';
import {
  convertBigNumberValueToLocalString,
  convertEsdtToWei,
  convertSecondsToDays,
  convertSecondsToDaysWithWords,
  convertWeiToEsdt,
  EGLD_DECIMALS,
  ERROR_CONNECT_YOUR_WALLET,
  ERROR_DATA_NOT_LOADED,
  ERROR_EXCEED_MAX_VALUE,
  ERROR_INVALID_VALUE,
  ERROR_VALUE_CANNOT_BE_NEGATIVE,
  formatLocalDateTime, getHatomUnbondTokenAttributes,
  getImageUrl, getLiquidUnstakeTokenAttributes,
  MAX_PERCENTAGE,
  parseBigNumber,
  SECOND_TO_MILLISECONDS,
  toastError
} from 'z/utils';
import { EnterAmount } from './EnterAmount';
import { Review } from './Review';
import { SelectNft } from './SelectNft';
import { routeNames } from 'routes';
import { useNavigate } from 'react-router-dom';
import { isDev, MAX_BULK_LOAN_SIZE } from 'config';
import {BinaryCodec} from "@multiversx/sdk-core/out";
import { getChainId } from 'z/elrond/sdkDappHelpers';

const fadeBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  // bgcolor: '#2b3943',
  boxShadow: 24,
  px: 3,
  py: 4,
  borderRadius: '10px',
  color: 'white',
  background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});


const steps = ['Select NFT Collateral', 'Enter Borrow Amount', 'Review'];

export const BorrowEgld = () => {
  const navigate = useNavigate();
const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
  const { address } = useGetAccount();
  // const address = 'erd126rkz9fle5uc9l6z8hqzsfkvy50zy4a4me2748drg0re0pqx6gdq4vzz9z';
  const isLoggedIn = useGetIsLoggedIn();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const [activeStep, setActiveStep] = useState<number>(0);

  const [commonSettings, setCommonSettings] = useState<CommonSettingsType | undefined>();
  const [selectedNftCollectionIndex, setSelectedNftCollectionIndex] = useState<number>(0);
  const [nfts, setNfts] = useState<NftWithIndexType[]>([]);
  const [visibleNfts, setVisibleNfts] = useState<NftWithIndexType[]>([]);
  const [nftLoading, setNftsLoading] = useState<number>(0);
  const [selectedNftIndex, setSelectedNftIndex] = useState<number>(-1);
  const [nftPrices, setNftPrices] = useState<string[]>([]);
  const [nftPricesLoading, setNftPricesLoading] = useState<number>(0);

  const [searchKey, setSearchKey] = useState<string>('');

  const [borrowAmount, setBorrowAmount] = useState<string>('0');
  const [borrowAmountError, setBorrowAmountError] = useState('');
  const [selectedBorrowPlanIndex, setSelectedBorrowPlanIndex] = useState<number>(0);

  const [liquidationPrice, setLiquidationPrice] = useState<string>('0');
  const [repaymentDeadline, setRepaymentDeadline] = useState<string>('');
  const [loanAmount, setLoanAmount] = useState<string>('0');
  const [borrowAvailableAmount, setBorrowAvailableAmount] = useState<string>('0');

  const [rarity, setRarity] = useState<NftRarity[]>([]);

  const [selectedNfts, setSelectedNfts] = useState<number[]>([]);
  const [showBorrowModal, setShowBorrowModal] = useState<boolean>(false);

  const [borrowerStatus, setBorrowerStatus] = useState<boolean>(true);
  useEffect(() => {
    if (hasPendingTransactions) return;

    (async () => {
      const _commonSettings = await queryCommonSettings(apiAddress, address);
      // console.log('_commonSettings', _commonSettings);
      setCommonSettings(_commonSettings);

      if (isLoggedIn && _commonSettings && !_commonSettings.user_borrow_status) {
        // toastError(`Max borrow is 0 EGLD since the borrower cap is ${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(_commonSettings.total_lending_amount).multipliedBy(_commonSettings.global_max_borrower_cap_percent).dividedBy(MAX_PERCENTAGE)))} EGLD right`);
        setBorrowerStatus(false);
      }
    })();
  }, [hasPendingTransactions]);

  const getNfts = () => {
    (async () => {
      if (address && commonSettings && selectedNftCollectionIndex >= 0 && commonSettings.collection_ids.length > selectedNftCollectionIndex) {
        setNftsLoading(1);
        const _nfts = await getNftsByCollectionFromApi(apiAddress, address, commonSettings.collection_ids[selectedNftCollectionIndex]);
        // console.log('_nfts', _nfts);
        setNfts(_nfts);
        setNftsLoading(0);

        if (_nfts.length > 0) {
          setNftPricesLoading(1);
          let _nftPrices = await queryComputeNftPrices(apiAddress, _nfts);
          if(commonSettings.collection_ids[selectedNftCollectionIndex] == "UJWLEGLD-17e9e9") {
            _nftPrices = [];
            for (let i = 0;i<_nfts.length;i++){
              const nft = _nfts[i];
              const attributes = getLiquidUnstakeTokenAttributes(nft);
              _nftPrices.push(attributes.unstake_amount.toFixed());
            }
          }
          else if(commonSettings.collection_ids[selectedNftCollectionIndex] == "HLSR-374950"){
            _nftPrices = [];
            for (let i = 0;i<_nfts.length;i++){
              const nft = _nfts[i];
              const attributes = getHatomUnbondTokenAttributes(nft);
              _nftPrices.push(attributes.egld_amount.toFixed());
            }
          }

          // console.log('_nftPrices', _nftPrices);
          setNftPrices(_nftPrices);

          const _rarity: NftRarity[] = await getRarityFromXoxno(commonSettings.collection_ids[selectedNftCollectionIndex]);
          // console.log('_rarity: ', _rarity);
          setRarity(_rarity);
          setNftPricesLoading(0);
        } else {
          setNftPrices([]);
        }
      }
    })();
  };

  useEffect(() => {
    setSelectedNftIndex(-1);
    setRarity([]);
    setSelectedNfts([]);
    getNfts();
  }, [selectedNftCollectionIndex, commonSettings, address]);

  useEffect(() => {
    let _visibleNfts = [];
    if (searchKey) {
      for (const nft of nfts) {
        // console.log(nft.identifier);
        if (nft.identifier.toLowerCase().includes(searchKey.toLowerCase())) {
          _visibleNfts.push(nft);
        }
      }
    } else {
      _visibleNfts = nfts;
    }
    setVisibleNfts(_visibleNfts);
  }, [nfts, searchKey]);

  const borrowerStatusMsg = () => {
    if (!commonSettings) {
      toastError(ERROR_DATA_NOT_LOADED);
      return;
    }
    toastError(`Max borrower cap is ${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(commonSettings.total_lending_amount).multipliedBy(commonSettings.global_max_borrower_cap_percent).dividedBy(MAX_PERCENTAGE)))} EGLD`);
  };

  const onClickNextButton = () => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (!borrowerStatus) {
      borrowerStatusMsg();
      return;
    }
    if (selectedNftIndex < 0 || selectedNfts.length > 1) {
      toastError('You must select an NFT');
      return;
    }
    if (!commonSettings) {
      toastError(ERROR_DATA_NOT_LOADED);
      return;
    }

    // const collection_cap_amount = BigNumber(commonSettings.total_lending_amount).multipliedBy(commonSettings.collection_max_lend_cap_percents[selectedNftIndex]).dividedBy(MAX_PERCENTAGE);
    // if (collection_cap_amount.lt(commonSettings.collection_borrow_amounts[selectedNftIndex])) {
    //   toastError(`Max borrow is 0 EGLD since the collection cap is ${convertBigNumberValueToLocalString(convertWeiToEsdt(collection_cap_amount))} EGLD right`);
    //   return;
    // }

    if (activeStep === 1) {
      if (BigNumber(borrowAmount).comparedTo(0) <= 0) {
        toastError(ERROR_INVALID_VALUE);
        return;
      }
      if (convertEsdtToWei(borrowAmount).gt(borrowAvailableAmount)) {
        toastError(`Max borrow is ${convertBigNumberValueToLocalString(convertWeiToEsdt(borrowAvailableAmount))} EGLD`);
        return;
      }
    }

    // borrowEgld
    if (activeStep === 2) {
      borrowEgld();
      return;
    }

    setActiveStep(activeStep + 1);
  };

  const onClickPrevButton = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const onClickBorrow = () => {
    if (!borrowerStatus) {
      borrowerStatusMsg();
      return;
    }

    if (selectedNfts.length <= 1) {
      return;
    }

    if (selectedNfts.length > MAX_BULK_LOAN_SIZE) {
      toastError(`Can't create more than ${MAX_BULK_LOAN_SIZE} loans`);
      return;
    }
    if (!commonSettings) return;

    // const collection_cap_amount = BigNumber(commonSettings.total_lending_amount).multipliedBy(commonSettings.collection_max_lend_cap_percents[selectedNftIndex]).dividedBy(MAX_PERCENTAGE);
    // if (collection_cap_amount.lt(commonSettings.collection_borrow_amounts[selectedNftIndex])) {
    //   toastError(`Max borrow is 0 EGLD since the collection cap is ${convertBigNumberValueToLocalString(convertWeiToEsdt(collection_cap_amount))} EGLD right`);
    //   return;
    // }

    setSelectedBorrowPlanIndex(commonSettings.max_borrow_periods.length - 1);
    setShowBorrowModal(true);
  };

  // const handleChangeBorrowAmount = (value: number) => {
  //   if (selectedNFT.price < value) {
  //     toastError('You can not borrow over max amount.', {
  //       position: 'top-right',
  //     });
  //     return;
  //   }

  //   setSelectedNFT({ ...selectedNFT, borrow: value });
  // };

  // const handleSelectNFT = (nftData: any) => {
  //   const t_selectedNFT = { ...nftData, borrow: nftData.price };
  //   setSelectedNFT(t_selectedNFT);
  //   console.log(t_selectedNFT);
  //   // setActiveStep(activeStep + 1);
  // };

  useEffect(() => {
    if (commonSettings && selectedNftCollectionIndex >= 0 && selectedNftCollectionIndex < commonSettings.collection_ids.length && selectedNftIndex >= 0 && selectedNftIndex < nfts.length && selectedNftIndex < nftPrices.length && selectedBorrowPlanIndex < commonSettings.borrow_plan_count) {
      if (selectedNfts.length > 1) {
        let minBorrowAvailableAmount = borrowAvailableAmount;
        for (let i = 0; i < selectedNfts.length; i++) {
          const _borrowAvailableAmount = BigNumber.min(
            BigNumber(nftPrices[selectedNftIndex]).multipliedBy(commonSettings.collection_collateral_rates[selectedNftCollectionIndex]).div(MAX_PERCENTAGE)
            , commonSettings.collection_max_borrow_amounts[selectedNftCollectionIndex]).toFixed();
          if (BigNumber(minBorrowAvailableAmount).comparedTo(0) <= 0) {
            minBorrowAvailableAmount = _borrowAvailableAmount;
          } else {
            minBorrowAvailableAmount = BigNumber.min(minBorrowAvailableAmount, _borrowAvailableAmount).toFixed();
          }
          setBorrowAvailableAmount(minBorrowAvailableAmount);
        }

        const _repaymentDeadline = formatLocalDateTime(Date.now() + commonSettings.max_borrow_periods[selectedBorrowPlanIndex] * SECOND_TO_MILLISECONDS);
        // console.log('_repaymentDeadline', _repaymentDeadline);
        setRepaymentDeadline(_repaymentDeadline);
      } else {
        const _liquidationPrice = BigNumber(nftPrices[selectedNftIndex]).multipliedBy(commonSettings.global_liquidation_rate).div(MAX_PERCENTAGE).toFixed();
        // console.log('_liquidationPrice', _liquidationPrice);
        setLiquidationPrice(_liquidationPrice);

        const _borrowAvailableAmount = BigNumber.min(
          BigNumber(nftPrices[selectedNftIndex]).multipliedBy(commonSettings.collection_collateral_rates[selectedNftCollectionIndex]).div(MAX_PERCENTAGE)
          , commonSettings.collection_max_borrow_amounts[selectedNftCollectionIndex]).toFixed();
        // console.log('_borrowAvailableAmount', _borrowAvailableAmount);
        setBorrowAvailableAmount(_borrowAvailableAmount);

        const _repaymentDeadline = formatLocalDateTime(Date.now() + commonSettings.max_borrow_periods[selectedBorrowPlanIndex] * SECOND_TO_MILLISECONDS);
        // console.log('_repaymentDeadline', _repaymentDeadline);
        setRepaymentDeadline(_repaymentDeadline);
      }
    }
  }, [selectedNftIndex, nftPrices, selectedBorrowPlanIndex]);

  useEffect(() => {
    if (commonSettings && selectedNftCollectionIndex >= 0 && selectedNftCollectionIndex < commonSettings.collection_ids.length && selectedNftIndex >= 0 && selectedNftIndex < nfts.length && selectedNftIndex < nftPrices.length && selectedBorrowPlanIndex < commonSettings.borrow_plan_count) {
      const _loanAmount = convertEsdtToWei(borrowAmount).multipliedBy(MAX_PERCENTAGE + commonSettings.borrow_rates[selectedBorrowPlanIndex]).div(MAX_PERCENTAGE).toFixed();
      // console.log('_loanAmount', _loanAmount);
      setLoanAmount(_loanAmount);
    }
  }, [selectedBorrowPlanIndex, borrowAmount]);

  const handleAddNft = (index: number) => {
    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (!borrowerStatus) {
      borrowerStatusMsg();
      return;
    }
    // if (selectedNftIndex < 0) {
    //   toastError('You must select an NFT');
    //   return;
    // }
    if (!commonSettings) {
      toastError(ERROR_DATA_NOT_LOADED);
      return;
    }

    // const collection_cap_amount = BigNumber(commonSettings.total_lending_amount).multipliedBy(commonSettings.collection_max_lend_cap_percents[selectedNftIndex]).dividedBy(MAX_PERCENTAGE);
    // if (collection_cap_amount.lt(commonSettings.collection_borrow_amounts[selectedNftIndex])) {
    //   toastError(`Max borrow is 0 EGLD since the collection cap is ${convertBigNumberValueToLocalString(convertWeiToEsdt(collection_cap_amount))} EGLD right`);
    //   return;
    // }

    if (selectedNfts.length < MAX_BULK_LOAN_SIZE) {
      setSelectedNfts(current => [index, ...current]);
    } else {
      toastError(`Can't create more than ${MAX_BULK_LOAN_SIZE} loans`);
    }
  };

  const handleRemoveNft = (index: number) => {
    const nfts = selectedNfts.filter((item: number) => item !== index);
    setSelectedNfts(nfts);
  };

  const onChangeBorrowAmount = (value: string) => {
    const bValue = parseBigNumber(value);
    let error = '';
    if (bValue == undefined) {
      error = 'Invalid number';
    } else if (bValue.comparedTo(0) < 0) {
      error = ERROR_VALUE_CANNOT_BE_NEGATIVE;
    } else if (convertEsdtToWei(bValue).comparedTo(borrowAvailableAmount) > 0) {
      error = ERROR_EXCEED_MAX_VALUE;
    }

    setBorrowAmountError(error);
    setBorrowAmount(value);
  };

  const onMaxBorrowAmount = () => {
    onChangeBorrowAmount(convertWeiToEsdt(borrowAvailableAmount, undefined, EGLD_DECIMALS).toFixed());
  };

  const borrowEgld = async () => {
    if (!borrowerStatus) {
      borrowerStatusMsg();
      return;
    }

    if (!address) {
      toastError(ERROR_CONNECT_YOUR_WALLET);
      return;
    }
    if (!(commonSettings && selectedNftCollectionIndex >= 0 && selectedNftCollectionIndex < commonSettings.collection_ids.length && selectedNftIndex >= 0 && selectedNftIndex < nfts.length && selectedNftIndex < nftPrices.length && selectedBorrowPlanIndex < commonSettings.borrow_plan_count)) {
      toastError(ERROR_DATA_NOT_LOADED);
      return;
    }

    // const collection_cap_amount = BigNumber(commonSettings.total_lending_amount).multipliedBy(commonSettings.collection_max_lend_cap_percents[selectedNftIndex]).dividedBy(MAX_PERCENTAGE);
    // if (collection_cap_amount.lt(commonSettings.collection_borrow_amounts[selectedNftIndex])) {
    //   toastError(`Max borrow is 0 EGLD since the collection cap is ${convertBigNumberValueToLocalString(convertWeiToEsdt(collection_cap_amount))} EGLD right`);
    //   return;
    // }

    if (selectedNfts.length > 1) {
      // multi
      const loan_amount = BigNumber(borrowAvailableAmount).multipliedBy(MAX_PERCENTAGE + commonSettings.borrow_rates[selectedBorrowPlanIndex]).div(MAX_PERCENTAGE);
      const nonces = [];
      for (let i = 0; i < selectedNfts.length; i++) {
        nonces.push(nfts[selectedNfts[i]].nonce);
      }
      const result = await sendBorrowEgld(
        chainID,
        address,
        commonSettings.collection_ids[selectedNftCollectionIndex],
        nonces,
        selectedBorrowPlanIndex,
        borrowAvailableAmount,
        loan_amount.toFixed()
      );

      setSessionId(result.sessionId);
    } else {
      if (BigNumber(borrowAmount).comparedTo(0) <= 0) {
        toastError(ERROR_INVALID_VALUE);
        return;
      }
      if (borrowAmountError) {
        toastError(borrowAmountError);
        return;
      }

      const result = await sendBorrowEgld(
        chainID,
        address,
        commonSettings.collection_ids[selectedNftCollectionIndex],
        [nfts[selectedNftIndex].nonce],
        selectedBorrowPlanIndex,
        convertEsdtToWei(borrowAmount).toFixed(),
        loanAmount
      );

      setSessionId(result.sessionId);
    }
  };

  const [sessionId, setSessionId] = useState<string>('');
  const transactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
  });

  useEffect(() => {
    if (transactionStatus.isSuccessful) {
      // console.log('Borrow EGLD request was successful');
      navigate(routeNames.loans);
    }
  }, [sessionId, hasPendingTransactions]);

  // useEffect(() => {
  //   if (ongoingSessionId > 0 && ongoingSessionId in pendingTransactions) {
  //     const tx = pendingTransactions[ongoingSessionId].transactions[0];
  //     console.log({tx});

  //     if (tx) {
  //       (async () => {
  //         const borrowEgld: BorrowEgldType = {
  //           loan_owner: address,
  //           lend_nft_tx_hash: tx.hash,
  //           collection_id: nftCollections[selectedNftCollectionIndex].collection_id,
  //           nft_id: nftsWithPrice[selectedNftIndex].identifier,
  //           nft_nonce: nftsWithPrice[selectedNftIndex].nonce,
  //           borrow_amount: convertEsdtToWei(borrowAmount).toFixed(),
  //           borrow_plan_id: borrowPlans[selectedBorrowPlanIndex].borrow_plan_id,
  //         };
  //         console.log('borrowEgld', borrowEgld);
  //         const res = await borrowEgldFromBackend(borrowEgld);
  //         console.log('borrowEgldFromBackend res', res);
  //         if (res.status === ReturnResultStatusTypeEnum.SUCCESS) {
  //           toast.success('Borrow EGLD request was successful', {
  //             position: 'top-right',
  //             duration: 5000,
  //           });

  //           // after success, sleep 5 seconds and redirect to My Loans page
  //           await sleep(5000);
  //           navigate(routeNames.loans);
  //         } else {
  //           toastError(res.message, {
  //             position: 'top-right',
  //             duration: 5000,
  //           });
  //         }

  //         // const apiNetworkProvider = new ApiNetworkProvider(apiAddress);
  //         // const watcher = new TransactionWatcher(apiNetworkProvider);
  //         // const txx = await watcher.awaitCompleted({ getHash: () => ({ hex: () => tx.hash }) });
  //         // console.log('txx', txx);

  //         // go to the first step
  //         // setActiveStep(0);
  //       })();
  //     }

  //     // clear
  //     setOngoingSessionId(0);
  //   }
  // }, [pendingTransactions]);

  return (
    <>
      <div className='container' style={{ marginTop: '40px' }}>
        <div className='info-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='title'>
              <span>Borrow EGLD</span>
              <span>Use your NFT as collateral and borrow EGLD</span>
            </div>
            <div className='egld-logo' >
              <img src={egld_white_logo} />
            </div>
          </div>

          <div style={{ marginTop: '60px' }}>
            <EPunkStepper steps={steps} activeStep={activeStep} />
          </div>

          <div style={{ marginTop: '50px' }}>
            {activeStep === 0 && (<SelectNft
              commonSettings={commonSettings}
              selectedNftCollectionIndex={selectedNftCollectionIndex}
              setSelectedNftCollectionIndex={setSelectedNftCollectionIndex}
              visibleNfts={visibleNfts}
              nftLoading={nftLoading}
              selectedNftIndex={selectedNftIndex}
              setSelectedNftIndex={setSelectedNftIndex}
              nftPrices={nftPrices}
              nftPricesLoading={nftPricesLoading}
              searchKey={searchKey}
              setSearchkey={setSearchKey}
              rarity={rarity}
              selectedNfts={selectedNfts}
              handleAddNft={handleAddNft}
              handleRemoveNft={handleRemoveNft}
              activeStep={activeStep}
              onClickBorrow={onClickBorrow}
              onClickNextButton={onClickNextButton}
            />)}
            {
              commonSettings && selectedNftCollectionIndex >= 0 && selectedNftCollectionIndex < commonSettings.collection_ids.length && selectedNftIndex >= 0 && selectedNftIndex < nfts.length && selectedNftIndex < nftPrices.length && selectedBorrowPlanIndex < commonSettings.borrow_plan_count && (<>
                {activeStep === 1 && (<EnterAmount
                  commonSettings={commonSettings}
                  selectedNft={nfts[selectedNftIndex]}
                  selectedNftPrice={nftPrices[selectedNftIndex]}
                  borrowAmount={borrowAmount}
                  onChangeBorrowAmount={onChangeBorrowAmount}
                  onMaxBorrowAmount={onMaxBorrowAmount}
                  selectedBorrowPlanIndex={selectedBorrowPlanIndex}
                  setSelectedBorrowPlanIndex={setSelectedBorrowPlanIndex}
                  selectedNftCollectionIndex={selectedNftCollectionIndex}
                  liquidationPrice={liquidationPrice}
                  repaymentDeadline={repaymentDeadline}
                  loanAmount={loanAmount}
                  borrowAvailableAmount={borrowAvailableAmount}
                  borrowAmountError={borrowAmountError}
                />)}
                {activeStep === 2 && (<Review
                  commonSettings={commonSettings}
                  selectedNft={nfts[selectedNftIndex]}
                  selectedNftPrice={nftPrices[selectedNftIndex]}
                  borrowAmount={borrowAmount}
                  selectedBorrowPlanIndex={selectedBorrowPlanIndex}
                  selectedNftCollectionIndex={selectedNftCollectionIndex}
                  liquidationPrice={liquidationPrice}
                  repaymentDeadline={repaymentDeadline}
                  loanAmount={loanAmount}
                  borrowAvailableAmount={borrowAvailableAmount}
                />)}
              </>)
            }
          </div>

          <div className='d-flex justify-content-end gap-3' style={{ marginTop: '30px' }}>
            {
              activeStep > 0 && activeStep < 3 &&
              (<div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickPrevButton}>
                {'< Prev'}
              </div>)
            }

            {
              activeStep == 1 && (
                <button className="step-button eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickNextButton}>
                  {'Next >'}
                </button>
              )
            }
            {
              activeStep == 2 && (
                <button className="step-button eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={onClickNextButton}>
                  {'Borrow'}
                </button>
              )
            }
          </div>
        </div>
        {
          commonSettings && selectedNfts.length > 1 && <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showBorrowModal}
            onClose={() => setShowBorrowModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showBorrowModal}>
              <Box sx={fadeBoxStyle} style={{ width: 'auto', maxHeight: '95%', overflowY: 'scroll' }} className='borrow-modal-box scrollbar'>
                <div className='mt-2'>
                  <table className="borrow-modal-box-table">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">NFT</th>
                        <th scope="col">Borrow</th>
                        <th scope="col">Debt</th>
                        <th scope="col">Liquidation Price</th>
                        <th scope="col">Deadline</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedNfts.map((nftIndex: number) => {
                        const nft = visibleNfts.filter((nft: NftWithIndexType) => nft.index === nftIndex)[0];
                        const _debtAmount = BigNumber(borrowAvailableAmount).multipliedBy(MAX_PERCENTAGE + commonSettings.borrow_rates[selectedBorrowPlanIndex]).div(MAX_PERCENTAGE).toFixed();
                        const _liquidationPrice = BigNumber(_debtAmount).div(commonSettings.liquidation_threshold / MAX_PERCENTAGE).toFixed();
                        return <tr key={nftIndex}>
                          <td>
                            <img style={{ width: '50px', height: '50px', borderRadius: '5px', objectFit: 'contain' }} src={isDev ? nft.url : getImageUrl(nft.collection, nft.identifier)} />
                          </td>
                          <td>{nft.identifier}</td>
                          <td className=''>
                            <div className='d-flex gap-2 justify-content-center align-items-center'>
                              <img className='egld-badge' src={egld_white_logo} alt='egld' />
                              <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(borrowAvailableAmount))}</span>
                            </div>
                          </td>
                          <td className=''>
                            <div className='d-flex gap-2 justify-content-center align-items-center'>
                              <img className='egld-badge' src={egld_white_logo} alt='egld' />
                              <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(_debtAmount))}</span>
                            </div>
                          </td>
                          <td className=''>
                            <div className='d-flex gap-2 justify-content-center align-items-center'>
                              <img className='egld-badge' src={egld_white_logo} alt='egld' />
                              <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(_liquidationPrice))}</span>
                            </div>
                          </td>
                          <td>{repaymentDeadline}</td>
                        </tr>;
                      })}
                    </tbody>
                  </table>
                </div>
                <div className='renew-debt-box mt-3'>
                  <div className='d-flex justify-content-between mt-2'>
                    <span>Max Borrow Period</span>
                    <div className='d-flex gap-2'>
                      <span>{convertSecondsToDaysWithWords(commonSettings.max_borrow_periods[selectedBorrowPlanIndex], 2)}</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mt-2'>
                    <span>Interest Rate</span>
                    <div className='d-flex gap-2'>
                      <span>{commonSettings.borrow_rates[selectedBorrowPlanIndex] / 100}%</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mt-2'>
                    <span>Borrow</span>
                    <div className='d-flex gap-2'>
                      <img className='egld-badge' src={egld_white_logo} alt='egld' />
                      <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(borrowAvailableAmount).multipliedBy(selectedNfts.length)))}</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mt-2'>
                    <span>Debt</span>
                    <div className='d-flex gap-2'>
                      <img className='egld-badge' src={egld_white_logo} alt='egld' />
                      <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(borrowAvailableAmount).multipliedBy(MAX_PERCENTAGE + commonSettings.borrow_rates[selectedBorrowPlanIndex]).div(MAX_PERCENTAGE).multipliedBy(selectedNfts.length).toFixed()))}</span>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center mt-4'>
                  <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={borrowEgld}>
                    Borrow
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        }
      </div>
    </>
  );
};
