import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { convertBigNumberValueToLocalString, convertWeiToEsdt } from 'z/utils';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { PoolCard } from 'components/PoolCard';
import { isMobile, isTablet } from 'react-device-detect';

export const CollectionPoolsTab = (props: any) => {
    const columns: GridColDef[] = [
        {
            field: 'pool_nft_token_id',
            headerName: 'collection',
            flex: 2,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_type',
            headerName: 'pool type',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_curve_type',
            headerName: 'curve type',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_spot_price',
            headerName: 'spot price',
            flex: 1,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_trade_fee_percent',
            headerName: 'fee',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_swap_token_reserve',
            headerName: 'EGLD balance',
            flex: 1,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pool_buy_order_count',
            headerName: 'buy orders',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_nft_token_reserve',
            headerName: 'sell orders',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_accumulated_fee',
            headerName: 'accumulated fee',
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_delta',
            headerName: 'delta',
            flex: 1,
            renderCell: (params) => params.value.includes('%') ? (
                <div className='d-flex gap-2'>
                    <span>{params.value}</span>
                </div>
            ) : (
                <div className='d-flex gap-2'>
                    <img className='egld-badge' src={egld_white_logo} alt='egld' />
                    <span>{convertBigNumberValueToLocalString(convertWeiToEsdt(params.value))}</span>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pool_owner',
            headerName: 'owner',
            flex: 2,
            renderCell: (params) => (
                <div className='d-flex gap-2'>
                    <span>{`${params.value.substring(0, 5)}...${params.value.slice(-5)}`}</span>
                </div>
            ),
            align: 'center',
            headerAlign: 'center',
        },
    ];

    const navigate = useNavigate();
    const handleOnCellClick = (params: any) => {
        navigate(`/collection/${props.collectionId}/${params.row.pool_id}`);
    };

    return (
        <div style={{ marginTop: '40px' }}>
            {isMobile || isTablet ? (
                props.pools?.map((item: any, index: number) => {
                    return <PoolCard key={index} item={item} />;
                })
            ) : (
                <DataGrid
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 },
                        fontSize: '18px',
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            minHeight: '400px'
                        },
                        '&:hover:not(.Mui-disabled)': {
                            cursor: 'pointer'
                        },
                        fontFamily: 'Poppins',
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    rows={props.pools}
                    columns={columns}
                    getRowId={(row) => `collection-${row.pool_id}`}
                    disableColumnMenu
                    disableSelectionOnClick
                    // hideFooter
                    loading={props.poolsLoading}
                    onCellClick={handleOnCellClick}
                />
            )}
        </div>
    );
};
