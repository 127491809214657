import React, { useEffect, useState, useContext } from 'react';
import { useGetNetworkConfig, useGetPendingTransactions, useGetAccount, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { isMobile } from 'react-device-detect';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { styled } from '@mui/material/styles';
import { InputAdornment, OutlinedInput, Slider } from '@mui/material';
import { queryGaugeCommonSetting, queryViewUserContext, sendJewelGaugeVote } from 'z/elrond';
import { JewelGaugeCommonContext, JewelGaugeUserContext, JewelVoteAllocation } from 'z/types';
import { Spinner } from 'components/Spinner';
import { convertBigNumberValueToLocalString, convertSecondsToDaysWithWords, convertSecondsToPrintDateTimeString, convertWeiToEsdt, ERROR_CONNECT_YOUR_WALLET, getTokenImage, getUserGaugeUpdatableTimestamp, PERCENT_DENOMINATOR, SECOND_TO_MILLISECONDS, toastError } from 'z/utils';
import BigNumber from 'bignumber.js';
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart';
import { getChainId } from 'z/elrond/sdkDappHelpers';
import './index.scss';
import { SP_METADATA } from 'data';

const LeverageSlider = styled(Slider)({
    color: '#54F5B7',
    height: 4,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-markLabel': {
        top: '30px'
    },
    '& .MuiSlider-thumb': {
        height: 15,
        width: 15,
        backgroundColor: 'currentColor',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1,
        fontSize: 10,
        background: 'unset',
        padding: 0,
        width: 35,
        height: 35,
        color: 'black',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#54F5B7',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const valueLabelFormat = (value: number) => {
    return `${value} %`;
};

interface valueMark {
    value: number,
    label: string,
}

const marks: valueMark[] = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

export const Gauge = () => {
  const {
        network: { apiAddress },     
    } = useGetNetworkConfig();
    const chainID = getChainId();
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const isLoggedIn = useGetIsLoggedIn();
    const TOTAL_WEIGHT = 100;

    const LINE_WIDTH = 20;
    const [selected, setSelected] = useState<number | undefined>();
    const [hovered, setHovered] = useState<number | undefined>(undefined);

    const [commonSettings, setCommonSettings] = useState<JewelGaugeCommonContext | undefined>();
    const [totalVotedAmount, setTotalVotedAmount] = useState<string>('0');
    const [voteAllocations, setVoteAllocations] = useState<JewelVoteAllocation[]>([]);
    const [votePercents, setVotePercents] = useState<number[]>([]);
    const [userContexts, setUserContexts] = useState<JewelGaugeUserContext | undefined>();
    const [availUserWeight, setAvailUserWeight] = useState<number>(100);
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _commonSettings = await queryGaugeCommonSetting();
            if (_commonSettings) {
                // console.log('_commonSettings', _commonSettings);
                setCommonSettings(_commonSettings);

                const allocations: JewelVoteAllocation[] = [];
                let totalVotePoint = BigNumber('0');
                for (let i = 0; i < _commonSettings.sp_addresses.length; i++) {
                    const votePoint = convertWeiToEsdt(_commonSettings.vote_points[i]).toNumber();
                    totalVotePoint = totalVotePoint.plus(_commonSettings.vote_points[i]);
                    const item: JewelVoteAllocation = {
                        title: SP_METADATA[_commonSettings.sp_addresses[i]].name,
                        value: votePoint,
                        color: SP_METADATA[_commonSettings.sp_addresses[i]].color,
                    };

                    allocations.push(item);
                }
                setTotalVotedAmount(totalVotePoint.toFixed());

                if (totalVotePoint.gt('0')) {
                    const percents: number[] = [];
                    let maxPercent = 0;
                    let selectedIndex = 0;
                    for (let i = 0; i < allocations.length; i++) {
                        const percent = allocations[i].value / convertWeiToEsdt(totalVotePoint).toNumber() * 100;
                        percents.push(percent);

                        if (percent > maxPercent) {
                            maxPercent = percent;
                            selectedIndex = i;
                        }
                    }
                    setVotePercents(percents);
                    setSelected(selectedIndex);
                }

                setVoteAllocations(allocations);
            }
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            if (isLoggedIn) {
                const _userContexts = await queryViewUserContext(address);
                // console.log('_userGauges: ', _userContexts);

                if (_userContexts) {
                    setUserContexts(_userContexts);

                    let totalWeight = 0;
                    for (const votePercnet of _userContexts.user_vote_percents) {
                        totalWeight += votePercnet;
                    }
                    setAvailUserWeight(TOTAL_WEIGHT - totalWeight / PERCENT_DENOMINATOR);
                }
            }
        })();
    }, [hasPendingTransactions, address]);

    const [selectedSPAddress, setSelectedSPAddress] = useState<string>('');
    const [selectedSPIndex, setSelectedSPIndex] = useState<number>(0);
    const [weight, setWeight] = useState<number>(0);

    const handleClickSp = (item: string, index: number) => {
        setSelectedSPAddress(item);
        setSelectedSPIndex(index);

        if (commonSettings&& userContexts && Date.now() > getUserGaugeUpdatableTimestamp(userContexts.user_last_vote_timestamps[index], commonSettings.vote_reset_timestamp)) {
            const newAvailWeight = availUserWeight + userContexts.user_vote_percents[index] / PERCENT_DENOMINATOR;
            setAvailUserWeight(newAvailWeight);
        }
    };

    const handleWeight = (value: number) => {
        if (value >= 0) {
            if (value < availUserWeight) {
                setWeight(value);
            } else {
                setWeight(availUserWeight);
            }
        }
    };

    const handleVote = async () => {
        if (!commonSettings || !userContexts) return;

        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_YOUR_WALLET);
            return;
        }

        if (selectedSPAddress === '') {
            toastError('Please select provider to vote!');
            return;
        }

        if (Date.now() < getUserGaugeUpdatableTimestamp(userContexts.user_last_vote_timestamps[selectedSPIndex], commonSettings.vote_reset_timestamp)) {
            return;
        }

        if (weight < 0 || weight > availUserWeight) {
            toastError('Invalid weight!');
            return;
        }

        if (BigNumber(userContexts.user_jwlash_stake_amount).lte('0')) {
            toastError('Please stake JWLASH to vote!');
            return;
        }
        await sendJewelGaugeVote(chainID, address, selectedSPAddress, Math.floor(weight * PERCENT_DENOMINATOR));
    };

    return (
        <>
            <div className='container' style={{ marginTop: '40px' }}>
                <div className='info-item'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            <span>Gauge</span>
                        </div>
                        <div className='egld-logo' >
                            <img src={egld_white_logo} />
                        </div>
                    </div>
                    <div className='d-flex mt-1 mb-4 align-items-center w-100 overflow-auto' style={{ height: '40px' }}>
                        <div className='d-flex ml-3'>
                            <div className={`mx-2 farm-dex-tab farm-dex-tab-active`}>EGLD</div>
                        </div>
                    </div>
                    <div className='d-flex mt-3'>
                        {`Vote with $JWLASH for $EGLD delegation staking. 1 $JWLASH = 1 vote.`}
                    </div>
                    <div className='d-flex mt-1'>
                        {`Votes will determine the % of $EGLD sent to the respective WL Validator/Staking Provider (SP).`}
                    </div>
                    <div className='d-flex'>
                        {`- Delegation of $EGLD amount will be based on Voting Power share %.`}
                    </div>
                    <div className='d-flex'>
                        {`- Undelegation of $EGLD amount will be based on Voting Power share %.`}
                    </div>
                    <div className='d-flex mt-1'>
                        {`Votes allocation will be adjusted every Thusday.`}
                    </div>
                    <div className='d-flex mt-5 h4'>
                        {`Votes allocation (Current)`}
                    </div>
                    <div className='d-flex row mt-4'>
                        <div className='col-lg-3 col-md-4 col-sm-12 col-12 mt-2'>
                            {
                                voteAllocations.map((item: any, index: number) => (
                                    <div key={index} className='d-flex mx-3 align-items-center'>
                                        <div style={{
                                            width: '15px',
                                            height: '15px',
                                            background: item.color
                                        }}></div>
                                        <div className='mx-1'>{`${item.title} (${votePercents.length > index ? convertBigNumberValueToLocalString(votePercents[index]) : '-'}%)`}</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='col-lg-6 col-md-8 col-sm-12 col-12 text-center mt-2'>
                            <div className='position-relative'>
                                {
                                    selected != undefined ? (
                                        voteAllocations.map((item: any, index: number) => {
                                            if (selected === index) {
                                                return <div
                                                    className='position-absolute'
                                                    key={index}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                    }}>
                                                    <div className='d-flex mt-3 align-items-center justify-content-center'>
                                                        <div style={{
                                                            width: '15px',
                                                            height: '15px',
                                                            background: item.color,
                                                        }}></div>
                                                        <div className='mx-1'>{item.title}</div>
                                                    </div>
                                                    <div className='mt-2 h2'>{`${votePercents.length > index ? convertBigNumberValueToLocalString(votePercents[index]) : '-'}%`}</div>
                                                </div>;
                                            }
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                                <PieChart
                                    data={voteAllocations}
                                    className='gauge-pie-chart'
                                    radius={pieChartDefaultProps.radius - 6}
                                    style={{
                                        fontFamily:
                                            '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                                        fontSize: '6px',
                                        width: '300px',
                                        height: '300px',
                                        position: 'relative',
                                        zIndex: 999,
                                    }}
                                    lineWidth={LINE_WIDTH}
                                    segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                                    segmentsShift={(index) => (index === selected ? 2 : 0)}
                                    animate
                                    onClick={(_, index) => {
                                        setSelected(index === selected ? undefined : index);
                                    }}
                                    onMouseOver={(_, index) => {
                                        setHovered(index);
                                    }}
                                    onMouseOut={() => {
                                        setHovered(undefined);
                                    }}
                                >
                                </PieChart>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 col-sm-12 col-12 mt-2'>
                            <div className='gauge-stats'>
                                <div className='gauge-stats-type'>TOTAL veJWLASH VOTED</div>
                                <div className='mt-1 d-flex align-items-center'>
                                    <img src={getTokenImage('JWLASH-xxxxxx')} width={25} height={25} />
                                    <div className='mx-2'>{convertBigNumberValueToLocalString(convertWeiToEsdt(totalVotedAmount))}</div>
                                </div>
                            </div>
                            <div className='gauge-stats mt-3'>
                                <div className='gauge-stats-type'>veJWLASH UNUSED</div>
                                <div className='mt-1 d-flex align-items-center'>
                                    <img src={getTokenImage('JWLASH-xxxxxx')} width={25} height={25} />
                                    <div className='mx-2'>{convertBigNumberValueToLocalString(convertWeiToEsdt(commonSettings ? BigNumber(commonSettings.total_jwlash_stake_amount).minus(totalVotedAmount) : '0'))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mt-5 h4 font-bold'>
                        {`Your Votes`}
                    </div>
                    <div className='d-flex mt-2'>
                        {`You can only change individual gauge votes once per 10 days. Make sure to do decreases first and increases last.`}
                    </div>
                    <div className='row mt-5 mb-2 gauge-header'>
                        <div className='col-5'>Name</div>
                        <div className={`${isMobile ? 'col-2' : 'col-3'}`}>Voted</div>
                        <div className={`${isMobile ? 'col-5' : 'col-4'} d-flex`}>Can vote</div>
                    </div>
                    {
                        commonSettings ? (
                            commonSettings.sp_addresses.map((item: string, index: number) => (
                                <div
                                    key={index}
                                    className={`row gauge-item ${selectedSPAddress === item ? 'gauge-item-selected' : ''}`}
                                    onClick={() => handleClickSp(item, index)}
                                >
                                    <div className='col-5 d-flex align-items-center'>{SP_METADATA[item].name}</div>
                                    <div className={`${isMobile ? 'col-2' : 'col-3'} d-flex align-items-center`}>{`${convertBigNumberValueToLocalString(userContexts ? userContexts.user_vote_percents[index] / PERCENT_DENOMINATOR : '0')}%`}</div>
                                    <div className={`${isMobile ? 'col-5' : 'col-4'} d-flex align-items-center`}>
                                        {
                                            !userContexts || Date.now() > getUserGaugeUpdatableTimestamp(userContexts.user_last_vote_timestamps[index], commonSettings.vote_reset_timestamp) ? (
                                                <div className='px-1'>Yes</div>
                                            ) : (
                                                <>
                                                    <HttpsOutlinedIcon />
                                                    <span>{convertSecondsToPrintDateTimeString((getUserGaugeUpdatableTimestamp(userContexts.user_last_vote_timestamps[index], commonSettings.vote_reset_timestamp) - Date.now()) / SECOND_TO_MILLISECONDS)}</span>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='mt-5'>
                                <Spinner />
                            </div>
                        )
                    }
                    <div className='mt-4'>Vote Weight</div>
                    <div className='row mt-1'>
                        <div className='col-lg-8 col-md-8 col-sm-8 col-8'>
                            <LeverageSlider
                                valueLabelDisplay="auto"
                                aria-label="pretto slider"
                                min={0}
                                max={100}
                                step={0.01}
                                marks={marks}
                                value={Number(weight)}
                                valueLabelFormat={valueLabelFormat}
                                onChange={(e: any) => handleWeight(e.target.value)}
                            />
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex align-items-center'>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                fullWidth
                                value={weight}
                                onChange={(e: any) => handleWeight(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        textAlign: 'end',
                                        padding: '7px 0px'
                                    }
                                }}
                            />
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12 d-flex align-items-center'>
                            <div className='eg-btn btn--primary2 capsule px-4 py-2 w-100 d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }} onClick={handleVote}>
                                vote
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
