import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { JewelGlobalPoolCommonContext, JewelGlobalPoolContext } from 'z/types';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, getTokenDecimal, getTokenImage, getTokenTicker, PERCENT_DENOMINATOR } from 'z/utils';
import BigNumber from 'bignumber.js';

interface GlobalPoolProps {
    pool: JewelGlobalPoolContext,
    userTokens: any,
    jewelGlobalCommonContext: JewelGlobalPoolCommonContext | undefined,
    handleOpenSupplyModal: (is_supply: boolean, mm_pool: JewelGlobalPoolContext) => void,
    handleOpenBorrowModal: (is_borrow: boolean, mm_pool: JewelGlobalPoolContext) => void,
}

export const GlobalPool: FC<GlobalPoolProps> = ({
    pool,
    userTokens,
    jewelGlobalCommonContext,
    handleOpenSupplyModal,
    handleOpenBorrowModal,
}) => {

    return (
        <>
            {isMobile ? (
                <div className='row mt-4 lend-pool-container mobile-lend-pool-container'>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex'>
                        <div className='d-flex justify-content-center align-items-center farm-container'>
                            <img src={getTokenImage(pool.token_id)} className='egld-image mx-2' alt={pool.token_id}></img>
                            <div>
                                <div>{getTokenTicker(pool.token_id)}</div>
                                <div>{`$${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.token_price, 8))}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-3'>
                        <div>Supply APY:</div>
                        <div className='avaliable-color'>{`${jewelGlobalCommonContext && BigNumber(pool.supplied_amount).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(pool.borrow_apr, 4).multipliedBy(100 - jewelGlobalCommonContext.treasury_percent / PERCENT_DENOMINATOR).dividedBy(100).multipliedBy(BigNumber(pool.debt_amount).dividedBy(pool.supplied_amount))) : '-'} %`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div>Borrow APY:</div>
                        <div className='avaliable-color'>{`-${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.borrow_apr, 4))} %`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>LTV</div>
                        <div className='text-end'>
                            <div>{`${jewelGlobalCommonContext ? jewelGlobalCommonContext.max_debt_percent / PERCENT_DENOMINATOR : '-'} %`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Total Supply</div>
                        <div className='text-end'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Total Borrowed</div>
                        <div className='text-end'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.debt_amount, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Available</div>
                        <div className='text-end'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(pool.debt_amount).gt(pool.supplied_amount) ? '0' : BigNumber(pool.supplied_amount).minus(pool.debt_amount), getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center d-flex justify-content-between mt-1'>
                        <div className='align-self-center'>Your Balance</div>
                        <div className='text-end'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.token_id].balance, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center mt-2'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenSupplyModal(true, pool)}>
                                    Supply
                                </div>
                            </div>
                            <div className='col-12 d-flex justify-content-center mt-2'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenBorrowModal(true, pool)}>
                                    Borrow
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row mt-4 lend-pool-container'>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                        <div className='d-flex align-items-center farm-container'>
                            <img src={getTokenImage(pool.token_id)} className='egld-image mx-2' alt={pool.token_id}></img>
                            <div>
                                <div>{getTokenTicker(pool.token_id)}</div>
                                <div>{`$${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.token_price, 8))}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                        <div className='avaliable-color'>{`${jewelGlobalCommonContext && BigNumber(pool.supplied_amount).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(pool.borrow_apr, 4).multipliedBy(100 - jewelGlobalCommonContext.treasury_percent / PERCENT_DENOMINATOR).dividedBy(100).multipliedBy(BigNumber(pool.debt_amount).dividedBy(pool.supplied_amount))) : '-'} %`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                        <div className='avaliable-color'>{`-${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.borrow_apr, 4))} %`}</div>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-12 text-center align-self-center'>
                        <div>{`${jewelGlobalCommonContext ? jewelGlobalCommonContext.max_debt_percent / PERCENT_DENOMINATOR : '-'} %`}</div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12 d-flex'>
                        <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.supplied_amount, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 text-center align-self-center'>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(pool.debt_amount, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)} /`}</div>
                            <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(pool.debt_amount).gt(pool.supplied_amount) ? '0' : BigNumber(pool.supplied_amount).minus(pool.debt_amount), getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 text-end align-self-center'>
                        <div>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(userTokens[pool.token_id].balance, getTokenDecimal(pool.token_id)))} ${getTokenTicker(pool.token_id)}`}</div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-12 align-self-center'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenSupplyModal(true, pool)}>
                                    Supply
                                </div>
                            </div>
                            <div className='col-12 d-flex justify-content-center mt-2'>
                                <div className="eg-btn btn--primary2 capsule px-2 py-2" style={{ cursor: 'pointer', width: '100%' }} onClick={() => handleOpenBorrowModal(true, pool)}>
                                    Borrow
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
