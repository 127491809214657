import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetNetworkConfig, useGetPendingTransactions, useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import LogoImage from 'assets/img/logo.png';
import { Tabs } from 'antd';
import { TextField } from '@mui/material';
import { Backdrop, Box, Modal } from '@mui/material';
import { animated, useSpring } from 'react-spring';
import { TabButton } from 'components/TabButton/TabButton';
import { CollectionBuyTab } from 'components/CollectionBuyTab';
import { CollectionSellTab } from 'components/CollectionSellTab';
import { CollectionActivityTab } from 'components/CollectionActivityTab';
import { PoolActivity, PoolNft, NftCollectionPoolInfo, PoolCurveTypeEnum, NftWithAmmData, PoolStateEnum, NftCollection, NftRarity } from 'z/types';
import { queryViewCollectionPool, queryViewPoolData, queryViewPoolNfts } from 'z/elrond';
import { convertBigNumberValueToLocalString, convertWeiToEsdt, createNftId } from 'z/utils';
import { getNftsByIdFromApi, getNftsByCollectionFromApi, getAmmNftsByCollectionFromApi, getRarityFromXoxno } from 'z/api';
import { NFT_AMM_SC_ADDRESS } from 'config';
import { Spinner } from 'components/Spinner';
import { routeNames } from 'routes';
import { AmmCollectionContext } from 'App';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { CollectionLogo } from 'components/CollectionLogo';

const { TabPane } = Tabs;
const fadeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 290,
    // bgcolor: '#2b3943',
    boxShadow: 24,
    px: 3,
    py: 4,
    borderRadius: '10px',
    color: 'white',
    background: 'linear-gradient(180deg, #2b3943, #1a242b)',
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => void;
    onExited?: () => void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});


export const Pool = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccountInfo();

    const navigate = useNavigate();
    const params = useParams();

    const { ammCollections } = useContext(AmmCollectionContext);

    const [collectionInfo, setCollectionInfo] = useState<NftCollection>();
    const [poolLoading, setPoolLoading] = useState<boolean>(false);
    const [poolNfts, setPoolNfts] = useState<NftWithAmmData[]>([]);
    const [userNfts, setUserNfts] = useState<NftWithAmmData[]>([]);
    const [poolInfo, setPoolInfo] = useState<NftCollectionPoolInfo>();
    const [activityLoading, setActivityLoading] = useState<boolean>(false);
    const [activities, setActivities] = useState<PoolActivity[]>([]);

    const [rarity, setRarity] = useState<NftRarity[]>([]);

    useEffect(() => {
        if (params.collectionId && params.collectionId.length > 0 && ammCollections && ammCollections.length > 0) {
            const info: NftCollection[] = ammCollections.filter((collectionInfo: NftCollection) => { return collectionInfo.nft_token_id == params.collectionId; });
            if (info.length > 0) {
                setCollectionInfo(info[0]);
            }
        }
    }, [ammCollections]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            if (params.collectionId && params.collectionId.length > 0) {
                setPoolLoading(true);
                const [_pool, _poolNfts] = await queryViewPoolData(apiAddress, params.collectionId, Number(params.poolId));
                // console.log('_pool: ', _pool);
                if (!_pool || _pool.pool_state === PoolStateEnum.Inactive || _pool.pool_state === PoolStateEnum.ActiveButNoSwap) {
                    setPoolLoading(false);
                    navigate(routeNames.mypools);
                    return;
                }
                setPoolInfo(_pool);
                // console.log('_poolNfts: ', _poolNfts);
                const _nfts = await getAmmNftsByCollectionFromApi(apiAddress, NFT_AMM_SC_ADDRESS, params.collectionId, true, _poolNfts);
                setPoolNfts(_nfts);

                const _rarity: NftRarity[] = await getRarityFromXoxno(params.collectionId);
                // console.log('_rarity: ', _rarity);
                setRarity(_rarity);
                
                setPoolLoading(false);
            }
        })();
    }, [hasPendingTransactions]);

    const [showLiquidityModal, setShowLiquidityModal] = useState<boolean>(false);
    const [addLiquidity, setAddLiquidity] = useState<boolean>(true);

    const handleAddLiquidity = () => {
        setShowLiquidityModal(true);
    };
    const handleRemoveLiquidity = () => {
        setAddLiquidity(false);
        setShowLiquidityModal(true);
    };

    const handleEditPool = () => {
        navigate(`/collection/${params.collectionId}/${params.poolId}/edit`);
    };

    return (
        <div className='container amm-container' style={{ marginTop: '70px' }}>
            {poolLoading ? (
                <Spinner />
            ) : (
                <>
                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                        {'< Back'}
                    </div>
                    <div className='row mt-5'>
                        <div className='col-lg-3 col-md-3 col-sm-12 d-flex justify-content-center'>
                            <CollectionLogo className='amm-logo' collectionId={collectionInfo?.nft_token_id} />
                        </div>
                        <div className='col-lg-9 col-md-9 col-sm-12'>
                            <div className='container'>
                                <div className='row mt-2'>
                                    <div className='col-12 m-2'>
                                        <h3>{collectionInfo?.nft_collection_name} ({collectionInfo?.nft_token_id})</h3>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>EGLD balance</span>
                                            <div className='mt-2 d-flex gap-1 align-items-center'>
                                                <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                                {poolInfo && convertBigNumberValueToLocalString(convertWeiToEsdt(poolInfo.pool_swap_token_reserve))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>Amount of NFTs</span>
                                            <div className='mt-2'>{poolInfo && poolInfo.pool_nft_token_reserve}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>Delta</span>
                                            <div className='mt-2 d-flex gap-1 align-items-center'>
                                                {
                                                    poolInfo && poolInfo.pool_curve_type == PoolCurveTypeEnum.Linear ?
                                                        <>
                                                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                                            {convertBigNumberValueToLocalString(convertWeiToEsdt(poolInfo.pool_linear_curve_step_amount))}
                                                        </> :
                                                        poolInfo?.pool_exponential_curve_step_percent?.toFixed(2) + ' %'
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>Status</span>
                                            <div className='mt-2'>{poolInfo && poolInfo.pool_state}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>Pool Type</span>
                                            <div className='mt-2'>{poolInfo && poolInfo.pool_type ? poolInfo.pool_type : 'Liquidity'}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>Accumulated fee</span>
                                            <div className='mt-2 d-flex gap-1 align-items-center'>
                                                <img className='egld-badge' src={egld_white_logo} alt='egld' />
                                                {poolInfo && convertBigNumberValueToLocalString(convertWeiToEsdt(poolInfo.pool_accumulated_fee))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6'>
                                        <div className='m-2 amm-collection-status'>
                                            <span>Owner</span>
                                            <div className='mt-2'>{poolInfo && poolInfo.pool_owner.substring(0, 5) + '...' + poolInfo.pool_owner.slice(-5)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {poolInfo && address == poolInfo.pool_owner && (
                        <div className='row mt-4 mb-2'>
                            <div className='col-12 d-flex justify-content-center'>
                                <button className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleEditPool}>
                                    edit pool
                                </button>
                                {/* <button className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleAddLiquidity}>
                                Add Liquidity
                            </button>
                            <button className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer', marginLeft: '20px' }} onClick={handleRemoveLiquidity}>
                                Remove Liquidity
                            </button> */}
                            </div>
                        </div>
                    )}
                    <div className='row mt-5'>
                        <div className='col-12'>
                            <Tabs defaultActiveKey="buy" centered>
                                <TabPane
                                    key="buy"
                                    tab={<TabButton title="pool nfts" data={poolNfts.length} />}
                                >
                                    <CollectionBuyTab items={poolNfts} rarity={rarity} actionDisabled={true} />
                                </TabPane>
                                {/* <TabPane
                            key="sell"
                            tab={<TabButton title="sell" data={userNfts.length} />}
                        >
                            <CollectionSellTab items={userNfts} poolInfo={poolInfo} />
                        </TabPane> */}
                                {/* <TabPane key="activity" tab="activity">
                            <CollectionActivityTab activities={activities} activityLoading={activityLoading} />
                        </TabPane> */}
                            </Tabs>
                        </div>
                    </div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={showLiquidityModal}
                        onClose={() => setShowLiquidityModal(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={showLiquidityModal}>
                            <Box sx={fadeBoxStyle}>
                                <div className='mt-2 d-flex flex-column p-2'>
                                    <span className='text-color-gray'>NFT Amount</span>
                                </div>
                                <div className='mt-2 d-flex flex-column p-2'>
                                    <TextField
                                        fullWidth
                                        label='Input Amount'
                                    />
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} >
                                        {addLiquidity ? 'Add Liquidity' : 'Remove Liquidity'}
                                    </div>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>
                </>
            )}
        </div>
    );
};
