import React, { useContext, useEffect, useState } from 'react';
import { NftEnumType } from '@multiversx/sdk-dapp/types/tokens.types';
import { NftWithAmmData, SwapTypeEnum } from 'z/types';
import { NFT_QUANTITY, convertBigNumberValueToLocalString, convertWeiToEsdt, getImageUrl } from 'z/utils';
import { swapEgldForNft, swapNftForEgld } from 'z/elrond';
import { useGetAccountInfo, useGetIsLoggedIn, useGetPendingTransactions, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { AmmCollectionContext } from 'App';
import BigNumber from 'bignumber.js';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ReactVideoPlay, VideoSourceType } from 'react-video-play';
import 'react-video-play/public/css/react-video-play.css';
import { isDev } from 'config';
import unknown_logo from 'assets/img/unknown.png';

export enum NftCardType {
    AddNft = "AddNft",
    RemoveNft = "RemoveNft",
    SwapNft = "SwapNft",
}

export const NftCard = (props: any) => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccountInfo();
    const isLoggedIn = useGetIsLoggedIn();

    const { isChanged, setIsChanged } = useContext(AmmCollectionContext);

    const [amount, nftAmount] = useState<number>(NFT_QUANTITY);
    const handleChange = (e: any) => {
        let amount = e.target.value;
        if (amount > Number(props.item.nft_token_amount)) {
            amount = Number(props.item.nft_token_amount);
        }
        nftAmount(amount);
    };

    const [isAction, setIsAction] = useState<boolean>(false);
    const handleAction = async (item: NftWithAmmData) => {
        if (amount < NFT_QUANTITY) return;
        if (props.nftCardType == NftCardType.AddNft) {
            // add nft
            const status = !isAction;
            const newItem: NftWithAmmData = { ...props.item };
            newItem.nft_token_amount = amount.toString();
            props.handleAddNft(newItem, status);
            setIsAction(status);
        } else if (props.nftCardType == NftCardType.RemoveNft) {
            // remove nft
            const status = !isAction;
            const newItem: NftWithAmmData = { ...props.item };
            newItem.nft_token_amount = amount.toString();
            props.handleRemoveNft(newItem, status);
            setIsAction(status);
        } else if (props.nftCardType == NftCardType.SwapNft) {
            // swap nft
            if (props.swapType == SwapTypeEnum.Sell) {
                // sell
                if (item.pool_trade_sell_price) {
                    const result = await swapEgldForNft(item, item.pool_trade_sell_price);
                    setSessionId(result.sessionId);
                }
            } else {
                // buy
                if (isLoggedIn && props.bestOffer && convertWeiToEsdt(props.bestOffer) > new BigNumber(0)) {
                    const result = await swapNftForEgld(item, address);
                    setSessionId(result.sessionId);
                }
            }
        }
    };

    const [sessionId, setSessionId] = useState<string>('');
    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
    });

    useEffect(() => {
        if (transactionStatus.isSuccessful) {
            // console.log('handleSwap: success');
            setIsChanged(!isChanged);
        }
    }, [sessionId, hasPendingTransactions]);

    return (
        <div className='amm-nft-card' style={isAction ? { opacity: 0.3 } : { opacity: 1 }}>
            {props.item.url?.includes('.mp4') ? (
                <ReactVideoPlay
                    sources={[
                        {
                            name: '720p',
                            source: [{
                                source: props.item.url,
                                type: VideoSourceType.video_mp4
                            }]
                        }
                    ]}
                    poster={isDev ? unknown_logo : getImageUrl(props.item.collection, props.item.identifier)}
                    enableAdv={false}
                    enableSlider={false}
                    autoplay={false}
                    muted={true}
                    controls={false}
                />
            ) : (
                <LazyLoadImage src={isDev ? props.item.url : getImageUrl(props.item.collection, props.item.identifier)} width='100%' style={{ overflow: "auto", minHeight: '150px' }} alt={props.item.identifier} effect="blur" />
            )}
            <div className='text-white h5 px-2 mt-3' style={{ fontWeight: "bold" }}>{props.item.identifier}</div>
            {/*<div className='text-white h6 px-2 mt-2'>{props.rarity.name}</div>*/}
            {/*<div className='text-white h6 px-2 mt-2'>{`Rank: ${props.rarity.metadata.rarity.rank}`}</div>*/}
            {props.item.type === NftEnumType.SemiFungibleESDT && (
                <div className='text-white h6 px-2 mt-2'>balance: {props.item.nft_token_amount}</div>
            )}
            {props.nftCardType == NftCardType.SwapNft && (
                props.actionDisabled ? (
                    <></>
                ) : (
                    props.swapType == SwapTypeEnum.Sell ? (
                        <div className='d-flex text-white h6 px-2 mt-2 gap-1 align-items-center'>
                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                            {convertBigNumberValueToLocalString(convertWeiToEsdt(props.item.pool_trade_sell_price))}
                        </div>
                    ) : (
                        <div className='d-flex text-white h6 px-2 mt-2 gap-1 align-items-center'>
                            <img className='egld-badge' src={egld_white_logo} alt='egld' />
                            {convertBigNumberValueToLocalString(convertWeiToEsdt(props.bestOffer))}
                        </div>
                    )
                )
            )}
            {props.item.type === NftEnumType.SemiFungibleESDT && (
                props.actionDisabled || props.nftCardType == NftCardType.SwapNft ? (
                    <></>
                ) : (
                    <div className='nft-amm-card-amount'>
                        <input value={amount} onChange={handleChange} disabled={isAction ? true : false}></input>
                    </div>
                )
            )}
            {props.actionDisabled ? (
                <></>
            ) : (
                <div className='px-3 mt-2 mb-1'>
                    {isAction ? (
                        <div className="w-100 eg-btn btn--primary2 capsule px-2" style={{ cursor: 'pointer', fontSize: '23px' }} onClick={() => handleAction(props.item)}>
                            -
                        </div>
                    ) : (
                        <div className="w-100 eg-btn btn--primary2 capsule px-1 py-2" style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => handleAction(props.item)}>
                            {props.nftCardType == NftCardType.AddNft ? 'Add' : props.nftCardType == NftCardType.RemoveNft ? 'Remove' : props.swapType == SwapTypeEnum.Sell ? 'Buy' : 'Sell'}
                        </div>
                    )}
                </div>
            )}
        </div >
    );
};
