import { ChainId, ContractManager, MVXProxyNetworkAddress } from '@ashswap/ash-sdk-js/out';
import {
  Address,
  AddressType,
  AddressValue,
  BigUIntType,
  BigUIntValue,
  BinaryCodec,
  BooleanValue,
  BytesValue,
  CompositeValue,
  Field,
  FieldDefinition,
  List,
  OptionalType,
  OptionalValue,
  ResultsParser,
  SmartContract,
  Struct,
  StructType,
  TokenIdentifierType,
  TokenIdentifierValue,
  TypedValue,
  U32Value,
  U64Type,
  U64Value,
  VariadicValue
} from '@multiversx/sdk-core/out';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { ProxyNetworkProvider } from '@ashswap/ash-sdk-js/node_modules/@multiversx/sdk-network-providers/out';
import { apiTimeout, ASHSWAP_VOTING_ESCROW_ADDRESS, ASH_TOKEN_ID, AUTO_COMPOUND_CRON_JOB_TIME, HATOM_SEGLD_MM_ADDRESS, HTM_TOKEN_ID, isDev, JEWEL_ASHSWAP_SC_ADDRESS, JEWEL_HATOM_FARM_SC_ADDRESS, LATEST_LOAN_SHOW_SIZE, NETWORK_JEWEL_API, USDC_TOKEN_ID, WEGLD_TOKEN_ID } from 'config';
import { getNftsByCollectionFromApi, getTokenPriceFromAshswap, getUserTokenBalance } from 'z/api';
import {
  ASH_LP_DECIMALS,
  calcYieldBoost,
  convertWeiToEsdt,
  DAY_IN_SECONDS,
  ERROR_MVX_QUERY_FAILED,
  FARM_MAX_PERCENT,
  getTokenUsdcPrice,
  getTokenTicker,
  SECOND_TO_MILLISECONDS,
  sleep,
  toastError,
  YEAR_IN_SECONDS,
  getIsStablePool,
  getHatomBaseTokenId,
  PERCENT_DENOMINATOR
} from 'z/utils';
import { CommonSettingsType, LendUserType, LoanType, NftWithIndexType, parseLoanStatusEnum, parseLoanTypeEnum, parsePoolStateEnum, parsePoolCurveTypeEnum, parseSwapTypeEnum, NftCollection, NftCollectionPoolInfo, PoolNft, PoolActivity, PoolCurveTypeEnum, parsePoolTypeEnum, LendUserDataType, JewelLendPool, parseJewelLendStateEnum, JewelLendContext, JewelFarmContextData, parseJewelFarmStateEnum, JewelFarmPosition, JewelFarmBaseContextData, JewelFarmPositionSafetyContext, JewelStakeBaseContext, parseJewelStakeStateEnum, parseJewelStakeEpochStateEnum, JewelStakeEpochContext, JewelStakeUserContext, JewelStakeUserEpochContext, JewelFarmContext, JewelFarmBaseContext, JewelOnedexFarmBaseContext, parseJewelOnedexFarmStateEnum, JewelOnedexFarmContext, JewelOnedexPostionContext, parseJewelOnedexPositionEnum, JewelLiquidStakeBaseContext, parseJewelLiquidStakeStateEnum, JewelLiquidStakeStatsContext, JewelGaugeCommonContext, JewelGaugeUserContext, JewelHatomFarmCommonContext, JewelHatomFarmContext, parseJewelFarmPositionStateEnum, JewelHatomPostionContext, JewelStableCommonContext, JewelStableStatsContext, JewelFlashMintCommonContext, JewelFlashMintFarmContext, JewelFlashMintPositionContext, JewelMoneyMarketCommonContext, parseMoneyMarketPoolTypeEnum, JewelMoneyMarketPoolContext, JewelMoneyMarketUserPoolContext, JewelExchangeFarmBaseContext, JewelExchangeFarmContext, JewelExchangeLockOptionContext, EsdtTokenPayment, JewelExchangeFarmTokenContext, JewelLpFarmContext, JewelFlexiLoanCommonContext, JewelFlexiLoanWhitelistContext, parseJewelFlexiLoanDexTypeEnum, JewelGlobalPoolCommonContext, JewelGlobalPoolContext, JewelGlobalUserPoolContext, JewelGlobalUserContext, JewelFacilitatorCommonContext, JewelBtcEthStakeBaseContext, JewelBtcEthStakeUserContext } from '../types';
import { nftLendingSmartContract, nftAmmSmartContract, jewelLendSmartContract, jewelUsdtAshFarmSmartContract, farmAbiRegistry, getNetworkProvider, jewelStakeSmartContract, jewelOnedexFarmSmartContract, jewelLiquidStakeSmartContract, jewelGaugeSmartContract, jewelHatomFarmSmartContract, getMvxProvider, jewelStableSmartContract, jewelFlashMintSmartContract, jewelBorrowSmartContract, jewelExchangeSmartContract, jewelSwapRouterSmartContract, jewelFlexiLoanSmartContract, jewelGlobalPoolSmartContract, jewelOracleSmartContract, jewelFacilitatorSmartContract, jewelBtcEthStakeSmartContract, hatomRewardsBoosterSmartContract } from './index';
import Item from 'antd/es/list/Item';
import BigNumber from '@multiversx/sdk-network-providers/node_modules/bignumber.js/bignumber';

export const queryCommonSettings = async (apiAddress: string, address: string): Promise<CommonSettingsType | undefined> => {
  try {
    const networkProvider = await getMvxProvider();
    const args: TypedValue[] = [
      address === '' ? OptionalValue.newMissing() : new OptionalValue(new OptionalType(new AddressType()), new AddressValue(new Address(address))),
    ];
    const interaction = nftLendingSmartContract.methodsExplicit.viewCommonSettings(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    // const lendUserData = value.lend_user_data;
    // const decodedLendUserData: LendUserDataType[] = lendUserData.map((item: any) => ({
    //   user_lend_amount: item.user_lend_amount.toFixed(),
    //   user_last_lend_timestamp: item.user_last_lend_timestamp.toNumber(),
    //   user_address: item.user_address.toString(),
    // }));
    let nfts = value.collection_ids.filter((v: any) => v != "BAXC-cdf74d").map((v: any) => v.toString());
    nfts = nfts.filter((v: any) => v != "MAFIA-bd0abc");
    const decoded: CommonSettingsType = {
      rewards_pool_rate: value.rewards_pool_rate.toNumber(),
      epunks_buyback_rate: value.epunks_buyback_rate.toNumber(),
      team_rate: value.team_rate.toNumber(),
      epunks_buyback_wallet: value.epunks_buyback_wallet.toString(),
      team_wallet: value.team_wallet.toString(),
      liquidation_wallet: value.liquidation_wallet.toString(),

      lend_epoch_length: value.lend_epoch_length.toNumber(),
      lend_lock_period: value.lend_lock_period.toNumber(),
      liquidation_threshold: value.liquidation_threshold.toNumber(),
      lend_grace_period: value.lend_grace_period.toNumber(),
      liquidation_fee: value.liquidation_fee.toNumber(),

      total_lending_amount: value.total_lending_amount.toFixed(),
      lend_users: value.lend_users.toNumber(),
      current_lend_epoch_id: value.current_lend_epoch_id.toNumber(),
      lend_epoch_start_timestamp: value.lend_epoch_start_timestamp.toNumber(),
      lend_rewards_pool_amount: value.lend_rewards_pool_amount.toFixed(),

      global_liquidation_rate: value.global_liquidation_rate.toNumber(),
      global_max_borrow_amount: value.global_max_borrow_amount.toFixed(),
      global_collection_max_lend_cap_percent: value.global_collection_max_lend_cap_percent.toNumber(),
      global_max_borrower_cap_percent: value.global_max_borrower_cap_percent.toNumber(),

      borrow_plan_count: value.borrow_plan_count.toNumber(),
      max_borrow_periods: value.max_borrow_periods.map(((v: any) => v.toNumber())),
      borrow_rates: value.borrow_rates.map((v: any) => v.toNumber()),

      accumulated_loan_amount: value.accumulated_loan_amount.toFixed(),
      total_loan_amount: value.total_loan_amount.toFixed(),
      total_collateral_amount: value.total_collateral_amount.toFixed(),
      opened_loan_count: value.opened_loan_count.toNumber(),
      loan_owners: value.loan_owners.toNumber(),
      last_loan_id: value.last_loan_id.toNumber(),

      collection_ids: nfts,
      collection_collateral_rates: value.collection_collateral_rates.map((v: any) => v.toNumber()),
      collection_trait_counts: value.collection_trait_counts.map((v: any) => v.toNumber()),
      collection_supplies: value.collection_supplies.map((v: any) => v.toNumber()),
      collection_max_borrow_amounts: value.collection_max_borrow_amounts.map((v: any) => v.toFixed()),
      collection_borrow_amounts: value.collection_borrow_amounts.map((v: any) => v.toFixed()),
      collection_max_lend_cap_percents: value.collection_max_lend_cap_percents.map((v: any) => v.toNumber()),

      // lend_user_data: decodedLendUserData,
      user_borrow_status: value.user_borrow_status,
    };
    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryViewOpenedLoans = async (apiAddress: string): Promise<LoanType[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new BooleanValue(true), // filter_opened
    ];
    const interaction = nftLendingSmartContract.methodsExplicit.viewLoans(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: LoanType[] = values.map((value: any) => ({
      loan_id: value.loan_id.toNumber(),
      loan_type: parseLoanTypeEnum(value.loan_type.name),
      loan_status: parseLoanStatusEnum(value.loan_status.name),
      loan_owner: value.loan_owner.toString(),
      loan_collection_id: value.loan_collection_id.toString(),
      loan_nft_nonce: value.loan_nft_nonce.toNumber(),
      loan_nft_price: value.loan_nft_price.toFixed(),
      loan_borrow_amount: value.loan_borrow_amount.toFixed(),
      loan_debt_amount: value.loan_debt_amount.toFixed(),
      loan_deadline_timestamp: value.loan_deadline_timestamp.toNumber(),
      loan_borrow_plan_id: value.loan_borrow_plan_id.toNumber(),
      loan_open_timestamp: value.loan_open_timestamp.toNumber(),
      loan_close_timestamp: value.loan_close_timestamp.toNumber(),
      loan_nft_valuation: value.loan_nft_valuation.toFixed(),
      loan_liquidation_price: value.loan_liquidation_price.toFixed(),
      loan_cashout: value.loan_cashout.toFixed(),
    }));

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewLoans = async (apiAddress: string): Promise<LoanType[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new BooleanValue(false), // filter_opened
    ];
    const interaction = nftLendingSmartContract.methodsExplicit.viewLoans(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: LoanType[] = values.map((value: any) => ({
      loan_id: value.loan_id.toNumber(),
      loan_type: parseLoanTypeEnum(value.loan_type.name),
      loan_status: parseLoanStatusEnum(value.loan_status.name),
      loan_owner: value.loan_owner.toString(),
      loan_collection_id: value.loan_collection_id.toString(),
      loan_nft_nonce: value.loan_nft_nonce.toNumber(),
      loan_nft_price: value.loan_nft_price.toFixed(),
      loan_borrow_amount: value.loan_borrow_amount.toFixed(),
      loan_debt_amount: value.loan_debt_amount.toFixed(),
      loan_deadline_timestamp: value.loan_deadline_timestamp.toNumber(),
      loan_borrow_plan_id: value.loan_borrow_plan_id.toNumber(),
      loan_open_timestamp: value.loan_open_timestamp.toNumber(),
      loan_close_timestamp: value.loan_close_timestamp.toNumber(),
      loan_nft_valuation: value.loan_nft_valuation.toFixed(),
      loan_liquidation_price: value.loan_liquidation_price.toFixed(),
      loan_cashout: value.loan_cashout.toFixed(),
    }));

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewUser = async (apiAddress: string, address: string): Promise<LendUserType | undefined> => {
  try {
    const networkProvider = await getMvxProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = nftLendingSmartContract.methodsExplicit.viewUser(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }
    const value = firstValue.valueOf();
    const decoded: LendUserType = {
      user_lend_amount: value.user_lend_amount.toFixed(),
      user_last_lend_timestamp: value.user_last_lend_timestamp.toNumber(),
      lender_rewards_amounts: value.rewards_amount.toFixed(),

      user_loan_ids: value.user_loan_ids.map((v: any) => v.toNumber()),
      user_borrow_amount: value.user_borrow_amount.toFixed(),
      user_debt_amount: value.user_debt_amount.toFixed(),
    };



    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};


export const queryViewLoansById = async (apiAddress: string, loanIds: number[]): Promise<LoanType[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const ids: TypedValue[] = loanIds.map(v => new U32Value(v));
    const args: TypedValue[] = [
      VariadicValue.fromItems(...ids),
    ];
    const interaction = nftLendingSmartContract.methodsExplicit.viewLoansById(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    const decoded: LoanType[] = values.map((value: any, index: number) => ({
      index,
      loan_id: value.loan_id.toNumber(),
      loan_type: parseLoanTypeEnum(value.loan_type.name),
      loan_status: parseLoanStatusEnum(value.loan_status.name),
      loan_owner: value.loan_owner.toString(),
      loan_collection_id: value.loan_collection_id.toString(),
      loan_nft_nonce: value.loan_nft_nonce.toNumber(),
      loan_nft_price: value.loan_nft_price.toFixed(),
      loan_borrow_amount: value.loan_borrow_amount.toFixed(),
      loan_debt_amount: value.loan_debt_amount.toFixed(),
      loan_deadline_timestamp: value.loan_deadline_timestamp.toNumber(),
      loan_borrow_plan_id: value.loan_borrow_plan_id.toNumber(),
      loan_open_timestamp: value.loan_open_timestamp.toNumber(),
      loan_close_timestamp: value.loan_close_timestamp.toNumber(),
      loan_nft_valuation: value.loan_nft_valuation.toFixed(),
      loan_liquidation_price: value.loan_liquidation_price.toFixed(),
      loan_cashout: value.loan_cashout.toFixed(),
    }));

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewLatestLoans = async (apiAddress: string, size: number): Promise<LoanType[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new U32Value(size)
    ];
    const interaction = nftLendingSmartContract.methodsExplicit.viewLatestLoans(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    const decoded: LoanType[] = values.map((value: any, index: number) => ({
      index,
      loan_id: value.loan_id.toNumber(),
      loan_type: parseLoanTypeEnum(value.loan_type.name),
      loan_status: parseLoanStatusEnum(value.loan_status.name),
      loan_owner: value.loan_owner.toString(),
      loan_collection_id: value.loan_collection_id.toString(),
      loan_nft_nonce: value.loan_nft_nonce.toNumber(),
      loan_nft_price: value.loan_nft_price.toFixed(),
      loan_borrow_amount: value.loan_borrow_amount.toFixed(),
      loan_debt_amount: value.loan_debt_amount.toFixed(),
      loan_deadline_timestamp: value.loan_deadline_timestamp.toNumber(),
      loan_borrow_plan_id: value.loan_borrow_plan_id.toNumber(),
      loan_open_timestamp: value.loan_open_timestamp.toNumber(),
      loan_close_timestamp: value.loan_close_timestamp.toNumber(),
      loan_nft_valuation: value.loan_nft_valuation.toFixed(),
      loan_liquidation_price: value.loan_liquidation_price.toFixed(),
      loan_cashout: value.loan_cashout.toFixed(),
    }));

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryComputeNftPrices = async (apiAddress: string, nfts: any): Promise<string[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const items: TypedValue[] = [];
    nfts.map((nft: any) => items.push(
      CompositeValue.fromItems(
        new TokenIdentifierValue(nft.collection),
        new U64Value(nft.nonce),
      )
    ));
    const args: TypedValue[] = [VariadicValue.fromItems(...items)];

    const interaction = nftLendingSmartContract.methodsExplicit.computeNftPrices(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: string[] = values.map((value: any) => value.toFixed());
    // console.log("prices");
    // console.log(decoded);

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

// AMM
export const queryViewCollections = async (apiAddress: string): Promise<NftCollection[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = nftAmmSmartContract.methodsExplicit.getNftCollections();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    let decoded: NftCollection[] = values.map((value: any, index: number) => ({
      index,
      nft_token_id: value.nft_token_id.toString(),
      nft_listed_count: value.nft_listed_count.toNumber(),
      nft_floor_price: value.nft_floor_price.toFixed(),
      nft_best_offer_price: value.nft_best_offer_price.toFixed(),
      nft_offer_tvl: value.nft_offer_tvl.toFixed(),
      nft_collection_trading_volume: value.nft_collection_trading_volume.toFixed(),
    }));
    // console.log(decoded);
    decoded = decoded.filter((item)=> item.nft_token_id!="BAXC-cdf74d" );
    decoded = decoded.filter((item)=> item.nft_token_id != "MAFIA-bd0abc");
    // console.log(decoded);

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewCommonSettings = async (apiAddress: string): Promise<[NftCollection[], PoolActivity[]]> => {
  try {
    const networkProvider = new ApiNetworkProvider(apiAddress);
    const args: TypedValue[] = [
      new U32Value(LATEST_LOAN_SHOW_SIZE)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getCommonSettings(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [[], []];
    }
    const values = firstValue.valueOf();
    const nftCollections = values.nft_collections;
    const latest_activities = values.latest_activities;
    let decodedNftCollections: NftCollection[] = nftCollections.map((value: any, index: number) => ({
      index,
      nft_token_id: value.nft_token_id.toString(),
      nft_listed_count: value.nft_listed_count.toNumber(),
      nft_floor_price: value.nft_floor_price.toFixed(),
      nft_best_offer_price: value.nft_best_offer_price.toFixed(),
      nft_offer_tvl: value.nft_offer_tvl.toFixed(),
      nft_collection_trading_volume: value.nft_collection_trading_volume.toFixed(),
    }));

    decodedNftCollections = decodedNftCollections.filter((item)=> item.nft_token_id!="BAXC-cdf74d");
    decodedNftCollections = decodedNftCollections.filter((item)=> item.nft_token_id!="MAFIA-bd0abc");
    let decodedPoolActivity: PoolActivity[] = latest_activities.map((value: any, index: number) => ({
      index,
      pool_nft_token_id: value.nft_token_id.toString(),
      pool_id: value.pool_id.toNumber(),
      pool_activity_id: value.pool_activity_id.toNumber(),
      pool_activity_token_nonce: value.pool_activity_token_nonce.toNumber(),
      pool_activity_token_amount: value.pool_activity_token_amount.toFixed(),
      pool_activity_caller_address: value.pool_activity_caller_address.toString(),
      pool_activity_type: parseSwapTypeEnum(value.pool_activity_type.name),
      pool_activity_price: value.pool_activity_price.toFixed(),
      pool_activity_timestamp: value.pool_activity_timestamp.toNumber(),
    }));
    decodedPoolActivity.sort((a: PoolActivity, b: PoolActivity) => b.pool_activity_timestamp - a.pool_activity_timestamp);
    if (decodedPoolActivity.length > LATEST_LOAN_SHOW_SIZE) {
      decodedPoolActivity = decodedPoolActivity.slice(0, LATEST_LOAN_SHOW_SIZE);
    }

    return [decodedNftCollections, decodedPoolActivity];
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [[], []];
  }
};

export const queryViewCollectionData = async (apiAddress: string, pool_nft_token_id: string): Promise<[NftCollectionPoolInfo[], PoolNft[]]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(pool_nft_token_id)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getCollectionData(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [[], []];
    }
    const values = firstValue.valueOf();
    const collectionPools = values.collection_pools;
    const collectionNfts = values.collection_nfts;
    const decodedCollectionPools: NftCollectionPoolInfo[] = collectionPools.map((value: any, index: number) => {
      const data = {
        index,
        pool_nft_token_id: value.pool_nft_token_id.toString(),
        pool_id: value.pool_id.toNumber(),
        pool_owner: value.pool_owner.toString(),
        pool_state: parsePoolStateEnum(value.pool_state.name),
        pool_type: parsePoolTypeEnum(value.pool_type.name),
        pool_trade_fee_percent: value.pool_trade_fee_percent.toNumber() / 100 + '%',
        pool_spot_price: value.pool_spot_price.toFixed(),
        pool_curve_type: parsePoolCurveTypeEnum(value.pool_curve_type.name),
        pool_linear_curve_step_amount: value.pool_linear_curve_step_amount.toFixed(),
        pool_exponential_curve_step_percent: value.pool_exponential_curve_step_percent.toNumber() / 100,
        pool_accumulated_fee: value.pool_accumulated_fee.toFixed(),
        pool_swap_token_id: value.pool_swap_token_id.toString(),
        pool_swap_token_decimal: value.pool_swap_token_decimal.toNumber(),
        pool_swap_token_reserve: value.pool_swap_token_reserve.toFixed(),
        pool_buy_order_count: value.pool_buy_order_count.toNumber(),
        pool_nft_token_reserve: value.pool_nft_token_reserve.toNumber(),
        pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
        pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
        pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
        service_fee: value.service_fee.toNumber() / 100,
        pool_delta: value.pool_linear_curve_step_amount.toFixed(),
      };
      if (data.pool_curve_type == PoolCurveTypeEnum.Exponential) {
        data.pool_delta = data.pool_exponential_curve_step_percent + '%';
      }
      return data;
    });

    const decodedCollectionNfts: PoolNft[] = collectionNfts.map((value: any, index: number) => ({
      index,
      pool_id: value.pool_id.toNumber(),
      nft_token_id: value.nft_token_id.toString(),
      nft_token_nonce: value.nft_token_nonce.toNumber(),
      nft_token_amount: value.nft_token_amount.toFixed(),
      pool_spot_buy_price: value.pool_spot_buy_price.toFixed(),
      pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
      pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
      pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
    }));

    decodedCollectionNfts.sort((a: PoolNft, b: PoolNft) => convertWeiToEsdt(a.pool_trade_sell_price).toNumber() - convertWeiToEsdt(b.pool_trade_sell_price).toNumber());

    return [decodedCollectionPools, decodedCollectionNfts];
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [[], []];
  }
};

export const queryViewPoolData = async (apiAddress: string, pool_nft_token_id: string, poolId: number): Promise<[NftCollectionPoolInfo | undefined, PoolNft[]]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(pool_nft_token_id),
      new U32Value(poolId)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getPoolData(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [undefined, []];
    }
    const values = firstValue.valueOf();
    const poolInfo = values.pool_info;
    const poolNfts = values.pool_nfts;
    const decodedPoolInfo: NftCollectionPoolInfo = {
      pool_nft_token_id: poolInfo.pool_nft_token_id.toString(),
      pool_id: poolInfo.pool_id.toNumber(),
      pool_owner: poolInfo.pool_owner.toString(),
      pool_state: parsePoolStateEnum(poolInfo.pool_state.name),
      pool_type: parsePoolTypeEnum(poolInfo.pool_type.name),
      pool_trade_fee_percent: poolInfo.pool_trade_fee_percent.toNumber() / 100,
      pool_spot_price: poolInfo.pool_spot_price.toFixed(),
      pool_curve_type: parsePoolCurveTypeEnum(poolInfo.pool_curve_type.name),
      pool_linear_curve_step_amount: poolInfo.pool_linear_curve_step_amount.toFixed(),
      pool_exponential_curve_step_percent: poolInfo.pool_exponential_curve_step_percent.toNumber() / 100,
      pool_accumulated_fee: poolInfo.pool_accumulated_fee.toFixed(),
      pool_swap_token_id: poolInfo.pool_swap_token_id.toString(),
      pool_swap_token_decimal: poolInfo.pool_swap_token_decimal.toNumber(),
      pool_swap_token_reserve: poolInfo.pool_swap_token_reserve.toFixed(),
      pool_buy_order_count: poolInfo.pool_buy_order_count.toNumber(),
      pool_nft_token_reserve: poolInfo.pool_nft_token_reserve.toNumber(),
      pool_spot_sell_price: poolInfo.pool_spot_sell_price.toFixed(),
      pool_trade_buy_price: poolInfo.pool_trade_buy_price.toFixed(),
      pool_trade_sell_price: poolInfo.pool_trade_sell_price.toFixed(),
      service_fee: poolInfo.service_fee.toNumber() / 100,
      pool_delta: poolInfo.pool_linear_curve_step_amount.toFixed(),
    };

    const decodedPoolNfts: PoolNft[] = poolNfts.map((value: any, index: number) => ({
      index,
      pool_id: value.pool_id.toNumber(),
      nft_token_id: value.nft_token_id.toString(),
      nft_token_nonce: value.nft_token_nonce.toNumber(),
      nft_token_amount: value.nft_token_amount.toFixed(),
      pool_spot_buy_price: value.pool_spot_buy_price.toFixed(),
      pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
      pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
      pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
    }));

    return [decodedPoolInfo, decodedPoolNfts];
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [undefined, []];
  }
};

export const queryViewCollectionNfts = async (apiAddress: string, pool_nft_token_id: string): Promise<PoolNft[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(pool_nft_token_id)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getCollectionNfts(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    const decoded: PoolNft[] = values.map((value: any, index: number) => ({
      index,
      pool_id: value.pool_id.toNumber(),
      nft_token_id: value.nft_token_id.toString(),
      nft_token_nonce: value.nft_token_nonce.toNumber(),
      nft_token_amount: value.nft_token_amount.toFixed(),
      pool_spot_buy_price: value.pool_spot_buy_price.toFixed(),
      pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
      pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
      pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
    }));

    decoded.sort((a: PoolNft, b: PoolNft) => convertWeiToEsdt(a.pool_trade_sell_price).toNumber() - convertWeiToEsdt(b.pool_trade_sell_price).toNumber());
    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewCollectionPools = async (apiAddress: string, pool_nft_token_id: string): Promise<NftCollectionPoolInfo[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(pool_nft_token_id)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getNftCollectionPools(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    const decoded: NftCollectionPoolInfo[] = values.map((value: any, index: number) => {
      const data = {
        index,
        pool_nft_token_id: value.pool_nft_token_id.toString(),
        pool_id: value.pool_id.toNumber(),
        pool_owner: value.pool_owner.toString(),
        pool_state: parsePoolStateEnum(value.pool_state.name),
        pool_type: parsePoolTypeEnum(value.pool_type.name),
        pool_trade_fee_percent: value.pool_trade_fee_percent.toNumber() / 100 + '%',
        pool_spot_price: value.pool_spot_price.toFixed(),
        pool_curve_type: parsePoolCurveTypeEnum(value.pool_curve_type.name),
        pool_linear_curve_step_amount: value.pool_linear_curve_step_amount.toFixed(),
        pool_exponential_curve_step_percent: value.pool_exponential_curve_step_percent.toNumber() / 100,
        pool_accumulated_fee: value.pool_accumulated_fee.toFixed(),
        pool_swap_token_id: value.pool_swap_token_id.toString(),
        pool_swap_token_decimal: value.pool_swap_token_decimal.toNumber(),
        pool_swap_token_reserve: value.pool_swap_token_reserve.toFixed(),
        pool_buy_order_count: value.pool_buy_order_count.toNumber(),
        pool_nft_token_reserve: value.pool_nft_token_reserve.toNumber(),
        pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
        pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
        pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
        service_fee: value.service_fee.toNumber() / 100,
        pool_delta: value.pool_linear_curve_step_amount.toFixed(),
      };
      if (data.pool_curve_type == PoolCurveTypeEnum.Exponential) {
        data.pool_delta = data.pool_exponential_curve_step_percent + '%';
      }
      return data;
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewOwnPools = async (apiAddress: string, address: string): Promise<NftCollectionPoolInfo[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address))
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getOwnPools(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    const decoded: NftCollectionPoolInfo[] = values.map((value: any, index: number) => {
      const data = {
        index,
        pool_nft_token_id: value.pool_nft_token_id.toString(),
        pool_id: value.pool_id.toNumber(),
        pool_owner: value.pool_owner.toString(),
        pool_state: parsePoolStateEnum(value.pool_state.name),
        pool_type: parsePoolTypeEnum(value.pool_type.name),
        pool_trade_fee_percent: value.pool_trade_fee_percent.toNumber() / 100 + '%',
        pool_spot_price: value.pool_spot_price.toFixed(),
        pool_curve_type: parsePoolCurveTypeEnum(value.pool_curve_type.name),
        pool_linear_curve_step_amount: value.pool_linear_curve_step_amount.toFixed(),
        pool_exponential_curve_step_percent: value.pool_exponential_curve_step_percent.toNumber() / 100,
        pool_accumulated_fee: value.pool_accumulated_fee.toFixed(),
        pool_swap_token_id: value.pool_swap_token_id.toString(),
        pool_swap_token_decimal: value.pool_swap_token_decimal.toNumber(),
        pool_swap_token_reserve: value.pool_swap_token_reserve.toFixed(),
        pool_buy_order_count: value.pool_buy_order_count.toNumber(),
        pool_nft_token_reserve: value.pool_nft_token_reserve.toNumber(),
        pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
        pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
        pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
        service_fee: value.service_fee.toNumber() / 100,
        pool_delta: value.pool_linear_curve_step_amount.toFixed(),
      };
      if (data.pool_curve_type == PoolCurveTypeEnum.Exponential) {
        data.pool_delta = data.pool_exponential_curve_step_percent + '%';
      }
      return data;
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewCollectionActivities = async (apiAddress: string, pool_nft_token_id: string): Promise<PoolActivity[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(pool_nft_token_id)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getCollectionActivities(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    const decoded: PoolActivity[] = values.map((value: any, index: number) => ({
      index,
      pool_nft_token_id: value.nft_token_id.toString(),
      pool_id: value.pool_id.toNumber(),
      pool_activity_id: value.pool_activity_id.toNumber(),
      pool_activity_token_nonce: value.pool_activity_token_nonce.toNumber(),
      pool_activity_token_amount: value.pool_activity_token_amount.toFixed(),
      pool_activity_caller_address: value.pool_activity_caller_address.toString(),
      pool_activity_type: parseSwapTypeEnum(value.pool_activity_type.name),
      pool_activity_price: value.pool_activity_price.toFixed(),
      pool_activity_timestamp: value.pool_activity_timestamp.toNumber(),
    }));
    decoded.sort((a: PoolActivity, b: PoolActivity) => (a.pool_activity_timestamp > b.pool_activity_timestamp) ? -1 : 1);

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewCollectionPool = async (apiAddress: string, pool_nft_token_id: string, poolId: number): Promise<NftCollectionPoolInfo | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(pool_nft_token_id),
      new U32Value(poolId)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getNftCollectionPool(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }
    const value = firstValue.valueOf();
    const decoded: NftCollectionPoolInfo = {
      pool_nft_token_id: value.pool_nft_token_id.toString(),
      pool_id: value.pool_id.toNumber(),
      pool_owner: value.pool_owner.toString(),
      pool_state: parsePoolStateEnum(value.pool_state.name),
      pool_type: parsePoolTypeEnum(value.pool_type.name),
      pool_trade_fee_percent: value.pool_trade_fee_percent.toNumber() / 100,
      pool_spot_price: value.pool_spot_price.toFixed(),
      pool_curve_type: parsePoolCurveTypeEnum(value.pool_curve_type.name),
      pool_linear_curve_step_amount: value.pool_linear_curve_step_amount.toFixed(),
      pool_exponential_curve_step_percent: value.pool_exponential_curve_step_percent.toNumber() / 100,
      pool_accumulated_fee: value.pool_accumulated_fee.toFixed(),
      pool_swap_token_id: value.pool_swap_token_id.toString(),
      pool_swap_token_decimal: value.pool_swap_token_decimal.toNumber(),
      pool_swap_token_reserve: value.pool_swap_token_reserve.toFixed(),
      pool_buy_order_count: value.pool_buy_order_count.toNumber(),
      pool_nft_token_reserve: value.pool_nft_token_reserve.toNumber(),
      pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
      pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
      pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
      service_fee: value.service_fee.toNumber() / 100,
      pool_delta: value.pool_linear_curve_step_amount.toFixed(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryViewPoolNfts = async (apiAddress: string, pool_nft_token_id: string, poolId: number): Promise<PoolNft[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(pool_nft_token_id),
      new U32Value(poolId)
    ];
    const interaction = nftAmmSmartContract.methodsExplicit.getPoolNfts(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }
    const values = firstValue.valueOf();
    const decoded: PoolNft[] = values.map((value: any, index: number) => ({
      index,
      pool_id: value.pool_id.toNumber(),
      nft_token_id: value.nft_token_id.toString(),
      nft_token_nonce: value.nft_token_nonce.toNumber(),
      nft_token_amount: value.nft_token_amount.toFixed(),
      pool_spot_buy_price: value.pool_spot_buy_price.toFixed(),
      pool_spot_sell_price: value.pool_spot_sell_price.toFixed(),
      pool_trade_buy_price: value.pool_trade_buy_price.toFixed(),
      pool_trade_sell_price: value.pool_trade_sell_price.toFixed(),
    }));

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

///////////////// FARM /////////////////
export const queryViewJewelLendContext = async (apiAddress: string): Promise<JewelLendContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelLendSmartContract.methodsExplicit.viewJewelLendContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();

    const admins = value.admins;
    const decodedAdmins: string[] = admins.map((item: any) => item.toString());
    const borrowWhitelists = value.borrow_whitelist;
    const decodedBorrowWhitelists: string[] = borrowWhitelists.map((item: any) => item.toString());

    const depositTokenIds = value.deposit_token_ids;
    const ibTokenIds = value.ib_token_ids;
    const depositTokenAmounts = value.deposit_token_amounts;
    const ibTokenAmounts = value.ib_token_amounts;
    const loanAmounts = value.loan_amounts;

    const lendPool: JewelLendPool[] = [];
    for (let i = 0; i < depositTokenIds.length; i++) {
      const pool: JewelLendPool = {
        deposit_token_id: depositTokenIds[i].toString(),
        ib_token_id: ibTokenIds[i].toString(),
        deposit_token_amount: depositTokenAmounts[i].toFixed(),
        ib_token_amount: ibTokenAmounts[i].toFixed(),
        loan_amount: loanAmounts[i].toFixed(),
      };
      lendPool.push(pool);
    }

    const decodedValue: JewelLendContext = {
      state: parseJewelLendStateEnum(value.state.name),
      admins: decodedAdmins,
      borrow_whitelist: decodedBorrowWhitelists,
      lendPool: lendPool,
    };

    return decodedValue;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryGetEquivalent = async (apiAddress: string, tokenId: string, tokenAmount: string): Promise<string> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(tokenId),
      new BigUIntValue(tokenAmount)
    ];
    const interaction = jewelLendSmartContract.methodsExplicit.getEquivalent(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return '0';
    }

    const value = firstValue.valueOf();
    return value.toFixed();
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};

export const queryViewJewelFarmBaseContextData = async (apiAddress: string, address: string, balance: string): Promise<JewelFarmBaseContextData | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelUsdtAshFarmSmartContract.methodsExplicit.viewBaseContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();

    const userTokens: any = [];
    if (address) {
      const userTokenIds: string[] = [];
      for (let i = 0; i < value.liquidation_tokens.length; i++) {
        userTokenIds.push(value.liquidation_tokens[i].toString());
      }
      userTokenIds.push(value.reward_token_id.toString());
      userTokenIds.push(value.wegld_token_id.toString());

      for (let i = 0; i < value.farms.length; i++) {
        const farm = value.farms[i];
        for (let j = 0; j < farm.token_ids.length; j++) {
          userTokenIds.push(farm.token_ids[j].toString());
        }
        userTokenIds.push(farm.lp_token_id.toString());
        userTokenIds.push(farm.farm_token_id.toString());
      }
      const _userTokens = await getUserTokenBalance(apiAddress, address, userTokenIds);

      userTokens['EGLD'] = {
        balance: balance,
      };

      for (let i = 0; i < _userTokens.length; i++) {
        userTokens[_userTokens[i].identifier] = {
          balance: _userTokens[i].balance,
        };
      }

      for (let i = 0; i < userTokenIds.length; i++) {
        if (!userTokens[userTokenIds[i]]) {
          userTokens[userTokenIds[i]] = {
            balance: '0',
          };
        }
      }
    }
    const _tokens = await getTokenPriceFromAshswap();

    const proxy = new ProxyNetworkProvider(isDev ? MVXProxyNetworkAddress.Devnet : NETWORK_JEWEL_API, { timeout: apiTimeout });
    const votingEscrowContract = ContractManager
      .getVotingEscrowContract(ASHSWAP_VOTING_ESCROW_ADDRESS)
      .onChain(isDev ? ChainId.Devnet : ChainId.Mainnet)
      .onProxy(proxy);
    const currentTimestamp = Math.floor(Date.now() / SECOND_TO_MILLISECONDS);
    const _veashBalance = await votingEscrowContract.getUserBalanceAtTs(JEWEL_ASHSWAP_SC_ADDRESS, currentTimestamp);
    const _veashTotalSupply = await votingEscrowContract.getTotalSupplyAtTs(currentTimestamp);

    const decodedFarms: JewelFarmContextData[] = [];
    for (let i = 0; i < value.farms.length; i++) {
      const item = value.farms[i];

      const farm_token_id = item.farm_token_id.toString();
      const lp_token_id = item.lp_token_id.toString();
      const dex_pool_address = item.dex_pool_address.toString();
      const dex_farm_address = item.dex_farm_address.toString();
      const nfts = await getNftsByCollectionFromApi(apiAddress, JEWEL_ASHSWAP_SC_ADDRESS, farm_token_id);
      let _stakedLpAmount = '0';
      let _lpPrice = '0';
      let _reserves: any[] = [];
      let _lpTotalSupply = new BigNumber('0');
      let _totalStakedLpValue = '0';
      let _totalLiquidityValue = '0';
      let _lpLockedAmt = '0';
      let _farmTokenSupply = new BigNumber('0');
      let _existFarmTokenBal = '0';
      let _rewardPerDay = '0';
      let _ashPrice = 0;
      let _ashBaseApr = 0;

      if (nfts.length > 0) {
        const nft = nfts[0];
        const farmTokenAttributesStruct = farmAbiRegistry.getStruct("FarmTokenAttributes");
        const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(nft.attributes, "base64"), farmTokenAttributesStruct).valueOf();
        _stakedLpAmount = decodedAttributes.initial_farming_amount.toFixed();
        _totalStakedLpValue = new BigNumber(nft.balance).multipliedBy(decodedAttributes.initial_farming_amount).dividedBy(decodedAttributes.initial_farm_amount).toFixed();
        _existFarmTokenBal = nft.balance;

        if (_tokens.data) {
          const _prices = _tokens.data.tokens;
          _lpPrice = _prices.filter((token: any) => token.id === lp_token_id)[0]['price'];
          _ashPrice = _prices.filter((token: any) => token.id === ASH_TOKEN_ID)[0]['price'];

          for (let j = 0; j < _tokens.data.farms.length; j++) {
            if (_tokens.data.farms[j].address === dex_farm_address) {
              _totalLiquidityValue = new BigNumber(_tokens.data.farms[j].farmingTokenBalance).multipliedBy(_tokens.data.farms[j].farmingToken.price).toFixed();
              _lpLockedAmt = _tokens.data.farms[j].farmingTokenBalance;
              break;
            }
          }
        }

        if (item.token_ids.length > 3) {
          const poolContract = ContractManager.getPoolContract(
            dex_pool_address
          ).onChain(isDev ? ChainId.Devnet : ChainId.Mainnet).onProxy(proxy);
          _reserves = await poolContract.getReserves();
          _lpTotalSupply = await poolContract.getTotalSupply();
        } else {
          const poolContract = ContractManager.getPoolV2Contract(
            dex_pool_address
          ).onChain(isDev ? ChainId.Devnet : ChainId.Mainnet).onProxy(proxy);
          _reserves = await poolContract.getReserves();
          _lpTotalSupply = await poolContract.getTotalSupply();
        }

        const farmContract = ContractManager
          .getFarmContract(dex_farm_address)
          .onChain(isDev ? ChainId.Devnet : ChainId.Mainnet)
          .onProxy(proxy);

        // const rewardsAmount = await farmContract.calculateRewardsForGivenPosition(new BigNumber(nft.balance), decodedAttributes);
        // const apr = rewardsAmount.multipliedBy(YEAR_IN_SECONDS).dividedBy(AUTO_COMPOUND_CRON_JOB_TIME).dividedBy(_stakedLpAmount).dividedBy(100);
        // console.log(rewardsAmount.toFixed(), _stakedLpAmount, apr.toFixed());
        // const n = YEAR_IN_SECONDS / AUTO_COMPOUND_CRON_JOB_TIME;
        // _apy = Math.pow((apr.toNumber() / n) + 1, n) - 1;
        _farmTokenSupply = await farmContract.getFarmTokenSupply();
        const rewardPerSec = await farmContract.getRewardPerSec();
        _rewardPerDay = rewardPerSec.multipliedBy(DAY_IN_SECONDS).multipliedBy(nft.balance).dividedBy(_farmTokenSupply).toFixed(0);
        // const apr = new BigNumber(_rewardPerDay).multipliedBy(365).dividedBy(_farmTokenSupply);
        // const apy = Math.pow((apr.toNumber() / n) + 1, n) - 1;
        // console.log(apr.toFixed(), apy);

        const _farmTokenSupplyValue = convertWeiToEsdt(_farmTokenSupply.multipliedBy(_lpPrice), ASH_LP_DECIMALS);
        const _totalAshRewardPerYear = convertWeiToEsdt(rewardPerSec.multipliedBy(YEAR_IN_SECONDS));
        if (_farmTokenSupplyValue.gt('0')) {
          _ashBaseApr = _totalAshRewardPerYear.multipliedBy(_ashPrice).multipliedBy(0.4).multipliedBy(100).div(_farmTokenSupplyValue).toNumber();
        }
      }

      // boost
      const _tvlExcludeOwnLPValue = new BigNumber(_totalLiquidityValue).minus(_totalStakedLpValue);
      const _totalLPExcludeOwnLPWei = _tvlExcludeOwnLPValue.multipliedBy(_lpLockedAmt).div(_totalLiquidityValue);
      const _lpWei = new BigNumber(_totalStakedLpValue).multipliedBy(_lpLockedAmt).div(_totalLiquidityValue);
      const _veForMaxBoost = _veashTotalSupply.multipliedBy(_totalStakedLpValue).div(_tvlExcludeOwnLPValue);
      const _totalLP = _lpWei.plus(_totalLPExcludeOwnLPWei);
      const _maxYieldBoost = calcYieldBoost(
        _lpWei.toFixed(),
        _totalLP.toFixed(),
        _veForMaxBoost.multipliedBy(10 ** ASH_LP_DECIMALS).toFixed(),
        _veashTotalSupply
          .plus(_veForMaxBoost)
          .multipliedBy(10 ** ASH_LP_DECIMALS).toFixed(),
        _farmTokenSupply.toFixed(),
        _existFarmTokenBal,
      );

      const _boost = calcYieldBoost(
        _lpWei.toFixed(),
        _totalLP.toFixed(),
        _veashBalance.toFixed(),
        _veashTotalSupply.toFixed(),
        _farmTokenSupply.toFixed(),
        _existFarmTokenBal
      );


      let _token_ids = [];
      let _borrow_amounts = [];
      let _farm_shares_per_token = [];
      let _borrow_shares_per_token = [];
      let _reserves_amounts = [];

      if (item.token_ids.length < 4) {
        _token_ids = item.token_ids.map((id: any) => id.toString());
        _borrow_amounts = item.borrow_amounts.map((amount: any) => amount.toFixed());
        _farm_shares_per_token = item.farm_shares_per_token.map((amount: any) => amount.toFixed());
        _borrow_shares_per_token = item.borrow_shares_per_token;
        _reserves_amounts = _reserves.map((amount: any) => amount.toFixed());
      } else {
        _token_ids.push(item.token_ids[1].toString());
        _token_ids.push(item.token_ids[2].toString());
        _token_ids.push(item.token_ids[0].toString());
        _token_ids.push(item.token_ids[3].toString());
        _borrow_amounts.push(item.borrow_amounts[1].toFixed());
        _borrow_amounts.push(item.borrow_amounts[2].toFixed());
        _borrow_amounts.push(item.borrow_amounts[0].toFixed());
        _farm_shares_per_token.push(item.farm_shares_per_token[1].toFixed());
        _farm_shares_per_token.push(item.farm_shares_per_token[2].toFixed());
        _farm_shares_per_token.push(item.farm_shares_per_token[0].toFixed());
        _borrow_shares_per_token.push(item.borrow_shares_per_token[1]);
        _borrow_shares_per_token.push(item.borrow_shares_per_token[2]);
        _borrow_shares_per_token.push(item.borrow_shares_per_token[0]);
        _reserves_amounts.push(_reserves[1].toFixed());
        _reserves_amounts.push(_reserves[2].toFixed());
        _reserves_amounts.push(_reserves[0].toFixed());
      }

      const farm = {
        farm_id: item.farm_id.toNumber(),
        dex_pool_address: dex_pool_address,
        dex_farm_address: item.dex_farm_address.toString(),
        token_ids: _token_ids,
        lp_token_id: lp_token_id,
        farm_token_id: farm_token_id,
        farm_token_nonce: item.farm_token_nonce.toNumber(),
        farm_token_amount: item.farm_token_amount.toFixed(),
        min_deposit_base_token: item.min_deposit_base_token.toString(),
        min_deposit_base_amount: item.min_deposit_base_amount.toFixed(),
        pending_reward_token_amount: item.pending_reward_token_amount.toFixed(),
        borrow_amounts: _borrow_amounts,
        farm_shares_per_token: _farm_shares_per_token,
        borrow_shares_per_token: _borrow_shares_per_token,
        total_farm_share: item.total_farm_share.toFixed(),
        opened_position_count: item.opened_position_count.toNumber(),
        staked_lp_amount: _stakedLpAmount,
        lp_price: _lpPrice,
        reserves: _reserves_amounts,
        lp_total_supply: _lpTotalSupply.toFixed(),
        total_staked_lp_value: _totalStakedLpValue,
        total_liquidity_value: _totalLiquidityValue,
        lp_locked_amt: _lpLockedAmt,
        farm_token_supply: _farmTokenSupply.toFixed(),
        exist_farm_token_bal: _existFarmTokenBal,
        reward_per_day: _rewardPerDay,
        ash_base_apr: _ashBaseApr,
        max_yeild_boost: _maxYieldBoost,
        current_boost: _boost,
      };

      decodedFarms.push(farm);
    }

    const decodedValue: JewelFarmBaseContextData = {
      state: parseJewelFarmStateEnum(value.state.name),
      admins: value.admins.map((item: any) => item.toString()),
      jewel_lend_address: value.jewel_lend_address.toString(),
      reward_token_id: value.reward_token_id.toString(),
      wegld_token_id: value.wegld_token_id.toString(),
      ashswap_governance_address: value.ashswap_governance_address.toString(),
      ashswap_fee_distributor_address: value.ashswap_fee_distributor_address.toString(),
      ashswap_pool_addresses: value.ashswap_pool_addresses.map((item: any) => item.toString()),
      xexchange_pool_addresses: value.xexchange_pool_addresses.map((item: any) => item.toString()),
      min_compound_rewards_amount: value.min_compound_rewards_amount.toFixed(),
      liquidation_tokens: value.liquidation_tokens.map((item: any) => item.toString()),
      liquidation_thresholds: value.liquidation_thresholds.map((item: any) => item.toNumber()),
      max_debt_tokens: value.max_debt_tokens.map((item: any) => item.toString()),
      max_debt_amounts: value.max_debt_amounts.map((item: any) => item.toFixed()),
      fee_collector_addresses: value.fee_collector_addresses.map((item: any) => item.toString()),
      fee_collector_percents: value.fee_collector_percents.map((item: any) => item.toNumber()),
      farms: decodedFarms,
      last_position_id: value.last_position_id.toNumber(),
      total_opened_position_count: value.total_opened_position_count.toNumber(),
      user_tokens: userTokens,
      ashswap_tokens: _tokens,
    };

    return decodedValue;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryViewJewelFarmBaseContext = async (): Promise<JewelFarmBaseContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelUsdtAshFarmSmartContract.methodsExplicit.viewBaseContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();

    const _tokens = await getTokenPriceFromAshswap();

    const decodedValue: JewelFarmBaseContext = {
      state: parseJewelFarmStateEnum(value.state.name),
      admins: value.admins.map((item: any) => item.toString()),
      jewel_lend_address: value.jewel_lend_address.toString(),
      reward_token_id: value.reward_token_id.toString(),
      wegld_token_id: value.wegld_token_id.toString(),
      ashswap_governance_address: value.ashswap_governance_address.toString(),
      ashswap_fee_distributor_address: value.ashswap_fee_distributor_address.toString(),
      ashswap_pool_addresses: value.ashswap_pool_addresses.map((item: any) => item.toString()),
      xexchange_pool_addresses: value.xexchange_pool_addresses.map((item: any) => item.toString()),
      min_compound_rewards_amount: value.min_compound_rewards_amount.toFixed(),
      liquidation_tokens: value.liquidation_tokens.map((item: any) => item.toString()),
      liquidation_thresholds: value.liquidation_thresholds.map((item: any) => item.toNumber()),
      max_debt_tokens: value.max_debt_tokens.map((item: any) => item.toString()),
      max_debt_amounts: value.max_debt_amounts.map((item: any) => item.toFixed()),
      fee_collector_addresses: value.fee_collector_addresses.map((item: any) => item.toString()),
      fee_collector_percents: value.fee_collector_percents.map((item: any) => item.toNumber()),
      ashswap_tokens: _tokens,
    };

    return decodedValue;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryViewJewelFarms = async (apiAddress: string): Promise<JewelFarmContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelUsdtAshFarmSmartContract.methodsExplicit.viewFarms();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decodedFarms: JewelFarmContext[] = [];
    for (let i = 0; i < values.length; i++) {
      const item = values[i];

      const farm_token_id = item.farm_token_id.toString();
      const nfts = await getNftsByCollectionFromApi(apiAddress, JEWEL_ASHSWAP_SC_ADDRESS, farm_token_id);

      const farm = {
        farm_id: item.farm_id.toNumber(),
        dex_pool_address: item.dex_pool_address.toString(),
        dex_farm_address: item.dex_farm_address.toString(),
        token_ids: item.token_ids.map((tokenId: any) => tokenId.toString()),
        lp_token_id: item.lp_token_id.toString(),
        farm_token_id: farm_token_id,
        farm_token_nonce: item.farm_token_nonce.toNumber(),
        farm_token_amount: item.farm_token_amount.toFixed(),
        min_deposit_base_token: item.min_deposit_base_token.toString(),
        min_deposit_base_amount: item.min_deposit_base_amount.toFixed(),
        pending_reward_token_amount: item.pending_reward_token_amount.toFixed(),
        borrow_amounts: item.borrow_amounts.map((amount: any) => amount.toFixed()),
        farm_shares_per_token: item.farm_shares_per_token.map((amount: any) => amount.toFixed()),
        borrow_shares_per_token: item.borrow_shares_per_token.map((amount: any) => amount.toFixed()),
        total_farm_share: item.total_farm_share.toFixed(),
        opened_position_count: item.opened_position_count.toNumber(),
        nfts: nfts,
      };

      decodedFarms.push(farm);
    }

    return decodedFarms;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewJewelFarmData = async (apiAddress: string, item: JewelFarmContext, ashswapTokens: any): Promise<JewelFarmContextData | undefined> => {
  try {
    let _stakedLpAmount = '0';
    let _lpPrice = '0';
    let _reserves: any[] = [];
    let _lpTotalSupply = new BigNumber('0');
    let _totalStakedLpValue = '0';
    let _totalLiquidityValue = '0';
    let _lpLockedAmt = '0';
    let _farmTokenSupply = new BigNumber('0');
    let _existFarmTokenBal = '0';
    let _rewardPerDay = '0';
    let _ashPrice = 0;
    let _ashBaseApr = 0;

    const proxy = new ProxyNetworkProvider(isDev ? MVXProxyNetworkAddress.Devnet : NETWORK_JEWEL_API, { timeout: apiTimeout });

    const _tokens = ashswapTokens;
    const nfts = item.nfts;

    if (nfts.length > 0) {
      const nft = nfts[0];
      const farmTokenAttributesStruct = farmAbiRegistry.getStruct("FarmTokenAttributes");
      const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(nft.attributes, "base64"), farmTokenAttributesStruct).valueOf();
      _stakedLpAmount = new BigNumber(nft.balance).multipliedBy(decodedAttributes.initial_farming_amount).dividedBy(decodedAttributes.initial_farm_amount).toFixed();
      _existFarmTokenBal = nft.balance;

      if (_tokens.data) {
        const _prices = _tokens.data.tokens;
        const filteredPrices = _prices.filter((token: any) => token.id === item.lp_token_id);
        if (filteredPrices.length > 0) {
          _lpPrice = filteredPrices[0]['price'];
        }
        _ashPrice = _prices.filter((token: any) => token.id === ASH_TOKEN_ID)[0]['price'];

        for (let j = 0; j < _tokens.data.farms.length; j++) {
          if (_tokens.data.farms[j].address === item.dex_farm_address) {
            _totalLiquidityValue = new BigNumber(_tokens.data.farms[j].farmingTokenBalance).multipliedBy(_tokens.data.farms[j].farmingToken.price).toFixed();
            _totalStakedLpValue = new BigNumber(nft.balance).multipliedBy(decodedAttributes.initial_farming_amount).multipliedBy(_tokens.data.farms[j].farmingToken.price).dividedBy(decodedAttributes.initial_farm_amount).toFixed();
            _lpLockedAmt = _tokens.data.farms[j].farmingTokenBalance;
            break;
          }
        }
      }

      if (getIsStablePool(item.farm_id)) {
        const poolContract = ContractManager.getPoolContract(
          item.dex_pool_address
        ).onChain(isDev ? ChainId.Devnet : ChainId.Mainnet).onProxy(proxy);
        _reserves = await poolContract.getReserves();
        _lpTotalSupply = await poolContract.getTotalSupply();
      } else {
        const poolContract = ContractManager.getPoolV2Contract(
          item.dex_pool_address
        ).onChain(isDev ? ChainId.Devnet : ChainId.Mainnet).onProxy(proxy);
        _reserves = await poolContract.getReserves();
        _lpTotalSupply = await poolContract.getTotalSupply();
      }

      const farmContract = ContractManager
        .getFarmContract(item.dex_farm_address)
        .onChain(isDev ? ChainId.Devnet : ChainId.Mainnet)
        .onProxy(proxy);

      _farmTokenSupply = await farmContract.getFarmTokenSupply();
      const rewardPerSec = await farmContract.getRewardPerSec();
      const _farmTokenSupplyValue = convertWeiToEsdt(_farmTokenSupply.multipliedBy(_lpPrice), ASH_LP_DECIMALS);
      const _totalAshRewardPerYear = convertWeiToEsdt(rewardPerSec.multipliedBy(YEAR_IN_SECONDS));
      if (_farmTokenSupplyValue.gt('0')) {
        _ashBaseApr = _totalAshRewardPerYear.multipliedBy(_ashPrice).multipliedBy(0.4).multipliedBy(100).div(_farmTokenSupplyValue).toNumber();
      }

      _rewardPerDay = rewardPerSec.multipliedBy(DAY_IN_SECONDS).multipliedBy(nft.balance).dividedBy(_farmTokenSupply).toFixed(0);
    }

    // boost
    const _tvlExcludeOwnLPValue = new BigNumber(_totalLiquidityValue).minus(_totalStakedLpValue);
    const _totalLPExcludeOwnLPWei = _tvlExcludeOwnLPValue.multipliedBy(_lpLockedAmt).div(_totalLiquidityValue);
    const _lpWei = new BigNumber(_totalStakedLpValue).multipliedBy(_lpLockedAmt).div(_totalLiquidityValue);
    const votingEscrowContract = ContractManager
      .getVotingEscrowContract(ASHSWAP_VOTING_ESCROW_ADDRESS)
      .onChain(isDev ? ChainId.Devnet : ChainId.Mainnet)
      .onProxy(proxy);
    const currentTimestamp = Math.floor(Date.now() / SECOND_TO_MILLISECONDS);
    const _veashBalance = await votingEscrowContract.getUserBalanceAtTs(JEWEL_ASHSWAP_SC_ADDRESS, currentTimestamp);
    const _veashTotalSupply = await votingEscrowContract.getTotalSupplyAtTs(currentTimestamp);
    const _veForMaxBoost = _veashTotalSupply.multipliedBy(_totalStakedLpValue).div(_tvlExcludeOwnLPValue);
    const _totalLP = _lpWei.plus(_totalLPExcludeOwnLPWei);
    const _maxYieldBoost = calcYieldBoost(
      _lpWei.toFixed(),
      _totalLP.toFixed(),
      _veForMaxBoost.multipliedBy(10 ** ASH_LP_DECIMALS).toFixed(),
      _veashTotalSupply
        .plus(_veForMaxBoost)
        .multipliedBy(10 ** ASH_LP_DECIMALS).toFixed(),
      _farmTokenSupply.toFixed(),
      _existFarmTokenBal,
    );

    const _boost = calcYieldBoost(
      _lpWei.toFixed(),
      _totalLP.toFixed(),
      _veashBalance.toFixed(),
      _veashTotalSupply.toFixed(),
      _farmTokenSupply.toFixed(),
      _existFarmTokenBal
    );

    let _token_ids = [];
    let _borrow_amounts = [];
    let _farm_shares_per_token = [];
    let _borrow_shares_per_token = [];
    let _reserves_amounts = [];

    if (item.token_ids.length > 3) {
      _token_ids.push(item.token_ids[1]);
      _token_ids.push(item.token_ids[2]);
      _token_ids.push(item.token_ids[0]);
      _token_ids.push(item.token_ids[3]);
      _borrow_amounts.push(item.borrow_amounts[1]);
      _borrow_amounts.push(item.borrow_amounts[2]);
      _borrow_amounts.push(item.borrow_amounts[0]);
      _farm_shares_per_token.push(item.farm_shares_per_token[1]);
      _farm_shares_per_token.push(item.farm_shares_per_token[2]);
      _farm_shares_per_token.push(item.farm_shares_per_token[0]);
      _borrow_shares_per_token.push(item.borrow_shares_per_token[1]);
      _borrow_shares_per_token.push(item.borrow_shares_per_token[2]);
      _borrow_shares_per_token.push(item.borrow_shares_per_token[0]);
      _reserves_amounts.push(_reserves[1]);
      _reserves_amounts.push(_reserves[2]);
      _reserves_amounts.push(_reserves[0]);
    } else {
      _token_ids = item.token_ids;
      _borrow_amounts = item.borrow_amounts;
      _farm_shares_per_token = item.farm_shares_per_token;
      _borrow_shares_per_token = item.borrow_shares_per_token;
      _reserves_amounts = _reserves.map((amount: any) => amount.toFixed());
    }

    const farm: JewelFarmContextData = {
      farm_id: item.farm_id,
      dex_pool_address: item.dex_pool_address,
      dex_farm_address: item.dex_farm_address,
      token_ids: _token_ids,
      lp_token_id: item.lp_token_id,
      farm_token_id: item.farm_token_id,
      farm_token_nonce: item.farm_token_nonce,
      farm_token_amount: item.farm_token_amount,
      min_deposit_base_token: item.min_deposit_base_token,
      min_deposit_base_amount: item.min_deposit_base_amount,
      pending_reward_token_amount: item.pending_reward_token_amount,
      borrow_amounts: _borrow_amounts,
      farm_shares_per_token: _farm_shares_per_token,
      borrow_shares_per_token: _borrow_shares_per_token,
      total_farm_share: item.total_farm_share,
      opened_position_count: item.opened_position_count,
      staked_lp_amount: _stakedLpAmount,
      lp_price: _lpPrice,
      reserves: _reserves_amounts,
      lp_total_supply: _lpTotalSupply.toFixed(),
      total_staked_lp_value: _totalStakedLpValue,
      total_liquidity_value: _totalLiquidityValue,
      lp_locked_amt: _lpLockedAmt,
      farm_token_supply: _farmTokenSupply.toFixed(),
      exist_farm_token_bal: _existFarmTokenBal,
      reward_per_day: _rewardPerDay,
      ash_base_apr: _ashBaseApr,
      max_yeild_boost: _maxYieldBoost,
      current_boost: _boost,
    };

    return farm;

  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryViewAllOpenedPositionIds = async (apiAddress: string, user_address: string | undefined): Promise<number[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
    ];

    if (user_address) {
      args.push(new AddressValue(new Address(user_address)));
    }

    const interaction = jewelUsdtAshFarmSmartContract.methodsExplicit.viewAllOpenedPositionIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const ids = [];

    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < values[i].length; j++) {
        ids.push(values[i][j].toNumber());
      }
    }

    return ids;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewPositionsByIds = async (apiAddress: string, positionIds: number[]): Promise<JewelFarmPosition[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const items = [];
    for (let i = 0; i < positionIds.length; i++) {
      items.push(new U32Value(positionIds[i]));
    }
    const args: TypedValue[] = [
      VariadicValue.fromItems(...items),
    ];

    const interaction = jewelUsdtAshFarmSmartContract.methodsExplicit.viewPositionsByIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelFarmPosition[] = values.map((value: any) => {
      return {
        position_id: value.position_id.toNumber(),
        position_farm_id: value.position_farm_id.toNumber(),
        position_state: value.position_state.name,
        position_owner: value.position_owner.toString(),
        position_leverage_percent: value.position_leverage_percent.toNumber(),
        position_debt_token: value.position_debt_token.toString(),
        position_debt_amount: value.position_debt_amount.toFixed(),
        position_farm_share: value.position_farm_share.toFixed(),
        position_open_timestamp: value.position_open_timestamp.toNumber(),
        position_deposit_payments: value.position_deposit_payments.map((item: any) => ({
          token_identifier: item.token_identifier.toString(),
          token_nonce: item.token_nonce.toString(),
          amount: item.amount.toFixed(),
        }))
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryViewPositionsSafetyContextByIds = async (apiAddress: string, positionIds: number[]): Promise<JewelFarmPositionSafetyContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const items = [];
    for (let i = 0; i < positionIds.length; i++) {
      items.push(new U32Value(positionIds[i]));
    }
    const args: TypedValue[] = [
      VariadicValue.fromItems(...items),
    ];

    const interaction = jewelUsdtAshFarmSmartContract.methodsExplicit.viewPositionSafetyContexts(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: JewelFarmPositionSafetyContext[] = values.map((value: any) => {
      return {
        position_id: value.position_id.toNumber(),
        value: value.value.toString(),
        lp_token_amount: value.lp_token_amount.toString(),
        debt_ratio: value.debt_ratio.toNumber(),
        liquidation_threshold: value.liquidation_threshold.toNumber(),
        to_be_liquidated: value.to_be_liquidated,
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryFarmGetEquivalent = async (apiAddress: string, farmId: number, tokenIn: string, tokenOut: string, amountIn: string): Promise<string> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new U32Value(farmId),
      new TokenIdentifierValue(tokenIn),
      new TokenIdentifierValue(tokenOut),
      new BigUIntValue(amountIn)
    ];

    const interaction = jewelUsdtAshFarmSmartContract.methodsExplicit.getEquivalent(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return '0';
    }

    const value = firstValue.valueOf();

    return value.toFixed();
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};

// stake
export const queryStakeBaseContext = async (jewelStakeSmartContract: SmartContract): Promise<JewelStakeBaseContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelStakeSmartContract.methodsExplicit.viewBaseContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    let redeem_token_id = "";
    if (value.redeem_token_id) {
      redeem_token_id = value.redeem_token_id.toString();
    }
    const decoded: JewelStakeBaseContext = {
      state: parseJewelStakeStateEnum(value.state.name),
      admins: value.admins.map((v: any) => v.toString()),

      jewel_farm_address: value.jewel_farm_address.toString(),
      ash_token: value.ash_token.toString(),
      jwlash_token: value.jwlash_token.toString(),
      redeem_token_id: redeem_token_id,

      epoch_length: value.epoch_length.toNumber(),
      lock_period: value.lock_period.toNumber(),

      current_epoch_id: value.current_epoch_id.toNumber(),
      current_epoch_state: parseJewelStakeEpochStateEnum(value.current_epoch_state.name),

      total_stake_amount: value.total_stake_amount.toFixed(0),
      total_staker_count: value.total_staker_count.toNumber(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryStakeViewAllEpochs = async (jewelStakeSmartContract: SmartContract): Promise<JewelStakeEpochContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelStakeSmartContract.methodsExplicit.viewAllEpochContexts();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: JewelStakeEpochContext[] = values.map((value: any) => {
      return {
        epoch_id: value.epoch_id.toNumber(),
        epoch_state: parseJewelStakeEpochStateEnum(value.epoch_state.name),
        epoch_start_timestamp: value.epoch_start_timestamp.toNumber(),
        epoch_end_timestamp: value.epoch_end_timestamp.toNumber(),
        epoch_total_stake_amount: value.epoch_total_stake_amount.toFixed(),
        epoch_total_staker_count: value.epoch_total_staker_count.toNumber(),
        epoch_reward_tokens: value.epoch_reward_tokens.map((v: any) => v.toString()),
        epoch_reward_amounts: value.epoch_reward_amounts.map((v: any) => v.toFixed()),
        epoch_claimed_reward_amounts: value.epoch_claimed_reward_amounts.map((v: any) => v.toFixed()),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryStakeViewUserContext = async (address: string, jewelStakeSmartContract: SmartContract): Promise<JewelStakeUserContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelStakeSmartContract.methodsExplicit.viewUserContext(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelStakeUserContext = {
      user_address: value.user_address.toString(),
      user_stake_amount: value.user_stake_amount.toFixed(),
      user_last_stake_timestamp: value.user_last_stake_timestamp.toNumber(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryStakeViewUserEpochs = async (address: string, jewelStakeSmartContract: SmartContract): Promise<JewelStakeUserEpochContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
      new BooleanValue(false), // only_claimable
    ];
    const interaction = jewelStakeSmartContract.methodsExplicit.viewUserEpochs(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: JewelStakeUserEpochContext[] = values.map((value: any) => {
      return {
        epoch_id: value.epoch_id.toNumber(),
        claimed: value.claimed,
        claimable: value.claimable,
        rewards_tokens: value.rewards_tokens.map((v: any) => v.toString()),
        rewards_amounts: value.rewards_amounts.map((v: any) => v.toFixed()),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

// Onedex Farm
export const queryOnedexFarmBaseContext = async (): Promise<JewelOnedexFarmBaseContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelOnedexFarmSmartContract.methodsExplicit.viewBaseContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelOnedexFarmBaseContext = {
      state: parseJewelOnedexFarmStateEnum(value.state.name),
      admins: value.admins.map((v: any) => v.toString()),

      onedex_swap_address: value.onedex_swap_address.toString(),
      onedex_farm_address: value.onedex_farm_address.toString(),
      wegld_token_id: value.wegld_token_id.toString(),
      egld_wrapper_address: value.egld_wrapper_address.toString(),

      fee_address: value.fee_address.toString(),

      farm_count: value.farm_count.toNumber(),

      last_position_id: value.last_position_id.toNumber(),
      total_opened_position_count: value.total_opened_position_count.toNumber(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryOnedexViewFarms = async (apiAddress: string, onedexPairs: any, exchangePairs: any): Promise<JewelOnedexFarmContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelOnedexFarmSmartContract.methodsExplicit.viewFarmsV2();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: JewelOnedexFarmContext[] = values.map((value: any) => {
      let apr = new BigNumber('0');
      const first_token_id = value.first_token_id.toString();
      const second_token_id = value.second_token_id.toString();
      const rewards_token_id = value.rewards_token_id.toString();
      const lp_token_supply = new BigNumber(value.lp_token_supply);
      const fee = value.fee_percent.toNumber();
      const farm_yearly_reward_amount = new BigNumber(value.farm_yearly_reward_amount).minus(new BigNumber(value.farm_yearly_reward_amount).multipliedBy(fee / FARM_MAX_PERCENT));
      if (rewards_token_id === first_token_id && lp_token_supply.gt('0')) {
        apr = farm_yearly_reward_amount.multipliedBy(100).dividedBy(new BigNumber(value.first_token_reserve).dividedBy(convertWeiToEsdt(lp_token_supply)).multipliedBy(2));
      } else if (rewards_token_id === second_token_id && lp_token_supply.gt('0')) {
        apr = farm_yearly_reward_amount.multipliedBy(100).dividedBy(new BigNumber(value.second_token_reserve).dividedBy(convertWeiToEsdt(lp_token_supply)).multipliedBy(2));
      } else if (lp_token_supply.gt('0')) {
        try {
          const farm_yearly_reward_amount_value = getTokenUsdcPrice(rewards_token_id, farm_yearly_reward_amount.toFixed(), onedexPairs, exchangePairs);
          const first_token_reserve_value = getTokenUsdcPrice(first_token_id, value.first_token_reserve.toFixed(), onedexPairs, exchangePairs);
          apr = new BigNumber(farm_yearly_reward_amount_value).multipliedBy(100).dividedBy(new BigNumber(first_token_reserve_value).dividedBy(convertWeiToEsdt(lp_token_supply)).multipliedBy(2));
        } catch (error) {
        }
      }

      return {
        farm_id: value.farm_id.toNumber(),

        dex_swap_pool_id: value.dex_swap_pool_id.toNumber(),
        dex_farm_pool_id: value.dex_farm_pool_id.toNumber(),

        min_deposit_base_token: value.min_deposit_base_token.toString(),
        min_deposit_base_amount: value.min_deposit_base_amount.toFixed(),
        min_compound_rewards_amount: value.min_compound_rewards_amount.toFixed(),

        fee_percent: fee,

        first_token_id: first_token_id,
        second_token_id: second_token_id,
        lp_token_id: value.lp_token_id.toString(),
        rewards_token_id: rewards_token_id,

        pending_first_token_amount: value.pending_first_token_amount.toFixed(),
        pending_second_token_amount: value.pending_second_token_amount.toFixed(),

        farm_share: value.farm_share.toFixed(),
        lp_token_amount: value.lp_token_amount.toFixed(),
        current_rewards_amount: value.current_rewards_amount.toFixed(),

        farm_yearly_reward_amount: value.farm_yearly_reward_amount.toFixed(),

        lp_token_supply: value.lp_token_supply.toFixed(),
        first_token_reserve: value.first_token_reserve.toFixed(),
        second_token_reserve: value.second_token_reserve.toFixed(),

        opened_position_count: 0,

        apr: apr.toNumber(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryOnedexViewFarm = async (farmId: number): Promise<JewelOnedexFarmContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new U32Value(farmId),
    ];
    const interaction = jewelOnedexFarmSmartContract.methodsExplicit.viewFarm(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelOnedexFarmContext = {
      farm_id: value.farm_id.toNumber(),

      dex_swap_pool_id: value.dex_swap_pool_id.toNumber(),
      dex_farm_pool_id: value.dex_farm_pool_id.toNumber(),

      min_deposit_base_token: value.min_deposit_base_token.toString(),
      min_deposit_base_amount: value.min_deposit_base_amount.toFixed(),
      min_compound_rewards_amount: value.min_compound_rewards_amount.toFixed(),

      fee_percent: value.fee_percent.toNumber(),

      first_token_id: value.first_token_id.toString(),
      second_token_id: value.second_token_id.toString(),
      lp_token_id: value.lp_token_id.toString(),
      rewards_token_id: value.rewards_token_id.toString(),

      pending_first_token_amount: value.pending_first_token_amount.toFixed(),
      pending_second_token_amount: value.pending_second_token_amount.toFixed(),

      farm_share: value.farm_share.toFixed(),
      lp_token_amount: value.lp_token_amount.toFixed(),
      current_rewards_amount: value.current_rewards_amount.toFixed(),

      farm_yearly_reward_amount: value.farm_yearly_reward_amount.toFixed(),

      lp_token_supply: value.lp_token_supply.toFixed(),
      first_token_reserve: value.first_token_reserve.toFixed(),
      second_token_reserve: value.second_token_reserve.toFixed(),

      opened_position_count: value.opened_position_count.toNumber(),

      apr: 0,
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryOnedexViewAllOpenedPostionIds = async (address: string): Promise<number[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelOnedexFarmSmartContract.methodsExplicit.viewAllOpenedPositionIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: number[] = [];
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < values[i].length; j++) {
        decoded.push(values[i][j].toNumber());
      }
    }

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryOnedexViewPostion = async (positionId: number): Promise<JewelOnedexPostionContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new U32Value(positionId),
    ];
    const interaction = jewelOnedexFarmSmartContract.methodsExplicit.viewPosition(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelOnedexPostionContext = {
      position_id: value.position_id.toNumber(),
      position_farm_id: value.position_farm_id.toNumber(),
      position_state: parseJewelOnedexPositionEnum(value.position_state.name),
      position_owner: value.position_owner.toString(),
      position_farm_share: value.position_farm_share.toFixed(),
      position_open_timestamp: value.position_open_timestamp.toNumber(),
      position_deposit_payments: value.position_deposit_payments.map((v: any) => ({
        token_identifier: v.token_identifier.toString(),
        token_nonce: v.token_nonce.toNumber(),
        amount: v.amount.toFixed(),
      }))
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryOnedexViewPostionByIds = async (positionIds: number[]): Promise<JewelOnedexPostionContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const items = [];
    for (let i = 0; i < positionIds.length; i++) {
      items.push(new U32Value(positionIds[i]));
    }
    const args: TypedValue[] = [
      VariadicValue.fromItems(...items),
    ];
    const interaction = jewelOnedexFarmSmartContract.methodsExplicit.viewPositionsByIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: JewelOnedexPostionContext[] = values.map((value: any) => {
      return {
        position_id: value.position_id.toNumber(),
        position_farm_id: value.position_farm_id.toNumber(),
        position_state: parseJewelOnedexPositionEnum(value.position_state.name),
        position_owner: value.position_owner.toString(),
        position_farm_share: value.position_farm_share.toFixed(),
        position_open_timestamp: value.position_open_timestamp.toNumber(),
        position_deposit_payments: value.position_deposit_payments.map((v: any) => ({
          token_identifier: v.token_identifier.toString(),
          token_nonce: v.token_nonce.toNumber(),
          amount: v.amount.toFixed(),
        }))
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

// liquid stake
export const queryLiquidStakeBaseContext = async (): Promise<JewelLiquidStakeBaseContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelLiquidStakeSmartContract.methodsExplicit.viewBaseContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelLiquidStakeBaseContext = {
      state: parseJewelLiquidStakeStateEnum(value.state.name),
      admins: value.admins.map((v: any) => v.toString()),

      fee_percent: value.fee_percent.toNumber(),
      fee_address: value.fee_address.toString(),

      jwlegld_token_id: value.jwlegld_token_id.toString(),
      sjwlegld_token_id: value.sjwlegld_token_id.toString(),

      unstake_token_id: value.unstake_token_id.toString(),

      whitelisted_sp_addresses: value.whitelisted_sp_addresses.map((v: any) => v.toString()),
      sp_vote_percents: value.sp_vote_percents.map((v: any) => v.toNumber()),
      sp_undelegation_ranking: value.sp_undelegation_ranking.map((v: any) => v.toString()),
      sp_delegated_amounts: value.sp_delegated_amounts.map((v: any) => v.toString()),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryLiquidStakStatsContext = async (): Promise<JewelLiquidStakeStatsContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelLiquidStakeSmartContract.methodsExplicit.viewStatsContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelLiquidStakeStatsContext = {
      prestaked_egld_amount: value.prestaked_egld_amount.toFixed(),
      staked_egld_reserve: value.staked_egld_reserve.toFixed(),
      preunstaked_egld_amount: value.preunstaked_egld_amount.toFixed(),
      jwlegld_supply: value.jwlegld_supply.toFixed(),
      delegating_egld_amount: value.delegating_egld_amount.toFixed(),
      undelegating_egld_amount: value.undelegating_egld_amount.toFixed(),

      unstake_token_supply: value.unstake_token_supply.toFixed(),
      unbonded_egld_amount: value.unbonded_egld_amount.toFixed(),

      jwlegld_pool_reserve: value.jwlegld_pool_reserve.toFixed(),
      sjwlegld_pool_reserve: value.sjwlegld_pool_reserve.toFixed(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryLiquidStakDynamicFee = async (): Promise<number> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelLiquidStakeSmartContract.methodsExplicit.getDynamicFee();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return 0;
    }

    const value = firstValue.valueOf();
    const decoded: number = value.toNumber();

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return 0;
  }
};

// Gauge
export const queryGaugeCommonSetting = async (): Promise<JewelGaugeCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelGaugeSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelGaugeCommonContext = {
      ash_stake_address: value.ash_stake_address.toString(),
      liquid_stake_address: value.liquid_stake_address.toString(),
      vote_reset_timestamp: value.vote_reset_timestamp.toNumber(),
      user_count: value.user_count.toNumber(),
      sp_addresses: value.sp_addresses.map((v: any) => v.toString()),
      vote_points: value.vote_points.map((v: any) => v.toFixed()),
      total_jwlash_stake_amount: value.total_jwlash_stake_amount.toFixed(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryViewUserContext = async (address: string): Promise<JewelGaugeUserContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelGaugeSmartContract.methodsExplicit.viewUserContext(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelGaugeUserContext = {
      user_jwlash_stake_amount: value.user_jwlash_stake_amount.toFixed(),
      user_vote_percents: value.user_vote_percents.map((v: any) => v.toNumber()),
      user_last_vote_timestamps: value.user_last_vote_timestamps.map((v: any) => v.toNumber()),
      user_vote_points: value.user_vote_points.map((v: any) => v.toFixed()),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

// Hatom Farm
export const queryHatomFarmCommonContext = async (): Promise<JewelHatomFarmCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelHatomFarmSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelHatomFarmCommonContext = {
      hatom_controller_address: value.hatom_controller_address.toString(),
      jewel_lend_address: value.jewel_lend_address.toString(),
      jewel_swap_router_address: value.jewel_swap_router_address.toString(),
      jewel_liquid_stake_address: value.jewel_liquid_stake_address.toString(),
      fee_percents: value.fee_percents.map((v: any) => v.toNumber()),
      fee_addresses: value.fee_addresses.map((v: any) => v.toString()),
      number_of_farms: value.number_of_farms.toNumber(),
      number_of_positions: value.number_of_positions.toNumber(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryHatomViewFarms = async (): Promise<JewelHatomFarmContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelHatomFarmSmartContract.methodsExplicit.viewFarms();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelHatomFarmContext[] = values.map((value: any) => {
      return {
        hatom_money_market_address: value.hatom_money_market_address.toString(),
        hatom_base_token_id: value.hatom_base_token_id.toString(),
        hatom_ib_token_id: value.hatom_ib_token_id.toString(),
        min_deposit_amount: value.min_deposit_amount.toFixed(),
        max_debt_amount: value.max_debt_amount.toFixed(),
        hatom_loop_count: value.hatom_loop_count.toNumber(),
        hatom_borrow_percent: value.hatom_borrow_percent.toNumber(),
        hatom_reward_token_ids: value.hatom_reward_token_ids.map((v: any) => v.toString()),

        hatom_base_supply_amount: value.hatom_base_supply_amount.toFixed(),
        hatom_base_debt_amount: value.hatom_base_debt_amount.toFixed(),
        hatom_ib_supply_amount: value.hatom_ib_supply_amount.toFixed(),
        jewel_lend_borrow_amount: value.jewel_lend_borrow_amount.toFixed(),
        hatom_farm_share: value.hatom_farm_share.toFixed(),
        hatom_farm_lender_share: value.hatom_farm_lender_share.toFixed(),
        hatom_debt_share: value.hatom_debt_share.toFixed(),

        number_of_positions: value.number_of_positions.toNumber(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryHatomViewAllOpenedPositionIds = async (address: string): Promise<number[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelHatomFarmSmartContract.methodsExplicit.viewAllOpenedPositionIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: number[] = [];
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < values[i].length; j++) {
        decoded.push(values[i][j].toNumber());
      }
    }

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryHatomViewPostionByIds = async (positionIds: number[]): Promise<JewelHatomPostionContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const items = [];
    for (let i = 0; i < positionIds.length; i++) {
      items.push(new U32Value(positionIds[i]));
    }
    const args: TypedValue[] = [
      VariadicValue.fromItems(...items),
    ];
    const interaction = jewelHatomFarmSmartContract.methodsExplicit.viewPositionsByIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: JewelHatomPostionContext[] = values.map((value: any) => {
      return {
        position_id: value.position_id.toNumber(),
        position_hatom_mm_address: value.position_hatom_mm_address.toString(),
        position_state: parseJewelFarmPositionStateEnum(value.position_state.name),
        position_owner: value.position_owner.toString(),
        position_leverage_percent: value.position_leverage_percent.toNumber(),
        position_jewel_debt_amount: value.position_jewel_debt_amount.toFixed(),
        position_farm_share: value.position_farm_share.toFixed(),
        position_farm_lender_share: value.position_farm_lender_share.toFixed(),
        position_debt_share: value.position_debt_share.toFixed(),
        position_open_timestamp: value.position_open_timestamp.toNumber(),
        position_deposit_payment: {
          token_identifier: value.position_deposit_payment.token_identifier.toString(),
          token_nonce: value.position_deposit_payment.token_nonce.toNumber(),
          amount: value.position_deposit_payment.amount.toFixed(),
        }
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryHatomViewPositionById = async (positionId: number): Promise<JewelHatomPostionContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new U32Value(positionId),
    ];
    const interaction = jewelHatomFarmSmartContract.methodsExplicit.viewPosition(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelHatomPostionContext = {
      position_id: value.position_id.toNumber(),
      position_hatom_mm_address: value.position_hatom_mm_address.toString(),
      position_state: parseJewelFarmPositionStateEnum(value.position_state.name),
      position_owner: value.position_owner.toString(),
      position_leverage_percent: value.position_leverage_percent.toNumber(),
      position_jewel_debt_amount: value.position_jewel_debt_amount.toFixed(),
      position_farm_share: value.position_farm_share.toFixed(),
      position_farm_lender_share: value.position_farm_lender_share.toFixed(),
      position_debt_share: value.position_debt_share.toFixed(),
      position_open_timestamp: value.position_open_timestamp.toNumber(),
      position_deposit_payment: {
        token_identifier: value.position_deposit_payment.token_identifier.toString(),
        token_nonce: value.position_deposit_payment.token_nonce.toNumber(),
        amount: value.position_deposit_payment.amount.toFixed(),
      }
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryHatomViewPositions = async (address: string): Promise<JewelHatomPostionContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(HATOM_SEGLD_MM_ADDRESS)),
      new U32Value(0),
      new U32Value(10),
      new OptionalValue(new OptionalType(new AddressType()), new AddressValue(new Address(address))), //TARGET_POSITION_ADDRESS
    ];
    const interaction = jewelHatomFarmSmartContract.methodsExplicit.viewOpenedPositions(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelHatomPostionContext[] = values.map((value: any) => {
      return {
        position_id: value.position_id.toNumber(),
        position_hatom_mm_address: value.position_hatom_mm_address.toString(),
        position_state: parseJewelFarmPositionStateEnum(value.position_state.name),
        position_owner: value.position_owner.toString(),
        position_leverage_percent: value.position_leverage_percent.toNumber(),
        position_jewel_debt_amount: value.position_jewel_debt_amount.toFixed(),
        position_farm_share: value.position_farm_share.toFixed(),
        position_farm_lender_share: value.position_farm_lender_share.toFixed(),
        position_debt_share: value.position_debt_share.toFixed(),
        position_open_timestamp: value.position_open_timestamp.toNumber(),
        position_deposit_payment: {
          token_identifier: value.position_deposit_payment.token_identifier.toString(),
          token_nonce: value.position_deposit_payment.token_nonce.toNumber(),
          amount: value.position_deposit_payment.amount.toFixed(),
        }
      };

    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

// JWLUSD
export const queryJewelStableCommonContext = async (): Promise<JewelStableCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelStableSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelStableCommonContext = {
      jewel_ashswap_farm_address: value.jewel_ashswap_farm_address.toString(),
      jewel_swap_router_address: value.jewel_swap_router_address.toString(),

      hatom_controller_address: value.hatom_controller_address.toString(),
      hatom_base_token_ids: value.hatom_base_token_ids.map((v: any) => v.toString()),
      hatom_mm_addresses: value.hatom_mm_addresses.map((v: any) => v.toString()),
      hatom_ib_token_ids: value.hatom_ib_token_ids.map((v: any) => v.toString()),
      hatom_reward_token_ids: value.hatom_reward_token_ids.map((v: any) => v.toString()),

      fee_percent: value.fee_percent.toNumber(),
      fee_address: value.fee_address.toString(),

      jwlusd_token: value.jwlusd_token.toString(),
      sjwlusd_token: value.sjwlusd_token.toString(),
      unstake_token: value.unstake_token.toString(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelStableStatsContext = async (): Promise<JewelStableStatsContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelStableSmartContract.methodsExplicit.viewStatsContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelStableStatsContext = {
      hatom_base_current_reserves: value.hatom_base_current_reserves.map((v: any) => v.toFixed()),
      hatom_ib_token_reserves: value.hatom_ib_token_reserves.map((v: any) => v.toFixed()),

      jwlusd_supply: value.jwlusd_supply.toFixed(),
      jwlusd_pool_reserve: value.jwlusd_pool_reserve.toFixed(),
      sjwlusd_pool_reserve: value.sjwlusd_pool_reserve.toFixed(),
      unbonding_jwlusd_reserve: value.unbonding_jwlusd_reserve.toFixed(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelStableDynamicFee = async (): Promise<number> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelStableSmartContract.methodsExplicit.getDynamicFee();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return 0;
    }

    const value = firstValue.valueOf();
    const decoded: number = value.toNumber();

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return 0;
  }
};

// Jewel Flash Mint
export const queryJewelFlashMintCommonContext = async (): Promise<JewelFlashMintCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelFlashMintSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelFlashMintCommonContext = {
      liquid_stake_sc_address: value.liquid_stake_sc_address.toString(),
      jewel_swap_router_sc_address: value.jewel_swap_router_sc_address.toString(),
      hatom_controller_sc_address: value.hatom_controller_sc_address.toString(),
      egld_wrapper_sc_address: value.egld_wrapper_sc_address.toString(),
      jewel_stable_sc_address: value.jewel_stable_sc_address.toString(),
      jewel_ashswap_farm_sc_address: value.jewel_ashswap_farm_sc_address.toString(),
      wegld_token_id: value.wegld_token_id.toString(),
      jwlegld_token_id: value.jwlegld_token_id.toString(),
      sjwlegld_token_id: value.sjwlegld_token_id.toString(),
      jwlusd_token_id: value.jwlusd_token_id.toString(),
      usdc_token_id: value.usdc_token_id.toString(),
      farmer_fee_percent: value.farmer_fee_percent.toNumber(),
      protocol_fee_percent: value.protocol_fee_percent.toNumber(),
      treasury_wallet: value.treasury_wallet.toString(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelFlashMintViewFarms = async (): Promise<JewelFlashMintFarmContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelFlashMintSmartContract.methodsExplicit.viewFarms();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelFlashMintFarmContext[] = values.map((value: any) => {
      return {
        farm_id: value.farm_id.toNumber(),
        farm_max_cap: value.farm_max_cap.toFixed(),
        farm_base_token_id: value.farm_base_token_id.toString(),
        farm_deposit_token_id: value.farm_deposit_token_id.toString(),
        farm_debt_token_id: value.farm_debt_token_id.toString(),
        min_deposit_base_amount: value.min_deposit_base_amount.toFixed(),
        farm_leverage_max_percent: value.farm_leverage_max_percent.toNumber(),

        farm_base_supply_amount: value.farm_base_supply_amount.toFixed(),
        farm_base_borrow_amount: value.farm_base_borrow_amount.toFixed(),
        farm_share: value.farm_share.toFixed(),
        farm_jwlegld_borrow_amount: value.farm_jwlegld_borrow_amount.toFixed(),
        farm_jwlusd_borrow_amount: value.farm_jwlusd_borrow_amount.toFixed(),
        farm_hatom_money_market_sc_address: value.farm_hatom_money_market_sc_address.toString(),

        number_of_positions: value.number_of_positions.toNumber(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelFlashMintViewAllOpenedPositionIds = async (address: string): Promise<number[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelFlashMintSmartContract.methodsExplicit.viewAllOpenedPositionIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: number[] = [];
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < values[i].length; j++) {
        decoded.push(values[i][j].toNumber());
      }
    }

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelFlashMintViewPostionByIds = async (positionIds: number[], farms: JewelFlashMintFarmContext[]): Promise<JewelFlashMintPositionContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const items = [];
    for (let i = 0; i < positionIds.length; i++) {
      items.push(new U32Value(positionIds[i]));
    }
    const args: TypedValue[] = [
      VariadicValue.fromItems(...items),
    ];
    const interaction = jewelFlashMintSmartContract.methodsExplicit.viewPositionsByIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    let index = 0;
    const decoded: JewelFlashMintPositionContext[] = values.map((value: any) => {
      index++;
      const farm = farms.filter((farm: JewelFlashMintFarmContext) => farm.farm_id == value.position_farm_id.toNumber())[0];
      return {
        index: index,
        position_id: value.position_id.toNumber(),
        position_farm_id: value.position_farm_id.toNumber(),
        position_state: parseJewelFarmPositionStateEnum(value.position_state.name),
        position_owner: value.position_owner.toString(),
        position_leverage_percent: value.position_leverage_percent.toNumber(),
        position_farm_share: value.position_farm_share.toFixed(),
        position_debt_amount: getTokenTicker(farm.farm_debt_token_id) === 'JWLEGLD' ? value.position_jwlegld_debt_amount.toFixed() : value.position_jwlusd_debt_amount.toFixed(),
        position_open_timestamp: value.position_open_timestamp.toNumber(),
        position_deposit_payment: {
          token_identifier: value.position_deposit_payment.token_identifier.toString(),
          token_nonce: value.position_deposit_payment.token_nonce.toNumber(),
          amount: value.position_deposit_payment.amount.toFixed(),
        },
        lp_position_deposit_value: '0',
        is_lp_position: false,
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

// Jewel Lp Farm
export const queryJewelFlashMintViewLpFarms = async (): Promise<JewelLpFarmContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelFlashMintSmartContract.methodsExplicit.viewLpFarms();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelLpFarmContext[] = values.map((value: any) => {
      return {
        lp_farm_id: value.lp_farm_id.toNumber(),
        jaf_farm_id: value.jaf_farm_id.toNumber(),
        lp_farm_base_token_id: value.lp_farm_base_token_id.toString(),
        lp_farm_debt_token_id: value.lp_farm_debt_token_id.toString(),
        lp_farm_min_deposit_base_amount: value.lp_farm_min_deposit_base_amount.toFixed(),
        lp_farm_leverage_max_percent: value.lp_farm_leverage_max_percent.toNumber(),
        lp_farm_max_cap: value.lp_farm_max_cap.toFixed(),
        lp_farm_debt_token_amount: value.lp_farm_debt_token_amount.toFixed(),

        token_ids: value.token_ids.map((v: any) => v.toString()),
        lp_token_id: value.lp_token_id.toString(),

        number_of_positions: value.number_of_positions.toNumber(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelFlashMintViewAllOpenedLpPositionIds = async (address: string): Promise<number[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelFlashMintSmartContract.methodsExplicit.viewAllOpenedLpPositionIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: number[] = [];
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < values[i].length; j++) {
        decoded.push(values[i][j].toNumber());
      }
    }

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelFlashMintViewLpPostionByIds = async (positionIds: number[], index: number): Promise<JewelFlashMintPositionContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const items = [];
    for (let i = 0; i < positionIds.length; i++) {
      items.push(new U32Value(positionIds[i]));
    }
    const args: TypedValue[] = [
      VariadicValue.fromItems(...items),
    ];
    const interaction = jewelFlashMintSmartContract.methodsExplicit.viewLpPositionsByIds(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();

    const decoded: JewelFlashMintPositionContext[] = values.map((value: any) => {
      index++;
      return {
        index: index,
        position_id: value.position_id.toNumber(),
        position_farm_id: value.position_farm_id.toNumber(),
        position_state: parseJewelFarmPositionStateEnum(value.position_state.name),
        position_owner: value.position_owner.toString(),
        position_leverage_percent: value.position_leverage_percent.toNumber(),
        position_farm_share: value.position_farm_share.toFixed(),
        position_debt_amount: value.position_debt_amount.toFixed(),
        position_open_timestamp: value.position_open_timestamp.toNumber(),
        position_deposit_payment: {
          token_identifier: value.position_deposit_payment.token_identifier.toString(),
          token_nonce: value.position_deposit_payment.token_nonce.toNumber(),
          amount: value.position_deposit_payment.amount.toFixed(),
        },
        lp_position_deposit_value: value.position_deposit_value.toFixed(),
        is_lp_position: true,
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

// Jewel Borrow
export const queryJewelMoneyMarketCommonContext = async (): Promise<JewelMoneyMarketCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelBorrowSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelMoneyMarketCommonContext = {
      jewel_swap_router_address: value.jewel_swap_router_address.toString(),
      jewel_liquid_stake_address: value.jewel_liquid_stake_address.toString(),
      jewel_stablecoin_address: value.jewel_stablecoin_address.toString(),
      jewel_lend_address: value.jewel_lend_address.toString(),
      hatom_controller_address: value.hatom_controller_address.toString(),
      hatom_liquid_staking_address: value.hatom_liquid_staking_address.toString(),
      jewel_hatom_farm_address: value.jewel_hatom_farm_address.toString(),
      jewel_ashswap_farm_address: value.jewel_ashswap_farm_address.toString(),
      egld_wrapper_address: value.egld_wrapper_address.toString(),

      jwlegld_token_id: value.jwlegld_token_id.toString(),
      jwlusd_token_id: value.jwlusd_token_id.toString(),
      wegld_token_id: value.wegld_token_id.toString(),
      ashswap_farm_reward_token_id: value.ashswap_farm_reward_token_id.toString(),

      fee_address: value.fee_address.toString(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelBorrowPools = async (): Promise<JewelMoneyMarketPoolContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelBorrowSmartContract.methodsExplicit.viewBorrowPools();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelMoneyMarketPoolContext[] = values.map((value: any) => {
      const baseTokenId = value.base_token_id.toString();
      const baseTokenTicker = getTokenTicker(baseTokenId);
      let hatomBaseTokenId = '';
      if (baseTokenTicker.substring(0, 3) !== 'HTM' && baseTokenTicker.substring(0, 1) === 'H') {
        hatomBaseTokenId = getHatomBaseTokenId(baseTokenTicker);
      }

      return {
        debt_token_id: value.debt_token_id.toString(),
        base_token_id: baseTokenId,

        borrow_pool_type: parseMoneyMarketPoolTypeEnum(value.borrow_pool_type.name),
        price_multiplier: value.price_multiplier.toFixed(),
        max_borrow_percent: value.max_borrow_percent.toNumber(),
        liquidation_percent: value.liquidation_percent.toNumber(),
        max_debt_cap: value.max_debt_cap.toFixed(),
        hatom_mm_address: value.hatom_mm_address ? value.hatom_mm_address.toString() : '',

        supplied_amount: value.supplied_amount.toFixed(),
        supplied_share: value.supplied_share.toFixed(),
        borrowed_amount: value.borrowed_amount.toFixed(),

        base_token_amount: value.base_token_amount.toFixed(),

        fee_rate: value.fee_rate.toFixed(),
        fee_rate_update_timestamp: value.fee_rate_update_timestamp.toNumber(),
        instant_apr: value.instant_apr.toNumber(),

        hatom_base_token_id: hatomBaseTokenId,

        ashswap_farm_id: value.ashswap_farm_id.toNumber(),
        ashswap_farm_token_id: value.ashswap_farm_token_id ? value.ashswap_farm_token_id.toString() : '',
        ashswap_farm_token_nonce: value.ashswap_farm_token_nonce.toNumber(),
        ashswap_farm_token_amount: value.ashswap_farm_token_amount.toFixed(0),
        ashswap_farm_reward_pending_amount: value.ashswap_farm_reward_pending_amount.toFixed(0),
        ashswap_farm_lp_supply_amount: value.ashswap_farm_lp_supply_amount.toFixed(0),
        ashswap_farm_lp_supply_debt_value: value.ashswap_farm_lp_supply_debt_value.toFixed(0),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelBorrowPool = async (debt_token_id: string, base_token_id: string): Promise<JewelMoneyMarketPoolContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(debt_token_id),
      new TokenIdentifierValue(base_token_id),
    ];
    const interaction = jewelBorrowSmartContract.methodsExplicit.viewBorrowPool(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();

    const baseTokenId = value.base_token_id.toString();
    const baseTokenTicker = getTokenTicker(baseTokenId);
    let hatomBaseTokenId = '';
    if (baseTokenTicker.substring(0, 1) === 'H') {
      hatomBaseTokenId = getHatomBaseTokenId(baseTokenTicker);
    }

    const decoded: JewelMoneyMarketPoolContext = {
      debt_token_id: value.debt_token_id.toString(),
      base_token_id: baseTokenId,

      borrow_pool_type: parseMoneyMarketPoolTypeEnum(value.borrow_pool_type.name),
      price_multiplier: value.price_multiplier.toFixed(),
      max_borrow_percent: value.max_borrow_percent.toNumber(),
      liquidation_percent: value.liquidation_percent.toNumber(),
      max_debt_cap: value.max_debt_cap.toFixed(),
      hatom_mm_address: value.hatom_mm_address ? value.hatom_mm_address.toString() : '',

      supplied_amount: value.supplied_amount.toFixed(),
      supplied_share: value.supplied_share.toFixed(),
      borrowed_amount: value.borrowed_amount.toFixed(),

      base_token_amount: value.base_token_amount.toFixed(),

      fee_rate: value.fee_rate.toFixed(),
      fee_rate_update_timestamp: value.fee_rate_update_timestamp.toNumber(),
      instant_apr: value.instant_apr.toNumber(),

      hatom_base_token_id: hatomBaseTokenId,

      ashswap_farm_id: value.ashswap_farm_id.toNumber(),
      ashswap_farm_token_id: value.ashswap_farm_token_id ? value.ashswap_farm_token_id.toString() : '',
      ashswap_farm_token_nonce: value.ashswap_farm_token_nonce.toNumber(),
      ashswap_farm_token_amount: value.ashswap_farm_token_amount.toFixed(0),
      ashswap_farm_reward_pending_amount: value.ashswap_farm_reward_pending_amount.toFixed(0),
      ashswap_farm_lp_supply_amount: value.ashswap_farm_lp_supply_amount.toFixed(0),
      ashswap_farm_lp_supply_debt_value: value.ashswap_farm_lp_supply_debt_value.toFixed(0),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelBorrowUserPools = async (address: string): Promise<JewelMoneyMarketUserPoolContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelBorrowSmartContract.methodsExplicit.viewUserPools(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelMoneyMarketUserPoolContext[] = values.map((value: any) => {
      return {
        debt_token_id: value.debt_token_id.toString(),
        base_token_id: value.base_token_id.toString(),

        user_supplied_share: value.user_supplied_share.toFixed(),
        user_supplied_amount: value.user_supplied_amount.toFixed(),
        user_max_borrow_amount: value.user_max_borrow_amount.toFixed(),
        user_borrowed_amount: value.user_borrowed_amount.toFixed(),

        user_debt_amount: value.user_debt_amount.toFixed(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelBorrowUserPool = async (address: string, debt_token_id: string, base_token_id: string): Promise<JewelMoneyMarketUserPoolContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
      new TokenIdentifierValue(debt_token_id),
      new TokenIdentifierValue(base_token_id),
    ];
    const interaction = jewelBorrowSmartContract.methodsExplicit.viewUserPool(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelMoneyMarketUserPoolContext = {
      debt_token_id: value.debt_token_id.toString(),
      base_token_id: value.base_token_id.toString(),

      user_supplied_share: value.user_supplied_share.toFixed(),
      user_supplied_amount: value.user_supplied_amount.toFixed(),
      user_max_borrow_amount: value.user_max_borrow_amount.toFixed(),
      user_borrowed_amount: value.user_borrowed_amount.toFixed(),

      user_debt_amount: value.user_debt_amount.toFixed(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelBorrowGetEquivalent = async (token_in: string, token_out: string, amount_in: string): Promise<string> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(token_in),
      new TokenIdentifierValue(token_out),
      new BigUIntValue(amount_in),
    ];
    const interaction = jewelBorrowSmartContract.methodsExplicit.getEquivalent(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return '0';
    }
    const value = firstValue.valueOf();

    const decoded = value.toFixed();

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};

// Jewel Swap Router
export const queryJsrGetAmountIn = async (tokenIn: string, tokenOut: string, amountOut: string): Promise<string> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(tokenIn),
      new TokenIdentifierValue(tokenOut),
      new BigUIntValue(amountOut),
    ];

    const interaction = jewelSwapRouterSmartContract.methodsExplicit.getAmountIn(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return '0';
    }

    const value = firstValue.valueOf();

    return value.toFixed();
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};

export const queryJsrGetAmountOut = async (tokenIn: string, tokenOut: string, amountIn: string): Promise<string> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(tokenIn),
      new TokenIdentifierValue(tokenOut),
      new BigUIntValue(amountIn),
    ];

    const interaction = jewelSwapRouterSmartContract.methodsExplicit.getAmountIn(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return '0';
    }

    const value = firstValue.valueOf();

    return value.toFixed();
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};

// Jewel FlexiLoan
export const queryJewelFlexiLoanCommonContext = async (): Promise<JewelFlexiLoanCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelFlexiLoanSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelFlexiLoanCommonContext = {
      liquid_stake_sc_address: value.liquid_stake_sc_address.toString(),
      onedex_swap_sc_address: value.onedex_swap_sc_address.toString(),
      onedex_farm_sc_address: value.onedex_farm_sc_address.toString(),
      jewel_ashswap_farm_sc_address: value.jewel_ashswap_farm_sc_address.toString(),
      wegld_token_id: value.wegld_token_id.toString(),
      jwlegld_token_id: value.jwlegld_token_id.toString(),
      sjwlegld_token_id: value.sjwlegld_token_id.toString(),
      lock_period: value.lock_period.toNumber(),
      debt_limit: value.debt_limit.toFixed(),
      treasury_address: value.treasury_address.toString(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelFlexiLoanWhitelists = async (): Promise<JewelFlexiLoanWhitelistContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelFlexiLoanSmartContract.methodsExplicit.viewWhitelists();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelFlexiLoanWhitelistContext[] = values.map((value: any) => {
      return {
        wp_address: value.wp_address.toString(),
        wp_token_id: value.wp_address.toString(),
        wp_dex_type: parseJewelFlexiLoanDexTypeEnum(value.wp_dex_type.name),
        wp_ashswap_pool_address: value.wp_address.toString(),
        wp_ashswap_farm_address: value.wp_address.toString(),
        wp_onedex_swap_pool_id: value.wp_address.toNumber(),
        wp_onedex_farm_pool_id: value.wp_address.toNumber(),

        wp_sjwlegld_amount: value.wp_address.toFixed(),
        wp_jwlegld_debt_amount: value.wp_address.toFixed(),

        wp_avail_jwlegld_withdraw_amount: value.wp_address.toFixed(),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

// Jewel Global Pool
export const queryJewelGlobalPoolCommonContext = async (): Promise<JewelGlobalPoolCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelGlobalPoolSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelGlobalPoolCommonContext = {
      treasury_address: value.treasury_address.toString(),
      treasury_percent: value.treasury_percent.toNumber(),
      jewel_swap_router_address: value.jewel_swap_router_address.toString(),
      jewel_oracle_address: value.jewel_oracle_address.toString(),
      max_debt_percent: value.max_debt_percent.toNumber(),
      liquidation_percent: value.liquidation_percent.toNumber(),

      pools_num: value.pools_num.toNumber(),
      users_num: value.users_num.toNumber(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelGlobalPools = async (): Promise<JewelGlobalPoolContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelGlobalPoolSmartContract.methodsExplicit.viewPoolContexts();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelGlobalPoolContext[] = values.map((value: any) => {
      return {
        token_id: getTokenTicker(value.token_id.toString()) === 'EGLD' ? 'EGLD' : value.token_id.toString(),
        supplied_amount: value.supplied_amount.toFixed(0),
        supplied_share: value.supplied_share.toFixed(0),
        debt_amount: value.debt_amount.toFixed(0),
        debt_share: value.debt_share.toFixed(0),
        update_timestamp: value.update_timestamp.toNumber(),
        borrow_apr: value.borrow_apr.toNumber(),
        // token_price: value.token_price.toFixed(0),
        token_price: '100000000',
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelGlobalUserPools = async (address: string): Promise<JewelGlobalUserPoolContext[]> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelGlobalPoolSmartContract.methodsExplicit.viewUserPoolContexts(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return [];
    }

    const values = firstValue.valueOf();
    const decoded: JewelGlobalUserPoolContext[] = values.map((value: any) => {
      return {
        token_id: getTokenTicker(value.token_id.toString()) === 'EGLD' ? 'EGLD' : value.token_id.toString(),
        supplied_share: value.supplied_share.toFixed(0),
        supplied_amount: value.supplied_amount.toFixed(0),
        debt_share: value.debt_share.toFixed(0),
        debt_amount: value.debt_amount.toFixed(0),
      };
    });

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return [];
  }
};

export const queryJewelGlobalUserContext = async (address: string): Promise<JewelGlobalUserContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = jewelGlobalPoolSmartContract.methodsExplicit.viewUserContext(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelGlobalUserContext = {
      supplied_value: value.supplied_value.toFixed(0),
      debt_value: value.debt_value.toFixed(0),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

// Jewel Oracle
export const queryPriceFromJewelOracle = async (tokenId: string): Promise<string> => {
  try {
    const networkProvider = await getNetworkProvider();
    const args: TypedValue[] = [
      new TokenIdentifierValue(tokenId === 'EGLD' ? WEGLD_TOKEN_ID : tokenId),
    ];
    const interaction = jewelOracleSmartContract.methodsExplicit.getUncensoredPrice(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      console.log(returnMessage);
      // toastError(returnMessage);
      return '0';
    }

    const value = firstValue.valueOf();

    return value.toFixed(0);
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};

// Jewel Facilitator
export const queryJewelFacilitatorCommonContext = async (): Promise<JewelFacilitatorCommonContext | undefined> => {
  try {
    const networkProvider = await getNetworkProvider();
    const interaction = jewelFacilitatorSmartContract.methodsExplicit.viewCommonContext();
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelFacilitatorCommonContext = {
      treasury_address: value.treasury_address.toString(),
      jewel_global_pool_address: value.jewel_global_pool_address.toString(),

      jewel_liquid_stake_address: value.jewel_liquid_stake_address.toString(),
      jwlegld_token_id: value.jwlegld_token_id,
      jewel_stablecoin_address: value.jewel_stablecoin_address.toString(),
      jwlusd_token_id: value.jwlusd_token_id,

      jwlegld_facilitated_amount: value.jwlegld_facilitated_amount.toFixed(),
      jwlusd_facilitated_amount: value.jwlusd_facilitated_amount.toFixed(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export function parseEsdtTokenPayment(value: any) {
  return {
    token_identifier: value.token_identifier.toString(),
    token_nonce: value.token_nonce.toNumber(),
    amount: value.amount.toFixed(),
  };
}

// Jewel BTC,ETH Stake
export const queryJewelBtcEthStakeBaseContext = async (base_token_id: string): Promise<JewelBtcEthStakeBaseContext | undefined> => {
  try {
    const args: TypedValue[] = [
      new TokenIdentifierValue(base_token_id),
    ];
    const networkProvider = await getNetworkProvider();
    const interaction = jewelBtcEthStakeSmartContract.methodsExplicit.viewBaseContext(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelBtcEthStakeBaseContext = {
      jewel_hatom_farm_address: value.jewel_hatom_farm_address.toString(),
      jewel_swap_router_address: value.jewel_swap_router_address.toString(),
      base_token_id: value.base_token_id.toString(),
      fee_percent: value.fee_percent.toNumber(),
      fee_address: value.fee_address.toString(),
      base_token_decimal: value.base_token_decimal.toNumber(),
      base_token_hatom_mm_address: value.base_token_hatom_mm_address.toString(),
      min_compound_reward_amount: value.min_compound_reward_amount.toFixed(),

      jwl_token_id: value.jwl_token_id.toString(),
      unstake_token_id: value.unstake_token_id.toString(),

      jwl_token_supply: value.jwl_token_supply.toFixed(),
      ib_token_supply: value.ib_token_supply.toFixed(),

      jwl_token_pool_reserve: value.jwl_token_pool_reserve.toFixed(),
      sjwl_token_pool_reserve: value.sjwl_token_pool_reserve.toFixed(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

export const queryJewelBtcEthStakeUserContext = async (base_token_id: string, user_address: string): Promise<JewelBtcEthStakeUserContext | undefined> => {
  try {
    const args: TypedValue[] = [
      new TokenIdentifierValue(base_token_id),
      new AddressValue(new Address(user_address)),
    ];
    const networkProvider = await getNetworkProvider();
    const interaction = jewelBtcEthStakeSmartContract.methodsExplicit.viewUserContext(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return undefined;
    }

    const value = firstValue.valueOf();
    const decoded: JewelBtcEthStakeUserContext = {
      user_sjwl_amount: value.user_sjwl_amount.toFixed(),
      jwl_token_id: value.jwl_token_id.toString(),
      user_address: value.user_address.toString(),
    };

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return undefined;
  }
};

// Hatom Rewards booster
export const queryHatomBoosterAccruedRewards = async (): Promise<string> => {
  try {
    const args: TypedValue[] = [
      new AddressValue(new Address(JEWEL_HATOM_FARM_SC_ADDRESS)),
      new TokenIdentifierValue(USDC_TOKEN_ID),
    ];
    const networkProvider = await getNetworkProvider();
    const interaction = hatomRewardsBoosterSmartContract.methodsExplicit.getAccountAccruedRewards(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return '0';
    }

    const value = firstValue.valueOf();
    const decoded = value.toFixed(0);

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};

export const queryHatomBoosterAccountStake = async (): Promise<string> => {
  try {
    const args: TypedValue[] = [
      new AddressValue(new Address(JEWEL_HATOM_FARM_SC_ADDRESS)),
    ];
    const networkProvider = await getNetworkProvider();
    const interaction = hatomRewardsBoosterSmartContract.methodsExplicit.getAccountStake(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await networkProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      // toastError(returnMessage);
      return '0';
    }

    const value = firstValue.valueOf();
    const decoded = value.toFixed(0);

    return decoded;
  } catch (e) {
    console.error(e);
    // toastError(ERROR_MVX_QUERY_FAILED);
    return '0';
  }
};
