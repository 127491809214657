import axios from "axios";
import { MAIAR_TOKENS_API, XEXCHANGE_TOKENS_API } from "config";
import { DAY_IN_SECONDS, ERROR_MVX_API_FAILED, ERROR_XOXNO_API_FAILED, SECOND_TO_MILLISECONDS, toastError } from "z/utils";
import { axiosConfig } from ".";

export async function getNftsFromXoxno(url: string, address?: string) {

    try {
        const { data } = await axios.get(url, axiosConfig);
        const items = [];

        if (data.results && data.results.length > 0) {
            for (let i = 0; i < data.results.length; i++) {
                if (address) {
                    if (data.results[i].saleInfoNft.seller && data.results[i].saleInfoNft.seller != address) {
                        items.push({
                            ...data.results[i],
                            index: i,
                        });
                    }
                } else {
                    items.push({
                        ...data.results[i],
                        index: i,
                    });
                }
            }
        }

        return {
            count: data.count,
            items: items,
        };
    } catch (e) {
        console.log('getNftsFromXoxno: ', e);
        // toastError(ERROR_XOXNO_API_FAILED);
    }
    return {
        count: 0,
        items: [],
    };

}

export async function getRarityFromXoxno(collectionId: string) {
    return [];
    try {
        const url = `https://jewelswap-app.s3.amazonaws.com/rarity/${collectionId}.json`;
        const { data } = await axios.get(url);
        return data;
    } catch (e) {
        console.log('getRarityFromXoxno: ', e);
        // toastError(ERROR_XOXNO_API_FAILED);
    }

}

export async function getOnedexPairs() {
    try {
        const current_timestamp = (Date.now() / SECOND_TO_MILLISECONDS) - 120;
        const request_url = `${MAIAR_TOKENS_API}${current_timestamp}`;
        const { data } = await axios.get(request_url);

        return data;
    } catch (e) {
        console.log('getOnedexPairs: ', e);
        // toastError(ERROR_XOXNO_API_FAILED);
    }
}

export async function getExchangePairs() {
    try {
        const { data } = await axios.get(XEXCHANGE_TOKENS_API);

        return data;
    } catch (e) {
        console.log('getExchangePairs: ', e);
        // toastError(ERROR_XOXNO_API_FAILED);
    }
}
