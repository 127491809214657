import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { NftCard, NftCardType } from 'components/NftCard';
import { NftRarity, NftWithAmmData, SwapTypeEnum } from 'z/types';

export const CollectionSellTab = (props: any) => {

    return (
        <div className='container'>
            <Row>
                {props.items?.map((item: NftWithAmmData, index: number) => {
                   // const nftRarity = props.rarity?.filter((rarity: NftRarity) => rarity.identifier === item.identifier);
                   //  if (nftRarity.length > 0) {
                        return <Col lg={2} md={3} sm={6} xs={6} className='mt-3' key={index}>
                            <NftCard item={item}  nftCardType={NftCardType.SwapNft} swapType={SwapTypeEnum.Buy} bestOffer={props.bestOffer} />
                        </Col>;
                    // }
                })}
            </Row>
        </div>
    );
};
