import BigNumber from 'bignumber.js';

export const BIGNUM_ZERO = new BigNumber(0);

const localDecimalSeparator = 0.1.toLocaleString().replace(/\d/g, '');
const bgFormatter = {
  decimalSeparator: localDecimalSeparator,
  groupSeparator: localDecimalSeparator == '.' ? ',' : '.',
  groupSize: 3
};

export const convertBigNumberValueToLocalString = (
  value: BigNumber.Value,
  precision?: number,
): string => {
  let v = BigNumber(value).toFormat(precision ? precision : 4, BigNumber.ROUND_FLOOR, bgFormatter);

  // remove trailing zeros
  if (v.search(localDecimalSeparator) >= 0) {
    v = v.replace(/\.?0+$/, '');
  }

  return v;
};

export function printEsdt(value: BigNumber, decimals: number): string {
  if (value.isLessThan(0.0001)) {
    return convertBigNumberValueToLocalString(value, decimals);
  }

  return convertBigNumberValueToLocalString(value);
}

export const floorNumber = (num: number, precision?: number): number => {
  precision = precision === undefined ? 0 : precision;
  const factor = Math.pow(10, precision);
  return Math.floor(num * factor) / factor;
};

export const parseBigNumber = (value: BigNumber.Value): BigNumber => {
  try {
    return BigNumber(value);
  } catch (e) {
    return BigNumber('0');
  }
};

export function floorDecimals(value:number, decimals:number) {   return Number(Math.floor(Number(value + 'e' + decimals))+'e-'+decimals); }
