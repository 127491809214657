import { numberToPaddedHex } from '@multiversx/sdk-core/out/utils.codec';
import BigNumber from 'bignumber.js';
import { isDev, SEGLD_TOKEN_ID, USDC_TOKEN_ID, USDT_TOKEN_ID, WEGLD_TOKEN_ID } from 'config';

export const convertWeiToEsdt = (amount: BigNumber.Value, decimals?: number, precision?: number): BigNumber => {
    if (!amount) amount = '0';
    return BigNumber(amount).shiftedBy(decimals == null ? -18 : -decimals).decimalPlaces(precision == null ? 4 : precision, BigNumber.ROUND_FLOOR);
};

export const convertEsdtToWei = (amount: BigNumber.Value, decimals?: number): BigNumber => {
    if (!amount) amount = '0';
    return BigNumber(amount).shiftedBy(decimals == null ? 18 : decimals).decimalPlaces(0, BigNumber.ROUND_FLOOR);
};

export const createNftId = (collection_id: string, nft_nonce: number) => {
    return `${collection_id}-${numberToPaddedHex(nft_nonce)}`;
};

export const getCollectionId = (collection_id: string) => {
    const collectionTickers = collection_id.split('-');
    let collectionId = '';
    if (collectionTickers[0]) {
        collectionId += collectionTickers[0].toUpperCase();
    }
    if (collection_id.includes('-')) {
        collectionId += '-';
    }
    if (collectionTickers[1]) {
        collectionId += collectionTickers[1].toLocaleLowerCase();
    }
    return collectionId;
};

export const getTokenTicker = (tokenId: string) => {
    if (tokenId) {
        if (isDev) {
            if (tokenId === 'ALP-0e6b1c') return 'USDT/ASH LP';
            if (tokenId === 'ALP-6b7c94') return 'BUSD/EGLD LP';
            if (tokenId === 'ALP-9b7a73') return 'USDT/BUSD/USDC LP';
            if (tokenId === 'ALP-bf3d53') return 'EGLD/SEGLD LP';
            if (tokenId === 'ALP-9471de') return 'ASH/JWLASH LP';
            if (tokenId === 'ALP-097c45') return 'EGLD/JWLEGLD LP';
            if (tokenId === 'ALP-7f4b3f') return 'USDC/JWLUSD LP';
            if (tokenId === 'ALP-3763b9') return 'SEGLD/JWLEGLD LP';
        } else {
            if (tokenId === 'ALP-2d0cf8') return 'USDT/ASH LP';
            if (tokenId === 'ALP-5f9191') return 'BUSD/EGLD LP';
            if (tokenId === 'ALP-afc922') return 'USDT/BUSD/USDC LP';
            if (tokenId === 'ALP-0fe50a') return 'SEGLD/EGLD LP';
            if (tokenId === 'ALP-f7dee1') return 'ASH/JWLASH LP';
            if (tokenId === 'ALP-713ae8') return 'EGLD/JWLEGLD LP';
            if (tokenId === 'ALP-2265f4') return 'SEGLD/JWLEGLD LP';
            if (tokenId === 'ALP-0ed700') return 'HTM/JWLHTM LP';
            if (tokenId === 'ALP-0f46fa') return 'JWLEGLD/EPUNKS LP';
            if (tokenId === 'ALP-487964') return 'JWLUSD/SDAI LP';
            if (tokenId === 'ALP-19d6c0') return 'USDC/USDT LP';
            if (tokenId === 'ALP-8d8415') return 'USDC/EGLD LP';
            if (tokenId === 'ALP-45512a') return 'USDT/WDAI/JWLUSD LP';
            if (tokenId === 'ALP-25b383') return 'JWLUSD/JWLEGLD LP';
            if (tokenId === 'ALP-fe21d9') return 'USDC/JWLUSD LP';
            if (tokenId === 'ALP-20179e') return 'UTK/JWLUTK LP';
            if (tokenId === 'ALP-deda92') return 'MEX/JWLMEX LP';
            if (tokenId === 'ALP-1d3ebc') return 'APUSDC/USDC LP';
            if (tokenId === 'ALP-2ff298') return 'USDC/TAO LP';
            if (tokenId === 'ALP-ba9b1b') return 'WBTC/JWLBTC LP';
            if (tokenId === 'ALP-e05567') return 'WETH/JWLETH LP';
            if (tokenId === 'ALP-a82580') return 'JWLTAO/WTAO LP';
            if (tokenId === 'ALP-5b3202') return 'APUSDC/JWLAPUSDC LP';
            if (tokenId === 'ALP-c18005') return 'RSEGLD/EGLD LP';
        }

        if (tokenId.includes('-')) {
            const tokenTicker = tokenId.split('-')[0];
            if (tokenTicker === 'WEGLD') return 'EGLD';
            if (tokenTicker === 'WDAI') return 'DAI';
            if (tokenTicker === 'WSDAI') return 'SDAI';
            return tokenTicker;
        }
    }

    return tokenId;
};

export const getTokenDecimal = (tokenId: string) => {
    if (tokenId) {
        if (tokenId === 'EGLD') {
            return 18;
        }

        if (tokenId.includes('-')) {
            const tokenTicker = tokenId.split('-')[0];

            if (tokenTicker === 'WEGLD') return 18;
            if (tokenTicker === 'USDT') return 6;
            if (tokenTicker === 'USDC') return 6;
            if (tokenTicker === 'BUSD') return 18;
            if (tokenTicker === 'ASH') return 18;
            if (tokenTicker === 'ALP') return 18;
            if (tokenTicker === 'JWLASH') return 18;
            if (tokenTicker === 'ONE') return 18;
            if (tokenTicker === 'ONEWEGLD') return 18;
            if (tokenTicker === 'JWLEGLD') return 18;
            if (tokenTicker === 'JWLUSD') return 18;
            if (tokenTicker === 'SJWLUSD') return 18;
            if (tokenTicker === 'SEGLD') return 18;
            if (tokenTicker === 'HTM') return 18;
            if (tokenTicker === 'JWLHTM') return 18;
            if (tokenTicker === 'JIEGLD') return 18;
            if (tokenTicker === 'JISEGLD') return 18;
            if (tokenTicker === 'JIUSDT') return 6;
            if (tokenTicker === 'JIUSDC') return 6;
            if (tokenTicker === 'JIBUSD') return 18;
            if (tokenTicker === 'HEGLD') return 8;
            if (tokenTicker === 'HSEGLD') return 8;
            if (tokenTicker === 'HUSDC') return 8;
            if (tokenTicker === 'HUSDT') return 8;
            if (tokenTicker === 'HBUSD') return 8;
            if (tokenTicker === 'WDAI') return 18;
            if (tokenTicker === 'WSDAI') return 18;
            if (tokenTicker === 'UTK') return 18;
            if (tokenTicker === 'WBTC') return 8;
            if (tokenTicker === 'WETH') return 18;
            if (tokenTicker === 'EPUNKS') return 18;
            if (tokenTicker === 'APUSDC') return 6;
            if (tokenTicker === 'WTAO') return 9;
            if (tokenTicker === 'JWLETH') return 18;
            if (tokenTicker === 'JWLBTC') return 18;
            if (tokenTicker === 'JWLXMEX') return 18;
            if (tokenTicker === 'RSEGLD') return 18;
        }
    }

    return 18;
};

export const getMmTokenTicker = (tokenId: string) => {
    if (tokenId) {
        if (tokenId.substring(0, 1) === 'S') {
            return tokenId.slice(1, tokenId.length);
        }

        if (tokenId.substring(0, 1) === 'H') {
            return tokenId.slice(1, tokenId.length);
        }

        if (tokenId.substring(0, 2) === 'JI') {
            return tokenId.slice(2, tokenId.length);
        }
    }

    return tokenId;
};

export const getHatomBaseTokenId = (htokenTicker: string) => {
    if (htokenTicker.substring(0, 1) === 'H') {
        const baseTokenTicker = htokenTicker.slice(1, htokenTicker.length);
        if (baseTokenTicker === 'EGLD') return 'EGLD';
        if (baseTokenTicker === 'SEGLD') return SEGLD_TOKEN_ID;
        if (baseTokenTicker === 'USDC') return USDC_TOKEN_ID;
        if (baseTokenTicker === 'USDT') return USDT_TOKEN_ID;
    }

    return htokenTicker;
};

export const getFarmTokenIds = (tokenIds: string[], lpTokenId: string) => {
    const farmTokenIds = tokenIds.filter((tokenId: string) => tokenId !== lpTokenId);

    return farmTokenIds;
};

export const getTokenUsdcPrice = (tokenId: string, amount: string, onedexPairInfos: any[], exchangePairInfos: any[]): string => {
    try {
        if (tokenId === 'EGLD') {
            tokenId = WEGLD_TOKEN_ID;
        }

        const exchange_token_info = exchangePairInfos.filter((item: any) => item.fsymTokenIdentifier === tokenId && item.tsym === 'USDC');
        if (exchange_token_info.length > 0) {
            return convertWeiToEsdt(amount, getTokenDecimal(tokenId)).multipliedBy(exchange_token_info[0].value).toFixed();
        }

        const token_info = onedexPairInfos.filter((item: any) => item.fsymTokenIdentifier === tokenId && item.tsym === 'USDC');
        if (token_info.length > 0) {
            return convertWeiToEsdt(amount, getTokenDecimal(tokenId)).multipliedBy(token_info[0].value).toFixed();
        }

        return '0';
    } catch (error) {
        return '0';
    }
};

export const getOnedexDevnetTokenPrice = (tokenId: string): number => {
    if (getTokenTicker(tokenId) === 'LEGLD') return 40;
    if (getTokenTicker(tokenId) === 'USDT') return 1;
    return 0.2;
};
