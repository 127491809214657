import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetNetworkConfig, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Dropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { Spinner } from 'components/Spinner';
import {
  isDev,
  RSEGLD_TOKEN_ID,
  SRSEGLD_TOKEN_ID,
  WEGLD_TOKEN_ID,
} from 'config';
import { getUserTokenBalance } from 'z/api';
import {
  LiquidRestakingContract,
  LiquidRestakingPoolContext,
  LiquidRestakingStatsContext,
} from 'z/elrond';
import {
  convertBigNumberValueToLocalString,
  convertEsdtToWei,
  convertWeiToEsdt,
  ERROR_CONNECT_YOUR_WALLET,
  ERROR_INVALID_VALUE,
  getTokenDecimal,
  getTokenImage,
  getTokenTicker,
  toastError,
} from 'z/utils';
import { StakeInput } from '../Stake/StakeInput';
import { getChainId } from 'z/elrond/sdkDappHelpers';

export const Restake = () => {
  const {
    network: { apiAddress },
  } = useGetNetworkConfig();
  const isLoggedIn = useGetIsLoggedIn();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const liquidRestakingContract = new LiquidRestakingContract();
  const [statsContext, setStatsContext] = useState<LiquidRestakingStatsContext>();
  const [pools, setPools] = useState<LiquidRestakingPoolContext[]>([]);
  const [tokenIds, setTokenIds] = useState<string[]>(['EGLD']);

  const chainID = getChainId();
    const { address, balance } = useGetAccount();

  useEffect(() => {
    (async () => {
      const _stats = await liquidRestakingContract.viewStatsContext();
      console.log('stats', _stats);
      setStatsContext(_stats);
    })();
  }, [hasPendingTransactions]);
  useEffect(() => {
    (async () => {
      const _pools = await liquidRestakingContract.viewPoolContexts();
      console.log('pools', _pools);
      setPools(_pools);

      const _tokenIds = _pools.map((pool) => pool.token_id);
      setTokenIds(_tokenIds);
    })();
  }, []);

  const [selectedTokenId, setSelectedTokenId] = useState<string>(WEGLD_TOKEN_ID);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState<string>('0');
  const [rsegldBalance, setRsegldBalance] = useState<string>('0');
  const [srsegldBalance, setSrsegldBalance] = useState<string>('0');

  useEffect(() => {
    if (!address || hasPendingTransactions) return;
    if (selectedTokenId == WEGLD_TOKEN_ID) {
      setSelectedTokenBalance(balance);
    } else {
      (async () => {
        const _balanceInfos = await getUserTokenBalance(apiAddress, address, [selectedTokenId]);
        if (_balanceInfos.length === 0) {
          setSelectedTokenBalance('0');
        } else {
          setSelectedTokenBalance(_balanceInfos[0] && _balanceInfos[0].balance ? _balanceInfos[0].balance : '0');
        }
      })();
    }
  }, [selectedTokenId, address, hasPendingTransactions]);

  useEffect(() => {
    if (!address || hasPendingTransactions) return;
    (async () => {
      const _balanceInfos = await getUserTokenBalance(apiAddress, address, [RSEGLD_TOKEN_ID, SRSEGLD_TOKEN_ID]);
      
      let _rsegldBalance = '0';
      let _srsegldBalance = '0';
      for (const item of _balanceInfos) {
          if (item.identifier === RSEGLD_TOKEN_ID && item.balance) {
            _rsegldBalance = item.balance;
          } else if (item.identifier === SRSEGLD_TOKEN_ID && item.balance) {
            _srsegldBalance = item.balance;
          }
      }
      setRsegldBalance(_rsegldBalance);
      setSrsegldBalance(_srsegldBalance);
    })();
  }, [address, hasPendingTransactions]);

  const [apy, setApy] = useState<number>(0);
  const [realApr, setRealApr] = useState<number>(0);
 
  const [isMintExpanded, setIsMintExpanded] = useState<boolean>(false);
  const [mintInputAmount, setMintInputAmount] = useState<string>('0');
  const [mintInputAmountError, setMintInputAmountError] = useState<string>('');
  const [redeemInputAmount, setRedeemInputAmount] = useState<string>('0');
  const [redeemInputAmountError, setRedeemInputAmountError] = useState<string>('');

  const [isStakeExpanded, setIsStakeExpanded] = useState<boolean>(false);
  const [stakeInputAmount, setStakeInputAmount] = useState<string>('0');
  const [stakeInputAmountError, setStakeInputAmountError] = useState<string>('');
  const [unstakeInputAmount, setUnstakeInputAmount] = useState<string>('0');
  const [unstakeInputAmountError, setUnstakeInputAmountError] = useState<string>('');

  const handleMintExpand = () => {
    cleanMintAmount();

    setIsMintExpanded(!isMintExpanded);
  };

  const handleStakeExpand = () => {
      cleanStakeAmount();

      setIsStakeExpanded(!isStakeExpanded);
  };

  const cleanMintAmount = () => {
      setMintInputAmount('0');
      setMintInputAmountError('');
      setRedeemInputAmount('0');
      setRedeemInputAmountError('');
  };

  const cleanStakeAmount = () => {
      setStakeInputAmount('0');
      setStakeInputAmountError('');
      setUnstakeInputAmount('0');
      setUnstakeInputAmountError('');
  };

  async function handleMint() {
    console.log('started');
    let error = '';
    if (!isLoggedIn) {
      error = ERROR_CONNECT_YOUR_WALLET;
    } else if (BigNumber(mintInputAmount).comparedTo(0) <= 0) {
      error = ERROR_INVALID_VALUE;
    } else if (mintInputAmountError) {
      error = mintInputAmountError;
    }
    console.log('worked');

    if (error) {
      toastError(error);
      return;
    }

    if (selectedTokenId == WEGLD_TOKEN_ID) {
      await liquidRestakingContract.mintRsegldFromEgld(address, chainID, convertEsdtToWei(mintInputAmount));
    } else {
      await liquidRestakingContract.mintRsegld(address, chainID,
        TokenTransfer.fungibleFromBigInteger(selectedTokenId, convertEsdtToWei(mintInputAmount, getTokenDecimal(selectedTokenId)))
      );
    }
  }

  async function handleStake() {
    let error = '';
    if (!isLoggedIn) {
      error = ERROR_CONNECT_YOUR_WALLET;
    } else if (BigNumber(stakeInputAmount).comparedTo(0) <= 0) {
      error = ERROR_INVALID_VALUE;
    } else if (stakeInputAmountError) {
      error = stakeInputAmountError;
    }

    if (error) {
      toastError(error);
      return;
    }

    await liquidRestakingContract.stakeRsegld(address, chainID, 
        TokenTransfer.fungibleFromBigInteger(RSEGLD_TOKEN_ID, convertEsdtToWei(stakeInputAmount)));
  
    // await liquidRestakingContract.stakeRsegld(address,chainID,
    //     convertEsdtToWei(stakeInputAmount).toFixed()
    // );
  }

  async function handleUnstake() {
    let error = '';
    if (!isLoggedIn) {
      error = ERROR_CONNECT_YOUR_WALLET;
    } else if (BigNumber(unstakeInputAmount).comparedTo(0) <= 0) {
      error = ERROR_INVALID_VALUE;
    } else if (unstakeInputAmountError) {
      error = unstakeInputAmountError;
    }

    if (error) {
      toastError(error);
      return;
    }

    await liquidRestakingContract.unstakeRsegld(address,chainID,
      TokenTransfer.fungibleFromBigInteger(SRSEGLD_TOKEN_ID, convertEsdtToWei(unstakeInputAmount))
    );
  }

  return (
      <div className='container' style={{ marginTop: '40px' }}>
          <div className='info-item'>
              <div className='d-flex justify-content-between align-items-center'>
                  <div className='title'>
                      <span>Restake</span>
                  </div>
                  <div className='egld-logo'>
                      <img src={egld_white_logo} />
                  </div>
              </div>
              <div>

              <div className='d-flex mt-2'>
                  {`rsEGLD, Restaked EGLD is a Liquid Restaking Token (LRT) allowing users to stake their Native EGLD, Liquid Staked EGLD or EGLD-pegged LPs.`}
              </div>
              <div className='d-flex mt-1'>
                  <span>
                      {`Mint for rsEGLD 1:1 which will be sent for JewelSwap Ashswap Farms, JewelSwap/Hatom Money Markets supply, collateral activation when able to. A dynamic % will also be sent for LP creation. Assets will also be used for Sovereign Chains staking when able to. Stake rsEGLD for srsEGLD which will accrue Sovereign Shards staking yield once Sovereign Chains come into play. Redemption will be implemented eventually after Sovereign Shards launch and a redemption fee of 0.5% onwards may be charged subsequently. You will be able to swap EGLD-rsEGLD at Ashswap. `}
                      <a href='https://app.ashswap.io/swap/' target='_blank' rel='noreferrer'>Ashswap.</a>
                  </span>
              </div>
              {
                  statsContext ? (
                      <>
                          <div className='row mt-4' style={{ justifyContent: "center" }}>
                              <Accordion
                                  expanded={isMintExpanded}
                              >
                                  <AccordionSummary
                                      className={`col-12`}
                                      sx={{ cursor: 'auto !important' }}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                  >
                                      <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                          <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                              <Dropdown className='stable-dropdown'>
                                                  <Dropdown.Toggle id='dropdown-basic-button' className='d-flex'>
                                                      <div className='d-flex align-items-center'>
                                                          <img src={getTokenImage(selectedTokenId)} className='egld-image mx-2' alt={selectedTokenId}></img>
                                                          <div>{getTokenTicker(selectedTokenId)}</div>
                                                      </div>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                      {
                                                        tokenIds.map((tokenId: string, index: number) => (
                                                          <Dropdown.Item className='d-flex align-items-center' key={index} onClick={() => setSelectedTokenId(tokenId)}>
                                                            <img src={getTokenImage(tokenId)} alt={tokenId}></img>
                                                            <div className='token-id'>{getTokenTicker(tokenId)}</div>
                                                          </Dropdown.Item>
                                                        ))
                                                      }
                                                  </Dropdown.Menu>
                                              </Dropdown>
                                          </div>
                                          <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                              <div>{`TVL`}</div>
                                              <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(0, getTokenDecimal(selectedTokenId)))} rsEGLD`}</div>
                                          </div>
                                          <div className='col-lg-2 col-md-2 col-sm-12'></div>
                                          <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                              <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                                  <div className='d-flex align-items-center'>Your balance</div>
                                                  <div className='text-end'>
                                                      <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(selectedTokenBalance, getTokenDecimal(selectedTokenId)))} ${getTokenTicker(selectedTokenId)}` : '-'}</div>
                                                      <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(rsegldBalance))} rsEGLD` : '-'}</div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                              <div
                                                  className="px-2 py-2 d-flex justify-content-center align-items-center"
                                                  style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                                  onClick={handleMintExpand}
                                              >
                                                  <div className='d-flex'>Mint</div>
                                                  {isMintExpanded ? (
                                                      <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                                  ) : (
                                                      <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                                  )}
                                              </div>
                                          </div>
                                      </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                      className='row'
                                      sx={{ padding: '0px 16px 16px !important' }}
                                  >
                                      <div className='col-lg-6 col-md-6 col-sm-12'>
                                          <div className='row'>
                                              <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                                  <div className='d-flex'>
                                                      {`Avail ${getTokenTicker(selectedTokenId)} Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(selectedTokenBalance, getTokenDecimal(selectedTokenId))) : '-'} ${getTokenTicker(selectedTokenId)}`}
                                                  </div>
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                                  <StakeInput
                                                      userBalance={selectedTokenBalance}
                                                      inputAmount={mintInputAmount}
                                                      amountError={mintInputAmountError}
                                                      setInputAmount={setMintInputAmount}
                                                      setAmountError={setMintInputAmountError}
                                                      isUnstake={false}
                                                      tokenId={selectedTokenId}
                                                  />
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                                  <div className='d-flex'>
                                                      <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleMint}>
                                                          {`Mint`}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                                  <div className='d-flex'>
                                                      {`Avail rsEGLD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(rsegldBalance)) : '-'} rsEGLD`}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </AccordionDetails>
                              </Accordion>
                          </div>
                          <div className='row mt-4' style={{ justifyContent: "center" }}>
                              <Accordion
                                  expanded={isStakeExpanded}
                              >
                                  <AccordionSummary
                                      className={`col-12`}
                                      sx={{ cursor: 'auto !important' }}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                  >
                                      <div className={`stake-pool-container w-100 ${isMobile ? '' : 'row'}`}>
                                          <div className='col-lg-3 col-md-3 col-sm-12 align-self-center'>
                                              <div className='d-flex align-items-center'>
                                                  <img src={getTokenImage(RSEGLD_TOKEN_ID)} className='egld-image mx-2' alt='ash'></img>
                                                  <div>
                                                      <div>{`rsEGLD`}</div>
                                                      <div style={{ fontSize: '0.8rem' }}>{`1 srsEGLD = ${BigNumber(statsContext.srsegld_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(BigNumber(statsContext.rsegld_pool_reserve).dividedBy(statsContext.srsegld_pool_reserve), 5) : '-'} rsEGLD`}</div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                              <div>{`TVL`}</div>
                                              <div className=''>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(statsContext.srsegld_pool_reserve))} srsEGLD`}</div>
                                          </div>
                                          <div className={`col-lg-2 col-md-2 col-sm-12 ${isMobile ? 'd-flex justify-content-between mt-2' : 'align-self-center'}`}>
                                              <div>{`APY / APR(24H)`}</div>
                                              {
                                                  isDev ? (
                                                      <div className='avaliable-color'>{`${apy == 0 ? '-' : apy} %`}</div>
                                                  ) : (
                                                      <div className='avaliable-color'>{`${apy == 0 ? '-' : apy}% / ${convertBigNumberValueToLocalString(realApr)}%`}</div>
                                                  )
                                              }
                                          </div>
                                          <div className={`col-lg-3 col-md-3 col-sm-12 align-self-center`}>
                                              <div className={`d-flex justify-content-between ${isMobile ? 'mt-1' : ''}`}>
                                                  <div className='d-flex align-items-center'>Your balance</div>
                                                  <div className='text-end'>
                                                      <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(rsegldBalance))} rsEGLD` : '-'}</div>
                                                      <div>{isLoggedIn ? `${convertBigNumberValueToLocalString(convertWeiToEsdt(srsegldBalance))} srsEGLD (= ${BigNumber(statsContext.srsegld_pool_reserve).gt('0') ? convertBigNumberValueToLocalString(convertWeiToEsdt(BigNumber(srsegldBalance).multipliedBy(statsContext.rsegld_pool_reserve).dividedBy(statsContext.srsegld_pool_reserve))) : '0'} rsEGLD)` : '-'}</div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className={`col-lg-2 col-md-2 col-sm-12 d-flex align-items-center ${isMobile ? 'mt-4' : ''}`}>
                                              <div
                                                  className="px-2 py-2 d-flex justify-content-center align-items-center"
                                                  style={{ cursor: 'pointer', width: '100%', borderWidth: '1px', borderColor: 'rgba(84, 245, 183, 0.6)', borderStyle: 'solid', borderRadius: '5px' }}
                                                  onClick={handleStakeExpand}
                                              >
                                                  <div className='d-flex'>Stake / Unstake</div>
                                                  {isStakeExpanded ? (
                                                      <KeyboardArrowUpIcon sx={{ color: 'white' }} />
                                                  ) : (
                                                      <KeyboardArrowDownIcon sx={{ color: 'white' }} />
                                                  )}
                                              </div>
                                          </div>
                                      </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                      className='row'
                                      sx={{ padding: '0px 16px 16px !important' }}
                                  >
                                      <div className='col-lg-6 col-md-6 col-sm-12'>
                                          <div className='row'>
                                              <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 1 : 1 }}>
                                                  <div className='d-flex'>
                                                      {`Avail rsEGLD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(rsegldBalance)) : '-'} rsEGLD`}
                                                  </div>
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 2 : 3 }}>
                                                  <StakeInput
                                                      userBalance={rsegldBalance}
                                                      inputAmount={stakeInputAmount}
                                                      amountError={stakeInputAmountError}
                                                      setInputAmount={setStakeInputAmount}
                                                      setAmountError={setStakeInputAmountError}
                                                      isUnstake={false}
                                                      tokenId={RSEGLD_TOKEN_ID}
                                                  />
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 3 : 5 }}>
                                                  <div className='d-flex'>
                                                      <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleStake}>
                                                          {`Stake`}
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12 mt-3' style={{ order: isMobile ? 4 : 2 }}>
                                                  <div className='d-flex'>
                                                      {`Avail srsEGLD Balance: ${isLoggedIn ? convertBigNumberValueToLocalString(convertWeiToEsdt(srsegldBalance)) : '-'} srsEGLD`}
                                                  </div>
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12' style={{ order: isMobile ? 5 : 4 }}>
                                                  <StakeInput
                                                      userBalance={srsegldBalance}
                                                      inputAmount={unstakeInputAmount}
                                                      amountError={unstakeInputAmountError}
                                                      setInputAmount={setUnstakeInputAmount}
                                                      setAmountError={setUnstakeInputAmountError}
                                                      isUnstake={true}
                                                      tokenId={SRSEGLD_TOKEN_ID}
                                                  />
                                              </div>
                                              <div className='col-lg-6 col-md-6 col-sm-12 mt-1' style={{ order: isMobile ? 6 : 6 }}>
                                                  <div className='d-flex'>
                                                      <div className="eg-btn btn--primary2 capsule px-4 py-2" style={{ cursor: 'pointer' }} onClick={handleUnstake}>
                                                          {`Unstake`}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className='col-lg-6 col-md-6 col-sm-12'>
                                      </div>
                                  </AccordionDetails>
                              </Accordion>
                          </div>
                      </>
                  ) : (
                      <div className='mt-5'>
                          <Spinner />
                      </div>
                  )}
            </div>
          </div>
      </div >
  );
};
