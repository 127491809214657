import { Address, AddressValue, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, Transaction, TransactionPayload, TypedValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { JEWEL_LIQUID_STAKE_SC_ADDRESS, JEWEL_LIQUID_STAKE_GAS_LIMIT, JEWEL_LIQUID_DEPOSIT_GAS_LIMIT, JEWEL_LIQUID_UNSTAKE_GAS_LIMIT } from 'config';
import { NftWithIndexType } from 'z/types';
import { NFT_QUANTITY } from 'z/utils';
import { getChainId } from '../sdkDappHelpers';

export const sendJewelLiquidStake = async (chainID: string, address: string, stakeAmount: string) => {

  const data = new TransactionPayload('mintJwlegld');
  console.log(chainID);
  console.log(getChainId());
  const tx = new Transaction({
    value: stakeAmount,
    sender: new Address(address),
    receiver: new Address(JEWEL_LIQUID_STAKE_SC_ADDRESS),
    gasLimit: 250_000_000,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Mint Request',
      errorMessage: 'Error occured during Mint Request',
      successMessage: 'Mint Request Successful',
    }
  });
};

export const sendJewelLiquidUnstake = async (chainID: string, address: string, unstakeTokenId: string, unstakeAmount: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(unstakeTokenId),
    new BigUIntValue(unstakeAmount),
    new StringValue('redeemJwlegld'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_LIQUID_STAKE_SC_ADDRESS),
    gasLimit: JEWEL_LIQUID_UNSTAKE_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Redeem Request',
      errorMessage: 'Error occured during Redeem Request',
      successMessage: 'Redeem Request Successful',
    }
  });
};

export const sendJewelUnbond = async (chainID: string, address: string, nfts: NftWithIndexType[], scAddress: string) => {
  const args: TypedValue[] = [
    new AddressValue(new Address(scAddress)),
  ];
  args.push(new U32Value(nfts.length));
  for (const nft of nfts) {
    args.push(new TokenIdentifierValue(nft.collection));
    args.push(new U64Value(nft.nonce));
    args.push(new U64Value(NFT_QUANTITY));
  }
  args.push(new StringValue('withdraw'));

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`MultiESDTNFTTransfer@${argumentsString}`);

  let gasLimit = JEWEL_LIQUID_UNSTAKE_GAS_LIMIT + 10_000_000 * nfts.length;
  if (gasLimit > 600_000_000) {
    gasLimit = 600_000_000;
  }

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(address),
    gasLimit: gasLimit,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  const result = await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unbond Request',
      errorMessage: 'Error occured during Unbond Request',
      successMessage: 'Unbond Request Successful',
    }
  });

  return result;
};

export const sendJewelJwlEgldStake = async (chainID: string, address: string, stakeTokenId: string, stakeAmount: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(stakeTokenId),
    new BigUIntValue(stakeAmount),
    new StringValue('stakeJwlegld'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_LIQUID_STAKE_SC_ADDRESS),
    gasLimit: JEWEL_LIQUID_DEPOSIT_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Stake Request',
      errorMessage: 'Error occured during Stake Request',
      successMessage: 'Stake Request Successful',
    }
  });
};

export const sendJewelJwlEgldUnstake = async (chainID: string, address: string, unstakeTokenId: string, unstakeAmount: string) => {

  const args: TypedValue[] = [
    new TokenIdentifierValue(unstakeTokenId),
    new BigUIntValue(unstakeAmount),
    new StringValue('unstakeJwlegld'),
  ];
  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = new TransactionPayload(`ESDTTransfer@${argumentsString}`);

  const tx = new Transaction({
    value: '0',
    sender: new Address(address),
    receiver: new Address(JEWEL_LIQUID_STAKE_SC_ADDRESS),
    gasLimit: JEWEL_LIQUID_DEPOSIT_GAS_LIMIT,
    chainID: chainID,
    data,
  });

  await refreshAccount();
  await sendTransactions({
    transactions: tx,
    transactionsDisplayInfo: {
      processingMessage: 'Processing Unstake Request',
      errorMessage: 'Error occured during Unstake Request',
      successMessage: 'Unstake Request Successful',
    }
  });
};
